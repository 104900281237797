import { useState } from 'react';

import SortableTableHeader, { HeaderData, SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody } from 'Components/Table/Table/Table';
import { undefinedComparator } from 'Helpers/Compare';
import { RiskHistory, RiskResponse } from 'Models/RiskRegister';
import { DisplayableTag, tagsComparator } from 'Models/Tags';

import { RiskTableRow } from './RiskTableRow/RiskTableRow';

interface BaseProps {
    openLinksInNewTabs?: boolean;

    /**
     * If the table is being displayed for a specific control, this is the identifier of the control. The Control Environment column will be extended to include the control's weight for each mitigated Risk.
     */
    controlContext?: string;
}

interface CurrentRisksProps extends BaseProps {
    type: 'current risks';
    risks: RiskResponse[];
    getDisplayableTags: (tagIds: string[]) => DisplayableTag[];
}

interface AssessmentRisksProps extends BaseProps {
    type: 'assessment risks';
    riskHistories: RiskHistory[];

    /**
     * The timestamp of the assessment during which the risks were assessed.
     * The risk table will link to the history tab of the details pages for risks, passing along the timestamp so that the best-fit risk rating history will be auto-expanded.
     */
    assessmentTimestamp: string;
}

export type RiskTableProps = CurrentRisksProps | AssessmentRisksProps;

interface RisksTableState {
    currentSort: RiskSortFilterOptions;
    currentSortDirection: SortDirection;
}

enum RiskSortFilterOptions {
    TITLE = 'title',
    INHERENT_RISK = 'inherent_risk',
    CONTROL_ENVIRONMENT = 'control_environment',
    CURRENT_RISK = 'current_risk',
    TREATMENT_PLAN = 'treatment_plan_description',
    TARGET_RISK = 'target_risk',
    TAGS = 'tags',
    STRATEGY = 'strategy',
}

export const RiskTable = (props: RiskTableProps): JSX.Element => {
    const [risksTableState, setRisksTableState] = useState<RisksTableState>({ currentSort: RiskSortFilterOptions.TITLE, currentSortDirection: SortDirection.ASC });
    const items = props.type === 'current risks' ? props.risks : props.riskHistories;

    const headerValues: HeaderData[] = [
        {
            dataKey: RiskSortFilterOptions.TITLE,
            label: 'RISK',
        },
        {
            dataKey: RiskSortFilterOptions.INHERENT_RISK,
            label: 'INHERENT RISK',
        },
        {
            dataKey: RiskSortFilterOptions.CONTROL_ENVIRONMENT,
            label: 'CONTROL ENVIRONMENT',
        },
        {
            dataKey: RiskSortFilterOptions.CURRENT_RISK,
            label: 'CURRENT RESIDUAL RISK',
        },
        {
            dataKey: RiskSortFilterOptions.TREATMENT_PLAN,
            label: 'TREATMENT PLAN',
        },
        {
            dataKey: RiskSortFilterOptions.TARGET_RISK,
            label: 'TARGET RESIDUAL RISK',
        },
        {
            dataKey: RiskSortFilterOptions.STRATEGY,
            label: 'STRATEGY',
        },
        {
            dataKey: RiskSortFilterOptions.TAGS,
            label: 'TAGS',
        },
    ];

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: (newSort: string, newSortDirection: SortDirection) => setRisksTableState({ currentSort: newSort as RiskSortFilterOptions, currentSortDirection: newSortDirection }),
        currentSort: risksTableState.currentSort,
        currentSortDirection: risksTableState.currentSortDirection,
        tableIncludesOverflowMenu: false,
    };

    const sortRisks = (): RiskResponse[] | RiskHistory[] => {
        let sortResult = 0;

        return items.sort((riskA, riskB) => {
            switch (risksTableState.currentSort) {
                case RiskSortFilterOptions.TITLE:
                    sortResult = (riskA[risksTableState.currentSort] as string).localeCompare(riskB[risksTableState.currentSort] as string);
                    break;
                case RiskSortFilterOptions.TAGS:
                    sortResult = undefinedComparator(riskA.tags.length, riskB.tags.length, (valueA, valueB) => (valueA > valueB ? 1 : -1));
                    break;
                case RiskSortFilterOptions.TREATMENT_PLAN:
                    sortResult = undefinedComparator(riskA.treatment_plan_description, riskB.treatment_plan_description, (valueA, valueB) => valueA.localeCompare(valueB));
                    break;
                case RiskSortFilterOptions.STRATEGY:
                    sortResult = undefinedComparator(riskA.strategy?.title, riskB.strategy?.title, (valueA, valueB) => valueA.localeCompare(valueB));
                    break;
                case RiskSortFilterOptions.INHERENT_RISK:
                    sortResult = undefinedComparator(riskA.total_inherent_risk, riskB.total_inherent_risk, (valueA, valueB) => (valueA > valueB ? 1 : -1));
                    break;
                case RiskSortFilterOptions.CONTROL_ENVIRONMENT:
                    sortResult = undefinedComparator(riskA.control_environment_effectiveness, riskB.control_environment_effectiveness, (valueA, valueB) => (valueA > valueB ? 1 : -1));
                    break;
                case RiskSortFilterOptions.CURRENT_RISK:
                    sortResult = undefinedComparator(riskA.total_current_risk, riskB.total_current_risk, (valueA, valueB) => (valueA > valueB ? 1 : -1));
                    break;
                case RiskSortFilterOptions.TARGET_RISK:
                    sortResult = undefinedComparator(riskA.total_target_risk, riskB.total_target_risk, (valueA, valueB) => (valueA > valueB ? 1 : -1));
                    break;
                default:
                    sortResult = 0;
                    break;
            }
            return risksTableState.currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const tableRow = (risk: RiskResponse | RiskHistory): JSX.Element => {
        const controlMapping = props.controlContext && props.type === 'current risks' ? (risk as RiskResponse).controls.find((controlMapping) => controlMapping.control.identifier === props.controlContext) : undefined;
        const controlWeight = controlMapping ? controlMapping.weight : undefined;
        const tags = props.type === 'current risks' ? props.getDisplayableTags((risk as RiskResponse).tags) : (risk as RiskHistory).tags.sort(tagsComparator);
        const assessmentTimestamp = props.type === 'current risks' ? undefined : props.assessmentTimestamp;
        return <RiskTableRow key={risk.id} risk={risk} tags={tags} controlWeight={controlWeight} openLinksInNewTabs={props.openLinksInNewTabs} assessmentTimestamp={assessmentTimestamp} />;
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortRisks().map(tableRow)}</TableBody>
        </Table>
    );
};
