import { Table as MaterialTable, TableBody as MaterialTableBody, TableCell as MaterialTableCell, TableRow as MaterialTableRow } from '@mui/material';
import { Component, ReactNode } from 'react';

import { Link } from 'Components/Buttons/Buttons';

import styles from './Table.module.css';

export interface TableComponentWithChildren {
    children: ReactNode;
    className?: string;
}

/**
 * A simple table. Must have TableHead or TableBody as child.
 */
export default class Table extends Component<TableComponentWithChildren> {
    render(): JSX.Element {
        return (
            <div className={styles.tableWrapper}>
                <MaterialTable>{this.props.children}</MaterialTable>
            </div>
        );
    }
}

/**
 * A simple body for a table. Must have TableRow as child and Table as a parent.
 */
export class TableBody extends Component<TableComponentWithChildren> {
    render(): JSX.Element {
        return <MaterialTableBody sx={{ borderTop: 'none' }}>{this.props.children}</MaterialTableBody>;
    }
}

/**
 * A simple row for a table. Must have a Table Cell as child and TableBody or TableHeader as a parent.
 */
export class TableRow extends Component<TableComponentWithChildren> {
    render(): JSX.Element {
        return <MaterialTableRow sx={{ lineHeight: '12px' }}>{this.props.children}</MaterialTableRow>;
    }
}

const defaultTableCellStyle = { lineHeight: '12px', verticalAlign: 'center', padding: '.75rem' } as const;

/**
 * A simple cell for a Table. Must have TableRow as a parent. Anything can be it's child.
 * @param {string?} className Can pass CSS from parent component to child component. CSS must be declared in Parent module.
 */
export class TableCell extends Component<{ className?: string; children?: React.ReactNode }> {
    render(): JSX.Element {
        return (
            <MaterialTableCell className={this.props.className && this.props.className} sx={!this.props.className ? defaultTableCellStyle : {}}>
                {this.props.children}
            </MaterialTableCell>
        );
    }
}

/**
 * A cell only meant to wrap an OverflowMenu Component. Use TableCell for any other component.
 */
export class TableOverflowCell extends Component<TableComponentWithChildren> {
    render(): JSX.Element {
        return (
            <MaterialTableCell className={this.props.className} sx={{ padding: '.25rem 0rem', verticalAlign: 'center' }}>
                {this.props.children}
            </MaterialTableCell>
        );
    }
}

/**
 * A cell that will contain the default text for a cell. If not using the default styles use TableCell.
 */
export class TableCellDefaultText extends Component<TableComponentWithChildren> {
    render(): JSX.Element {
        return <MaterialTableCell sx={defaultTableCellStyle}>{this.props.children}</MaterialTableCell>;
    }
}
/**
 * A cell that will contain a link to another location. If creating a link for a modal, use TableCell.
 * @param {string} link The location in the webpage the user will navigate to on click.
 * @param {string} children The text of the link.
 */
export class TableCellLink extends Component<{ link: string; children: string }> {
    render(): JSX.Element {
        return (
            <MaterialTableCell sx={defaultTableCellStyle}>
                <Link to={this.props.link} size="sm">
                    {this.props.children}
                </Link>
            </MaterialTableCell>
        );
    }
}
