import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageCell from 'Components/Containers/PageCell/PageCell';
import Text from 'Components/Text/Text';
import { SERVICES, TPRM, VENDORS } from 'Config/Paths';
import { TprmDashboardServiceExtended } from 'Models/Dashboards';

import styles from './TPRMServiceCallouts.module.css';
import { SelectedServiceDetails, ServicesDisplayModal } from '../ServicesDisplayModal/ServicesDisplayModal';

enum Modals {
    NONE,
    DISPLAY_SERVICES,
}

export interface TPRMServiceCalloutsProps {
    vendorCount?: number;
    vendorServicesCount?: number;
    overdueAssessments?: TprmDashboardServiceExtended[];
    vendorServiceError?: string;
}

export const TPRMServiceCallouts = (props: TPRMServiceCalloutsProps): JSX.Element => {
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const navigate = useNavigate();

    const overDueAssessmentServices: SelectedServiceDetails[] | undefined = props.overdueAssessments?.map((assessment) => {
        return {
            serviceId: assessment.id,
            serviceName: assessment.name,
            vendorId: assessment.vendor_id,
            vendorName: assessment.vendor_name,
        };
    });

    const handleOverdueClick = () => {
        if (props.overdueAssessments) {
            setModalState(Modals.DISPLAY_SERVICES);
        }
    };

    return (
        <>
            {modalState === Modals.DISPLAY_SERVICES && overDueAssessmentServices && <ServicesDisplayModal hideModal={() => setModalState(Modals.NONE)} selectedServices={overDueAssessmentServices} subtitle={'Overdue Assessments'} />}
            <div className={styles.numberCallouts}>
                <PageCell variant="transparentBlue">
                    <div onClick={() => navigate(`/${TPRM}/${VENDORS}`)} className={styles.assessmentScheduleBox}>
                        <Text variant="Header2" color="white">
                            Total Vendors
                        </Text>
                        {props.vendorCount !== undefined ? (
                            <div className={styles.assessmentNumber}>{props.vendorCount}</div>
                        ) : (
                            <div className={styles.skeleton}>
                                <Skeleton>
                                    <div className={styles.assessmentNumber}>---</div>
                                </Skeleton>
                            </div>
                        )}
                    </div>
                </PageCell>
            </div>
            <div className={styles.numberCallouts}>
                <PageCell variant="transparentBlue">
                    <div onClick={() => navigate(`/${TPRM}/${SERVICES}`)} className={styles.assessmentScheduleBox}>
                        <Text variant="Header2" color="white">
                            Total Vendor Services
                        </Text>
                        {props.vendorServicesCount !== undefined ? (
                            <div className={styles.assessmentNumber}>{props.vendorServicesCount}</div>
                        ) : (
                            <div className={styles.skeleton}>
                                <Skeleton>
                                    <div className={styles.assessmentNumber}>---</div>
                                </Skeleton>
                            </div>
                        )}
                    </div>
                </PageCell>
            </div>
            <div className={styles.numberCallouts}>
                <PageCell variant="transparentBlue">
                    <div onClick={handleOverdueClick} className={styles.assessmentScheduleBox}>
                        <Text variant="Header2" color="white">
                            Overdue Vendor Service Assessments
                        </Text>
                        {props.overdueAssessments ? (
                            <div className={props.overdueAssessments.length > 0 ? styles.assessmentNumberOverdue : styles.assessmentNumber}>{props.overdueAssessments.length}</div>
                        ) : (
                            <div className={styles.skeleton}>
                                <Skeleton>
                                    <div className={styles.assessmentNumber}>---</div>
                                </Skeleton>
                            </div>
                        )}
                    </div>
                </PageCell>
            </div>
        </>
    );
};
