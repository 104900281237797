import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import PageCell from 'Components/Containers/PageCell/PageCell';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import FormFieldUserMultiSelect from 'Components/FormField/FormFieldUserSelect/FormFieldUserMultiSelect';
import FormFieldUserSelect from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Chip } from 'Components/Text/Chip';
import Text from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { getFrameworkGroupControlParts, getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getNextDueDate } from 'Helpers/ScheduleFrequency/ScheduleFrequency';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { UpdateAssessmentConfigurationRequest } from 'Models/Configuration';
import { ValidationError } from 'Models/ErrorTypes';
import { OperationalControl } from 'Models/OperationalControls';
import { ScheduleFrequency, ScheduleFrequencySelectOptions } from 'Models/ScheduleFrequency';
import { UserResponse } from 'Models/User';

import styles from './ControlAssessmentTab.module.css';
import { ControlDeleted } from '../ControlSettings';

export interface ControlAssessmentTabProps {
    users: UserResponse[];
    controlsApi: ControlsApi;
    detailedControlResponse: OperationalControl;
    assessmentConfigurationUpdated: () => Promise<void>;
}

interface FormFieldsState {
    reviewer?: UserResponse;
    owner?: UserResponse;
    delegates?: UserResponse[];
    reviewScheduleNumber?: number;
    reviewScheduleFrequency?: 'DAYS' | 'MONTHS' | 'YEARS';
    reviewScheduleStartDate?: Date;
}

enum Modal {
    Delete,
    None,
}
const ControlAssessmentTab = ({ controlIsDeleted = false, ...props }: ControlAssessmentTabProps & ControlDeleted) => {
    const [isUpdatingControl, setIsUpdatingControl] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [scheduleWasChanged, setScheduleWasChanged] = useState<boolean>(false); // This is used to render a badge showing the user the next assessment due date when a change to the schedule is made. We don't want it present all the time since the purpose is to serve as a warning/confirmation that they intend to change the schedule and verify that the next due date is what they expect.
    const [displayedModal, setDisplayedModal] = useState<Modal>(Modal.None);

    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({});

    useEffect(() => {
        setFormFieldsState({
            reviewer: props.users.find((user) => user.cognito_subject === props.detailedControlResponse.configuration.review_configuration?.reviewer_subject),
            reviewScheduleNumber: props.detailedControlResponse.configuration.review_configuration?.review_schedule_number,
            reviewScheduleFrequency: props.detailedControlResponse.configuration.review_configuration?.review_schedule_frequency,
            reviewScheduleStartDate: props.detailedControlResponse.configuration.review_configuration?.review_schedule_start_date ? new Date(props.detailedControlResponse.configuration.review_configuration.review_schedule_start_date) : undefined,
            owner: props.users.find((user) => user.cognito_subject === props.detailedControlResponse.configuration.review_configuration?.owner_subject),
            delegates: props.users.filter((user) => props.detailedControlResponse.configuration.review_configuration?.delegate_subjects?.includes(user.cognito_subject)),
        });
    }, [props.detailedControlResponse, props.users]);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
        setScheduleWasChanged(true);
    };

    // Updates the start date of the control review schedule when it changes. This needs to be different from handleChange() since it is not a standard HTML input field.
    const handleChangeDate = (reviewScheduleStartDate: Date): void => {
        setFormFieldsState({ ...formFieldsState, reviewScheduleStartDate: reviewScheduleStartDate });
        setScheduleWasChanged(true);
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
        setScheduleWasChanged(true);
    };

    const handleSelectUserChange = (user: UserResponse | undefined, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: user });
    };

    const handleSelectUsersChange = (users: UserResponse[] | undefined, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: users });
    };

    // Update the Control's assessment configuration.
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setIsUpdatingControl(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            validateAssessmentConfiguration(formFieldsState.owner, formFieldsState.reviewer, formFieldsState.reviewScheduleNumber, formFieldsState.reviewScheduleFrequency as ScheduleFrequency, formFieldsState.reviewScheduleStartDate);
            const controlAssessmentConfiguration: UpdateAssessmentConfigurationRequest = {
                reviewer_subject: formFieldsState.reviewer?.cognito_subject,
                owner_subject: formFieldsState.owner!.cognito_subject,
                delegate_subjects: formFieldsState.delegates?.map((user) => user.cognito_subject),
                review_schedule_number: formFieldsState.reviewScheduleNumber!.toString(),
                review_schedule_frequency: formFieldsState.reviewScheduleFrequency! as ScheduleFrequency,
                review_schedule_start_date: jsDateToIso8601(formFieldsState.reviewScheduleStartDate!),
            };
            const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(props.detailedControlResponse);
            await props.controlsApi.updateControlAssessmentConfiguration(controlFramework, controlGroupId, controlId, controlAssessmentConfiguration);
            setSuccessMessage('Control assessment configuration updated.');
            setFailureMessage(undefined);
            await props.assessmentConfigurationUpdated();
        } catch (e) {
            if (e instanceof ValidationError) {
                handleRequestError(e);
            } else {
                handleRequestError(e);
            }
        } finally {
            setIsUpdatingControl(false);
        }
    };

    // Validate the values in the Edit Control form.
    const validateAssessmentConfiguration = (controlOwner?: UserResponse, controlReviewer?: UserResponse, controlReviewScheduleNumber?: number, controlReviewScheduleFrequency?: ScheduleFrequency, reviewScheduleStartDate?: Date): void => {
        // TODO: Validation for checking the assessment status of a control when the reviewer of that control is removed needs to be implemented. Initial attempt shows complication in the call to get the assessment when the control has not yet been configured for review.

        if (!controlOwner || !controlOwner.cognito_subject) {
            throw new ValidationError('Control Owner is required.');
        }
        if (controlOwner.cognito_subject === controlReviewer?.cognito_subject) {
            throw new ValidationError('Owner and Reviewer cannot be the same user.');
        }
        if (!controlReviewScheduleNumber) {
            throw new ValidationError('Control Review Schedule is required.');
        }
        if (!controlReviewScheduleFrequency) {
            throw new ValidationError('Control Review Schedule is required.');
        }
        if (!reviewScheduleStartDate) {
            throw new ValidationError('Control Review Schedule is required.');
        }
    };

    // Error handler when exceptions are thrown.
    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    if (controlIsDeleted) {
        return <Text>This control and the associated assessment configuration has been deleted.</Text>;
    }

    const nextAssessmentDate = () => {
        if (formFieldsState.reviewScheduleStartDate && formFieldsState.reviewScheduleNumber && formFieldsState.reviewScheduleFrequency) {
            return getNextDueDate(formFieldsState.reviewScheduleStartDate, formFieldsState.reviewScheduleNumber.toString(), formFieldsState.reviewScheduleFrequency as ScheduleFrequency);
        }
    };

    const deleteControlAssessmentConfiguration = async (): Promise<string> => {
        const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(props.detailedControlResponse);
        await props.controlsApi.deleteControlAssessmentConfiguration(controlFramework, controlGroupId, controlId);
        await props.assessmentConfigurationUpdated();
        return 'Control assessment settings deleted.';
    };

    return (
        <>
            {displayedModal === Modal.Delete && (
                <ConfirmationModal operationType="delete" areYouSureText="Are you sure you want to delete assessment settings for this control?" headerText="Delete Assessment Settings" performOperation={deleteControlAssessmentConfiguration} hideModal={() => setDisplayedModal(Modal.None)}>
                    <Text>{getHumanReadableControlIdFromControl(props.detailedControlResponse)}</Text>
                </ConfirmationModal>
            )}
            <PageCell>
                <Form noValidate onSubmit={handleSubmit}>
                    {successMessage && <LinkButtonToast variant="success" clearToast={() => setSuccessMessage(undefined)} linkButtonText={'Return to control'} linkButtonTo={getFrameworkGroupControlURL(props.detailedControlResponse.identifier)} text={successMessage} />}
                    {failureMessage && <TextToast variant="failure" clearToast={() => setFailureMessage(undefined)} text={failureMessage} />}
                    <ModalHeader text="Roles" />
                    <Text>If a reviewer is assigned, there will be an approval step when assessing this control.</Text>
                    <div className={styles.formRoleContainer}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldUserSelect users={props.users} onUserSelected={handleSelectUserChange} formFieldId="owner" selectedUser={formFieldsState.owner} formFieldLabel="Control Owner" isRequiredField={true} tooltip={'The individual responsible for ensuring the effectiveness of the control.'} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldUserSelect users={props.users} onUserSelected={handleSelectUserChange} formFieldId="reviewer" selectedUser={formFieldsState.reviewer} formFieldLabel="Control Reviewer" tooltip={'The individual responsible for continuous oversight of the control.'} isClearable />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldUserMultiSelect users={props.users} onUsersSelected={handleSelectUsersChange} formFieldId="delegates" selectedUsers={formFieldsState.delegates} formFieldLabel="Control Delegates" tooltip={'Delegates of the control owner. Responsible for testing control effectiveness.'} />
                        </div>
                    </div>
                    <ModalHeader text="Schedule" />
                    <div className={styles.formReviewContainer}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldDatePicker dateFormat="MM/dd/yyyy" selected={formFieldsState.reviewScheduleStartDate} handleChange={handleChangeDate} formFieldId="reviewScheduleStartDate" formFieldLabel="Start Date" placeholder={'MM/DD/YYYY'} invalidMessage={'Please enter a valid date (MM/DD/YYYY)'} required={true} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="reviewScheduleNumber" formFieldLabel="Frequency" required={true} tooltip="An integer representing how often the control is reviewed." handleChange={handleChange} value={formFieldsState.reviewScheduleNumber || ''} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldSelect options={ScheduleFrequencySelectOptions} handleChange={handleSelectChange} formFieldId="reviewScheduleFrequency" selectedOption={formFieldsState.reviewScheduleFrequency} formFieldLabel="Interval" isRequiredField={true} tooltip={'The frequency (month, year, etc.) that the control must be reviewed.'} isClearable />
                        </div>
                    </div>
                    <div className={styles.badgeContainer}>{scheduleWasChanged && nextAssessmentDate() && <Chip>{`Next Assessment Due Date: ${nextAssessmentDate()}`}</Chip>}</div>
                    <div className={styles.buttonContainer}>
                        <Button variant="danger" onClick={() => setDisplayedModal(Modal.Delete)} fontAwesomeImage={faTrash}>
                            CLEAR SETTINGS
                        </Button>
                        <div className={styles.submitButton}>
                            <Button variant="submit" isLoading={isUpdatingControl} loadingText="Saving...">
                                SAVE
                            </Button>
                        </div>
                    </div>
                </Form>
            </PageCell>
        </>
    );
};

export default ControlAssessmentTab;
