import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Modal } from 'react-bootstrap';

import { Button, Link } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Text from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';

import styles from './RelatedControlAssessmentsModal.module.css';

export interface RelatedControlAssessmentsModalProps {
    hideModal: () => void;
    title: string;
    relatedControlAssessmentDetails: RelatedControlAssessmentDetails[];
}
export interface RelatedControlAssessmentsModalState {
    relatedControlAssessmentDetailsSorted: RelatedControlAssessmentDetails[];
}

export interface RelatedControlAssessmentDetails {
    controlId: string; // Canonical identifier for a Control. Example: "CIS CSC (v8.0)#1#1.1"
    label: string;
    date: string;
}

export class RelatedControlAssessmentsModal extends Component<RelatedControlAssessmentsModalProps, RelatedControlAssessmentsModalState> {
    constructor(props: RelatedControlAssessmentsModalProps) {
        super(props);
        this.state = {
            relatedControlAssessmentDetailsSorted: this.props.relatedControlAssessmentDetails.sort((a, b) => {
                if (a.date > b.date) {
                    return 1;
                } else if (a.date < b.date) {
                    return -1;
                } else {
                    // If the dates are the same, sort by label (Control ID).
                    if (a.label > b.label) {
                        return 1;
                    } else if (a.label < b.label) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            }),
        };
    }

    render(): JSX.Element {
        return (
            <Modal show onHide={this.props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    <ModalHeader text={this.props.title} />
                    {this.state.relatedControlAssessmentDetailsSorted.map((details) => (
                        <div className={styles.detailsContainer} key={`${details.label}_${details.date}`}>
                            <Link size="sm" to={getFrameworkGroupControlURL(details.controlId)}>
                                {details.label}
                            </Link>
                            <div className={styles.detailsDateContainer}>
                                <Text color="darkGray" noStyles={true}>
                                    {iso8601ToUsDateShort(details.date)}
                                </Text>
                            </div>
                        </div>
                    ))}
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={this.props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
