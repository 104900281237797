import FormFieldMultiOptionSelect from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import { ReportListingTableFilterOptions } from 'Models/TPRM';
import { Filter, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';

export interface ReportListingFiltersProps {
    filterOptions: GroupedOptions[];
    selectedFilterOptions: (selectedFilterOptions: Filter | Filter[]) => void;
}

export const ReportListingFilters = (props: ReportListingFiltersProps): JSX.Element => {
    const handleSelectChange = (value: GroupOptionType[]): void => {
        const effectivenessFilterValues: (string | number)[] = [];
        const inherentRiskFilterValues: (string | number)[] = [];
        const residualRiskFilterValues: (string | number)[] = [];

        value.forEach((filter) => {
            if (filter.groupId === ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS) {
                effectivenessFilterValues.push(filter.value);
            }
            if (filter.groupId === ReportListingTableFilterOptions.INHERENT_RISK_SCORE) {
                const trimmed = parseFloat((filter.value as string).split('-')[0]);
                inherentRiskFilterValues.push(trimmed);
            }
            if (filter.groupId === ReportListingTableFilterOptions.RESIDUAL_RISK_SCORE) {
                const trimmed = parseFloat((filter.value as string).split('-')[0]);
                residualRiskFilterValues.push(trimmed);
            }
        });

        const allFilters: Filter[] = [
            {
                key: 'control_effectiveness',
                value: effectivenessFilterValues,
            },
            {
                key: 'riskRating',
                value: inherentRiskFilterValues,
            },
            {
                key: 'residual risk',
                value: residualRiskFilterValues,
            },
        ];
        props.selectedFilterOptions(allFilters);
    };

    const filterOptions = props.filterOptions.map((groupOption) => {
        if (groupOption.label === 'Inherent Risk Rating') {
            groupOption.options.map((option) => {
                option.value = `${option.value}-inherent`;
                return option;
            });
        } else if (groupOption.label === 'Residual Risk') {
            groupOption.options.map((option) => {
                option.value = `${option.value}-residual`;
                return option;
            });
        }
        return groupOption;
    });
    return <FormFieldMultiOptionSelect formFieldId="reportListingFilter" options={filterOptions} formFieldLabel="Filter by Risk or Effectiveness" handleChange={handleSelectChange} accessibilityLabel="Reports Filter" />;
};
