import PageCell from 'Components/Containers/PageCell/PageCell';
import { useUsers } from 'Components/Context/UsersContext';
import Text from 'Components/Text/Text';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './ControlMetadata.module.css';

export interface ControlMetadataProps {
    detailedControlResponse: OperationalControl;
}

export const ControlMetadata = (props: ControlMetadataProps): JSX.Element => {
    const { users } = useUsers();

    return (
        <div className={styles.sectionContainer}>
            <PageCell variant="transparentBlue">
                <Text color="white" variant="Header2">
                    Roles
                </Text>
                <hr />
                <div className={styles.detailRow}>
                    <Text variant="Text4" color="darkGray" toUppercase noStyles>
                        Owner:
                    </Text>
                    <Text color="white" variant="Text2">
                        {props.detailedControlResponse.configuration.review_configuration?.owner_subject ? getUserNameFromSubject(props.detailedControlResponse.configuration.review_configuration.owner_subject, users, UserNameFormat.FIRST_SPACE_LAST) : '-'}
                    </Text>
                </div>
                <div className={styles.detailRow}>
                    <Text variant="Text4" color="darkGray" toUppercase noStyles>
                        Reviewer:
                    </Text>
                    <Text color="white" variant="Text2">
                        {props.detailedControlResponse.configuration.review_configuration?.reviewer_subject ? getUserNameFromSubject(props.detailedControlResponse.configuration.review_configuration.reviewer_subject, users, UserNameFormat.FIRST_SPACE_LAST) : '-'}
                    </Text>
                </div>
                <div className={styles.detailRow}>
                    <Text variant="Text4" color="darkGray" toUppercase noStyles>
                        Delegates:
                    </Text>
                    <Text color="white" variant="Text2">
                        {props.detailedControlResponse.configuration.review_configuration?.delegate_subjects && props.detailedControlResponse.configuration.review_configuration.delegate_subjects.length > 0 ? props.detailedControlResponse.configuration.review_configuration.delegate_subjects.map((subject) => getUserNameFromSubject(subject, users, UserNameFormat.FIRST_SPACE_LAST)).join(', ') : '-'}
                    </Text>
                </div>
            </PageCell>
        </div>
    );
};
