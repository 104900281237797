import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { Button } from 'Components/Buttons/Buttons';
import IconButton from 'Components/Buttons/IconButton';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Placeholder from 'Components/Placeholder/Placeholder';
import { RiskStrategy, RiskStrategyRequest } from 'Models/RiskRegister';

import styles from './ManageRiskStrategiesModal.module.css';

export interface ManageRiskStrategiesModalProps {
    hideModal: () => void;
    riskRegisterApi: RiskRegisterApi;
}

/**
 * Modal for managing risk strategies.
 * Note that there are separate state variables and functions for existing and new risk strategies. This is because existing risk strategies can only be deleted, not modified. Whereas new risk strategies can be added, modified, or deleted (from state).
 */
export const ManageRiskStrategiesModal = (props: ManageRiskStrategiesModalProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [existingRiskStrategies, setExistingRiskStrategies] = useState<RiskStrategy[]>([]);
    const [newRiskStrategies, setNewRiskStrategies] = useState<RiskStrategyRequest[]>([]);

    useEffect(() => {
        const getExistingRiskStrategies = async () => {
            try {
                const response = await props.riskRegisterApi.getRiskStrategies();
                setExistingRiskStrategies(response.data);
            } catch (error) {
                setFailureMessage(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        getExistingRiskStrategies();
    }, [props.riskRegisterApi]);

    /**
     * Adds a new (empty) risk strategy for the user to fill in.
     */
    const handleNewRiskStrategyAdd = (): void => {
        setNewRiskStrategies([...newRiskStrategies, { title: '' }]);
    };

    /**
     * Removes a risk strategy from the list of existing risk strategies.
     */
    const handleExistingRiskStrategyRemove = (index: number): void => {
        const riskStrategies = [...existingRiskStrategies];
        riskStrategies.splice(index, 1);
        setExistingRiskStrategies(riskStrategies);
    };

    /**
     * Removes a risk strategy from the list of new risk strategies.
     */
    const handleNewRiskStrategyRemove = (index: number): void => {
        const riskStrategies = [...newRiskStrategies];
        riskStrategies.splice(index, 1);
        setNewRiskStrategies(riskStrategies);
    };

    /**
     * Sets the name for a new risk strategy.
     */
    const handleNewRiskStrategyChange = (index: number, value: string): void => {
        const riskStrategies = [...newRiskStrategies];
        riskStrategies[index].title = value;
        setNewRiskStrategies([...riskStrategies]);
    };

    /**
     * Save the risk strategy additions/deletions.
     */
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFailureMessage(undefined);
        setSuccessMessage(undefined);

        // Trim the title of the new risk strategies.
        let combinedRiskStrategies: RiskStrategyRequest[] = newRiskStrategies.map((riskStrategy) => {
            return { ...riskStrategy, title: riskStrategy.title.trim() };
        });
        // Combine the existing and new risk strategies.
        combinedRiskStrategies = [...existingRiskStrategies, ...combinedRiskStrategies];
        // Eliminate risk strategies with duplicate titles.
        combinedRiskStrategies = combinedRiskStrategies.filter((riskStrategy, index, self) => index === self.findIndex((rs) => rs.title === riskStrategy.title));
        // Eliminate new risk strategies that are empty strings.
        combinedRiskStrategies = combinedRiskStrategies.filter((riskStrategy) => riskStrategy.title !== '');
        // Sort the combined list of existing and new risk strategies.
        combinedRiskStrategies.sort((a, b) => a.title.localeCompare(b.title));

        try {
            setIsSubmitting(true);
            await props.riskRegisterApi.updateRiskStrategies(combinedRiskStrategies);
            setSuccessMessage('Risk strategies saved.');
        } catch (error) {
            setFailureMessage(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className={'modalFromBody'}>
                    <Placeholder />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Risk Strategies" secondaryText="Define strategies for handling risks, such as Accept, Mitigate, and Transfer." />
                    {existingRiskStrategies.length > 0 && (
                        <>
                            {existingRiskStrategies.map((riskStrategy, index) => {
                                return (
                                    <div className={styles.riskStrategyContainer} key={index}>
                                        <div className={styles.riskStrategyName}>
                                            <FormFieldText disabled={true} formFieldType="text" formFieldId={`existingRiskStrategy${index}`} formFieldLabel={`Risk Strategy`} value={riskStrategy.title} />
                                        </div>
                                        <IconButton onClick={() => handleExistingRiskStrategyRemove(index)} aria-label={`delete ${riskStrategy.title}`} fontAwesomeImage={faTrash} />
                                    </div>
                                );
                            })}
                        </>
                    )}
                    {newRiskStrategies.length > 0 && (
                        <>
                            {newRiskStrategies.map((riskStrategy, index) => {
                                return (
                                    <div className={styles.riskStrategyContainer} key={index}>
                                        <div className={styles.riskStrategyName}>
                                            <FormFieldText formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNewRiskStrategyChange(index, event.currentTarget.value)} formFieldId={`newRiskStrategy${index}`} formFieldLabel={`Risk Strategy`} required={true} value={riskStrategy.title || ''} />
                                        </div>
                                        <IconButton onClick={() => handleNewRiskStrategyRemove(index)} aria-label={`delete ${riskStrategy.title}`} fontAwesomeImage={faTrash} />
                                    </div>
                                );
                            })}
                        </>
                    )}
                    <div>
                        <Button variant="linkText" size="lg" onClick={handleNewRiskStrategyAdd}>
                            {'+ Add New Risk Strategy'}
                        </Button>
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit" isLoading={isSubmitting} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
