import { faGreaterThan, faLessThan, faTimes } from '@fortawesome/free-solid-svg-icons';
import { difference, intersection } from 'lodash-es';
import { ReactNode, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import { Button } from 'Components/Buttons/Buttons';
import IconButton from 'Components/Buttons/IconButton';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import Text from 'Components/Text/Text';

import styles from './MultipleMappingBase.module.css';

export interface MappableItem {
    id: string;
    title: string;
    description: string;
}

/**
 * @param mappingItems - an array of all unmapped and mapped items
 * @param currentMappedIdentifiers - an array of the identifiers of all mapped items
 */
export interface MultipleMappingBaseProps<T extends MappableItem> {
    mappingItems: T[];
    handleChange: (items: string[]) => void;
    sortMappingItems: (mappingItems: T[]) => void;
    currentMappedIdentifiers?: string[];
    renderMappingItems: (mappingItems: T[], handleSelected: (value: T, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void, selected: T[]) => ReactNode;
}
export const MultipleMappingBase = <T extends MappableItem>(props: MultipleMappingBaseProps<T>): JSX.Element => {
    const [unmappedItems, setUnmappedItems] = useState<T[]>(() => {
        // items is filtered array of item not already mapped
        const items = props.mappingItems.filter((item) => !props.currentMappedIdentifiers?.includes(item.id));
        // sort the items and return them
        props.sortMappingItems(items);
        return items;
    });
    const [mappedItems, setMappedItems] = useState<T[]>(() => {
        // items is filtered array of item already mapped
        const items = props.mappingItems.filter((item) => props.currentMappedIdentifiers?.includes(item.id));
        // sort the items and return them
        props.sortMappingItems(items);
        return items;
    });
    const [selected, setSelected] = useState<T[]>([]);
    const [previousRow, setPreviousRow] = useState<T | undefined>();
    const [filterValue, setFilterValue] = useState<string>('');

    const handleSelected = (value: T, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (unmappedItems && mappedItems) {
            const currentIndex = selected.indexOf(value);
            let newChecked: T[] = [...selected];
            setPreviousRow(value);
            //e.shift selects a block
            if (e.shiftKey) {
                if (currentIndex === -1 && previousRow) {
                    const newSelection: T[] = [];
                    if (unmappedItems.indexOf(previousRow) > -1 && unmappedItems.indexOf(value) > -1) {
                        const prevIndex = unmappedItems.indexOf(previousRow);
                        const newIndex = unmappedItems.indexOf(value);
                        if (prevIndex < newIndex) {
                            for (let i = prevIndex; i <= newIndex; i++) {
                                newSelection.push(unmappedItems[i]);
                            }
                        } else {
                            for (let i = newIndex; i <= prevIndex; i++) {
                                newSelection.push(unmappedItems[i]);
                            }
                        }
                    } else if (mappedItems.indexOf(previousRow) > -1 && mappedItems.indexOf(value) > -1) {
                        const prevIndex = mappedItems.indexOf(previousRow);
                        const newIndex = mappedItems.indexOf(value);
                        if (prevIndex < newIndex) {
                            for (let i = prevIndex; i <= newIndex; i++) {
                                newSelection.push(mappedItems[i]);
                            }
                        } else {
                            for (let i = newIndex; i <= prevIndex; i++) {
                                newSelection.push(mappedItems[i]);
                            }
                        }
                    }
                    newChecked = newSelection;
                }
                //allows the user to select or deselect more than one item not in sequence
            } else if (e.ctrlKey || e.metaKey) {
                if (currentIndex === -1) {
                    newChecked.push(value);
                } else {
                    newChecked.splice(currentIndex, 1);
                }
                //if no keys are pressed, adds or subtracts the one item that was selected.
            } else {
                if (currentIndex === -1) {
                    newChecked = [value];
                }
            }

            setSelected(
                newChecked.filter((item) => {
                    return item.title.toLowerCase().includes(filterValue.toLowerCase()) || item.description.toLowerCase().includes(filterValue.toLowerCase());
                })
            );
        } else return;
    };

    const setFilter = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setFilterValue(event.currentTarget.value);
    };
    const unmappedSelectedItems = intersection(selected, unmappedItems);
    const mappedSelectedItems = intersection(selected, mappedItems);

    const handleUnmappedToMapped = () => {
        if (unmappedItems && mappedItems) {
            // Set Mapped Items
            const newMappedItems = mappedItems.concat(unmappedSelectedItems);
            props.sortMappingItems(newMappedItems);
            setMappedItems(newMappedItems);

            // Set unmapped items
            const newUnmappedItems = difference(unmappedItems, unmappedSelectedItems);
            props.sortMappingItems(newUnmappedItems);
            setUnmappedItems(newUnmappedItems);

            // Update function with currently mapped items
            setSelected(difference(selected, unmappedSelectedItems));
            props.handleChange(newMappedItems.map((item) => item.id));
        }
    };

    const handleMappedToUnmapped = () => {
        if (unmappedItems && mappedItems) {
            // Set unmapped items
            const newUnmappedItems = unmappedItems.concat(mappedSelectedItems);
            props.sortMappingItems(newUnmappedItems);
            setUnmappedItems(newUnmappedItems);

            // Set Mapped items
            const newMappedItems = difference(mappedItems, mappedSelectedItems);
            props.sortMappingItems(newMappedItems);
            setMappedItems(newMappedItems);

            // Update function with currently mapped items
            setSelected(difference(selected, mappedSelectedItems));
            props.handleChange(newMappedItems.map((item) => item.id));
        }
    };

    const itemsList = (items: T[]) => (
        <div className={styles.list}>
            <Scrollbars>{props.renderMappingItems(items, handleSelected, selected)}</Scrollbars>
        </div>
    );

    const filteredMappedItemCount = () => {
        const amountNotFiltered = unmappedItems!.filter((item) => {
            return item.title.toLowerCase().includes(filterValue.toLowerCase()) || item.description.toLowerCase().includes(filterValue.toLowerCase());
        }).length;

        return `${unmappedItems.length - amountNotFiltered} item(s) hidden by the search filter`;
    };

    const filteredUnmappedItemCount = () => {
        const amountNotFiltered = mappedItems!.filter((item) => {
            return item.title.toLowerCase().includes(filterValue.toLowerCase()) || item.description.toLowerCase().includes(filterValue.toLowerCase());
        }).length;

        return `${mappedItems.length - amountNotFiltered} item(s) hidden by the search filter`;
    };

    return (
        <>
            <div className={styles.search}>
                <div className={styles.searchButton}>
                    <FormFieldText formFieldId="filterValue" handleChange={setFilter} formFieldLabel="Search for Item" value={filterValue} />
                </div>
                {filterValue && (
                    <Button variant="primary" onClick={() => setFilterValue('')} fontAwesomeImage={faTimes}>
                        CLEAR
                    </Button>
                )}
            </div>
            <div className={styles.grids}>
                <div className={styles.listContainer} data-testid="unselected items">
                    <div className={styles.listHeading}>
                        <Text variant="Header3">Available</Text>
                        {filterValue && (
                            <div className={styles.listSubHeader}>
                                <Text variant="Header4">{filteredMappedItemCount()}</Text>
                            </div>
                        )}
                    </div>
                    {itemsList(
                        unmappedItems!.filter((item) => {
                            return item.title.toLowerCase().includes(filterValue.toLowerCase()) || item.description.toLowerCase().includes(filterValue.toLowerCase());
                        })
                    )}
                </div>
                <div className={styles.buttonContainer}>
                    <div className={styles.buttonTop}>
                        <IconButton aria-label="Select Item to be Mapped" onClick={handleUnmappedToMapped} fontAwesomeImage={faGreaterThan} disabled={false} />
                    </div>
                    <IconButton aria-label="Select Item to No Longer Be Mapped" onClick={handleMappedToUnmapped} fontAwesomeImage={faLessThan} disabled={false} />
                </div>
                <div className={styles.listContainer} data-testid="selected items">
                    <div className={styles.listHeading}>
                        <Text variant="Header3">Selected</Text>
                        {filterValue && (
                            <div className={styles.listSubHeader}>
                                <Text variant="Header4">{filteredUnmappedItemCount()}</Text>
                            </div>
                        )}
                    </div>
                    {itemsList(
                        mappedItems!.filter((item) => {
                            return item.title.toLowerCase().includes(filterValue.toLowerCase()) || item.description.toLowerCase().includes(filterValue.toLowerCase());
                        })
                    )}
                </div>
            </div>
        </>
    );
};
