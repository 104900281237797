import { Link } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import { FieldLabel } from 'Components/FieldLabel/FieldLabel';
import Text from 'Components/Text/Text';
import { UserDefinedReference } from 'Components/UserDefinedHyperlink/UserDefinedHyperlink';
import { ISSUES_EXCEPTIONS, SERVICES, TPRM } from 'Config/Paths';
import { TPRM_VENDOR_MANAGER, TPRM_VENDOR_WEBSITE } from 'Config/Tooltips';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { countOpenExceptions } from 'Models/Exceptions';
import { countOpenIssues } from 'Models/Issues';
import { VendorResponseWithServices } from 'Models/TPRM';

import styles from './VendorSummary.module.css';

export interface VendorSummaryProps {
    vendor: VendorResponseWithServices;
}

/**
 * Renders details about a vendor that roughly match the details shown on the card for a vendor on the Manage Vendors page.
 * Used on the Issue Details and Exception Details pages.
 */
export const VendorSummary = (props: VendorSummaryProps) => {
    const { users } = useUsers();

    const numberOfOpenIssues = countOpenIssues(props.vendor.issues);
    const numberOfOpenExceptions = countOpenExceptions(props.vendor.exceptions);

    return (
        <div className={styles.detailsContainer}>
            <div className={styles.detailsGridSection}>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Vendor Manager" tooltipText={TPRM_VENDOR_MANAGER} />
                        <Text noStyles>{getUserNameFromSubject(props.vendor.vendor_manager_user_id, users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Issues" />
                        <div>
                            <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `vendorId=${props.vendor.id}`, hash: '#issues' }}>{`Open Issues: ${numberOfOpenIssues}`}</Link>
                        </div>
                    </div>
                </div>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Services" />
                        <Link size="sm" to={{ pathname: `/${TPRM}/${SERVICES}`, search: `vendorId=${props.vendor.id}` }}>
                            {`Services: ${props.vendor.services.length}`}
                        </Link>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Exceptions" />
                        <div>
                            <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `vendorId=${props.vendor.id}`, hash: '#exceptions' }}>{`Open Exceptions: ${numberOfOpenExceptions}`}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Website" tooltipText={TPRM_VENDOR_WEBSITE} />
                <UserDefinedReference reference={props.vendor.website} />
            </div>
        </div>
    );
};
