import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';

import styles from './DeleteUserModal.module.css';
import { UsersApi } from '../../../../Api/Users/UsersApi';
import { ModalHeader } from '../../../../Components/Modal/ModalHeader';
import { UserResponse } from '../../../../Models/User';

export interface DeleteUserModalProps {
    hideModal: () => void;
    userSelectedForDeletion: UserResponse;
    usersApi: UsersApi;
    userDeleted: () => void;
}

export interface DeleteUserModalState {
    successMessage?: string;
    failureMessage?: string;
    isDeletingUser: boolean;
}

export default class DeleteUserModal extends Component<DeleteUserModalProps, DeleteUserModalState> {
    constructor(props: DeleteUserModalProps) {
        super(props);

        this.state = {
            isDeletingUser: false,
        };
    }

    deleteUser = async (): Promise<void> => {
        this.setState({ isDeletingUser: true, successMessage: undefined, failureMessage: undefined });
        try {
            await this.props.usersApi.deleteUser(this.props.userSelectedForDeletion.cognito_subject);
            this.setState({ successMessage: 'User deleted.' });
            this.props.userDeleted();
        } catch (error) {
            this.handleRequestError(error);
        } finally {
            this.setState({ isDeletingUser: false });
        }
    };

    handleRequestError = (error: Error): void => {
        this.setState({ failureMessage: error.message, successMessage: undefined });
    };

    hideModal = (): void => {
        if (!this.state.isDeletingUser) {
            this.props.hideModal();
        }
    };

    render(): JSX.Element {
        return (
            <Modal show onHide={this.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.failureMessage && <Alert variant="danger">{this.state.failureMessage}</Alert>}
                    <ModalHeader text="Delete" />
                    <div className={styles.deleteText}>
                        Are you sure you want to delete: {this.props.userSelectedForDeletion.first_name} {this.props.userSelectedForDeletion.last_name}?
                    </div>

                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={this.hideModal} disabled={this.state.isDeletingUser} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="danger" onClick={() => this.deleteUser()} loadingText={'Deleting...'} isLoading={this.state.isDeletingUser} fontAwesomeImage={faTrash} disabled={this.state.successMessage ? true : false}>
                            DELETE
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
