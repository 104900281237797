import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import PageContent from 'Components/Containers/PageContent/PageContent';
import Text from 'Components/Text/Text';
import { DashboardType } from 'Models/Dashboards';

import { AssessmentsSchedule } from './AssessmentsSchedule/AssessmentsSchedule';
import { ComplianceRequirements } from './ComplianceRequirements/ComplianceRequirements';
import { ControlStrength } from './ControlStrength/ControlStrength';
import { ControlsOverTime } from './ControlsOverTime/ControlsOverTime';
import { useControlStrengthQuery, useCurrentComplianceSummaryQuery } from './OperationalControlsDashboard.hooks';
import styles from './OperationalControlsDashboard.module.css';
import { RequirementsOverview } from './RequirementsOverview/RequirementsOverview';
import { DashboardClosedExceptions } from '../Components/DashboardsHistoricalExceptions/DashboardsHistoricalExceptions';
import { DashboardClosedIssues } from '../Components/DashboardsHistoricalIssues/DashboardsHistoricalIssues';
import { DashboardsTrackingExceptions } from '../Components/DashboardsTrackingExceptions/DashboardsTrackingExceptions';
import { DashboardOpenIssues } from '../Components/DashboardsTrackingIssues/DashboardsTrackingIssues';

export interface OperationalControlsDashboardProps {
    dashboardApi: DashboardApi;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
}

export const OperationalControlsDashboard = (props: OperationalControlsDashboardProps): JSX.Element => {
    const [controlStrength, controlStrengthError] = useControlStrengthQuery(props.dashboardApi);
    const [complianceSummary, complianceSummaryError] = useCurrentComplianceSummaryQuery(props.dashboardApi);

    return (
        <>
            <PageContent>
                <Text variant="Header1" color="white">
                    Operational Controls
                </Text>
                <div className={styles.overviewSection}>
                    <ControlStrength controlStrength={controlStrength} controlStrengthError={controlStrengthError} />
                    <RequirementsOverview complianceSummary={complianceSummary} complianceSummaryError={complianceSummaryError} api={props.dashboardApi} />
                </div>
                <div className={styles.overviewSection}>
                    <ControlsOverTime dashboardApi={props.dashboardApi} controlStrength={controlStrength} controlStrengthError={controlStrengthError} />
                </div>
                <div className={styles.overviewSection}>
                    <AssessmentsSchedule api={props.dashboardApi} />
                </div>
                <Text variant="Header1" color="white">
                    Compliance Requirements
                </Text>
                <div className={styles.overviewSection}>
                    <ComplianceRequirements complianceSummary={complianceSummary} complianceSummaryError={complianceSummaryError} api={props.dashboardApi} />
                </div>
                <div className={styles.cellHeader}>
                    <Text variant="Header1" color="white">
                        Issue Management
                    </Text>
                </div>
                <div className={styles.overviewSection}>
                    <DashboardClosedIssues issuesApi={props.issuesApi} dashboardType={DashboardType.OPERATIONAL_CONTROLS} />
                    <DashboardOpenIssues issuesApi={props.issuesApi} dashboardType={DashboardType.OPERATIONAL_CONTROLS} />
                </div>
                <div className={styles.cellHeader}>
                    <Text variant="Header1" color="white">
                        Exception Management
                    </Text>
                </div>
                <div className={styles.overviewSection}>
                    <DashboardClosedExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.OPERATIONAL_CONTROLS} />
                    <DashboardsTrackingExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.OPERATIONAL_CONTROLS} />
                </div>
            </PageContent>
        </>
    );
};
