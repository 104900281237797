import { faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';

import styles from './CollapsibleModal.module.css';
import { ModalHeader, ModalHeaderProps } from '../ModalHeader';

export interface CollapsibleModalProps {
    hideModal: () => void;
    headerText: ModalHeaderProps;
    displayItems: JSX.Element[];
    previewAmount?: number;
}

export const CollapsibleModal = (props: CollapsibleModalProps): JSX.Element => {
    const defaultAmountShown = props.previewAmount ?? 10;
    const [showAll, setShowAll] = useState<boolean>(false);

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader {...props.headerText} />
                {props.displayItems.slice(0, showAll ? undefined : defaultAmountShown)}
                <div className={styles.showMoreButton}>
                    {props.displayItems.length > defaultAmountShown && (
                        <Button variant="linkText" size="lg" onClick={() => setShowAll(!showAll)}>
                            {showAll ? (
                                <>
                                    <FontAwesomeIcon icon={faChevronUp} />
                                    {` Show Less`}
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                    {` Show ${props.displayItems.length - defaultAmountShown} More`}
                                </>
                            )}
                        </Button>
                    )}
                </div>
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                        CLOSE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
