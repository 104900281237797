import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import IconButton from 'Components/Buttons/IconButton';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Placeholder from 'Components/Placeholder/Placeholder';
import { ResponseModel } from 'Models/ResponseModel';
import { FolderTypes } from 'Models/TPRM';

import styles from './ManageFolderTypesModal.module.css';

export interface ManageFolderTypesModalProps {
    hideModal: () => void;
    tprmApi: TPRMApi;
}

/**
 * Modal for managing folder types for Third-Party Risk Management document uploads.
 * Note that there are separate state variables and functions for existing and new folder types. This is because existing folder types can only be deleted, not modified. Whereas new folder types can be added, modified, or deleted (from state).
 */
export const ManageFolderTypesModal = (props: ManageFolderTypesModalProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [existingFolderTypes, setExistingFolderTypes] = useState<string[]>([]);
    const [newFolderTypes, setNewFolderTypes] = useState<string[]>([]);

    useEffect(() => {
        const getExistingFolderTypes = async () => {
            try {
                const response: ResponseModel<FolderTypes> = await props.tprmApi.getAllFolderTypes();
                setExistingFolderTypes(response.data.type_set.sort());
            } catch (error) {
                setFailureMessage(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        getExistingFolderTypes();
    }, [props.tprmApi]);

    /**
     * Adds a new (empty) folder type for the user to fill in.
     */
    const handleNewFolderTypeAdd = (): void => {
        setNewFolderTypes([...newFolderTypes, '']);
    };

    /**
     * Removes a folder type from the list of existing folder types.
     */
    const handleExistingFolderTypeRemove = (index: number): void => {
        const folderTypes = [...existingFolderTypes];
        folderTypes.splice(index, 1);
        setExistingFolderTypes(folderTypes);
    };

    /**
     * Removes a folder type from the list of new folder types.
     */
    const handleNewFolderTypeRemove = (index: number): void => {
        const folderTypes = [...newFolderTypes];
        folderTypes.splice(index, 1);
        setNewFolderTypes(folderTypes);
    };

    /**
     * Sets the name for a new folder type.
     */
    const handleNewFolderTypeChange = (index: number, value: string): void => {
        const folderTypes = [...newFolderTypes];
        folderTypes[index] = value;
        setNewFolderTypes([...folderTypes]);
    };

    /**
     * Save the folder types additions/deletions.
     */
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFailureMessage(undefined);
        setSuccessMessage(undefined);

        // Trim the values of the new folder types.
        let combinedFolderTypes = newFolderTypes.map((folderType) => folderType.trim());
        // Combine the existing and new folder types.
        combinedFolderTypes = [...existingFolderTypes, ...combinedFolderTypes];
        // Eliminate duplicates.
        combinedFolderTypes = Array.from(new Set(combinedFolderTypes));
        // Eliminate new folder types that are empty strings.
        combinedFolderTypes = combinedFolderTypes.filter((folderType) => folderType !== '');
        // Sort the combined list of existing and new folder types.
        combinedFolderTypes = combinedFolderTypes.sort();
        // Form the request object.
        const request: FolderTypes = { type_set: combinedFolderTypes };

        try {
            setIsSubmitting(true);
            await props.tprmApi.updateFolderTypes(request);
            setSuccessMessage('Folder type saved.');
        } catch (error) {
            setFailureMessage(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className={'modalFromBody'}>
                    <Placeholder />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Folder Types" secondaryText="Manage folder types for Third-Party Risk Management document uploads." />
                    {existingFolderTypes.length > 0 && (
                        <>
                            {existingFolderTypes.map((folderType, index) => {
                                return (
                                    <div className={styles.folderTypeContainer} key={index}>
                                        <div className={styles.folderTypeName}>
                                            <FormFieldText disabled={true} formFieldType="text" formFieldId={`existingFolderType${index}`} formFieldLabel={`Folder Type`} value={folderType} />
                                        </div>
                                        <IconButton onClick={() => handleExistingFolderTypeRemove(index)} aria-label={`delete ${folderType}`} fontAwesomeImage={faTrash} />
                                    </div>
                                );
                            })}
                        </>
                    )}
                    {newFolderTypes.length > 0 && (
                        <>
                            {newFolderTypes.map((folderType, index) => {
                                return (
                                    <div className={styles.folderTypeContainer} key={index}>
                                        <div className={styles.folderTypeName}>
                                            <FormFieldText formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNewFolderTypeChange(index, event.currentTarget.value)} formFieldId={`newFolderType${index}`} formFieldLabel={`Folder Type`} required={true} value={folderType || ''} />
                                        </div>
                                        <IconButton onClick={() => handleNewFolderTypeRemove(index)} aria-label={`delete ${folderType}`} fontAwesomeImage={faTrash} />
                                    </div>
                                );
                            })}
                        </>
                    )}
                    <div>
                        <Button variant="linkText" size="lg" onClick={handleNewFolderTypeAdd}>
                            {'+ Add New Folder Type'}
                        </Button>
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit" isLoading={isSubmitting} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
