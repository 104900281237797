import { ACTIONS, ASSESSMENTS, COMPLIANCE_REQUIREMENTS, EVIDENCE, EXCEPTIONS, EXPORTS, GOVERNANCE, ISSUES, OPERATIONAL_CONTROLS, RISKS, RISK_REGISTER, SERVICES, TPRM, VENDORS } from 'Config/Paths';
import { ActionExportRow, ControlAssessmentExportRow, EvidenceExportRow, ExceptionExportRow, GovernanceExportRow, IssueExportRow, OperationalControlExportRow, RequirementExportRow, RiskAssessmentExportRow, RiskExportRow, TPRMServiceAssessmentExportRow, TPRMServiceExportRow, TPRMVendorExportRow } from 'Models/Exports';
import { PaginatedResponseModel, ResponseModel } from 'Models/ResponseModel';
import { restApiGet } from 'RestApiConnector';

import { ExportsApi } from './ExportsApi';

export class AWSExportApi implements ExportsApi {
    getGovernanceExport = (): Promise<ResponseModel<GovernanceExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${GOVERNANCE}`, {});
    };

    getIssuesExport = (): Promise<ResponseModel<IssueExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${ISSUES}`, {});
    };

    getExceptionsExport = (): Promise<ResponseModel<ExceptionExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${EXCEPTIONS}`, {});
    };

    getOperationalControlExport = (): Promise<ResponseModel<OperationalControlExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${OPERATIONAL_CONTROLS}`, {});
    };

    getEvidenceControlExport = (): Promise<ResponseModel<EvidenceExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${OPERATIONAL_CONTROLS}/${EVIDENCE}`, {});
    };

    getControlAssessmentsExport = (): Promise<ResponseModel<ControlAssessmentExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${OPERATIONAL_CONTROLS}/${ASSESSMENTS}`, {});
    };

    getComplianceRequirementsExport = (nextPage?: string): Promise<PaginatedResponseModel<RequirementExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${COMPLIANCE_REQUIREMENTS}`, { next_page: nextPage });
    };

    getVendorServicesExport = (): Promise<ResponseModel<TPRMServiceExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${TPRM}/${VENDORS}/${SERVICES}`, {});
    };

    getVendorsExport = (): Promise<ResponseModel<TPRMVendorExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${TPRM}/${VENDORS}`, {});
    };
    getVendorServiceAssessmentsExport = (): Promise<ResponseModel<TPRMServiceAssessmentExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${TPRM}/${VENDORS}/${SERVICES}/${ASSESSMENTS}`, {});
    };

    getActionsExport = (): Promise<ResponseModel<ActionExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${ACTIONS}`, {});
    };

    getRisksExport = (): Promise<ResponseModel<RiskExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${RISK_REGISTER}/${RISKS}`, {});
    };

    getRiskAssessmentsExport = (): Promise<ResponseModel<RiskAssessmentExportRow[]>> => {
        return restApiGet(`/${EXPORTS}/${RISK_REGISTER}/${ASSESSMENTS}`, {});
    };
}
