import { Component } from 'react';

import { VendorResponseWithServices } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import VendorCard from './VendorCard/VendorCard';
import styles from './VendorListingGridView.module.css';

export interface VendorListingGridViewProps {
    vendors: VendorResponseWithServices[];
    selectedDeleteVendor: (vendorId: string) => void;
    selectedModifyVendor: (vendorId: string) => void;
    selectedCreateServiceForVendor: (vendor: VendorResponseWithServices) => void;
    selectedViewFolders: (vendorId: string) => void;
    users: UserResponse[];
}

export default class VendorListingGridView extends Component<VendorListingGridViewProps> {
    render(): JSX.Element {
        return (
            <div className={styles.gridView}>
                {this.props.vendors.map((vendor) => (
                    <VendorCard key={vendor.id} vendor={vendor} selectedCreateServiceForVendor={this.props.selectedCreateServiceForVendor} selectedModifyVendor={this.props.selectedModifyVendor} selectedDeleteVendor={this.props.selectedDeleteVendor} selectedViewFolders={this.props.selectedViewFolders} users={this.props.users} />
                ))}
            </div>
        );
    }
}
