import { Button } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import SortableTableHeader, { HeaderData, SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody, TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { ControlExceptionHistoryResponse, ControlExceptionResponse, ExceptionHistoryResponse, VendorExceptionHistoryResponse, VendorExceptionResponse, titleCaseExceptionStatus } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './ExceptionHistoryTable.module.css';

export interface ControlExceptionHistoryTableProps {
    type: IssuesExceptionsModule.CONTROLS;
    histories: ControlExceptionHistoryResponse[];
    displayMappedControlsModal: (mappedControls: OperationalControl[]) => void;
    onSelectHistory: (history: ExceptionHistoryResponse) => void;
}

export interface VendorExceptionHistoryTableProps {
    type: IssuesExceptionsModule.TPRM;
    histories: VendorExceptionHistoryResponse[];
    getVendorName: (vendorId: string) => string;
    onSelectHistory: (history: ExceptionHistoryResponse) => void;
}

export type ExceptionHistoryTableProps = ControlExceptionHistoryTableProps | VendorExceptionHistoryTableProps;

export const ExceptionHistoryTable = (props: ExceptionHistoryTableProps) => {
    const { users } = useUsers();
    const sortedHistories = props.histories.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));

    // `dataKey`s are arbitrary; the table does not allow sorting.
    const headerValues: HeaderData[] = [
        { dataKey: 'DATE', label: 'DATE', disableSort: true },
        { dataKey: 'STATUS', label: 'STATUS', disableSort: true },
        { dataKey: 'OWNER', label: 'OWNER', disableSort: true },
        { dataKey: 'REVIEWER', label: 'REVIEWER', disableSort: true },
        { dataKey: 'MAPPED ENTITIES', label: props.type === IssuesExceptionsModule.CONTROLS ? 'CONTROLS' : 'VENDOR', disableSort: true },
        { dataKey: 'EXPIRATION DATE', label: 'EXPIRATION DATE', disableSort: true },
    ];

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: () => void 0, // Arbitrary; the table does not allow sorting.
        currentSort: 'DATE', // Arbitrary; the table does not allow sorting.
        currentSortDirection: SortDirection.ASC, // Arbitrary; the table does not allow sorting.
    };

    const tableRow = (history: ExceptionHistoryResponse) => {
        return (
            <TableRow key={history.timestamp}>
                <TableCell className={styles.titleCell}>
                    <Button variant="linkText" size="sm" onClick={() => props.onSelectHistory(history)}>
                        {iso8601ToUsDateShort(history.timestamp)}
                    </Button>
                </TableCell>
                <TableCellDefaultText>
                    <Text noStyles>{titleCaseExceptionStatus(history.exception.status)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{getUserNameFromSubject(history.exception.owner_subject, users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{getUserNameFromSubject(history.exception.reviewer_subject, users)}</Text>
                </TableCellDefaultText>
                {props.type === IssuesExceptionsModule.CONTROLS ? (
                    <TableCellDefaultText>
                        <Button variant="linkText" size="sm" onClick={() => props.displayMappedControlsModal((history.exception as ControlExceptionResponse).impacted_controls)}>{`${(history.exception as ControlExceptionResponse).impacted_controls.length} ${(history.exception as ControlExceptionResponse).impacted_controls.length === 1 ? 'Control' : 'Controls'}`}</Button>
                    </TableCellDefaultText>
                ) : (
                    <TableCellDefaultText>
                        <Text noStyles>{props.getVendorName((history.exception as VendorExceptionResponse).impacted_vendor)}</Text>
                    </TableCellDefaultText>
                )}
                <TableCellDefaultText>
                    <Text noStyles>{iso8601ToUsDateShort(history.exception.expiration_date)}</Text>
                </TableCellDefaultText>
            </TableRow>
        );
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortedHistories.map(tableRow)}</TableBody>
        </Table>
    );
};
