import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Button } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { FileDragAndDrop, FileDragAndDropProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import FormFieldTextArea from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import { RiskTable } from 'Components/RiskRegister/RiskTable/RiskTable';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import Text from 'Components/Text/Text';
import { LinkButtonToast } from 'Components/Toast/Toast';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { RISK_REGISTER, RISK_REVIEW } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { submitRequestWithFiles } from 'Helpers/FileUtils';
import { validateUrl } from 'Helpers/InputValidation';
import { useFileDragAndDrop } from 'Hooks/FileDragAndDrop';
import { useDisplayableTagsLookup } from 'Hooks/Tags';
import { ResponseModel } from 'Models/ResponseModel';
import { RiskAssessment, RiskCategoryResponse, RiskResponse, SubmitRiskAssessmentRequest } from 'Models/RiskRegister';

import styles from './CreateRiskAssessment.module.css';
import { RiskPeriodComparison } from '../RiskAssessmentListing/RiskPeriodComparison/RiskPeriodComparison';

type SubmitState = { type: 'none' } | { type: 'submitting' } | { type: 'success'; message?: string } | { type: 'failure'; message?: string };

export interface CreateRiskAssessmentProps {
    riskRegisterApi: RiskRegisterApi;
    documentApi: DocumentApi;
    tagsApi: TagsApi;
}

export const CreateRiskAssessment = (props: CreateRiskAssessmentProps): JSX.Element => {
    const [comments, setComments] = useState<string>();
    const [link, setLink] = useState<string>();
    const [documents, onAddDocuments, onRemoveDocument] = useFileDragAndDrop();
    const [riskCategory, setRiskCategory] = useState<string>();
    const [riskCategoryOptions, setRiskCategoryOptions] = useState<RiskCategoryResponse[]>();
    const [pageErrorMessage, setPageErrorMessage] = useState<string>();
    const [riskAssessments, setRiskAssessments] = useState<RiskAssessment[]>();
    const [risks, setRisks] = useState<RiskResponse[]>();
    const [submitState, setSubmitState] = useState<SubmitState>({ type: 'none' });
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);

    const latestAssessmentsByCategory: Map<string, RiskAssessment> = useMemo(() => {
        const latestAssessmentsByCategory = new Map<string, RiskAssessment>();

        if (!riskAssessments) {
            return latestAssessmentsByCategory;
        }

        riskAssessments.forEach((assessment) => {
            if (!latestAssessmentsByCategory.has(assessment.category.id) || assessment.timestamp > latestAssessmentsByCategory.get(assessment.category.id)!.timestamp) {
                latestAssessmentsByCategory.set(assessment.category.id, assessment);
            }
        });

        return latestAssessmentsByCategory;
    }, [riskAssessments]);

    const latestAssessment = riskCategory === undefined ? undefined : latestAssessmentsByCategory.get(riskCategory);

    const validateForm = () => {
        if (link) {
            const validationResult = validateUrl(link);
            if (validationResult['valid'] !== true) {
                throw Error(validationResult['message']);
            }
        }
    };

    const createAssessment = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            validateForm();
            if (riskCategory) {
                setSubmitState({ type: 'submitting' });
                await submitRequestWithFiles(
                    props.documentApi,
                    documents.map((document) => ({ file: document })),
                    async (newDocumentation) => {
                        const submitRiskAssessmentRequest: SubmitRiskAssessmentRequest = {
                            category: riskCategory,
                            comments: comments,
                            documents: newDocumentation,
                            link: link,
                        };
                        await props.riskRegisterApi.submitRiskAssessment(submitRiskAssessmentRequest);
                    }
                );
                setSubmitState({ type: 'success', message: 'Review submitted.' });
            }
        } catch (error) {
            setSubmitState({ type: 'failure', message: error.message });
        }
    };

    useEffect(() => {
        const getRisks = async (): Promise<void> => {
            try {
                const riskResponse: ResponseModel<RiskResponse[]> = await props.riskRegisterApi.getAllRisks();
                setRisks(riskResponse.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };

        const getRiskAssessments = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getAllRiskAssessments();
                setRiskAssessments(response.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };

        const getRiskCategories = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getRiskCategories();
                setRiskCategoryOptions(response.data);

                if (response.data.length === 1) {
                    setRiskCategory(response.data[0].id);
                }
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };

        getRisks();
        getRiskAssessments();
        getRiskCategories();
    }, [props.riskRegisterApi]);

    const fileDragAndDropProps: FileDragAndDropProps = {
        labelText: 'Additional Documents',
        onAddFiles: onAddDocuments,
        onRemoveFile: onRemoveDocument,
        files: documents,
        tooltip: <FormFieldTooltip text="An optional set of documents that support your review of the current state of risks in the selected category." />,
    };

    const subheaderText = (() => {
        if (!riskCategory) {
            return 'Select a risk category.';
        } else if (latestAssessment) {
            return `Last Review: ${iso8601ToUsDateShort(latestAssessment.timestamp)}`;
        } else {
            return 'Last Review: N/A';
        }
    })();

    if (pageErrorMessage) {
        return <Text>{pageErrorMessage}</Text>;
    }

    if (riskCategoryOptions && risks && riskAssessments && getDisplayableTagsState.type === 'success') {
        const risksForCategory = riskCategory === undefined ? [] : risks.filter((risk) => risk.category.id === riskCategory);

        return (
            <>
                {(submitState.type === 'success' || submitState.type === 'failure') && submitState.message && <LinkButtonToast variant={submitState.type} clearToast={() => setSubmitState({ type: 'success' })} text={submitState.message} linkButtonText={'View Review Listing'} linkButtonTo={`/${RISK_REGISTER}/${RISK_REVIEW}`} />}
                <PageBackground color="blueMountains">
                    <PageContent>
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${RISK_REGISTER}/${RISK_REVIEW}`}>Risk Reviews</BreadcrumbLink>
                            <BreadcrumbText>Create Risk Review</BreadcrumbText>
                        </Breadcrumb>
                        <Text variant="Header1" color="white">
                            Create Risk Review
                        </Text>
                    </PageContent>
                    <div className={styles.header}>
                        <PageContent>
                            <PageCell>
                                <div className={styles.assessmentHeader}>
                                    <div>
                                        <Text variant="Header2">Create Risk Review</Text>
                                        <Text variant="Header4">{subheaderText}</Text>
                                    </div>
                                    <div className={styles.categorySelect}>
                                        <FormFieldSelect formFieldLabel="Risk Category" isRequiredField formFieldId="risk_category" handleChange={(value: ChangeEventType) => setRiskCategory(value as string)} options={riskCategoryOptions.map((option) => ({ label: option.title, value: option.id }))} selectedOption={riskCategory} tooltip="The classification/grouping of risks that you are assessing. Risk categories are defined in Settings." />
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate onSubmit={createAssessment}>
                                    <fieldset disabled={riskCategory === undefined}>
                                        <div className={styles.fieldContainer}>
                                            <FormFieldTextArea formFieldLabel="Comments" formFieldId="comments" handleChange={(event: React.FormEvent<HTMLInputElement>) => setComments(event.currentTarget.value)} value={comments} tooltip="Any information relevant to your assessment of the current state of risks in the selected category." />
                                        </div>
                                        <div className={styles.fieldContainer}>
                                            <FormFieldText handleChange={(event: React.FormEvent<HTMLInputElement>) => setLink(event.currentTarget.value)} formFieldId="link" formFieldLabel="Link" value={link ?? ''} tooltip="An optional hyperlink that supports your assessment of the current state of risks in the selected category." />
                                        </div>
                                        <div className={styles.fieldContainer}>
                                            <FileDragAndDrop {...fileDragAndDropProps} />
                                        </div>
                                        <div className={styles.buttonContainer}>
                                            <Button variant="submit" isLoading={submitState.type === 'submitting'} loadingText="Submitting...">
                                                Submit
                                            </Button>
                                        </div>
                                    </fieldset>
                                </Form>
                            </PageCell>
                        </PageContent>
                    </div>
                </PageBackground>
                {riskCategory && (
                    <div className={styles.listing}>
                        <PageBackground color="white">
                            <PageContent>
                                <PageCell>
                                    <PrimaryTabs defaultActiveTab="riskListing" removePadding transparent>
                                        <Tab eventKey="riskListing" title="Risk Listing">
                                            <RiskTable type="current risks" risks={risksForCategory} getDisplayableTags={getDisplayableTagsState.data} openLinksInNewTabs />
                                        </Tab>
                                        <Tab eventKey="riskPeriodComparison" title="Risk Period Comparison">
                                            <RiskPeriodComparison riskAssessments={riskAssessments} risks={risksForCategory} selectedRiskCategory={riskCategory} />
                                        </Tab>
                                    </PrimaryTabs>
                                </PageCell>
                            </PageContent>
                        </PageBackground>
                    </div>
                )}
            </>
        );
    } else return <Placeholder />;
};
