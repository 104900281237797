import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { AlertDashlet } from 'Components/Alerts/AlertDashlet/AlertDashlet';
import { Button, Link } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import { ControlBarData, ControlBarGraph } from 'Components/Graphs/ControlBarGraph';
import ProgressBarIndicator from 'Components/Indicator/ProgressBarIndicator';
import AssociatedControlsModal, { AssociatedControlsModalProps } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import { RelatedControlAssessmentsModal, RelatedControlAssessmentsModalProps } from 'Components/Modal/RelatedControlAssessmentsModal/RelatedControlAssessmentsModal';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { AlertTooltipType } from 'Components/Tooltips/AlertsTooltip';
import { CONFIGURATION, FRAMEWORKS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { controlComparator } from 'Helpers/Compare';
import { getHumanReadableControlIdFromControlId, getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { AlertRequest, AlertResponse } from 'Models/Alerts';
import { ControlEffectivenessProgressBarVariantAndPercentage, ControlListingView, DetailedControlGroupResponse, Effectiveness, OperationalControl, effectivenessAsString, effectivenessLabelAsNumber, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';

import { ControlGridView } from './ControlGridView/ControlGridView';
import styles from './ControlGroupDetails.module.css';
import ControlListView from './ControlListView/ControlListView';
import ControlListingBodyToolbar, { ControlListingBodyToolbarProps } from './ControlListingBodyToolbar/ControlListingBodyToolbar';
import { CreateControlModal, CreateControlModalProps } from './CreateControlModal/CreateControlModal';

export enum Modals {
    CreateControlModal,
    ComingDueAssessmentModal,
    RecentlyCompletedAssessmentModal,
    OverdueAssessmentModal,
    DisplayControlByEffectiveness,
    None,
}

export interface ControlGroupDetailsProps {
    controlsApi: ControlsApi;
    alertsLimitsApi: AlertsLimitsApi;
}

export interface PathParams {
    controlFramework: string;
    controlGroupId: string;
}

export const ControlGroupDetails = (props: ControlGroupDetailsProps): JSX.Element => {
    const { controlFramework, controlGroupId } = useParams<keyof PathParams>() as PathParams;

    const [controlGroupResponse, setControlGroupResponse] = useState<DetailedControlGroupResponse>();
    const [alertResponse, setAlertResponse] = useState<AlertResponse[]>();
    const [controlBarGraphProps, setControlBarGraphProps] = useState<ControlBarData>({ fail: 0, inactive: 0, moderate: 0, robust: 0, strong: 0, weak: 0 });
    const [controlResponse, setControlResponse] = useState<OperationalControl[]>();
    const [activeControlListingView, setActiveControlListingView] = useState<ControlListingView>(ControlListingView.GRID);
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);
    const [controlsByEffectiveness, setControlsByEffectiveness] = useState<OperationalControl[]>();

    useEffect(() => {
        const getControlGroupConfiguration = async (): Promise<void> => {
            try {
                const controlGroupConfigurationResponse = await props.controlsApi.getControlGroupConfiguration(controlFramework, controlGroupId);
                if (controlGroupConfigurationResponse) {
                    setControlGroupResponse(controlGroupConfigurationResponse.data);
                }
            } catch (err) {
                handleRequestError(err);
            }
        };

        getControlGroupConfiguration();
    }, [controlFramework, controlGroupId, props.controlsApi]);

    useEffect(() => {
        const getControlGroupsAlerts = async (): Promise<void> => {
            try {
                const entityId = getOperationalControlIdentifierString(controlFramework, controlGroupId);
                const alertsRequest: AlertRequest = { entity_id: entityId };
                const alertsResponse = await props.alertsLimitsApi.getAllAlerts(alertsRequest);
                setAlertResponse(alertsResponse.data);
            } catch (err) {
                handleRequestError(err);
            }
        };
        getControlGroupsAlerts();
    }, [controlFramework, controlGroupId, props.alertsLimitsApi]);

    const displayAlertDashlet = (): JSX.Element => {
        let entityName;
        if (controlGroupResponse && controlGroupResponse.is_custom) {
            entityName = controlGroupResponse.control_group_name;
        } else if (controlGroupResponse && !controlGroupResponse.is_custom) {
            entityName = `${controlGroupResponse.control_framework} ${controlGroupResponse.control_group_id}`;
        } else {
            entityName = 'UNKNOWN';
        }

        if (alertResponse !== undefined && alertResponse.length > 0) {
            // The number of active limits may not be 0. Hardcoding it to 0 like this is more of a matter of tech debt than an actual desire to not show the active limits count. The Group page does not currently make a request to retrieve its limits.
            return <AlertDashlet alerts={[alertResponse[0]]} alertTooltipEntityName={entityName} alertTooltipType={AlertTooltipType.GROUP} numberOfLimits={0} />;
        } else {
            return <AlertDashlet alerts={[]} alertTooltipEntityName={entityName} alertTooltipType={AlertTooltipType.GROUP} numberOfLimits={0} />;
        }
    };

    const updateControls = useCallback(() => {
        const getControls = async (): Promise<void> => {
            try {
                const controlResponse = await props.controlsApi.getControls(controlFramework, controlGroupId, {});
                if (controlResponse) {
                    const controlsSorted = controlResponse.data.sort(controlComparator);
                    setControlResponse(controlsSorted);
                    addControlsToGraph(controlsSorted);
                }
            } catch (error) {
                handleRequestError(error);
            }
        };
        getControls();
    }, [controlFramework, controlGroupId, props.controlsApi]);

    useEffect(() => {
        updateControls();
    }, [updateControls]);

    const handleRequestError = (error: Error): void => {
        console.error(error.message);
    };

    const addControlsToGraph = (controlResponses: OperationalControl[]) => {
        let inactiveControls = 0;
        let failControls = 0;
        let weakControls = 0;
        let moderateControls = 0;
        let strongControls = 0;
        let robustControls = 0;
        for (const controlResponse of controlResponses) {
            if (numberAsEffectiveness(controlResponse.effectiveness.control_effectiveness) === Effectiveness.INACTIVE) {
                inactiveControls++;
            } else if (numberAsEffectiveness(controlResponse.effectiveness.control_effectiveness) === Effectiveness.FAIL) {
                failControls++;
            } else if (numberAsEffectiveness(controlResponse.effectiveness.control_effectiveness) === Effectiveness.WEAK) {
                weakControls++;
            } else if (numberAsEffectiveness(controlResponse.effectiveness.control_effectiveness) === Effectiveness.MODERATE) {
                moderateControls++;
            } else if (numberAsEffectiveness(controlResponse.effectiveness.control_effectiveness) === Effectiveness.STRONG) {
                strongControls++;
            } else if (numberAsEffectiveness(controlResponse.effectiveness.control_effectiveness) === Effectiveness.ROBUST) {
                robustControls++;
            }
        }
        setControlBarGraphProps({ fail: failControls, inactive: inactiveControls, moderate: moderateControls, robust: robustControls, strong: strongControls, weak: weakControls });
    };

    const onControlGraphClick = (item: string) => {
        if (effectivenessLabelAsNumber(item) !== undefined) {
            const filteredControls = controlResponse
                ?.filter((control) => effectivenessAsString(numberAsEffectiveness(control.effectiveness.control_effectiveness)) === item)
                .map((control) => {
                    return control;
                });
            if (filteredControls !== undefined) {
                setControlsByEffectiveness(filteredControls);
                setDisplayedModal(Modals.DisplayControlByEffectiveness);
            }
        }
    };

    if (controlResponse && controlGroupResponse && alertResponse) {
        const settingsUrl = (() => {
            const controlGroupId = `${controlGroupResponse!.control_framework}#${controlGroupResponse!.control_group_id}`;
            return `${getFrameworkGroupControlURL(controlGroupId)}/${CONFIGURATION}`;
        })();

        const associatedControlsModalProps: AssociatedControlsModalProps = (() => {
            return {
                associatedControls: controlsByEffectiveness ?? [],
                hideModal: () => setDisplayedModal(Modals.None),
            };
        })();

        const controlListingBodyToolbarProps: ControlListingBodyToolbarProps = {
            switchListingView: setActiveControlListingView,
            activeControlListingView: activeControlListingView,
        };

        const createControlModalProps: CreateControlModalProps = {
            hideModal: () => setDisplayedModal(Modals.None),
            onControlCreated: updateControls,
            controlsApi: props.controlsApi,
            controlFramework: controlFramework,
            controlGroupId: controlGroupId,
        };

        const progressBarValues = ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(controlGroupResponse.control_group_effectiveness));

        const overdueAssessmentsModalProps: RelatedControlAssessmentsModalProps = {
            hideModal: () => setDisplayedModal(Modals.None),
            relatedControlAssessmentDetails: controlGroupResponse.assessment_schedule.overdue.map((details) => {
                const controlId = `${details.control_framework}#${details.control_group_id}#${details.control_id}`;
                return {
                    controlId: controlId,
                    label: getHumanReadableControlIdFromControlId(controlId, details.control_name),
                    date: details.assessment_due_date,
                };
            }),
            title: 'Overdue Assessments',
        };

        const comingDueAssessmentsModalProps: RelatedControlAssessmentsModalProps = {
            hideModal: () => setDisplayedModal(Modals.None),
            relatedControlAssessmentDetails: controlGroupResponse.assessment_schedule.coming_due.map((details) => {
                const controlId = `${details.control_framework}#${details.control_group_id}#${details.control_id}`;
                return {
                    controlId: controlId,
                    label: getHumanReadableControlIdFromControlId(controlId, details.control_name),
                    date: details.assessment_due_date,
                };
            }),
            title: 'Assessments Due in the Next 14 Days',
        };

        const recentlyCompletedAssessmentsModalProps: RelatedControlAssessmentsModalProps = {
            hideModal: () => setDisplayedModal(Modals.None),
            relatedControlAssessmentDetails: controlGroupResponse.assessment_schedule.completed_recently.map((details) => {
                const controlId = `${details.control_framework}#${details.control_group_id}#${details.control_id}`;
                return {
                    controlId: controlId,
                    label: getHumanReadableControlIdFromControlId(controlId, details.control_name),
                    date: details.timestamp,
                };
            }),
            title: 'Assessments Completed in the Last 14 Days',
        };

        // Call the child "ControlListing" component to render the HTML that allows viewing/sorting/filtering controls.
        return (
            <>
                {displayedModal === Modals.DisplayControlByEffectiveness && controlsByEffectiveness && <AssociatedControlsModal {...associatedControlsModalProps} />}
                {displayedModal === Modals.CreateControlModal && <CreateControlModal {...createControlModalProps} />}
                {displayedModal === Modals.OverdueAssessmentModal && <RelatedControlAssessmentsModal {...overdueAssessmentsModalProps} />}
                {displayedModal === Modals.ComingDueAssessmentModal && <RelatedControlAssessmentsModal {...comingDueAssessmentsModalProps} />}
                {displayedModal === Modals.RecentlyCompletedAssessmentModal && <RelatedControlAssessmentsModal {...recentlyCompletedAssessmentsModalProps} />}
                {/* Page Content */}
                <PageBackground color="blueMountains">
                    <PageContent>
                        <div className={styles.headerTitleContainer}>
                            <div>
                                <Breadcrumb textColor="white">
                                    <BreadcrumbLink link={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}#${controlGroupResponse.control_framework}`}>{controlGroupResponse.control_framework}</BreadcrumbLink>
                                    <BreadcrumbText>{controlGroupResponse.control_group_name}</BreadcrumbText>
                                </Breadcrumb>
                                <div className={styles.titleContainer}>
                                    <Text variant="Header1" color="white">
                                        {controlGroupResponse.is_custom ? controlGroupResponse.control_group_name : controlGroupResponse.control_group_id + '. ' + controlGroupResponse.control_group_name}
                                    </Text>
                                    {!controlGroupResponse.enabled && (
                                        <Text color="red" variant="Header1">
                                            &nbsp;(DISABLED)
                                        </Text>
                                    )}
                                </div>
                                <Text color="darkGray" variant="Header2">
                                    {controlGroupResponse.control_group_description}
                                </Text>
                            </div>
                            <div className={styles.headerButtons}>
                                <RBACComponent roles={[Role.ADMIN]}>
                                    <div className={styles.createButton}>
                                        <Button variant="primary" onClick={() => setDisplayedModal(Modals.CreateControlModal)} fontAwesomeImage={faPlus}>
                                            CREATE CONTROL
                                        </Button>
                                    </div>
                                    <Link variant="primaryButton" to={settingsUrl} fontAwesomeImage={faCog}>
                                        SETTINGS
                                    </Link>
                                </RBACComponent>
                            </div>
                        </div>
                        <div className={styles.graphs}>
                            <div className={styles.doughnutChart}>
                                <Text color="white" variant="Header3">
                                    Control Group Effectiveness
                                </Text>
                                <Text noStyles color="darkGray" variant="Text2">
                                    {numberAsEffectivenessString(controlGroupResponse.control_group_effectiveness)}
                                </Text>
                                <div className={styles.progressBar}>
                                    <ProgressBarIndicator variant={progressBarValues.variant} size="large" percent={progressBarValues.percent} />
                                    {controlGroupResponse.target_effectiveness && (
                                        <Text color="darkGray" variant="Text3" noStyles>
                                            {`Target Effectiveness: ${numberAsEffectivenessString(controlGroupResponse.target_effectiveness)}`}
                                        </Text>
                                    )}
                                </div>
                                <Text color="white" variant="Header3">
                                    Control Breakdown
                                </Text>
                                <div className={styles.graphContainer}>
                                    <ControlBarGraph controlBarData={controlBarGraphProps} yAxisLabel="NUMBER OF CONTROLS" onItemClick={onControlGraphClick} />
                                </div>
                            </div>
                            <div className={styles.alertsAssessment}>
                                <div className={styles.assessmentSchedule}>
                                    <div className={styles.boxTitle}>
                                        <Text color="white" variant="Header3">
                                            Assessment Schedule
                                        </Text>
                                        <hr />
                                    </div>
                                    <div onClick={() => setDisplayedModal(Modals.OverdueAssessmentModal)} className={styles.assessmentScheduleBox}>
                                        <div className={styles.assessmentTitle}>
                                            <Text noStyles color={controlGroupResponse.assessment_schedule.overdue.length === 0 ? 'darkGray' : 'yellow'}>
                                                Overdue
                                            </Text>
                                        </div>
                                        <div className={controlGroupResponse.assessment_schedule.overdue.length === 0 ? styles.assessmentNumber : styles.overdueNumber}>{controlGroupResponse.assessment_schedule.overdue.length}</div>
                                    </div>
                                    <div onClick={() => setDisplayedModal(Modals.ComingDueAssessmentModal)} className={styles.assessmentScheduleBox}>
                                        <div className={styles.assessmentTitle}>
                                            <Text noStyles color="darkGray">
                                                Due next 14 days
                                            </Text>
                                        </div>
                                        <div className={styles.assessmentNumber}>{controlGroupResponse.assessment_schedule.coming_due.length}</div>
                                    </div>
                                    <div onClick={() => setDisplayedModal(Modals.RecentlyCompletedAssessmentModal)} className={styles.assessmentScheduleBox}>
                                        <div className={styles.assessmentTitle}>
                                            <Text noStyles color="darkGray">
                                                Completed last 14 days
                                            </Text>
                                        </div>
                                        <div className={styles.assessmentNumber}>{controlGroupResponse.assessment_schedule.completed_recently.length}</div>
                                    </div>
                                </div>
                                <div className={styles.alertsBox}>{displayAlertDashlet()}</div>
                            </div>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        {(() => {
                            if (controlResponse.length === 0) {
                                return (
                                    <div className={styles.allControlsDisabledContainer}>
                                        <Text>There are no enabled controls.</Text>
                                    </div>
                                );
                            } else if (activeControlListingView === 'list') {
                                return (
                                    <Fragment>
                                        <ControlListingBodyToolbar {...controlListingBodyToolbarProps} />
                                        <ControlListView controls={controlResponse} />
                                    </Fragment>
                                );
                            } else {
                                return (
                                    <Fragment>
                                        <ControlListingBodyToolbar {...controlListingBodyToolbarProps} />
                                        <ControlGridView controlResponse={controlResponse} />
                                    </Fragment>
                                );
                            }
                        })()}
                    </PageContent>
                </PageBackground>
            </>
        );
    }

    return <Placeholder />;
};
