import { LineAndScatterChart, LineAndScatterChartProps, LineAndScatterChartType } from 'Components/BaseCharts/LineAndScatterChart';
import { StackedBarAndLineChart, StackedBarAndLineChartProps } from 'Components/BaseCharts/StackedBarAndLineChart';
import { ChartSeries } from 'Components/BaseCharts/charts.common';
import { Carousel } from 'Components/Carousel/Carousel';
import { CarouselItem } from 'Components/Carousel/CarouselItem/CarouselItem';
import { CSSColors } from 'Components/Colors';
import Text from 'Components/Text/Text';
import { iso8601ToUsDateLong, iso8601ToUsTimestampLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { GetControlEvidenceIntegrationResponse, GetControlEvidenceMetricResponse } from 'Models/ControlEvidence';
import { IntegrationName, MetricName } from 'Models/ExternalIntegrations';

import styles from './Performance.module.css';

// Predefined colors for the graphs.
// If there are more than 5 datasets, Apache eCharts provides an additional 9 colors by default (https://echarts.apache.org/en/option.html#color).
// If there are more than 14 datasets, the 9 default colors provided by Apache eCharts are repeatedly cycled through.
// Previously, this component would randomly generate colors beyond the 5 defaults, so something like that can be reimplemented if reusing the Apache eCharts defaults over and over is deemed unacceptable.
// See https://github.com/high-peaks-solutions/project-summit-client/pull/2162 for the previous implementation.
const COLORS = [CSSColors.BLUE, CSSColors.YELLOW, CSSColors.LIGHT_BLUE, CSSColors.DARK_GRAY, CSSColors.RED];

interface ControlPerformanceModel {
    integrationName: string;
    metricName: string;
    graphData: LineAndScatterChartProps | StackedBarAndLineChartProps;
}

export interface PerformanceProps {
    automatedEvidence: GetControlEvidenceIntegrationResponse[];
}

export const Performance = (props: PerformanceProps): JSX.Element => {
    /**
     * Create the dataset for a line-and-scatter graph.
     */
    const createDatasetLineAndScatter = (integrationName: IntegrationName, metric: GetControlEvidenceMetricResponse, datasetLabels: string[]): ControlPerformanceModel => {
        const metricLabels = metric.metric_values.map((singleMetric) => iso8601ToUsDateLong(singleMetric.metric_timestamp));
        const metricLabelsForTooltips = metric.metric_values.map((singleMetric) => iso8601ToUsTimestampLong(singleMetric.metric_timestamp));

        const dataSets: ChartSeries[] = datasetLabels.map((label, index) => {
            const chartSeries: ChartSeries = {
                color: COLORS[index], // This will be undefined if "index" is greater than 4, which leads to the Apache eCharts default colors being used.
                data: metric.metric_values.map((value) => parseFloat(value.metric_value[label])),
                name: label,
            };
            return chartSeries;
        });

        const chartProp: LineAndScatterChartProps = {
            onChartClick: () => {
                return;
            },
            type: LineAndScatterChartType.LINE,
            series: dataSets,
            xAxisLabels: metricLabels,
            xAxisLabelsForTooltips: metricLabelsForTooltips,
            yAxisInfo: [
                {
                    position: 'left',
                    min: 0,
                },
            ],
            variant: 'light',
            paddingVariant: 'sm',
        };

        return { integrationName: integrationName, metricName: metric.metric_name, graphData: chartProp };
    };

    /**
     * Create the dataset for a stacked bar graph.
     */
    const createDatasetSimulatedPhishingCampaignResults = (integrationName: IntegrationName, metric: GetControlEvidenceMetricResponse, datasetLabels: string[]): ControlPerformanceModel => {
        const metricLabels = metric.metric_values.map((singleMetric) => iso8601ToUsDateLong(singleMetric.metric_timestamp));
        const metricLabelsForTooltips = metric.metric_values.map((singleMetric) => singleMetric.metric_value['Campaign Name']);

        const dataSets: ChartSeries[] = datasetLabels.map((label, index) => {
            const chartSeries: ChartSeries = {
                color: COLORS[index], // This will be undefined if "index" is greater than 4, which leads to the Apache eCharts default colors being used.
                data: metric.metric_values.map((value) => parseFloat(value.metric_value[label])),
                name: label,
            };
            return chartSeries;
        });

        const chartProp: StackedBarAndLineChartProps = {
            bars: dataSets,
            onChartClick: () => {
                return;
            },
            variant: 'light',
            xAxisLabels: metricLabels,
            xAxisLabelsForTooltips: metricLabelsForTooltips,
            yAxisInfo: [
                {
                    position: 'left',
                    max: 100,
                    min: 0,
                },
            ],
        };

        return { integrationName: integrationName, metricName: metric.metric_name, graphData: chartProp };
    };

    /**
     * Parse the labels that will be used for the datasets and legends on the graph.
     */
    const parseDatasetLabels = (metric: GetControlEvidenceMetricResponse): string[] => {
        const datasetLabels: Set<string> = new Set();
        // We get all of the keys from every metric value, rather than doing something like just getting them from the first element in the metric_values array, in order to handle the possible scenario where the datasets differ from one day to another.
        // For example:
        // 1. In the BITSIGHT CRITICAL_THIRD_PARTY_SECURITY_RATINGS Metric, the Client may change their list of "Critical Third Parties".
        // 2. High Peaks Solutions may change the name of a dataset label, so this prevents needing to perform a migration to update all previous values. Although that is probably still advisable to avoid confusion.
        metric.metric_values.forEach((element) => {
            Object.keys(element.metric_value).forEach((key) => {
                datasetLabels.add(key);
            });
        });

        // Convert the set of dataset labels to an array.
        return [...datasetLabels];
    };

    /**
     * Render graphs for Control Performance (Metrics).
     * The majority of Metrics can be handled in a generic way, but some require special handling.
     */
    const drawGraphs = (dataset: ControlPerformanceModel): JSX.Element => {
        if (dataset.integrationName === IntegrationName.PROOFPOINT_SECURITY_AWARENESS_TRAINING && dataset.metricName === MetricName.SIMULATED_PHISHING_CAMPAIGN_RESULTS) {
            return <StackedBarAndLineChart {...(dataset.graphData as StackedBarAndLineChartProps)} />;
        } else {
            return <LineAndScatterChart {...(dataset.graphData as LineAndScatterChartProps)} />;
        }
    };

    const render = (): JSX.Element => {
        const graphProps: ControlPerformanceModel[] = [];

        props.automatedEvidence.forEach((integration) => {
            integration.metrics.forEach((metric) => {
                if (integration.integration_name === IntegrationName.PROOFPOINT_SECURITY_AWARENESS_TRAINING && metric.metric_name === MetricName.SIMULATED_PHISHING_CAMPAIGN_RESULTS) {
                    graphProps.push(createDatasetSimulatedPhishingCampaignResults(integration.integration_name, metric, ['Clicked Percent', 'Reported Percent']));
                } else {
                    graphProps.push(createDatasetLineAndScatter(integration.integration_name, metric, parseDatasetLabels(metric)));
                }
            });
        });

        // Wrap graphs in a carousel if there are more than one.
        if (graphProps.length === 1) {
            return (
                <div className={styles.graphContainer}>
                    <Text variant="Header3">{graphProps[0].integrationName}</Text>
                    <Text variant="Text4" color="darkGray">
                        {graphProps[0].metricName}
                    </Text>
                    <div className={styles.mainGraph}>{drawGraphs(graphProps[0])}</div>
                </div>
            );
        } else {
            return (
                <Carousel>
                    {graphProps.map((item: ControlPerformanceModel) => (
                        <CarouselItem key={`${item.integrationName}: ${item.metricName}`}>
                            <div className={styles.graphContainer}>
                                <Text variant="Header3">{item.integrationName}</Text>
                                <Text variant="Text4" color="darkGray">
                                    {item.metricName}
                                </Text>
                                <div className={styles.mainGraph}>{drawGraphs(item)}</div>
                            </div>
                        </CarouselItem>
                    ))}
                </Carousel>
            );
        }
    };

    return render();
};
