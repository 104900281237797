import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import Text from 'Components/Text/Text';

import { Framework, QuestionnaireConfigurationModal } from '../DueDiligenceQuestionnaireConfiguration';

export interface DeleteControlFrameworkModalProps extends QuestionnaireConfigurationModal {
    framework: Framework;
}

const DeleteControlFrameworkModal = (props: DeleteControlFrameworkModalProps): JSX.Element => {
    const deleteFramework = async (): Promise<string> => {
        await props.tprmApi.deleteControlFrameworkConfiguration(props.framework.framework);
        props.onModalActionComplete();
        return 'Framework deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Framework Configuration" areYouSureText="Are you sure you want to delete the following framework?" performOperation={deleteFramework} hideModal={props.hideModal}>
            <Text>{props.framework.frameworkName}</Text>
        </ConfirmationModal>
    );
};

export default DeleteControlFrameworkModal;
