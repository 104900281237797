import { AWSPublicDDQAuthApi } from 'Api/Auth/PublicDDQ/AWSPublicDDQAuthApi';
import { PublicDDQAuthApi } from 'Api/Auth/PublicDDQ/PublicDDQAuthApi';
import { PUBLIC } from 'Config/Paths';
import LocalStorageStringRepository from 'Helpers/LocalStorageStringRepository';
import { StringRepository } from 'Helpers/StringRepository';
import WindowNavigator from 'Helpers/WindowNavigator';

import { BasicAuthHandler } from './BasicAuthHandler';
import { Navigator } from '../../Navigator';

export const PUBLIC_DDQ_BASE64_CREDS = 'public_ddq_base64_creds';

export default class PublicDDQBasicAuthHandler implements BasicAuthHandler {
    authApi: PublicDDQAuthApi;
    navigator: Navigator;
    stringRepository: StringRepository;

    constructor(authApi: PublicDDQAuthApi = new AWSPublicDDQAuthApi(), navigator: Navigator = new WindowNavigator(), stringRepository: StringRepository = new LocalStorageStringRepository()) {
        this.authApi = authApi;
        this.navigator = navigator;
        this.stringRepository = stringRepository;
    }

    login = async (username: string, password: string, client_id: string, vendor_id: string, vendor_service_id: string): Promise<void> => {
        const passwordAndIds = JSON.stringify({ password: password, client_id: client_id, vendor_id: vendor_id, vendor_service_id: vendor_service_id });
        const encodedCredentials = window.btoa(`${username}:${passwordAndIds}`);
        await this.authApi.isAuthorized(encodedCredentials);
        this.stringRepository.setItem(PUBLIC_DDQ_BASE64_CREDS, encodedCredentials);
    };

    logout = (reason?: string): void => {
        this.stringRepository.removeItem(PUBLIC_DDQ_BASE64_CREDS);
        this.navigator.navigateTo(`/${PUBLIC}/${reason}`);
    };

    getBase64EncodedCredentials = (): string | null => this.stringRepository.getItem(PUBLIC_DDQ_BASE64_CREDS);
}
