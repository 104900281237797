import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { FileToBeUploaded, FileUpdates, UploadedFile } from 'Models/Files';
import { GroupOptionType, OptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import { OperationalControl } from './OperationalControls';
import { vendorNameComparator } from './TPRM';

// TODO: move to a central location, since this is also used by Exceptions.
export enum IssuesExceptionsModule {
    CONTROLS = 'Control Automation',
    TPRM = 'Third-Party Risk Management',
}

interface BaseIssue {
    id: string;
    owner_subject: string;
    delegates: string[];
    title: string;
    due_date: string;
    description: string;
    priority: IssuePriority;
    source: string;
    created_timestamp: string;
    created_by: string;
    last_updated_by: string;
    last_updated_timestamp: string;
    remediation_plan: string;
    risk_assessment: string;
    files: UploadedFile[];
    reference?: string;
}

export interface OpenIssue extends BaseIssue {
    status: IssueStatus.DRAFT_OPEN | IssueStatus.OPEN;
}

export interface ClosedIssue extends BaseIssue {
    status: IssueStatus.CLOSED;
    closure_statement: string;
    closed_timestamp: string;
    closed_by: string;
}

type OpenOrClosedIssue = OpenIssue | ClosedIssue;

/**
 * Matches the backend's domain object. Used directly only for Notifications.
 * TODO: Can this be reworked? Why aren't we using DTOs for notifications?
 */
export type Issue = OpenOrClosedIssue & {
    impacted_controls: string[];
    impacted_vendor?: string;
};

export type ControlIssueResponse = OpenOrClosedIssue & { type: IssuesExceptionsModule.CONTROLS; impacted_controls: OperationalControl[] };
export type VendorIssueResponse = OpenOrClosedIssue & { type: IssuesExceptionsModule.TPRM; impacted_vendor: string };
export type IssueResponse = ControlIssueResponse | VendorIssueResponse;

export interface CreateIssueRequest {
    status: IssueStatus.DRAFT_OPEN | IssueStatus.OPEN;
    owner_subject: string;
    delegates: string[];
    title: string;
    due_date: string;
    description: string;
    priority: IssuePriority;
    source: string;
    remediation_plan: string;
    risk_assessment: string;
    files: FileToBeUploaded[];
    reference?: string;
    impacted_controls: string[];
    impacted_vendor?: string;
}

interface BaseUpdateIssueRequest {
    owner_subject: string;
    delegates: string[];
    title: string;
    due_date: string;
    description: string;
    priority: IssuePriority;
    source: string;
    remediation_plan: string;
    risk_assessment: string;
    file_updates: FileUpdates;
    reference?: string;
    impacted_controls: string[];
    impacted_vendor?: string;
}

interface UpdateIssueWithoutClosingRequest extends BaseUpdateIssueRequest {
    status: IssueStatus.DRAFT_OPEN | IssueStatus.OPEN;
}

interface CloseIssueRequest extends BaseUpdateIssueRequest {
    status: IssueStatus.CLOSED;
    closure_statement: string;
}

export type UpdateIssueRequest = UpdateIssueWithoutClosingRequest | CloseIssueRequest;

export interface ControlIssueHistoryResponse {
    timestamp: string;
    issue: ControlIssueResponse;
}

export interface VendorIssueHistoryResponse {
    timestamp: string;
    issue: VendorIssueResponse;
}

export type IssueHistoryResponse = ControlIssueHistoryResponse | VendorIssueHistoryResponse;

export enum IssuesSortFilterOptions {
    TITLE = 'title',
    DUE_DATE = 'due_date',
    DESCRIPTION = 'description',
    SOURCE = 'source',
    OWNER = 'owner_subject',
    PRIORITY = 'priority',
    CONTROLS = 'controls',
    VENDOR = 'impacted_vendor',
    STATUS = 'status',
}

export enum IssuePriority {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export const titleCaseIssuePriority = (priority: IssuePriority): string => {
    switch (priority) {
        case IssuePriority.LOW:
            return 'Low';
        case IssuePriority.MEDIUM:
            return 'Medium';
        case IssuePriority.HIGH:
            return 'High';
    }
};

const priorityNumberValue = (priority: IssuePriority) => {
    switch (priority) {
        case IssuePriority.LOW:
            return 0;
        case IssuePriority.MEDIUM:
            return 1;
        case IssuePriority.HIGH:
            return 2;
    }
};

export const priorityComparator = (priorityA: IssuePriority, priorityB: IssuePriority): number => {
    if (priorityA === priorityB) {
        return 0;
    } else {
        const valueA = priorityNumberValue(priorityA);
        const valueB = priorityNumberValue(priorityB);
        return valueA < valueB ? 1 : -1;
    }
};

export const IssuePriorityOptions: OptionType[] = [
    {
        value: IssuePriority.LOW,
        label: titleCaseIssuePriority(IssuePriority.LOW),
    },
    {
        value: IssuePriority.MEDIUM,
        label: titleCaseIssuePriority(IssuePriority.MEDIUM),
    },
    {
        value: IssuePriority.HIGH,
        label: titleCaseIssuePriority(IssuePriority.HIGH),
    },
];

export const IssuePriorityFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: IssuesSortFilterOptions.PRIORITY,
        label: 'Low',
        value: IssuePriority.LOW,
    },
    {
        groupId: IssuesSortFilterOptions.PRIORITY,
        label: 'Medium',
        value: IssuePriority.MEDIUM,
    },
    {
        groupId: IssuesSortFilterOptions.PRIORITY,
        label: 'High',
        value: IssuePriority.HIGH,
    },
];

export enum IssuePriorityFilter {
    ALL = 'ALL',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export const isValidIssuePriorityFilter = (value: unknown): value is IssuePriorityFilter => typeof value === 'string' && Object.values(IssuePriorityFilter).includes(value as IssuePriorityFilter);

export const titleCaseIssuePriorityFilter = (priority: IssuePriorityFilter): string => {
    switch (priority) {
        case IssuePriorityFilter.ALL:
            return 'All Priorities';
        case IssuePriorityFilter.LOW:
            return 'Low';
        case IssuePriorityFilter.MEDIUM:
            return 'Medium';
        case IssuePriorityFilter.HIGH:
            return 'High';
    }
};

export const IssuePriorityFilterOptions: OptionType[] = [
    {
        value: IssuePriorityFilter.ALL,
        label: titleCaseIssuePriorityFilter(IssuePriorityFilter.ALL),
    },
    {
        value: IssuePriorityFilter.LOW,
        label: titleCaseIssuePriorityFilter(IssuePriorityFilter.LOW),
    },
    {
        value: IssuePriorityFilter.MEDIUM,
        label: titleCaseIssuePriorityFilter(IssuePriorityFilter.MEDIUM),
    },
    {
        value: IssuePriorityFilter.HIGH,
        label: titleCaseIssuePriorityFilter(IssuePriorityFilter.HIGH),
    },
];

export enum IssueStatus {
    DRAFT_OPEN = 'DRAFT_OPEN',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

export const IssueStatusFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: IssuesSortFilterOptions.STATUS,
        label: 'Open',
        value: IssueStatus.OPEN,
    },
    {
        groupId: IssuesSortFilterOptions.STATUS,
        label: 'Draft Open',
        value: IssueStatus.DRAFT_OPEN,
    },
    {
        groupId: IssuesSortFilterOptions.STATUS,
        label: 'Closed',
        value: IssueStatus.CLOSED,
    },
];

export const statusComparator = (statusA: IssueStatus, statusB: IssueStatus): number => {
    const statusValue = (status: IssueStatus): number => {
        switch (status) {
            case IssueStatus.DRAFT_OPEN:
                return 0;
            case IssueStatus.OPEN:
                return 1;
            case IssueStatus.CLOSED:
                return 2;
        }
    };

    const statusAValue = statusValue(statusA);
    const statusBValue = statusValue(statusB);
    return statusAValue - statusBValue;
};

export const titleCaseIssueStatus = (status: IssueStatus): string => {
    switch (status) {
        case IssueStatus.DRAFT_OPEN:
            return 'Draft Open';
        case IssueStatus.OPEN:
            return 'Open';
        case IssueStatus.CLOSED:
            return 'Closed';
    }
};

export const sortIssues = (issues: IssueResponse[], users: UserResponse[], sortBy: IssuesSortFilterOptions, sortDirection: SortDirection, vendorIdToNameMap: Map<string, string> = new Map([])): void => {
    issues.sort((issueA, issueB) => {
        let sortResult = 0;

        switch (sortBy) {
            case IssuesSortFilterOptions.OWNER:
                sortResult = compareUsersBySubjectForSorting(issueA.owner_subject, issueB.owner_subject, users);
                break;
            case IssuesSortFilterOptions.DUE_DATE:
                sortResult = issueA.due_date < issueB.due_date ? -1 : 1;
                break;
            case IssuesSortFilterOptions.PRIORITY:
                sortResult = priorityComparator(issueA.priority, issueB.priority);
                break;
            case IssuesSortFilterOptions.STATUS:
                sortResult = statusComparator(issueA.status, issueB.status);
                break;
            case IssuesSortFilterOptions.VENDOR:
                if (issueA.type === IssuesExceptionsModule.TPRM && issueB.type === IssuesExceptionsModule.TPRM) {
                    sortResult = vendorNameComparator(issueA.impacted_vendor, issueB.impacted_vendor, vendorIdToNameMap);
                } else {
                    throw new Error('Cannot sort Operational Controls issues by vendor.');
                }
                break;
            case IssuesSortFilterOptions.CONTROLS:
                if (issueA.type === IssuesExceptionsModule.CONTROLS && issueB.type === IssuesExceptionsModule.CONTROLS) {
                    sortResult = issueA.impacted_controls.length - issueB.impacted_controls.length;
                } else {
                    throw new Error('Cannot sort TPRM issues by control.');
                }
                break;
            default:
                sortResult = issueA[sortBy].localeCompare(issueB[sortBy]);
                break;
        }

        return sortDirection === SortDirection.ASC ? sortResult : -sortResult;
    });
};

/**
 * This relatively simple function exists because we have previously had inconsistencies in whether drafts are considered "open".
 * @returns The number of issues in the provided list that are considered "open".
 */
export const countOpenIssues = (issues: IssueResponse[]): number => issues.filter((issue) => issue.status === IssueStatus.OPEN).length;
