// Functions for performing validation on input fields that are generic in nature. Input validation for fields that are specific to a feature are defined within a given component.

import { ValidationError } from '../Models/ErrorTypes';

// Confirm a string is a valid URL. At the moment we only care that it contains a protocol at the beginning. At some point in the future this may be expanded to be more strict.
// Being pretty liberal with the definition of a protocol. Everything I can think of should be covered by this (http, https, smb, file, ftp, ftps, sftp, scp, etc...)
// Ensure this matches Common/hps_common/declarative2/hps/dataclass_validation.py/URL_REGEX in the back-end code.
export const validateUrl = (url: string): { valid: boolean; message: string } => {
    if (/^[a-zA-Z]{2,6}:\/\//.test(url) === false) {
        return { valid: false, message: 'URL must start with a protocol, such as https://' };
    } else {
        return { valid: true, message: '' };
    }
};

// Put some restrictions on files that can be uploaded. If updating this function, be sure to update the corresponding validation on the server-side (fileprocessing/port.py).
// File size limitations occur here, when the presigned POST/URL is generated, and when the file is processed on the server-side.
export const validateFileForUpload = (file: File): void => {
    const validMimeTypes = [];
    // Plaintext.
    validMimeTypes.push('text/plain');
    validMimeTypes.push('text/csv');
    validMimeTypes.push('text/xml');
    validMimeTypes.push('application/xml');
    validMimeTypes.push('application/rtf');
    validMimeTypes.push('application/json');
    // Office/PDF.
    validMimeTypes.push('application/msword');
    validMimeTypes.push('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    validMimeTypes.push('application/vnd.ms-excel');
    validMimeTypes.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    validMimeTypes.push('application/vnd.ms-powerpoint');
    validMimeTypes.push('application/vnd.openxmlformats-officedocument.presentationml.presentation');
    validMimeTypes.push('application/pdf');
    // Images.
    validMimeTypes.push('image/bmp');
    validMimeTypes.push('image/gif');
    validMimeTypes.push('image/jpeg');
    validMimeTypes.push('image/png');
    // Compressed/Archived.
    validMimeTypes.push('application/zip');
    validMimeTypes.push('application/x-zip-compressed');
    validMimeTypes.push('application/x-7z-compressed');
    validMimeTypes.push('application/gzip');
    validMimeTypes.push('application/x-tar');

    if (!validMimeTypes.includes(file.type)) {
        throw new ValidationError('Invalid file type. Valid types are plaintext, Office/PDF, images, and archives.');
    }

    if (file.size < 1 || file.size > 52428800) {
        throw new ValidationError('Invalid file size. Must not be empty and must not be over 50 MiB.');
    }

    if (!/^[ a-zA-Z0-9!_.'(),&-]+$/.test(file.name)) {
        throw new ValidationError("Invalid character in filename. Valid characters are letters, numbers, spaces, and any of !_.'(),&-");
    }
};

export const validateEmail = (email: string): boolean => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
};

export const validateEin = (ein: string): boolean => {
    return /^[0-9-]*$/.test(ein);
};

/**
 * Verifies that a password:
 *   Is between 8 and 128 characters.
 *   Has at least 3 of the following:
 *     A lower-case letter.
 *     An upper-case letter.
 *     A number.
 *     A special character.
 *
 * NIST SP 800-63B should be used for password guidance (https://pages.nist.gov/800-63-3/sp800-63b.html).
 */
export const validatePassword = (password: string): boolean => {
    // Check password length.
    if (/^.{8,128}$/.test(password) === false) {
        return false;
    }

    // Check password complexity.
    let complexityCount = 0;
    if (/[a-z]/.test(password)) {
        complexityCount += 1;
    }
    if (/[A-Z]/.test(password)) {
        complexityCount += 1;
    }
    if (/[0-9]/.test(password)) {
        complexityCount += 1;
    }
    if (/[^a-zA-Z0-9]/.test(password)) {
        complexityCount += 1;
    }
    if (complexityCount < 3) {
        return false;
    }

    return true;
};
