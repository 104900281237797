import { faCog } from '@fortawesome/free-solid-svg-icons';
import { ReactNode } from 'react';

import { AlertDashlet } from 'Components/Alerts/AlertDashlet/AlertDashlet';
import { Link } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import Text from 'Components/Text/Text';
import { AlertTooltipType } from 'Components/Tooltips/AlertsTooltip';
import { getFrameworkGroupControlParts, getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { AlertResponse } from 'Models/Alerts';
import { LimitResponse } from 'Models/Limits';
import { AssessmentDetailsResponse, OperationalControl } from 'Models/OperationalControls';

import { ControlAssessmentDashlet } from './ControlAssessmentDashlet/ControlAssessmentDashlet';
import styles from './ControlDetailsHeader.module.css';
import { ControlMetadata } from './ControlMetadata/ControlMetadata';
import { ControlText } from '../ControlText/ControlText';

export interface ControlDetailsHeaderProps {
    alertsResponse: AlertResponse[];
    breadcrumb: ReactNode;
    detailedControlResponse: OperationalControl;
    settingsLinkData: { to: string; state: Record<string, any> };
    limitResponse: LimitResponse[];
    assessmentDetailsResponse?: AssessmentDetailsResponse;
}

export const ControlDetailsHeader = (props: ControlDetailsHeaderProps) => {
    const displayAlertDashlet = (): JSX.Element => {
        const enabledLimitsCount = props.limitResponse.filter((limit) => limit.enabled).length;

        let entityName;
        if (props.detailedControlResponse.metadata.is_custom) {
            entityName = props.detailedControlResponse.metadata.control_name;
        } else if (props.detailedControlResponse.metadata.is_custom === false) {
            const { controlFramework, controlId } = getFrameworkGroupControlParts(props.detailedControlResponse);
            entityName = `${controlFramework}: ${controlId}`;
        } else {
            entityName = 'UNKNOWN';
        }

        return <AlertDashlet alerts={props.alertsResponse} alertTooltipEntityName={entityName} alertTooltipType={AlertTooltipType.CONTROL} numberOfLimits={enabledLimitsCount} />;
    };

    return (
        <PageBackground color="blueMountains">
            <PageContent>
                <div className={styles.titleAndDescriptionContainer}>
                    {props.breadcrumb}
                    <div className={styles.titleContainer}>
                        <div className={styles.title}>
                            <Text color="white" variant="Header1">
                                {getHumanReadableControlIdFromControl(props.detailedControlResponse)}
                            </Text>
                            {!props.detailedControlResponse.configuration.enabled && (
                                <Text color="red" variant="Header1">
                                    &nbsp;(DISABLED)
                                </Text>
                            )}
                        </div>
                        <div className={styles.controlDetailsHeaderContainer}>
                            <RBACComponent roles={[Role.ADMIN]}>
                                <Link variant="primaryButton" to={props.settingsLinkData.to} state={props.settingsLinkData.state} fontAwesomeImage={faCog} disabled={!props.detailedControlResponse.configuration.enabled}>
                                    SETTINGS
                                </Link>
                            </RBACComponent>
                        </div>
                    </div>
                    <div className={styles.controlDescription}>
                        <Text color="darkGray" variant="Header2">
                            <ControlText controlText={props.detailedControlResponse.metadata.control_text} />
                        </Text>
                    </div>
                </div>
                <div className={styles.container}>
                    <ControlAssessmentDashlet detailedControlResponse={props.detailedControlResponse} assessmentDetailsResponse={props.assessmentDetailsResponse} />
                    <ControlMetadata detailedControlResponse={props.detailedControlResponse} />
                    <div className={styles.sectionContainer}>
                        <PageCell variant="transparentBlue">{displayAlertDashlet()}</PageCell>
                    </div>
                </div>
            </PageContent>
        </PageBackground>
    );
};
