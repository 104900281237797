import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { ExceptionHistoryResponse } from 'Models/Exceptions';

import { ExceptionDetailsSnapshot } from '../ExceptionDetailsSnapshot/ExceptionDetailsSnapshot';

export interface ExceptionHistoryModalProps {
    history: ExceptionHistoryResponse;
    documentApi: DocumentApi;
    hideModal: () => void;
}

/**
 * Renders a modal that displays a detailed historical snapshot of an exception. Matches the "main" section (containing current information) of the Exception Details page. Does not include information about impacted controls or vendors.
 */
export const ExceptionHistoryModal = (props: ExceptionHistoryModalProps) => {
    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text={props.history.exception.title} secondaryText={iso8601ToUsDateShort(props.history.timestamp)} />
                <ExceptionDetailsSnapshot exception={props.history.exception} documentApi={props.documentApi} />
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                        CLOSE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
