/*
	ComplianceRequirements.tsx -- Allows a user to view information about Compliance Requirements.
*/
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ComplianceRequirementsApi } from 'Api/ComplianceRequirements/ComplianceRequirementsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import AssociatedControlsModal, { AssociatedControlsModalProps } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { ComplianceRequirement, ComplianceRequirementsForRegulationResponse, Regulation } from 'Models/ComplianceRequirements';
import { OperationalControl } from 'Models/OperationalControls';
import { OptionType } from 'Models/Types/GlobalType';

import styles from './ComplianceRequirements.module.css';
import { RequirementListing, RequirementListingProps } from './Components/RequirementListing';

// Determines which, if any, modal should be rendered on the page.
export enum Modal {
    MappedControls,
    None,
}

// The Regulation to load by default when the page loads.
const DEFAULT_REGULATION = 'SOC 2 (March 2020)';

export interface ComplianceRequirementsProps {
    complianceRequirementsApi: ComplianceRequirementsApi;
    controlsApi: ControlsApi;
}

export const ComplianceRequirements = (props: ComplianceRequirementsProps): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();

    // If the user is linked to this page for a specific regulation, preselect it.
    const [selectedRegulation, setSelectedRegulation] = useState<string | undefined>(() => {
        if (location.hash && location.hash.length > 0) {
            return decodeURI(location.hash.substring(1));
        }
        return undefined;
    });

    const [availableRegulations, setAvailableRegulations] = useState<Regulation[]>();
    // Renders a specified modal, or no modal.
    const [displayedModal, setDisplayedModal] = useState<Modal>(Modal.None);
    const [error, setError] = useState<string>();
    const [isUpdatingComplianceRequirementControl, setIsUpdatingComplianceRequirementControl] = useState(false);
    const [complianceRequirements, setComplianceRequirements] = useState<ComplianceRequirementsForRegulationResponse>();
    // Allows the behavior of the page to change based on "selecting" a Compliance Requirement. At the moment, this is only used by the Mapped Controls modals, but it could be used for/shared by future functionality.
    const [selectedComplianceRequirement, setSelectedComplianceRequirement] = useState<ComplianceRequirement>();

    const getComplianceRequirements = useCallback(
        (selectedRegulation: string) => {
            const getRequirement = async () => {
                try {
                    setIsUpdatingComplianceRequirementControl(true);
                    const getComplianceRequirementsResponse = await props.complianceRequirementsApi.getComplianceRequirementsForRegulation(selectedRegulation);
                    const complianceRequirements = getComplianceRequirementsResponse.data;

                    setComplianceRequirements(complianceRequirements);
                    setIsUpdatingComplianceRequirementControl(false);
                } catch (error) {
                    setError(error.message);
                }
            };
            getRequirement();
        },
        [props.complianceRequirementsApi]
    );

    useEffect(() => {
        if (selectedRegulation) {
            const encodedRegulation = encodeURIComponent(selectedRegulation);
            navigate(`${location.pathname}#${encodedRegulation}`, { replace: true });
            getComplianceRequirements(selectedRegulation);
        }
    }, [selectedRegulation, getComplianceRequirements, location.pathname, navigate]);

    // This effect is intended to fetch available regulations once, at page load time.
    useEffect(() => {
        const getAvailableRegulations = async (): Promise<void> => {
            if (availableRegulations) {
                return;
            }

            try {
                const availableRegulationResponse = await props.complianceRequirementsApi.getAvailableRegulations();
                setAvailableRegulations(availableRegulationResponse.data);

                // Load a specific Regulation when the page loads, else load the first Regulation alphabetically.
                if (!selectedRegulation) {
                    if (availableRegulationResponse.data.some((regulation) => regulation.name === DEFAULT_REGULATION)) {
                        setSelectedRegulation(DEFAULT_REGULATION);
                    } else {
                        setSelectedRegulation(availableRegulationResponse.data[0].name);
                    }
                }
            } catch (error) {
                setError(error.message);
            }
        };
        getAvailableRegulations();
    }, [availableRegulations, selectedRegulation, props.complianceRequirementsApi]);

    /**
     * Display the modal that shows the Controls a Compliance Requirement is mapped to.
     */
    const displayMappedControlsModal = (selectedComplianceRequirement: ComplianceRequirement): void => {
        setDisplayedModal(Modal.MappedControls);
        setSelectedComplianceRequirement(selectedComplianceRequirement);
    };

    /**
     * Initialize properties of the AssociatedControlsModal (Mapped Controls modal).
     */
    const associatedControlsModalProps = (): AssociatedControlsModalProps => {
        // Flatten the Compliance Requirement's assigned_control_sets attribute.
        const associatedControls: OperationalControl[] = [];
        if (selectedComplianceRequirement) {
            for (const control_list of selectedComplianceRequirement.assigned_control_sets) {
                for (const control of control_list) {
                    associatedControls.push(control);
                }
            }
        }

        return {
            hideModal: () => {
                setDisplayedModal(Modal.None);
                setSelectedComplianceRequirement(undefined);
            },
            associatedControls: associatedControls,
        };
    };

    const createRegulationOptions = (): OptionType[] => {
        return availableRegulations!.map((regulation) => {
            return {
                label: regulation.name,
                value: regulation.name,
            };
        });
    };

    const handleSelectChange = (value: ChangeEventType): void => {
        setSelectedRegulation(value as string);
        getComplianceRequirements(value as string);
    };

    const requirementListingProps: RequirementListingProps = {
        displayMappedControlsModal: displayMappedControlsModal,
        regulationName: selectedRegulation!,
        complianceRequirements: complianceRequirements,
    };

    if (complianceRequirements) {
        return (
            <>
                {displayedModal === Modal.MappedControls && selectedComplianceRequirement && <AssociatedControlsModal {...associatedControlsModalProps()} />}
                <PageBackground color="grey">
                    <PageContent>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1" color="darkBlue">
                                Compliance Requirements
                            </Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageContent>
                    <div className={styles.tableHeader}>
                        <Text variant="Header2">{selectedRegulation}</Text>
                        <div className={styles.selectContainer}>
                            <FormFieldSelect options={createRegulationOptions()} formFieldId="selectedRegulation" formFieldLabel="Regulation" handleChange={handleSelectChange} selectedOption={selectedRegulation} disabled={isUpdatingComplianceRequirementControl} />
                        </div>
                    </div>
                    <RequirementListing {...requirementListingProps} />
                </PageContent>
            </>
        );
    } else if (error) {
        return <Text>{error}</Text>;
    } else return <Placeholder />;
};

export default ComplianceRequirements;
