import moment from 'moment';

import { SegmentedProgressBarChart } from 'Components/BaseCharts/SegmentedProgressBarChart';
import { StackedAreaChart } from 'Components/BaseCharts/StackedAreaChart';
import { CSSColors } from 'Components/Colors';
import Text from 'Components/Text/Text';
import { ComplianceSummary, ComplianceSummarySnapshot } from 'Models/Dashboards';

import styles from './ComplianceRequirementSummary.module.css';

export interface ComplianceRequirementSummaryProps {
    complianceSummary: ComplianceSummary;
    complianceSummarySnapshots: ComplianceSummarySnapshot[];
    handleChartClick: (item: string) => void;
    handleProgressBarClicked: (requirementType: string) => void;
}

export const ComplianceRequirementSummary = (props: ComplianceRequirementSummaryProps): JSX.Element => {
    const lightBlue = CSSColors.LIGHT_BLUE;
    const yellow = CSSColors.YELLOW;
    const gray = CSSColors.GRAY;
    const darkGray = CSSColors.DARK_GRAY;

    const totalRequirements = props.complianceSummary.awaiting_assessments_count + props.complianceSummary.met_count + props.complianceSummary.not_mapped_count + props.complianceSummary.not_met_count;
    const totalNotMet = props.complianceSummary.awaiting_assessments_count + props.complianceSummary.not_mapped_count + props.complianceSummary.not_met_count;

    const requirementMaximum = Math.ceil(totalRequirements / 50) * 50;

    return (
        <div className={styles.container}>
            <div className={styles.requirements}>
                <div className={styles.requirementsMet}>
                    <Text color="white" variant="Text2" noStyles>
                        Requirements Met
                    </Text>
                    <div className={styles.assessmentNumber}>{`${((props.complianceSummary.met_count / totalRequirements) * 100).toFixed(1)}%`}</div>
                    <SegmentedProgressBarChart
                        name={props.complianceSummary.regulation}
                        data={[
                            { value: props.complianceSummary.met_count, name: 'Met', color: lightBlue },
                            { value: totalNotMet, name: 'Not Met', color: darkGray },
                        ]}
                        chartDimensions={{ width: '100%', height: 15 }}
                    />
                    <div className={styles.requirementsMetBreakdown}>
                        <Text color="darkGray" variant="Text2" noStyles>
                            {props.complianceSummary.met_count} of {totalRequirements}
                        </Text>
                    </div>
                </div>
                <div className={styles.requirementBreakdown}>
                    <Text variant="Text2" color="white">
                        Requirement Breakdown
                    </Text>
                    <SegmentedProgressBarChart
                        name={props.complianceSummary.regulation}
                        data={[
                            { value: props.complianceSummary.met_count, name: 'Met', color: lightBlue },
                            { value: props.complianceSummary.not_met_count, name: 'Unmet', color: yellow },
                            { value: props.complianceSummary.awaiting_assessments_count, name: 'Not Assessed', color: gray },
                            { value: props.complianceSummary.not_mapped_count, name: 'Not Mapped', color: darkGray },
                        ]}
                        onChartClick={props.handleProgressBarClicked}
                        chartDimensions={{ width: '100%', height: 50 }}
                    />
                </div>
            </div>
            <div>
                <StackedAreaChart
                    onChartClick={props.handleChartClick}
                    xAxisLabels={props.complianceSummarySnapshots.map(
                        (snapshot) =>
                            `${moment()
                                .month(snapshot.month.month - 1)
                                .format('MMM')
                                .toUpperCase()} ${moment().year(snapshot.month.year).format('YY')}`
                    )}
                    lines={[
                        { data: props.complianceSummarySnapshots.map((snapshot) => snapshot.summary.met_count), name: 'Met', color: lightBlue },
                        { data: props.complianceSummarySnapshots.map((snapshot) => snapshot.summary.awaiting_assessments_count), name: 'Not Assessed', color: gray },
                        { data: props.complianceSummarySnapshots.map((snapshot) => snapshot.summary.not_met_count), name: 'Unmet', color: yellow },
                        { data: props.complianceSummarySnapshots.map((snapshot) => snapshot.summary.not_mapped_count), name: 'Not Mapped', color: darkGray },
                    ]}
                    yAxisInfo={{ name: 'NUMBER OF REQUIREMENTS', position: 'left', min: 0, max: requirementMaximum, minInterval: 1 }}
                />
            </div>
        </div>
    );
};
