import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { TPRM } from 'Config/Paths';
import { useControlMappingItems } from 'Hooks/ControlMapping';
import { ControlExceptionResponse, VendorExceptionResponse } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { VendorResponse, VendorResponseWithServices } from 'Models/TPRM';

import { ManageExceptionForm, ManageExceptionFormProps } from '../Components/ManageExceptionForm/ManageExceptionForm';

export interface ManageExceptionProps {
    exceptionsApi: ExceptionsApi;
    controlsApi: ControlsApi;
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
}

/**
 * Ultimately renders a page that can be used to create, update, or delete an exception in the Operational Controls module or in the TPRM module.
 * This component is responsible for examining the URL to determine the type of exception being managed. It determines which single child needs to be rendered; the child is responsible for fetching data specific to the user's desired action and for passing that data into `ManageExceptionForm`.
 *
 * This structure/flow is used for two main reasons:
 * 1. To avoid fetching data that is not needed for the user's desired action. (For example, if the user is managing a TPRM exception, we do not want to use `useControlMappingItems`.)
 * 2. To improve readability. Without these intermediary components, it becomes very difficult to understand which data is supposed to be fetched and when it will be available.
 */
export const ManageException = (props: ManageExceptionProps) => {
    const { exceptionId } = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const impactedEntityId = query.get('impactedEntityId') ?? undefined;
    const exceptionType = location.pathname.startsWith(`/${TPRM}`) ? IssuesExceptionsModule.TPRM : IssuesExceptionsModule.CONTROLS;
    const isClosingException = query.get('closing') === String(true);

    switch (exceptionType) {
        case IssuesExceptionsModule.CONTROLS:
            if (exceptionId) {
                return <EditControlException {...props} exceptionId={exceptionId} isClosingException={isClosingException} />;
            } else {
                return <CreateControlException {...props} preselectedControlId={impactedEntityId} />;
            }
        case IssuesExceptionsModule.TPRM:
            if (exceptionId) {
                return <EditVendorException {...props} exceptionId={exceptionId} isClosingException={isClosingException} />;
            } else {
                return <CreateVendorException {...props} preselectedVendorId={impactedEntityId} />;
            }
    }
};

/**
 * Renders `ManageExceptionForm` for the use case of creating an Operational Controls exception, after fetching all controls to which the exception can be mapped.
 */
const CreateControlException = (props: ManageExceptionProps & { preselectedControlId?: string }) => {
    const [controlMappingItems, controlMappingItemsError] = useControlMappingItems(props.controlsApi);

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    } else if (controlMappingItems === undefined) {
        return <Placeholder />;
    } else {
        const manageExceptionFormProps: ManageExceptionFormProps = {
            type: 'creatingControlException',
            exceptionsApi: props.exceptionsApi,
            documentApi: props.documentApi,
            controls: controlMappingItems,
            preselectedControlId: props.preselectedControlId,
        };
        return <ManageExceptionForm {...manageExceptionFormProps} />;
    }
};

/**
 * Renders `ManageExceptionForm` for the use case of managing an Operational Controls exception, after fetching details for the exception and all controls to which the exception can be mapped.
 */
const EditControlException = (props: ManageExceptionProps & { exceptionId: string; isClosingException: boolean }) => {
    const [controlMappingItems, controlMappingItemsError] = useControlMappingItems(props.controlsApi);
    const [exception, setException] = useState<ControlExceptionResponse>();
    const [requestError, setRequestError] = useState<Error>();

    useEffect(() => {
        const getException = async (): Promise<void> => {
            try {
                const response = await props.exceptionsApi.getException(props.exceptionId);
                setException(response.data as ControlExceptionResponse);
            } catch (error) {
                setRequestError(error);
            }
        };

        getException();
    }, [props.exceptionsApi, props.exceptionId]);

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    } else if (requestError) {
        return <Text>{requestError.message}</Text>;
    } else if (controlMappingItems === undefined || exception === undefined) {
        return <Placeholder />;
    } else {
        const manageExceptionFormProps: ManageExceptionFormProps = {
            type: 'editingControlException',
            exceptionsApi: props.exceptionsApi,
            documentApi: props.documentApi,
            controls: controlMappingItems,
            exception: exception,
            isDraftingForClosure: props.isClosingException,
        };
        return <ManageExceptionForm {...manageExceptionFormProps} />;
    }
};

/**
 * Renders `ManageExceptionForm` for the use case of creating a TPRM exception, after fetching all vendors to which the exception can be mapped.
 */
const CreateVendorException = (props: ManageExceptionProps & { preselectedVendorId?: string }) => {
    const [vendors, setVendors] = useState<VendorResponseWithServices[]>();
    const [requestError, setRequestError] = useState<Error>();

    useEffect(() => {
        const getVendors = async (): Promise<void> => {
            try {
                const response = await props.tprmApi.getVendors();
                setVendors(response.data);
            } catch (error) {
                setRequestError(error);
            }
        };

        getVendors();
    }, [props.tprmApi]);

    if (requestError) {
        return <Text>{requestError.message}</Text>;
    } else if (vendors === undefined) {
        return <Placeholder />;
    } else {
        const manageExceptionFormProps: ManageExceptionFormProps = {
            type: 'creatingVendorException',
            exceptionsApi: props.exceptionsApi,
            documentApi: props.documentApi,
            vendors: vendors,
            preselectedVendorId: props.preselectedVendorId,
        };
        return <ManageExceptionForm {...manageExceptionFormProps} />;
    }
};

/**
 * Renders `ManageExceptionForm` for the use case of managing a TPRM exception, after fetching details for the exception and its impacted vendor.
 */
const EditVendorException = (props: ManageExceptionProps & { exceptionId: string; isClosingException: boolean }) => {
    const [exception, setException] = useState<VendorExceptionResponse>();
    const [vendor, setVendor] = useState<VendorResponse>();
    const [requestError, setRequestError] = useState<Error>();

    useEffect(() => {
        const getException = async (): Promise<void> => {
            try {
                const response = await props.exceptionsApi.getException(props.exceptionId);
                setException(response.data as VendorExceptionResponse);
            } catch (error) {
                setRequestError(error);
            }
        };

        getException();
    }, [props.exceptionsApi, props.exceptionId]);

    useEffect(() => {
        const getVendor = async (vendorId: string): Promise<void> => {
            try {
                const response = await props.tprmApi.getVendorDetails(vendorId);
                setVendor(response.data);
            } catch (error) {
                setRequestError(error);
            }
        };

        if (exception) {
            getVendor(exception.impacted_vendor);
        }
    }, [props.tprmApi, exception]);

    if (requestError) {
        return <Text>{requestError.message}</Text>;
    } else if (vendor === undefined || exception === undefined) {
        return <Placeholder />;
    } else {
        const manageExceptionFormProps: ManageExceptionFormProps = {
            type: 'editingVendorException',
            exceptionsApi: props.exceptionsApi,
            documentApi: props.documentApi,
            exception: exception,
            vendor: vendor,
            isDraftingForClosure: props.isClosingException,
        };
        return <ManageExceptionForm {...manageExceptionFormProps} />;
    }
};
