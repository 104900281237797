/*
    ComplianceRequirementStatus.tsx -- Used to render an icon depending on the status of a Compliance Requirement. This is defined in the shared Components/ folder because it's used in multiple pages (ComplianceRequirements and ControlDetails).
*/

import { faCheck, faMinusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';

// Models.
import Text from 'Components/Text/Text';

import { ComplianceRequirementStatusValues } from '../../Models/ComplianceRequirements';
// Generic components.
import { GenericTooltip, IconColor } from '../Tooltips/GenericTooltip';
// CSS.

export interface ComplianceRequirementStatusProps {
    status?: string | null;
}

export class ComplianceRequirementStatus extends Component<ComplianceRequirementStatusProps> {
    // Determine which icon should be displayed based on the Compliance Requirement's current status.
    renderIcon = (): JSX.Element => {
        switch (this.props.status) {
            case ComplianceRequirementStatusValues.MET:
                return <GenericTooltip text="Requirement Met" fontAwesomeIcon={faCheck} iconColor={IconColor.GREEN} />;
            case ComplianceRequirementStatusValues.MET_PARTIAL:
                return <GenericTooltip text="Requirement Partially Met" fontAwesomeIcon={faMinusCircle} iconColor={IconColor.YELLOW} />;
            case ComplianceRequirementStatusValues.NOT_MET:
                return <GenericTooltip text="Requirement Not Met" fontAwesomeIcon={faTimes} iconColor={IconColor.RED} />;
            case ComplianceRequirementStatusValues.BASELINE:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.BASELINE}
                    </Text>
                );
            case ComplianceRequirementStatusValues.EVOLVING:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.EVOLVING}
                    </Text>
                );
            case ComplianceRequirementStatusValues.INTERMEDIATE:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.INTERMEDIATE}
                    </Text>
                );
            case ComplianceRequirementStatusValues.ADVANCED:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.ADVANCED}
                    </Text>
                );
            case ComplianceRequirementStatusValues.INNOVATIVE:
                return (
                    <Text variant="Text3" noStyles>
                        {ComplianceRequirementStatusValues.INNOVATIVE}
                    </Text>
                );
            case null:
                return (
                    <Text variant="Text3" noStyles>
                        -
                    </Text>
                );
            default:
                return (
                    <Text variant="Text3" noStyles>
                        -
                    </Text>
                );
        }
    };

    render(): JSX.Element {
        return this.renderIcon();
    }
}
