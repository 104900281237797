import { faCheck, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Text from 'Components/Text/Text';
import { RiskRating, RiskRatingAsString, Service, VendorContact } from 'Models/TPRM';

import styles from './ConfirmSubmitIrqModal.module.css';

export interface ConfirmSubmitIrqModalProps {
    service: Service;
    hasExistingAssessment: boolean;
    inherentRiskRating: RiskRating;
    hideModal: () => void;
    onConfirm: (vendorContacts: VendorContact[]) => void;
    hasValidDDQConfiguration: boolean;
}

export const ConfirmSubmitIrqModal = (props: ConfirmSubmitIrqModalProps): JSX.Element => {
    // Keep track of which Vendor Service contacts should receive the DDQ email.
    // By default, all Vendor Service contacts with a valid email address are selected if there is a valid DDQ configuration (at least 1 question for the selected risk rating), otherwise none of the Vendor Service contacts are selected.
    const [selectedVendorContacts, setSelectedVendorContacts] = useState<VendorContact[]>(props.hasValidDDQConfiguration ? props.service.vendor_contacts.filter((vendor_contact) => vendor_contact.email_address !== undefined) : []);

    const existingAssessmentText = (() => {
        if (!props.hasExistingAssessment) {
            return <Text>Beginning the risk workflow process will make the vendor questionnaire, control assessment, and final report available for changes.</Text>;
        } else {
            const inherentRiskRatingString = RiskRatingAsString(props.inherentRiskRating);

            return <Text>The vendor questionnaire will be regenerated based on your current configuration for vendor services with {inherentRiskRatingString} inherent risk. Existing vendor responses and control assessments will be carried forward, except for questions whose configuration has changed.</Text>;
        }
    })();

    const notifyVendorContactText = (() => {
        if (!(props.service.vendor_contacts.length > 0 && props.service.vendor_contacts.some((vendor_contact) => vendor_contact.email_address !== undefined))) {
            return <Text noStyles={true}>There are no vendor service contacts with a valid email address. The vendor questionnaire cannot be sent.</Text>;
        } else if (!props.hasValidDDQConfiguration) {
            return (
                <Text noStyles={true}>
                    The vendor questionnaire does not have any questions configured for the selected risk rating. <b>The vendor service contacts will not be sent a notification.</b>
                </Text>
            );
        } else {
            return (
                <>
                    <Text>Select vendor service contacts to notify via email that the new vendor questionnaire is ready. Only these individuals will be able to register and complete the vendor questionnaire. This will not reset credentials of any vendor service contacts who have already registered.</Text>
                    {props.service.vendor_contacts
                        .filter((vendorContact) => vendorContact.email_address !== undefined)
                        .map((vendorContact, index) => (
                            <div key={index} className={styles.contactInfo}>
                                <Checkbox
                                    checked={selectedVendorContacts.includes(vendorContact)}
                                    color="default"
                                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                        const isChecked = event.currentTarget.checked;
                                        setSelectedVendorContacts((prevSelectedVendorContacts) => {
                                            if (isChecked) {
                                                return [...prevSelectedVendorContacts, vendorContact];
                                            } else {
                                                return prevSelectedVendorContacts.filter((contact) => contact !== vendorContact);
                                            }
                                        });
                                    }}
                                />
                                <Text noStyles={true}>{vendorContact.email_address}</Text>
                            </div>
                        ))}
                </>
            );
        }
    })();

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                <Form noValidate>
                    <ModalHeader text={props.hasExistingAssessment ? 'Overwrite Inherent Risk Rating' : 'Begin Risk Workflow Process'} secondaryText={`${props.service.vendor_name} - ${props.service.name}`} />
                    {existingAssessmentText}
                    {notifyVendorContactText}
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        {props.hasExistingAssessment ? (
                            <Button variant="danger" onClick={() => props.onConfirm(selectedVendorContacts)} fontAwesomeImage={faPencilAlt}>
                                OVERWRITE
                            </Button>
                        ) : (
                            <Button variant="primary" onClick={() => props.onConfirm(selectedVendorContacts)} fontAwesomeImage={faCheck}>
                                BEGIN
                            </Button>
                        )}
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
