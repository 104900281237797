import { RadioButtonOptionType } from 'Components/FormField/RadioButtonGroup/RadioButtonGroup';
import { IndicatorVariant } from 'Components/Indicator/Indicator';
import { TPRM_IRQ_SENSITIVE_DATA_STORAGE_OFFSHORE, TPRM_IRQ_SENSITIVE_DATA_STORAGE_ONSHORE, TPRM_IRQ_SENSITIVE_DATA_STORAGE_ONSHORE_CLOUD_HOSTED, TPRM_IRQ_SENSITIVE_DATA_STORAGE_ON_PREMISES, TPRM_IRQ_SENSITIVE_DATA_STORAGE_ON_PREMISES_REMOTE_ACCESS } from 'Config/Tooltips';
import { getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';

import { VendorExceptionResponse } from './Exceptions';
import { FileToBeUploaded, FileUpdates, UploadedFile } from './Files';
import { VendorIssueResponse } from './Issues';
import { ControlText, Effectiveness } from './OperationalControls';
import { ScheduleFrequencyKeys } from './ScheduleFrequency';
import { GroupOptionType, OptionType } from './Types/GlobalType';

/**
 * Contact information for Vendor personnel. Used for both Vendor and Vendor Service contact information.
 */
export interface VendorContact {
    name?: string;
    email_address?: string;
    phone_number?: string;
    additional_information?: string;
}

export interface SaveVendorRequest {
    name?: string;
    ein?: string;
    country?: string;
    vendor_manager_user_id?: string;
    vendor_contacts?: VendorContact[];
    state?: string;
    city?: string;
    address_line_1?: string;
    address_line_2?: string;
    zip_code?: string;
    [key: string]: any;
}

export interface Vendor {
    id: string;
    name: string;
    country: string;
    vendor_manager_user_id: string;
    created_time: string;
    vendor_contacts: VendorContact[];
    state?: string;
    city?: string;
    address_line_1?: string;
    address_line_2?: string;
    zip_code?: string;
    ein?: string;
    website?: string;
}

export interface VendorResponse extends Vendor {
    issues: VendorIssueResponse[];
    exceptions: VendorExceptionResponse[];
    [key: string]: any;
}

// TODO: Can we consolidate this with VendorResponse?
export interface VendorResponseWithServices extends VendorResponse {
    services: Service[];
    [key: string]: any;
}

export type VendorIdToNameMap = Map<string, string>;

/**
 * This corresponds to both CreateServiceRequest and UpdateServiceRequest on the back-end.
 */
export interface SaveVendorServiceRequest {
    name?: string;
    description?: string;
    vendor_service_manager_user_id?: string;
    delegates?: string[];
    vendor_contacts?: VendorContact[];
    assessment_due_date?: string;
    responsible_organization?: string;
    [key: string]: any;
}

export interface UpdateServiceAssessmentRequest {
    is_submit: boolean;
    new_assessment_documents?: FileToBeUploaded[];
    assessment_control_effectiveness?: Effectiveness;
    assessment_residual_risk_score?: number;
    assessment_additional_information?: string;
    new_assessment_due_date?: string;
}

export interface UpdateCommonAssessmentRequest {
    parent_identifier: ServiceIdentifier;
    child_identifier: ServiceIdentifier;
    linked: boolean;
}

export enum PendingRiskWorkflowArchivalState {
    WAITING = 'WAITING',
    PROCESSING = 'PROCESSING',
}

export interface PendingRiskWorkflowArchival {
    client_id: string;
    vendor_id: string;
    service_id: string;
    state: PendingRiskWorkflowArchivalState;
    assessment_submitter_id: string;
    new_assessment_due_date?: string;
}

export enum ServiceAssessmentState {
    // When a service is created, its assessment state is NOT_STARTED.
    // When the user starts the assessment flow (notifies the service contact), the assessment state becomes IN_PROGRESS.
    // When the user submits the assessment, the assessment state becomes ARCHIVING.
    // When archiving completes for a service, its assessment state returns to NOT_STARTED.

    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    ARCHIVING = 'ARCHIVING',
}

export const prettyPrintServiceAssessmentState = (serviceAssessmentState: ServiceAssessmentState) => {
    switch (serviceAssessmentState) {
        case ServiceAssessmentState.ARCHIVING:
            return 'Archiving';
        case ServiceAssessmentState.IN_PROGRESS:
            return 'In Progress';
        case ServiceAssessmentState.NOT_STARTED:
            return 'Not Started';
    }
};

export interface ServiceSummary {
    id: string;
    vendor_id: string;
    name: string;
}

export interface Service {
    id: string;
    vendor_id: string;
    name: string;
    vendor_name: string;
    description: string;
    vendor_service_manager_user_id: string;
    created_time: string;
    assessment_schedule?: Schedule;
    inherent_risk_score: number;
    assessment_state: ServiceAssessmentState;
    assessment_documents: UploadedFile[];
    common_assessment_children: ServiceSummary[];
    delegates: string[]; // List of optional user IDs to receive Notifications in addition to the Vendor Service Manager.
    vendor_contacts: VendorContact[]; // This property is called "vendor_contacts" because of the `VendorContact` interface, but note that these are the contacts for the _service_. This is not the same data as `Vendor.vendor_contacts`.
    common_assessment_parent?: ServiceSummary;
    assessment_due_date?: string;
    assessment_control_effectiveness?: number;
    assessment_residual_risk_score?: number;
    assessment_additional_information?: string;
    assessment_completed_time?: string;
    irq_completed_time?: string;
    responsible_organization?: string;
    pending_archival?: PendingRiskWorkflowArchival;
    [key: string]: any;
}

export interface ModifyServiceAssessmentDueDateRequest {
    assessment_due_date: string | null;
}

export interface ServiceIdentifier {
    vendor_id: string;
    service_id: string;
}

export interface SetInherentRiskEvidenceRequest {
    inherent_risk_score: number;
    vendor_contacts: VendorContact[]; // A list of Vendor Service contacts that will be notified about the Due Diligence Questionnaire (DDQ).
    file_updates: FileUpdates;
    text?: string;
    data_classification?: DataClassification;
    customer_details_annual_volume?: CustomerDetailsAnnualVolume;
    data_storage_location?: DataStorageLocation;
}

export interface InherentRiskEvidenceResponse {
    vendor_id: string;
    service_id: string;
    files: UploadedFile[];
    text?: string;
    data_classification?: DataClassification;
    customer_details_annual_volume?: CustomerDetailsAnnualVolume;
    data_storage_location?: DataStorageLocation;
}

export enum RiskRating {
    INACTIVE = 0,
    LOW,
    LOWMODERATE,
    MODERATE,
    MODERATEHIGH,
    HIGH,
}

export enum DataClassification {
    RESTRICTED = 'RESTRICTED',
    CONFIDENTIAL = 'CONFIDENTIAL',
    INTERNAL = 'INTERNAL',
    PUBLIC = 'PUBLIC',
}

export enum CustomerDetailsAnnualVolume {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

export enum DataStorageLocation {
    ON_PREMISES = 'ON_PREMISES',
    ON_PREMISES_REMOTE_ACCESS = 'ON_PREMISES_REMOTE_ACCESS',
    ONSHORE = 'ONSHORE',
    ONSHORE_CLOUD_HOSTED = 'ONSHORE_CLOUD_HOSTED',
    OFFSHORE = 'OFFSHORE',
}

export const NumberAsRiskScore = (riskScore: number): RiskRating => {
    if (riskScore === 0) {
        return RiskRating.INACTIVE;
    } else if (riskScore >= 1 && riskScore < 2) {
        return RiskRating.LOW;
    } else if (riskScore >= 2 && riskScore < 3) {
        return RiskRating.LOWMODERATE;
    } else if (riskScore >= 3 && riskScore < 4) {
        return RiskRating.MODERATE;
    } else if (riskScore >= 4 && riskScore < 5) {
        return RiskRating.MODERATEHIGH;
    } else if (riskScore === 5) {
        return RiskRating.HIGH;
    }

    return RiskRating.INACTIVE;
};

/**
 * This calculates what the risk score High Peaks recommends based on the answers tp the questions provided. The calculation comes from a rolling total. There are 5 possible risk values. That is divided by the 3 questions (hence every calculation begins with 5/3). Each question can has multiple answers. The lowest answers does not add a risk value. All other answers increment the riskiness of the selected answer. (ex. If there are 3 possible answers then the increment values are 0, .83333 and 1.6666 )
 * Choosing Public data for DataClassification will always return a low risk score.
 * @param dataClassification - value of data classification question
 * @param customerDetailsAnnualVolume  - value of customer details annual volume question
 * @param dataStorageLocation - value of data storage question
 */

export const calculateRiskScore = (dataClassification?: DataClassification, customerDetailsAnnualVolume?: CustomerDetailsAnnualVolume, dataStorageLocation?: DataStorageLocation): number => {
    let dataClassificationValue = 0;
    switch (dataClassification) {
        case DataClassification.PUBLIC:
            return RiskRating.LOW;
        case DataClassification.INTERNAL:
            dataClassificationValue = 5 / 3 / 3;
            break;
        case DataClassification.CONFIDENTIAL:
            dataClassificationValue = (5 / 3 / 3) * 2;
            break;
        case DataClassification.RESTRICTED:
            dataClassificationValue = 5 / 3;
            break;
    }
    let customerDetailsAnnualVolumeValue = 0;
    switch (customerDetailsAnnualVolume) {
        case CustomerDetailsAnnualVolume.LOW:
            customerDetailsAnnualVolumeValue = 0;
            break;
        case CustomerDetailsAnnualVolume.MEDIUM:
            customerDetailsAnnualVolumeValue = 5 / 3 / 2;
            break;
        case CustomerDetailsAnnualVolume.HIGH:
            customerDetailsAnnualVolumeValue = 5 / 3;
            break;
    }
    let dataStorageLocationValue = 0;
    switch (dataStorageLocation) {
        case DataStorageLocation.ON_PREMISES:
            dataStorageLocationValue = 0;
            break;
        case DataStorageLocation.ON_PREMISES_REMOTE_ACCESS:
            dataStorageLocationValue = 5 / 3 / 4;
            break;
        case DataStorageLocation.ONSHORE:
            dataStorageLocationValue = (5 / 3 / 4) * 2;
            break;
        case DataStorageLocation.ONSHORE_CLOUD_HOSTED:
            dataStorageLocationValue = (5 / 3 / 4) * 3;
            break;
        case DataStorageLocation.OFFSHORE:
            dataStorageLocationValue = 5 / 3;
            break;
    }

    const riskScore = dataClassificationValue + customerDetailsAnnualVolumeValue + dataStorageLocationValue;

    // This is different than the "NumberAsRiskScore" because 0-1 needs to equal low instead of inactive.
    if (riskScore >= 0 && riskScore < 1) {
        return RiskRating.LOW;
    } else if (riskScore >= 1 && riskScore < 2) {
        return RiskRating.LOWMODERATE;
    } else if (riskScore >= 2 && riskScore < 3) {
        return RiskRating.MODERATE;
    } else if (riskScore >= 3 && riskScore < 4) {
        return RiskRating.MODERATEHIGH;
    } else if (riskScore >= 4 && riskScore < 5) {
        return RiskRating.HIGH;
    } else {
        return riskScore;
    }
};

export const RiskRatingAsString = (riskRating: RiskRating): string => {
    switch (riskRating) {
        case RiskRating.INACTIVE:
            return 'Inactive';
        case RiskRating.HIGH:
            return 'High';
        case RiskRating.MODERATEHIGH:
            return 'Moderate/High';
        case RiskRating.MODERATE:
            return 'Moderate';
        case RiskRating.LOWMODERATE:
            return 'Low/Moderate';
        case RiskRating.LOW:
            return 'Low';
    }
};

export const numberAsRiskRatingString = (riskRating: number): string => {
    return RiskRatingAsString(NumberAsRiskScore(riskRating));
};

export const riskRatingLabelAsNumber = (riskRatingLabel: string) => {
    switch (riskRatingLabel) {
        case 'Inactive':
            return RiskRating.INACTIVE;
        case 'High':
            return RiskRating.HIGH;
        case 'Moderate/High':
            return RiskRating.MODERATEHIGH;
        case 'Moderate':
            return RiskRating.MODERATE;
        case 'Low/Moderate':
            return RiskRating.LOWMODERATE;
        case 'Low':
            return RiskRating.LOW;
    }
};

export enum ServiceListingSortFilterOptions {
    VENDOR = 'vendorName',
    VENDOR_ID = 'vendorId',
    RISKRATING = 'riskRating',
    RESIDUAL_RISK = 'residualRisk',
    SERVICE = 'serviceName',
    VENDOR_SERVICE_MANAGER_USER_ID = 'vendor_service_manager_user_id',
    DATE_CREATED = 'dateCreated',
    ASSESSMENT_DUE_DATE = 'assessmentDueDate',
}

export enum VendorListingSortProperty {
    NAME = 'name',
    CREATED_TIME = 'created_time',
    VENDOR_MANAGER_USER_ID = 'vendor_manager_user_id',
    WEBSITE = 'website',
}

export const RiskRatingFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: ServiceListingSortFilterOptions.RISKRATING,
        label: 'Inherent Risk: Inactive',
        value: RiskRating.INACTIVE,
    },
    {
        groupId: ServiceListingSortFilterOptions.RISKRATING,
        label: 'Inherent Risk: High',
        value: RiskRating.HIGH,
    },
    {
        groupId: ServiceListingSortFilterOptions.RISKRATING,
        label: 'Inherent Risk: Moderate/High',
        value: RiskRating.MODERATEHIGH,
    },
    {
        groupId: ServiceListingSortFilterOptions.RISKRATING,
        label: 'Inherent Risk: Moderate',
        value: RiskRating.MODERATE,
    },
    {
        groupId: ServiceListingSortFilterOptions.RISKRATING,
        label: 'Inherent Risk: Low/Moderate',
        value: RiskRating.LOWMODERATE,
    },
    {
        groupId: ServiceListingSortFilterOptions.RISKRATING,
        label: 'Inherent Risk: Low',
        value: RiskRating.LOW,
    },
];

export const ResidualRiskFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK,
        label: 'Residual Risk: Inactive',
        value: RiskRating.INACTIVE,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK,
        label: 'Residual Risk: High',
        value: RiskRating.HIGH,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK,
        label: 'Residual Risk: Moderate/High',
        value: RiskRating.MODERATEHIGH,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK,
        label: 'Residual Risk: Moderate',
        value: RiskRating.MODERATE,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK,
        label: 'Residual Risk: Low/Moderate',
        value: RiskRating.LOWMODERATE,
    },
    {
        groupId: ServiceListingSortFilterOptions.RESIDUAL_RISK,
        label: 'Residual Risk: Low',
        value: RiskRating.LOW,
    },
];

export const RiskRatingSelectOptions: OptionType[] = [
    {
        label: 'Low',
        value: RiskRating.LOW,
    },
    {
        label: 'Low/Moderate',
        value: RiskRating.LOWMODERATE,
    },
    {
        label: 'Moderate',
        value: RiskRating.MODERATE,
    },
    {
        label: 'Moderate/High',
        value: RiskRating.MODERATEHIGH,
    },
    {
        label: 'High',
        value: RiskRating.HIGH,
    },
];

export const Question1Options: RadioButtonOptionType[] = [
    {
        label: 'Public',
        value: DataClassification.PUBLIC,
        tooltip: `Marketing material, brochures, press releases, etc.`,
    },
    {
        label: 'Internal',
        value: DataClassification.INTERNAL,
        tooltip: `Office phone number, organization charts, etc.`,
    },
    {
        label: 'Confidential',
        value: DataClassification.CONFIDENTIAL,
        tooltip: `PII such as name, address, email address, phone number, driver's license, etc.`,
    },
    {
        label: 'Restricted',
        value: DataClassification.RESTRICTED,
        tooltip: `SSN, credit card, account number, password, strategic information such as M&A, etc.`,
    },
];
export const Question2Options: RadioButtonOptionType[] = [
    {
        label: '< 1,000 records',
        value: CustomerDetailsAnnualVolume.LOW,
    },
    {
        label: '1,000 - 100,000 records',
        value: CustomerDetailsAnnualVolume.MEDIUM,
    },
    {
        label: '> 100,000 records',
        value: CustomerDetailsAnnualVolume.HIGH,
    },
];
export const Question3Options: RadioButtonOptionType[] = [
    {
        label: 'On-Premises',
        value: DataStorageLocation.ON_PREMISES,
        tooltip: TPRM_IRQ_SENSITIVE_DATA_STORAGE_ON_PREMISES,
    },
    {
        label: 'On-Premises Remote Access',
        value: DataStorageLocation.ON_PREMISES_REMOTE_ACCESS,
        tooltip: TPRM_IRQ_SENSITIVE_DATA_STORAGE_ON_PREMISES_REMOTE_ACCESS,
    },
    {
        label: 'Onshore',
        value: DataStorageLocation.ONSHORE,
        tooltip: TPRM_IRQ_SENSITIVE_DATA_STORAGE_ONSHORE,
    },
    {
        label: 'Onshore Cloud-Hosted',
        value: DataStorageLocation.ONSHORE_CLOUD_HOSTED,
        tooltip: TPRM_IRQ_SENSITIVE_DATA_STORAGE_ONSHORE_CLOUD_HOSTED,
    },
    {
        label: 'Offshore',
        value: DataStorageLocation.OFFSHORE,
        tooltip: TPRM_IRQ_SENSITIVE_DATA_STORAGE_OFFSHORE,
    },
];

export const getRiskRatingVariantColor = (riskRating: RiskRating): IndicatorVariant => {
    switch (riskRating) {
        case RiskRating.INACTIVE:
            return IndicatorVariant.GRAY;
        case RiskRating.LOW:
            return IndicatorVariant.DARKGREEN;
        case RiskRating.LOWMODERATE:
            return IndicatorVariant.LIGHTGREEN;
        case RiskRating.MODERATE:
            return IndicatorVariant.YELLOW;
        case RiskRating.MODERATEHIGH:
            return IndicatorVariant.ORANGE;
        case RiskRating.HIGH:
            return IndicatorVariant.RED;
    }
};

// Questionnaire Configuration
export interface QuestionnaireConfigurationResponse {
    control_frameworks: ControlFrameworkConfiguration[];
}
export interface ControlFrameworkConfiguration {
    control_framework: string;
    control_framework_name: string;
    control_framework_version: string;
    control_groups: ControlGroupConfiguration[];
    is_custom: boolean;
}

export interface ControlGroupConfiguration {
    control_framework: string;
    control_group_id: string;
    controls: ControlConfiguration[];
    control_group_name: string;
    control_group_description?: string;
    is_custom: boolean;
}

export interface ControlConfiguration {
    control_framework: string;
    control_group_id: string;
    control_id: string;
    control_text: ControlText[];
    questions: QuestionConfiguration[];
    control_name?: string;
    is_custom: boolean;
}

export interface QuestionConfiguration {
    _type: QuestionType;
    text: string;
    mapped_risk_ratings: RiskRating[];
    options?: string[];
}

export enum QuestionType {
    FREEFORM = 'FREEFORM',
    SINGLE_SELECT = 'SINGLE_SELECT',
    MULTIPLE_SELECT = 'MULTIPLE_SELECT',
    DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
}

export interface CreateControlConfigurationRequest {
    control_text: ControlText[];
    control_name: string;
}

export interface UpdateControlConfigurationRequest {
    control_text?: ControlText[];
    control_name?: string;
    questions?: QuestionConfiguration[];
}

export interface CreateControlGroupConfigurationRequest {
    control_group_name: string;
    control_group_description: string;
}

export interface UpdateControlGroupConfigurationRequest {
    control_group_name?: string;
    control_group_description?: string;
}

export interface CreateControlFrameworkConfigurationRequest {
    control_framework_name: string;
}

export interface UpdateControlFrameworkConfigurationRequest {
    control_framework_name: string;
}

export type UpdateConfigurationRequest = UpdateControlFrameworkConfigurationRequest | UpdateControlGroupConfigurationRequest | UpdateControlConfigurationRequest;

export const identifierMappedToRequest = (request: UpdateConfigurationRequest, framework: string, groupId?: string, controlId?: string): [string, UpdateConfigurationRequest] => {
    return [getOperationalControlIdentifierString(framework, groupId, controlId), request];
};

// Questionnaire Instance
export interface QuestionnaireGroupControlsResponse {
    controls: ControlInstanceResponse[];
}

export interface ControlFrameworkInstanceResponse {
    control_framework: string;
    control_framework_name: string;
    control_framework_version: string;
    control_groups: ControlGroupInstanceResponse[];
    control_assessments_completed: number;
    questions_completed: number;
    is_custom: boolean;
}

export interface ControlGroupInstanceResponse {
    control_framework: string;
    control_group_id: string;
    control_group_name: string;
    number_of_controls: number;
    control_assessments_completed: number;
    number_of_questions: number;
    questions_completed: number;
    control_group_description?: string;
    is_custom: boolean;
}

export interface ControlInstanceResponse {
    control_framework: string;
    control_group_id: string;
    control_id: string;
    control_group_name: string;
    control_text: ControlText[];
    questions: QuestionTypes[];
    is_custom: boolean;
    control_name?: string;
    control_assessment_effectiveness?: Effectiveness;
    control_assessment_comment?: string;
    control_assessment_documents: UploadedFile[];
}

export interface QuestionInstance {
    id: string;
    control_identifier: string;
    text: string;
    answer_text?: string;
    answer_documents: UploadedFile[];
}

export interface SingleSelectQuestionInstance extends QuestionInstance {
    _type: QuestionType.SINGLE_SELECT;
    options?: string[];
    answer_index?: number;
}

export interface MultipleSelectQuestionInstance extends QuestionInstance {
    _type: QuestionType.MULTIPLE_SELECT;
    options?: string[];
    answer_indexes?: number[];
}

export interface UpdateQuestionRequest {
    _type: QuestionType;
    id: string;
    control_identifier: string;
    answer_text?: string;
    answer_documents: UploadedFile[];
    answerIndex?: number | number[];
}

export interface FreeformQuestionInstance extends QuestionInstance {
    _type: QuestionType.FREEFORM;
}

export interface DocumentUploadQuestionInstance extends QuestionInstance {
    _type: QuestionType.DOCUMENT_UPLOAD;
}

export type QuestionTypes = FreeformQuestionInstance | SingleSelectQuestionInstance | MultipleSelectQuestionInstance | DocumentUploadQuestionInstance;

export type Answer = string | number | number[] | undefined;

export interface UpdateControlInstanceRequest {
    updates?: Partial<ControlInstanceResponse>;
    new_documentation?: FileToBeUploaded[];
}

export interface QuestionUpdates {
    updates?: Partial<QuestionTypes>;
    new_documentation?: FileToBeUploaded[];
}

export interface UpdateControlInstanceQuestionsRequest {
    questions_updates?: { [key: string]: QuestionUpdates };
}

export interface QuestionnaireAggregationResponse {
    number_of_controls: number;
    number_of_questions: number;
    number_of_control_assessments_completed: number;
    number_of_questions_completed: number;
    control_effectiveness_summary: {
        '0': number;
        '1': number;
        '2': number;
        '3': number;
        '4': number;
        '5': number;
    };
    due_diligence_started_time?: string;
    control_assessment_started_time?: string;
}

// Assessment report
export interface QuestionnaireReport {
    average_control_effectiveness: number;
    control_frameworks: ReportControlFrameworkResponse[];
}

export interface ReportControlFrameworkResponse {
    control_framework: string;
    control_framework_name: string;
    control_framework_version: string;
    control_groups: ReportControlGroupResponse[];
    control_assessments_completed: number;
    questions_completed: number;
    control_framework_effectiveness: number;
    is_custom: boolean;
}
export interface ReportControlGroupResponse {
    control_framework: string;
    control_group_id: string;
    control_group_name: string;
    number_of_controls: number;
    control_assessments_completed: number;
    number_of_questions: number;
    questions_completed: number;
    control_group_description?: string;
    controls: ControlInstanceResponse[];
    control_group_effectiveness: number;
    is_custom: boolean;
}

export const vendorNameComparator = (vendorIdA: string, vendorIdB: string, vendorIdToNameMap: Map<string, string>): number => {
    const vendorNameA = vendorIdToNameMap.get(vendorIdA)!;
    const vendorNameB = vendorIdToNameMap.get(vendorIdB)!;
    return vendorNameA.localeCompare(vendorNameB);
};

export interface PublicDDQRegistrationRequest {
    email_address: string;
    password: string;
    password_confirm: string;
}

export type Schedule = {
    schedule_number: number;
    schedule_frequency: ScheduleFrequencyKeys;
};

export type ServiceAssessmentSchedule = {
    low?: Schedule;
    low_moderate?: Schedule;
    moderate?: Schedule;
    moderate_high?: Schedule;
    high?: Schedule;
};
export interface RiskWorkflowArchiveMetadata {
    timestamp: string;
    assessment_submitter_id: string;
}

export interface RiskWorkflowArchive {
    control_instances: AssessmentControlInstances[];
    inherent_risk_evidence: InherentRiskEvidenceResponse;
    questionnaire_aggregation: RiskWorkflowArchiveQuestionnaireAggregation;
    service: Service;
    metadata: RiskWorkflowArchiveMetadata;
    vendor: VendorResponse;
}

export interface AssessmentControlInstances {
    control_identifier: string;
    control_assessment_documents: UploadedFile[];
    control_text: ControlText[];
    is_custom: boolean;
    questions: QuestionInstance[];
    control_assessment_effectiveness?: number;
}

export interface RiskWorkflowArchiveQuestionnaireAggregation extends QuestionnaireAggregationResponse {
    control_frameworks: { [key: string]: RiskWorkflowArchiveFramework };
    control_groups: { [key: string]: RiskWorkflowArchiveControlGroup };
}

export interface RiskWorkflowArchiveFramework {
    control_identifier: string;
    is_custom: boolean;
    control_framework_name: string;
    control_framework_effectiveness: number;
}

export interface RiskWorkflowArchiveControlGroup {
    control_identifier: string;
    control_group_name: string;
    control_group_effectiveness: number;
    is_custom: boolean;
}

export enum ReportListingTableFilterOptions {
    DATE_CREATED = 'date_created',
    INHERENT_RISK_SCORE = 'riskRating',
    RESIDUAL_RISK_SCORE = 'residualRisk',
    CONTROL_EFFECTIVENESS = 'control_effectiveness',
    REPORT_TITLE = 'report_title',
}

export const ControlEffectivenessFilterOptionTypes: GroupOptionType[] = [
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Fail',
        value: Effectiveness.FAIL,
    },
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Weak',
        value: Effectiveness.WEAK,
    },
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Moderate',
        value: Effectiveness.MODERATE,
    },
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Strong',
        value: Effectiveness.STRONG,
    },
    {
        groupId: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS,
        label: 'Control Effectiveness: Robust',
        value: Effectiveness.ROBUST,
    },
];

export interface FolderTypes {
    type_set: string[];
}
export interface CreateNewFolderRequest {
    name: string;
    type: string;
    vendor_manager_notifications_enabled: boolean;
    effective_date?: string;
    expiration_date?: string;
    comments?: string;
    files?: FileToBeUploaded[];
}

export interface FolderVersion {
    id: string;
    name: string;
    type: string;
    vendor_manager_notifications_enabled: boolean;
    created_time: string;
    last_updated: string;
    last_updated_by: string;
    effective_date?: string;
    expiration_date?: string;
    comments?: string;
    files?: UploadedFile[];
    [key: string]: any;
}

export interface FolderAllVersions {
    current_version: FolderVersion;
    past_versions: FolderVersion[];
}

/**
 * Used to (re-)send the DDQ email to the Vendor Service contact(s).
 * Results in existing Vendor Service contact credentials being deleted, forcing them to re-register if they already have.
 * Can optionally clear all existing progress on the DDQ and Control Assessment.
 */
export interface ResetAndNotifyVendorRequest {
    reset_ddq: boolean;
    vendor_contacts: VendorContact[]; // A list of Vendor Service contacts that will be notified about the Due Diligence Questionnaire (DDQ).
}
