import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { TPRMServiceExportRow } from 'Models/Exports';
import { numberAsEffectivenessString } from 'Models/OperationalControls';
import { VendorContact, numberAsRiskRatingString, prettyPrintServiceAssessmentState } from 'Models/TPRM';

import styles from '../DataExports.module.css';

export interface VendorServicesProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const VendorServicesExport = (props: VendorServicesProps): JSX.Element => {
    const [services, setServices] = useState<TPRMServiceExportRow[]>();
    const [error, setError] = useState<string>();
    const { users } = useUsers();

    useEffect(() => {
        const getVendorServices = async (): Promise<void> => {
            try {
                const vendorServicesResponse = await props.exportsApi.getVendorServicesExport();
                setServices(vendorServicesResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getVendorServices();
    }, [props.exportsApi]);

    const columns: GridColumn<TPRMServiceExportRow>[] = [
        { field: 'vendor_name', headerName: 'Vendor Name', width: 300 },
        { field: 'name', headerName: 'Service Name', width: 300 },
        { field: 'description', headerName: 'Description', width: 300 },
        { field: 'assessment_state', headerName: 'Assessment State', width: 300, valueGetter: (value) => prettyPrintServiceAssessmentState(value) },
        dateColumn('assessment_due_date', 'Assessment Due Date'),
        { field: 'inherent_risk_score', headerName: 'Inherent Risk Score', width: 300, valueGetter: (value) => numberAsRiskRatingString(value) },
        { field: 'assessment_control_effectiveness', headerName: 'Overall Control Effectiveness', width: 300, valueGetter: (value) => numberAsEffectivenessString(value) },
        { field: 'assessment_residual_risk_score', headerName: 'Residual Risk Score', width: 300, valueGetter: (value) => numberAsRiskRatingString(value) },
        { field: 'vendor_service_manager_user_id', headerName: 'Vendor Service Manager', width: 300, valueGetter: (value) => getUserNameFromSubject(value, users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'responsible_organization', headerName: 'Responsible Organization', width: 300 },
        {
            field: 'delegates',
            headerName: 'Delegates',
            width: 300,
            valueGetter: (value) =>
                value
                    .map((userId: string) => getUserNameFromSubject(userId, users, UserNameFormat.FIRST_SPACE_LAST))
                    .sort()
                    .join(', '),
        },
        {
            field: 'vendor_contacts',
            headerName: 'Vendor Service Contacts',
            width: 300,
            valueGetter: (value) =>
                value
                    // Only show the name for each contact. Omit their email address and phone number.
                    .filter((vendorContact: VendorContact) => vendorContact.name !== undefined)
                    .sort((vendorContactA: VendorContact, vendorContactB: VendorContact) => (vendorContactA?.name || '').localeCompare(vendorContactB?.name || ''))
                    .map((vendorContact: VendorContact) => vendorContact.name)
                    .join(', '),
        },
        dateColumn('created_time', 'Created Date'),
    ];

    if (services) {
        return (
            <>
                <PageBackground color="grey">
                    <PageContent>
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                            <BreadcrumbText>Vendor Services</BreadcrumbText>
                        </Breadcrumb>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1">Vendor Services Export</Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <DataGrid columns={columns} getRowId={(vendorService: TPRMServiceExportRow) => vendorService.id} rows={services} title="Vendor Services Export Preview" fileName="Vendor Services" disableVirtualization={props.disableVirtualization} />
                    </PageContent>
                </PageBackground>
            </>
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
