import { chunk } from 'lodash-es';

import { SegmentedProgressBarChart } from 'Components/BaseCharts/SegmentedProgressBarChart';
import { Carousel } from 'Components/Carousel/Carousel';
import { CarouselItem } from 'Components/Carousel/CarouselItem/CarouselItem';
import { CSSColors } from 'Components/Colors';
import Text from 'Components/Text/Text';
import { ComplianceSummary } from 'Models/Dashboards';

import styles from './ComplianceCarousel.module.css';

export interface ComplianceCarouselProps {
    complianceSummary: ComplianceSummary[];
    handleChartClick: (regulationTypeSelected: string, complianceName: string) => void;
}

export const ComplianceCarousel = (props: ComplianceCarouselProps): JSX.Element => {
    const inactiveColor = CSSColors.DARK_GRAY;
    const moderateColor = CSSColors.YELLOW;
    const lineColor = CSSColors.LIGHT_BLUE;
    const gray = CSSColors.GRAY;

    const complianceSection = (complianceSummary: ComplianceSummary, index: number) => {
        return (
            <div key={index}>
                <Text variant="Text2" color="darkGray">
                    {complianceSummary.regulation}
                </Text>
                <SegmentedProgressBarChart
                    name={complianceSummary.regulation}
                    data={[
                        { value: complianceSummary.met_count, name: 'Met', color: lineColor },
                        { value: complianceSummary.not_met_count, name: 'Unmet', color: moderateColor },
                        { value: complianceSummary.awaiting_assessments_count, name: 'Not Assessed', color: gray },
                        { value: complianceSummary.not_mapped_count, name: 'Not Mapped', color: inactiveColor },
                    ]}
                    onChartClick={(item: string) => props.handleChartClick(item, complianceSummary.regulation)}
                    chartDimensions={{ width: '100%', height: 50 }}
                    tooltipDirection={index === 0 ? 'bottom' : undefined}
                />
            </div>
        );
    };

    const sortByRegulationName = (summaryA: ComplianceSummary, summaryB: ComplianceSummary): number => summaryA.regulation.localeCompare(summaryB.regulation, undefined, { numeric: true, sensitivity: 'base' });

    if (props.complianceSummary.length <= 3) {
        return <>{props.complianceSummary.sort(sortByRegulationName).map(complianceSection)}</>;
    } else {
        const complianceSummaryMap = chunk(props.complianceSummary.sort(sortByRegulationName), 3);

        return (
            <Carousel showControls={true} showIndicators={false}>
                {complianceSummaryMap.map((complianceArray, index) => {
                    return (
                        <CarouselItem key={index}>
                            <div className={styles.carousel}>{complianceArray.map(complianceSection)}</div>
                        </CarouselItem>
                    );
                })}
            </Carousel>
        );
    }
};
