import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import { Link } from 'Components/Buttons/Buttons';
import GovernanceLibraryTable, { GovernanceLibraryTableProps } from 'Components/Governance/GovernanceLibraryTable/GovernanceLibraryTable';
import Text from 'Components/Text/Text';
import { CREATE, GOVERNANCE } from 'Config/Paths';
import { GovernanceVersion, TextBasedGovernanceVersion } from 'Models/Governance';
import { UserResponse } from 'Models/User';

import styles from './Governance.module.css';

export interface GovernanceProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    users: UserResponse[];
    governanceItems: GovernanceVersion[];
    displayGovernanceTextModal: (governance: TextBasedGovernanceVersion) => void;
    displayGovernanceWithAssociatedControlsModal: (governanceWithAssociatedControls: GovernanceVersion) => void;
    controlIdentifier: string;
    openExternalUrl: (url: string) => void;
}

const Governance = (props: GovernanceProps): JSX.Element => {
    const governanceListingTableProps: GovernanceLibraryTableProps = {
        governanceApi: props.governanceApi,
        documentApi: props.documentApi,
        users: props.users,
        governanceItems: props.governanceItems,
        governanceItemsAreManageable: true,
        displayGovernanceTextModal: props.displayGovernanceTextModal,
        displayGovernanceMappedControlsModal: props.displayGovernanceWithAssociatedControlsModal,
        controlIdentifier: props.controlIdentifier,
        openExternalUrl: props.openExternalUrl,
    };
    return (
        <Fragment>
            <div className={styles.header}>
                <Text color="blue" variant="Header2">
                    Policies, Standards, and Procedures
                </Text>
                <Link variant="primaryButton" to={`/${GOVERNANCE}/${CREATE}?controlId=${encodeURIComponent(props.controlIdentifier)}`} fontAwesomeImage={faPlus}>
                    Add New
                </Link>
            </div>
            <div className={styles.pageSection}>
                <GovernanceLibraryTable {...governanceListingTableProps} />
            </div>
        </Fragment>
    );
};

export default Governance;
