import { TableRow } from '@mui/material';
import { useState } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import SortableTableHeader, { HeaderData, SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody, TableCellDefaultText } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
import { ACTIONS } from 'Config/Paths';
import { undefinedComparator } from 'Helpers/Compare';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { compareUsersBySubjectForSorting, getUserNameFromSubject } from 'Helpers/UserUtils';
import { actionStatusComparator } from 'Models/Actions';
import { AssociatedAction } from 'Models/RiskRegister';

import styles from './AssociatedActionListing.module.css';

export interface AssociatedActionListingProps {
    actions: AssociatedAction[];
}

interface ActionsTableState {
    currentSort: string;
    currentSortDirection: SortDirection;
}

enum ActionsSortFilterOptions {
    ACTION = 'title',
    STATUS = 'status',
    OWNER = 'owner',
    DUE_DATE = 'due_date',
    LAST_UPDATED = 'last_updated',
}

export const AssociatedActionListing = (props: AssociatedActionListingProps): JSX.Element => {
    const { users } = useUsers();
    const [actionsTableState, setActionsTableState] = useState<ActionsTableState>({ currentSort: ActionsSortFilterOptions.ACTION, currentSortDirection: SortDirection.ASC });

    const headerValues: HeaderData[] = [
        { dataKey: ActionsSortFilterOptions.ACTION, label: 'ACTION' },
        { dataKey: ActionsSortFilterOptions.STATUS, label: 'STATUS' },
        { dataKey: ActionsSortFilterOptions.OWNER, label: 'OWNER' },
        { dataKey: ActionsSortFilterOptions.LAST_UPDATED, label: 'LAST UPDATED' },
        { dataKey: ActionsSortFilterOptions.DUE_DATE, label: 'DUE DATE' },
    ];

    const sortActions = (): AssociatedAction[] => {
        let sortResult = 0;

        return props.actions.sort((actionA, actionB) => {
            switch (actionsTableState.currentSort) {
                case ActionsSortFilterOptions.ACTION:
                    sortResult = (actionA[actionsTableState.currentSort] as string).localeCompare(actionB[actionsTableState.currentSort] as string);
                    break;
                case ActionsSortFilterOptions.STATUS:
                    sortResult = actionStatusComparator(actionA.status, actionB.status);
                    break;
                case ActionsSortFilterOptions.LAST_UPDATED:
                    sortResult = undefinedComparator(actionA.last_updated, actionB.last_updated, (lastUpdatedA, lastUpdatedB) => (lastUpdatedA > lastUpdatedB ? 1 : -1));
                    break;
                case ActionsSortFilterOptions.DUE_DATE:
                    sortResult = undefinedComparator(actionA.due_date, actionB.due_date, (dueDateA, dueDateB) => (dueDateA > dueDateB ? 1 : -1));
                    break;
                case ActionsSortFilterOptions.OWNER:
                    sortResult = compareUsersBySubjectForSorting(actionA.owner, actionB.owner, users);
                    break;
                default:
                    sortResult = 0;
                    break;
            }

            return actionsTableState.currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        setActionsTableState({ currentSort: newSort as ActionsSortFilterOptions, currentSortDirection: newSortDirection });
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: setCurrentSort,
        currentSort: actionsTableState.currentSort,
        currentSortDirection: actionsTableState.currentSortDirection,
        tableIncludesOverflowMenu: false,
    };

    const tableRow = (action: AssociatedAction): JSX.Element => {
        return (
            <TableRow key={action.id}>
                <TableCellDefaultText>
                    <div className={styles.actionsTitleContainer}>
                        <Link size={'sm'} to={`/${ACTIONS}/${action.id}`}>
                            {action.title}
                        </Link>
                    </div>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{action.status}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{getUserNameFromSubject(action.owner, users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{iso8601ToUsDateShort(action.last_updated)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>{action.due_date && <Text noStyles>{iso8601ToUsDateShort(action.due_date)}</Text>}</TableCellDefaultText>
            </TableRow>
        );
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortActions().map(tableRow)}</TableBody>
        </Table>
    );
};
