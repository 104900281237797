/**
 * Functions for building URLs for hyperlinks to entities (Controls, Issues, Vendor Services, etc.) within SummIT Security (i.e., React routes).
 * This has nothing to do with SummIT Security back-end API URLs. Those are defined within the src/Api/ folder.
 */
import { ACTIONS, CONFIGURATION, CONTROLS, CREATE, DASHBOARDS, EXCEPTIONS, FOLDERS, FRAMEWORKS, GROUPS, ISSUES, OPERATIONAL_CONTROLS, SERVICES, TPRM, VENDORS } from 'Config/Paths';
import { IssuesExceptionsModule } from 'Models/Issues';
import { NotificationType, UserNotification } from 'Models/Notification';

export const getFrameworkGroupControlURLUnparsableIdentifier = 'Unrecognized identifier.'; // Exported so it can be imported in tests.

/**
 *
 * @param identifier The canonical identifier for a Framework, Group, or Control. For example, "CIS CSC (v8.0)", "CIS CSC (v8.0)#1", or "CIS CSC (v8.0)#1#1.1"
 * @returns a URL (string) based on the provided identifier.
 */
export const getFrameworkGroupControlURL = (identifier: string): string => {
    if (identifier.includes('#')) {
        const parts = identifier.split('#');
        if (parts.length === 2) {
            // The given identifier is a Control Group.
            return `/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${parts[0]}/${GROUPS}/${parts[1]}`;
        } else if (parts.length === 3) {
            // The given identifier is a Control.
            return `/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${parts[0]}/${GROUPS}/${parts[1]}/${CONTROLS}/${parts[2]}`;
        } else {
            throw Error(getFrameworkGroupControlURLUnparsableIdentifier);
        }
    } else {
        // The given identifier is a Control Framework.
        return `/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}#${identifier}`;
    }
};

/**
 * Given a Notification, determine the URL (route) for the entity (Action, Control, Exception, etc.) that it is related to.
 */
export const getNotificationUrl = (notification: UserNotification): string => {
    switch (notification._type) {
        case NotificationType.ACTION_DUE:
            return `/${ACTIONS}/${notification.identifier}`;
        case NotificationType.CONTROL_ASSESSMENT:
            return getFrameworkGroupControlURL(notification.control.identifier);
        case NotificationType.EXCEPTION_EXPIRY:
            // TODO: the logic here for determining the module can be removed if/when the backend returns DTOs, rather than domain objects.
            return getExceptionDetailsUrl(notification.identifier, notification.exception.impacted_vendor ? IssuesExceptionsModule.TPRM : IssuesExceptionsModule.CONTROLS);
        case NotificationType.FOLDER_EXPIRY:
            return `/${TPRM}/${VENDORS}/${notification.vendor.id}/${FOLDERS}/${notification.folder.id}`;
        case NotificationType.ISSUE_DUE:
            // TODO: the logic here for determining the module can be removed if/when the backend returns DTOs, rather than domain objects.
            return getIssueDetailsUrl(notification.identifier, notification.issue.impacted_vendor ? IssuesExceptionsModule.TPRM : IssuesExceptionsModule.CONTROLS);
        case NotificationType.SERVICE_ASSESSMENT_DUE:
            const [vendor_id, service_id] = notification.identifier.split('#');
            return `/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DASHBOARDS}`;
    }
};

/**
 * Returns a link to the Manage Issue page, where the user can create a new issue. If the ID of a control or a vendor is provided, the page will preselect the corresponding entity.
 */
export const getCreateIssueUrl = (issueType: IssuesExceptionsModule, preselectedEntityId?: string): string => {
    const pathPrefix = issueType === IssuesExceptionsModule.TPRM ? `/${TPRM}` : '';
    const preselectedEntityIdQueryParam = preselectedEntityId ? `?impactedEntityId=${encodeURIComponent(preselectedEntityId)}` : '';
    return `${pathPrefix}/${ISSUES}/${CREATE}${preselectedEntityIdQueryParam}`;
};

/**
 * Returns a link to the Issue Details page.
 */
export const getIssueDetailsUrl = (issueId: string, issueType: IssuesExceptionsModule): string => {
    const pathPrefix = issueType === IssuesExceptionsModule.TPRM ? `/${TPRM}` : '';
    return `${pathPrefix}/${ISSUES}/${issueId}`;
};

/**
 * Returns a link to the Manage Issue page, where the user can save changes to an existing issue. If `isClosingIssue` is true, the page will include form fields specific to closing the issue.
 */
export const getUpdateIssueUrl = (issueId: string, issueType: IssuesExceptionsModule, isClosingIssue: boolean): string => {
    const pathPrefix = issueType === IssuesExceptionsModule.TPRM ? `/${TPRM}` : '';
    return `${pathPrefix}/${ISSUES}/${issueId}/${CONFIGURATION}?closing=${isClosingIssue}`;
};

/**
 * Returns a link to the Manage Exception page, where the user can create a new exception. If the ID of a control or a vendor is provided, the page will preselect the corresponding entity.
 */
export const getCreateExceptionUrl = (exceptionType: IssuesExceptionsModule, preselectedEntityId?: string): string => {
    const pathPrefix = exceptionType === IssuesExceptionsModule.TPRM ? `/${TPRM}` : '';
    const preselectedEntityIdQueryParam = preselectedEntityId ? `?impactedEntityId=${encodeURIComponent(preselectedEntityId)}` : '';
    return `${pathPrefix}/${EXCEPTIONS}/${CREATE}${preselectedEntityIdQueryParam}`;
};

/**
 * Returns a link to the Exception Details page.
 */
export const getExceptionDetailsUrl = (exceptionId: string, exceptionType: IssuesExceptionsModule): string => {
    const pathPrefix = exceptionType === IssuesExceptionsModule.TPRM ? `/${TPRM}` : '';
    return `${pathPrefix}/${EXCEPTIONS}/${exceptionId}`;
};

/**
 * Returns a link to the Manage Exception page, where the user can save changes to an existing issue. If `isClosingException` is true, the page will include form fields specific to closing the exception.
 */
export const getUpdateExceptionUrl = (exceptionId: string, exceptionType: IssuesExceptionsModule, isClosingException: boolean): string => {
    const pathPrefix = exceptionType === IssuesExceptionsModule.TPRM ? `/${TPRM}` : '';
    return `${pathPrefix}/${EXCEPTIONS}/${exceptionId}/${CONFIGURATION}?closing=${isClosingException}`;
};
