import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep, isEqual } from 'lodash-es';
import { Fragment, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import IconButton from 'Components/Buttons/IconButton';
import { useUsers } from 'Components/Context/UsersContext';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import FormFieldTextArea from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import FormFieldUserMultiSelect from 'Components/FormField/FormFieldUserSelect/FormFieldUserMultiSelect';
import FormFieldUserSelect from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import Text from 'Components/Text/Text';
import { IRQ, SERVICES, TPRM, VENDORS } from 'Config/Paths';
import { validateEmail } from 'Helpers/InputValidation';
import { ValidationError } from 'Models/ErrorTypes';
import { SaveVendorServiceRequest, Service, ServiceAssessmentState, VendorContact, VendorResponse, VendorResponseWithServices } from 'Models/TPRM';
import { OptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './SaveTPRMVendorServiceModal.module.css';

export interface SaveTPRMVendorServiceModalProps {
    defaultVendor?: VendorResponseWithServices;
    hideModal: () => void;
    tprmApi: TPRMApi;
    users: UserResponse[];
    vendors?: VendorResponse[];
    vendorService?: Service;
    vendorServiceSaved: () => void;
}

interface FormFieldsState {
    vendorId?: string;
    name?: string;
    description?: string;
    vendorServiceManagerUserId?: string;
    delegates?: UserResponse[];
    vendor_contacts: VendorContact[];
    responsibleOrganization?: string;
}

const SaveTPRMVendorServiceModal = (props: SaveTPRMVendorServiceModalProps): JSX.Element => {
    const { users } = useUsers();

    const [successMessage, setSuccessMessage] = useState<JSX.Element | string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [vendorServiceManager, setVendorServiceManager] = useState<UserResponse>(); // Keeps track of the currently selected User in the Vendor Service Manager drop-down menu.
    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({
        vendorId: props.defaultVendor?.id || props.vendorService?.vendor_id,
        name: props.vendorService?.name,
        description: props.vendorService?.description,
        vendorServiceManagerUserId: props.vendorService?.vendor_service_manager_user_id,
        delegates: props.vendorService?.delegates ? props.users.filter((user) => props.vendorService?.delegates?.includes(user.cognito_subject)) : undefined,
        vendor_contacts: props.vendorService?.vendor_contacts || [],
        responsibleOrganization: props.vendorService?.responsible_organization,
    });

    useEffect(() => {
        const getSelectedUser = async (): Promise<void> => {
            const vendorServiceManager = users.find((user) => user.cognito_subject === props.vendorService?.vendor_service_manager_user_id);
            setVendorServiceManager(vendorServiceManager);
        };
        getSelectedUser();
    }, [props.vendorService?.vendor_service_manager_user_id, users]);

    const createVendorOptions = (): OptionType[] => {
        const vendorOptions: OptionType[] = [];
        props.vendors?.forEach((vendor) => {
            vendorOptions.push({
                label: vendor.name,
                value: vendor.id,
            });
        });
        return vendorOptions;
    };

    const saveVendorService = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        setIsSubmitting(true);
        try {
            validateForm();
            const serviceResponse = await props.tprmApi.saveVendorService(getMinimalRequest(), formFieldsState.vendorId!, props.vendorService?.id);
            if (props.vendorService !== undefined) {
                setSuccessMessage('Vendor service updated.');
            } else {
                setSuccessMessage(
                    <>
                        Vendor service created. The next step is to <Link to={`/${TPRM}/${VENDORS}/${serviceResponse.data.vendor_id}/${SERVICES}/${serviceResponse.data.id}/${IRQ}`}>start the Inherent Risk Questionnaire</Link>.
                    </>
                );
            }
            props.vendorServiceSaved();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    /**
     * This ensures only necessary fields are included in the request to the API.
     */
    const getMinimalRequest = (): SaveVendorServiceRequest => {
        const request: SaveVendorServiceRequest = {
            name: formFieldsState.name,
            description: formFieldsState.description,
            vendor_service_manager_user_id: formFieldsState.vendorServiceManagerUserId,
            delegates: formFieldsState.delegates?.map((user) => user.cognito_subject),
            vendor_contacts: cloneDeep(formFieldsState.vendor_contacts), // cloneDeep() is used so that we can set empty strings to undefined in the next step without modifying the original state, which would throw a React error about switching from controlled to uncontrolled components.
            responsible_organization: formFieldsState.responsibleOrganization,
        };

        const minimalRequest: SaveVendorServiceRequest = {};

        // Compare the form fields to the existing Vendor Service and only include fields that have changed.
        Object.keys(request).forEach((key) => {
            // We are updating an existing Vendor Service, only include fields that have changed.
            if (props.vendorService !== undefined) {
                // Need special handling for delegates and vendor_contacts since JavaScript can't easily check arrays and objects for equality.
                if (key === 'delegates') {
                    if (!isEqual(props.vendorService[key].sort(), request[key]?.sort())) {
                        minimalRequest[key] = request[key];
                    }
                } else if (key === 'vendor_contacts') {
                    if (!isEqual(props.vendorService[key].sort(), request[key]?.sort())) {
                        minimalRequest[key] = request[key];
                    }
                }
                // Everything else can be directly checked for equality.
                else if (props.vendorService[key] !== request[key]) {
                    minimalRequest[key] = request[key];
                }
            }
            // We are creating a new Vendor Service, include everything.
            else {
                minimalRequest[key] = request[key];
            }
        });

        // Discard any Vendor contacts where all attributes are "empty."
        // This is necessary because the API will save empty Vendor contacts to the database (as {}) and then empty Vendor contact "rows" will persist in the UI.
        minimalRequest.vendor_contacts = minimalRequest.vendor_contacts?.filter((vendorContact) => {
            return Object.values(vendorContact).some((value) => {
                if (typeof value === 'string' && value.trim().length === 0) {
                    return false;
                }
                return true;
            });
        });

        // Set any attributes of Vendor contacts that are "empty" strings to undefined before submitting the request.
        // This is necessary because the API will return an error if email_address is an empty string. The other attributes are included simply for completeness.
        minimalRequest.vendor_contacts?.forEach((vendorContact) => {
            if ((vendorContact.email_address?.trim().length ?? 0) === 0) {
                vendorContact.email_address = undefined;
            }
            if ((vendorContact.name?.trim().length ?? 0) === 0) {
                vendorContact.name = undefined;
            }
            if ((vendorContact.phone_number?.trim().length ?? 0) === 0) {
                vendorContact.phone_number = undefined;
            }
            if ((vendorContact.additional_information?.trim().length ?? 0) === 0) {
                vendorContact.additional_information = undefined;
            }
        });

        return minimalRequest;
    };

    const validateForm = (): void => {
        if (!formFieldsState.vendorId) {
            throw new ValidationError('A service must be assigned to a vendor.');
        }
        if (!formFieldsState.name) {
            throw new ValidationError('Service name is required.');
        }
        if (!formFieldsState.vendorServiceManagerUserId) {
            throw new ValidationError('Service manager is required.');
        }
        if (!formFieldsState.description) {
            throw new ValidationError('Service description is required.');
        }
        formFieldsState.vendor_contacts.forEach((vendor_contact) => {
            if (vendor_contact.email_address && !validateEmail(vendor_contact.email_address)) {
                throw new ValidationError('Invalid email address.');
            }
        });
    };

    /** Handles error responses from the API. */
    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    /** Handles changes made to general text fields. */
    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    /** Handles changes made to general drop-down (select) fields. */
    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
    };

    /** Handles changes made to the Vendor Service Manager drop-down (select) field. */
    const handleSelectUserChange = (user: UserResponse, formFieldId: string): void => {
        setVendorServiceManager(user);
        setFormFieldsState({ ...formFieldsState, [formFieldId]: user.cognito_subject });
    };

    /** Handles changes made to the Vendor Service Delegates drop-down (select) field. */
    const handleSelectUsersChange = (users: UserResponse[] | undefined, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: users });
    };

    /** Adds the fields (empty) for a new Vendor contact. */
    const handleAddVendorContact = (): void => {
        const vendorContacts = [...formFieldsState.vendor_contacts];
        vendorContacts.push({ name: '', email_address: '', phone_number: '', additional_information: '' });
        setFormFieldsState({ ...formFieldsState, vendor_contacts: vendorContacts });
    };

    /** Deletes an existing Vendor contact. */
    const handleDeleteVendorContact = (index: number): void => {
        const vendorContacts = [...formFieldsState.vendor_contacts];
        vendorContacts.splice(index, 1);
        setFormFieldsState({ ...formFieldsState, vendor_contacts: vendorContacts });
    };

    /** Handles changes made to an existing Vendor contact. */
    const handleVendorContactChange = (index: number, attribute: 'name' | 'email_address' | 'phone_number' | 'additional_information', value: string): void => {
        const vendorContacts = [...formFieldsState.vendor_contacts];
        vendorContacts[index] = {
            ...vendorContacts[index],
            [attribute]: value,
        };
        setFormFieldsState({ ...formFieldsState, vendor_contacts: vendorContacts });
    };

    const isArchiving = props.vendorService?.assessment_state === ServiceAssessmentState.ARCHIVING;

    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                {isArchiving && <Alert variant="warning">This service is being archived and changes cannot be made.</Alert>}
                <Form noValidate onSubmit={saveVendorService}>
                    {props.vendorService ? <Text variant="Header2">Update Vendor Service</Text> : <Text variant="Header2">Create Vendor Service</Text>}
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldSelect options={createVendorOptions()} handleChange={handleSelectChange} formFieldId="vendorId" formFieldLabel="Vendor Name" isRequiredField selectedOption={formFieldsState.vendorId} disabled={props.vendorService !== undefined} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText disabled={isArchiving} formFieldType="text" handleChange={handleChange} formFieldId="name" formFieldLabel="Vendor Service Name" required={true} tooltip="The name of the service that the selected vendor is providing." invalidMessage="Please enter a name for the service." value={formFieldsState.name || ''} />
                        </div>
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isArchiving} formFieldType="text" handleChange={handleChange} formFieldId="responsibleOrganization" formFieldLabel="Organization Responsible for Vendor Relationship" tooltip="The internal organization or department responsible for managing the relationship with the vendor for this service." invalidMessage="Please enter a valid organization." value={formFieldsState.responsibleOrganization || ''} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldUserSelect disabled={isArchiving} users={users ? users : []} onUserSelected={handleSelectUserChange} formFieldId="vendorServiceManagerUserId" selectedUser={vendorServiceManager} formFieldLabel="Vendor Service Manager" tooltip={'The internal user responsible for continuous oversight of the service provided by the vendor.'} isRequiredField />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldUserMultiSelect disabled={isArchiving} users={props.users} onUsersSelected={handleSelectUsersChange} formFieldId="delegates" selectedUsers={formFieldsState.delegates} formFieldLabel="Delegates" tooltip={'An optional list of additional internal users to receive notifications about the service.'} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldTextArea disabled={isArchiving} handleChange={handleChange} formFieldId="description" formFieldLabel="Vendor Service Description" rows={5} required invalidMessage="Please enter a valid description." tooltip="A description of the service that the vendor is providing." value={formFieldsState.description || ''} />
                    </div>
                    <Text variant="Header3" color="darkGray">
                        Vendor Service Contact Information
                    </Text>
                    {formFieldsState.vendor_contacts.map((vendorContact, index) => (
                        <Fragment key={index}>
                            <div className={styles.formFieldGroup}>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText disabled={isArchiving} formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVendorContactChange(index, 'name', event.currentTarget.value)} formFieldId={`vendorContactName${index}`} formFieldLabel={`Name`} tooltip="The name of the contact at this vendor for this service." value={vendorContact.name || ''} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText disabled={isArchiving} formFieldType="email" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVendorContactChange(index, 'email_address', event.currentTarget.value)} formFieldId={`vendorContactEmailAddress${index}`} formFieldLabel={`Email Address`} tooltip="The email address of the contact at this vendor for this service." value={vendorContact.email_address || ''} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText disabled={isArchiving} formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVendorContactChange(index, 'phone_number', event.currentTarget.value)} formFieldId={`vendorContactPhoneNumber${index}`} formFieldLabel={`Phone Number`} tooltip="The phone number of the contact at this vendor for this service." value={vendorContact.phone_number || ''} />
                                </div>
                                <div className={styles.trashIconContainer}>
                                    <IconButton aria-label={`delete vendor contact ${index}`} onClick={() => handleDeleteVendorContact(index)} fontAwesomeImage={faTrash} />
                                </div>
                            </div>
                            <div className={styles.formFieldContainer}>
                                <FormFieldTextArea handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVendorContactChange(index, 'additional_information', event.currentTarget.value)} formFieldId={`vendorContactAdditionalInformation${index}`} formFieldLabel={`Additional Information`} tooltip="Any additional information for the contact at this vendor for this service." value={vendorContact.additional_information || ''} />
                            </div>
                        </Fragment>
                    ))}
                    <div className={styles.formFieldContainer}>
                        <Button variant="linkText" size="lg" onClick={handleAddVendorContact}>
                            {'+ Add Contact'}
                        </Button>
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes} disabled={isSubmitting}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={isSubmitting || isArchiving} isLoading={isSubmitting} loadingText="Saving...">
                            SAVE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SaveTPRMVendorServiceModal;
