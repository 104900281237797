import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { useEffect, useState } from 'react';

import { axisColor, chartFont } from 'Components/BaseCharts/charts.common';
import { CSSColors } from 'Components/Colors';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import Text from 'Components/Text/Text';
import { undefinedComparator } from 'Helpers/Compare';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { RiskAssessment, RiskCategoryResponse, RiskResponse, formatTotalImpactOrProbability } from 'Models/RiskRegister';
import { OptionType } from 'Models/Types/GlobalType';

import styles from './RiskPeriodComparison.module.css';
import { ALL_RISKS } from '../RiskAssessmentListing';

export enum RiskPeriodTrendRiskType {
    INHERENT_RISK = 'total_inherent_risk',
    CONTROL_ENVIRONMENT = 'control_environment_effectiveness',
    CURRENT_RISK = 'total_current_risk',
    TARGET_RISK = 'total_target_risk',
}

const riskOptionType: OptionType[] = [
    {
        label: 'Inherent Risk',
        value: RiskPeriodTrendRiskType.INHERENT_RISK,
    },
    {
        label: 'Average Weighted Effectiveness',
        value: RiskPeriodTrendRiskType.CONTROL_ENVIRONMENT,
    },
    {
        label: 'Current Residual Risk',
        value: RiskPeriodTrendRiskType.CURRENT_RISK,
    },
    {
        label: 'Target Residual Risk',
        value: RiskPeriodTrendRiskType.TARGET_RISK,
    },
];

export interface RiskPeriodComparisonProps {
    risks: RiskResponse[];
    riskAssessments: RiskAssessment[];
    selectedRiskCategory: string;

    /**
     * Some pages display assessment deltas across multiple categories, while others display deltas for a single category. This prop is only used for the former.
     */
    categorySelection?: {
        categories: RiskCategoryResponse[];
        handleRiskCategoryChange: (value: ChangeEventType) => void;
    };
}

interface ChangeOverTimeDataForRisk {
    name: string;
    trend: 'better' | 'worse';
    currentValue?: number;
    previousData?: {
        value: number;
        timestamp: string;
        delta: number;
    };
}

// TODO: extract logic from getOption and test it.
export const RiskPeriodComparison = (props: RiskPeriodComparisonProps): JSX.Element => {
    const [selectedAssessmentDate, setSelectedAssessmentDate] = useState<string>(ALL_ASSESSMENTS); // By default, show the change since the last assessment for all risks in all categories.
    const [selectedRiskType, setSelectedRiskType] = useState<RiskPeriodTrendRiskType>(RiskPeriodTrendRiskType.INHERENT_RISK);

    const primaryFont = getComputedStyle(document.documentElement).getPropertyValue(chartFont);

    /**
     * Returns an array of data points, where each data point corresponds with a single risk, based on:
     * * The selected risk category.
     * * The selected data to be displayed (inherent risk, average weighted effectiveness, etc.).
     * * The selected assessment against which current data will be compared.
     * * * If a risk has not been previously assessed, then its data point will not include `previousValue` or `delta`.
     */

    useEffect(() => {
        if (props.selectedRiskCategory === ALL_RISKS && selectedAssessmentDate !== ALL_ASSESSMENTS) {
            setSelectedAssessmentDate(ALL_ASSESSMENTS);
        }
    }, [props.selectedRiskCategory, selectedAssessmentDate]);

    const getOption = (risks: RiskResponse[], primaryFont: string): EChartsOption => {
        const riskComparison = createComparisonData(risks, props.riskAssessments, selectedAssessmentDate, selectedRiskType, props.selectedRiskCategory);

        const scatterPointText = (() => {
            switch (selectedRiskType) {
                case RiskPeriodTrendRiskType.INHERENT_RISK:
                    return 'Inherent Risk';
                case RiskPeriodTrendRiskType.CONTROL_ENVIRONMENT:
                    return 'Average Weighted Effectiveness';
                case RiskPeriodTrendRiskType.CURRENT_RISK:
                    return 'Residual Risk';
                case RiskPeriodTrendRiskType.TARGET_RISK:
                    return 'Target Residual Risk';
            }
        })();

        return {
            aria: {
                enabled: true,
                data: {
                    maxCount: 0,
                },
            },
            tooltip: {
                trigger: 'item',
            },
            legend: { show: false },
            textStyle: {
                fontFamily: primaryFont,
                fontWeight: 'bold',
            },
            grid: {
                containLabel: true,
                left: '3%',
                right: '3%',
                top: '5%',
                bottom: '5%',
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01],
                position: 'top',
                axisLabel: { color: CSSColors.BLUE },
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: axisColor,
                    },
                },
                min: 0,
                max: selectedRiskType === RiskPeriodTrendRiskType.CONTROL_ENVIRONMENT ? 5 : 25,
            },
            yAxis: {
                type: 'category',
                data: riskComparison.map((risk) => risk.name),
                axisLabel: { color: CSSColors.BLUE, width: 250, overflow: 'break', lineHeight: 16, showMaxLabel: true, hideOverlap: false },
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: axisColor,
                    },
                },
            },
            series: [
                // Plot transparent bars to push the visible ("delta") bars into position, so that the visible bars are between the current and previous values.
                {
                    name: 'transparent',
                    type: 'bar',
                    stack: 'total',
                    data: riskComparison.map((risk) => {
                        if (risk.currentValue === undefined || risk.previousData === undefined) {
                            return undefined;
                        } else {
                            return risk.previousData.delta < 0 ? risk.currentValue : risk.previousData.value;
                        }
                    }),
                    itemStyle: { color: 'transparent' },
                    legendHoverLink: false,
                    silent: true,
                },
                // Plot "delta" bars between the current and previous values. For each bar, style its color based on whether the selected data has gotten better or worse.
                {
                    name: 'Delta',
                    type: 'bar',
                    stack: 'total',
                    data: riskComparison.map((risk) => {
                        if (risk.previousData === undefined) {
                            return undefined;
                        } else {
                            return {
                                name: `${risk.previousData.delta > 0 ? '+' : '-'}${formatTotalImpactOrProbability(Math.abs(risk.previousData.delta))} since ${iso8601ToUsDateShort(risk.previousData.timestamp)}`,
                                value: Math.abs(risk.previousData.delta),
                                itemStyle: { color: risk.trend === 'better' ? CSSColors.DARK_GREEN : CSSColors.RED },
                            };
                        }
                    }),
                    barWidth: 8,
                    tooltip: { formatter: (params: { data: { name: string } }) => params.data.name },
                },
                // Plot points for current values.
                {
                    name: `Current ${scatterPointText}`,
                    type: 'scatter',
                    symbolSize: 16,
                    data: riskComparison.map((risk) => ({
                        name: `Current ${scatterPointText}${(() => {
                            if (!risk.previousData) {
                                return ' (no previous value)';
                            } else {
                                if (risk.previousData.delta === 0) {
                                    return ' (no change)';
                                } else {
                                    return '';
                                }
                            }
                        })()}`,
                        value: risk.currentValue,
                        itemStyle: { color: risk.trend === 'better' ? CSSColors.DARK_GREEN : CSSColors.RED },
                        symbol: risk.previousData && risk.previousData.delta !== 0 ? 'arrow' : 'circle',
                        symbolSize: 16,
                        // Without an offset, the arrows don't quite overlap with enough of the ends of the bars.
                        symbolOffset: (() => {
                            if (!risk.previousData) {
                                return 0;
                            } else {
                                return risk.previousData.delta < 0 ? [-6, 0] : [6, 0];
                            }
                        })(),
                        symbolRotate: (() => {
                            if (!risk.previousData) {
                                return 0;
                            } else {
                                return risk.previousData.delta < 0 ? 90 : -90;
                            }
                        })(),
                        tooltip: { formatter: (params: { marker: string; seriesName: string; data: { name: string; value: number } }) => `${params.marker} ${params.data.name} &emsp; <b>${Number(params.data.value.toFixed(2))}</b>` },
                    })),
                },
                // Plot points for previous values.
                {
                    name: scatterPointText,
                    type: 'scatter',
                    symbol: 'circle',
                    symbolSize: 16,
                    data: riskComparison.map((risk) => {
                        if (risk.previousData === undefined || risk.previousData.delta === 0) {
                            return undefined;
                        } else {
                            return {
                                name: iso8601ToUsDateShort(risk.previousData.timestamp),
                                value: risk.previousData.value,
                                itemStyle: { color: risk.trend === 'better' ? CSSColors.DARK_GREEN : CSSColors.RED },
                            };
                        }
                    }),
                    tooltip: { formatter: (params: { marker: string; seriesName: string; data: { name: string; value: number } }) => `${params.marker}${params.seriesName} on ${params.data.name} &emsp; <b>${Number(params.data.value.toFixed(2))}</b>` },
                },
            ],
        };
    };

    const handleSelectRiskCategoryChange = (value: ChangeEventType): void => {
        if (value !== props.selectedRiskCategory && selectedAssessmentDate !== ALL_ASSESSMENTS) {
            setSelectedAssessmentDate(ALL_ASSESSMENTS);
        }

        props.categorySelection!.handleRiskCategoryChange(value);
    };

    const handleSelectDateChange = (value: ChangeEventType): void => {
        setSelectedAssessmentDate(value as string);
    };

    const handleSelectRiskTypeChange = (value: ChangeEventType): void => {
        setSelectedRiskType(value as RiskPeriodTrendRiskType);
    };

    /**
     * Constructs the list of options from which the user can select in the assessment date drop-down.
     *
     * "Most Recent Assessment" is always an option. It is the only option when the user is viewing assessments from all categories.
     * The other options come from the dates of previous assessments. If more than one assessment was performed for the same category on the same day, then only the most recent assessment from that day is used.
     */

    const assessmentDateOptions = (() => {
        const options: OptionType[] = [{ label: 'Most Recent Review', value: ALL_ASSESSMENTS }];

        const assessmentsForSelectedCategory = props.riskAssessments.filter((assessment) => assessment.category.id === props.selectedRiskCategory).sort((assessmentA, assessmentB) => (assessmentA.timestamp > assessmentB.timestamp ? -1 : 1));
        const assessmentDates = new Set<string>();

        assessmentsForSelectedCategory.forEach((assessment) => {
            const formattedTimestamp = iso8601ToUsDateShort(assessment.timestamp);
            if (!assessmentDates.has(formattedTimestamp)) {
                assessmentDates.add(formattedTimestamp);
                options.push({ label: formattedTimestamp, value: assessment.timestamp });
            }
        });

        return options;
    })();

    return (
        <div className={styles.tabContainer}>
            <PageContent>
                <div className={styles.cell}>
                    <PageCell>
                        <div className={styles.tableHeader}>
                            <Text variant="Header2">Risk Period Comparison</Text>
                            <div className={styles.filter}>
                                {props.categorySelection && (
                                    <div className={styles.selectContainer}>
                                        <FormFieldSelect options={props.categorySelection.categories.map((category) => ({ label: category.title, value: category.id }))} formFieldId="selectedRiskCategory" formFieldLabel="Filter By Risk Category" handleChange={handleSelectRiskCategoryChange} selectedOption={props.selectedRiskCategory} />
                                    </div>
                                )}
                                <div className={styles.selectContainer}>
                                    <FormFieldSelect options={assessmentDateOptions} formFieldId="selectedRiskAssessment" formFieldLabel="Filter By Risk Assessment Date" handleChange={handleSelectDateChange} selectedOption={selectedAssessmentDate} disabled={props.selectedRiskCategory === ALL_RISKS} />
                                </div>
                                <div className={styles.selectContainer}>
                                    <FormFieldSelect options={riskOptionType} formFieldId="selectedDate" formFieldLabel="Filter By Data" handleChange={handleSelectRiskTypeChange} selectedOption={selectedRiskType} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <ReactECharts
                            option={getOption(props.risks, primaryFont)}
                            style={{
                                height: '100%',
                                width: '100%',
                                minHeight: props.risks.length > 3 ? `${props.risks.length * 50}px` : '300px',
                                minWidth: '200px',
                            }}
                        />
                    </PageCell>
                </div>
            </PageContent>
        </div>
    );
};

const ALL_ASSESSMENTS = 'All_ASSESSMENTS';

export const createComparisonData = (risks: RiskResponse[], riskAssessments: RiskAssessment[], selectedAssessmentDate: string, selectedRiskType: RiskPeriodTrendRiskType, selectedRiskCategory: string): ChangeOverTimeDataForRisk[] => {
    const currentRisksByCategory = risks.filter((risk) => risk.category.id === selectedRiskCategory || selectedRiskCategory === ALL_RISKS);
    // Construct a Map that allows retrieval of the latest assessment (if present) by category.
    const latestAssessmentsForEachCategory = new Map<string, RiskAssessment>();
    riskAssessments.forEach((assessment) => {
        if (!latestAssessmentsForEachCategory.has(assessment.category.id) || assessment.timestamp > latestAssessmentsForEachCategory.get(assessment.category.id)!.timestamp) {
            //TODO: This should be using the category id from the RiskCategoryResponse object.
            latestAssessmentsForEachCategory.set(assessment.category.id, assessment);
        }
    });
    // Construct the data points for each risk that will be displayed on the chart.
    const riskPeriodValues: ChangeOverTimeDataForRisk[] = currentRisksByCategory.map((risk) => {
        // Get the data for the risk from its latest assessment--or from a specific assessment, if the user has selected a date.
        // This data will be `undefined` if the risk has not been assessed in the past.

        // if selectedAssessmentDate is selected find the assessment for the selected date if it is not selected return the most recent assessment for each category
        const previousAssessment = selectedAssessmentDate !== ALL_ASSESSMENTS ? riskAssessments.find((assessment) => assessment.timestamp === selectedAssessmentDate)! : latestAssessmentsForEachCategory.get(risk.category.id);

        const previousRisk = previousAssessment === undefined ? undefined : previousAssessment.risk_histories.find((assessedRisk) => assessedRisk.id === risk.id);

        // If the risk was previously assessed, and the assessment has the data the user is interested in, plot two points (previous and current) on the chart.
        // Otherwise, plot a single point (current) on the chart.
        const currentValue = risk[selectedRiskType];
        const previousValue = previousRisk ? previousRisk[selectedRiskType] : undefined;
        const delta = currentValue !== undefined && previousValue !== undefined ? currentValue - previousValue : undefined;
        const trend = (() => {
            if (delta === undefined) {
                return 'better';
            } else {
                if (selectedRiskType === RiskPeriodTrendRiskType.CONTROL_ENVIRONMENT) {
                    return delta >= 0 ? 'better' : 'worse';
                } else {
                    return delta > 0 ? 'worse' : 'better';
                }
            }
        })();

        return {
            name: risk.title,
            trend: trend,
            currentValue: currentValue,
            previousData:
                previousValue !== undefined && previousAssessment !== undefined && delta !== undefined
                    ? {
                          value: previousValue,
                          timestamp: previousAssessment!.timestamp,
                          delta: delta,
                      }
                    : undefined,
        };
    });

    // Sort the data points such that the risk with the greatest change since its last assessment is at the top of the chart.
    return riskPeriodValues.sort((riskA, riskB) => undefinedComparator(riskA.previousData?.delta, riskB.previousData?.delta, (deltaA, deltaB) => (Math.abs(deltaA) < Math.abs(deltaB) ? 1 : -1)));
};
