import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Text from 'Components/Text/Text';
import { VendorContact } from 'Models/TPRM';

import styles from './VendorContactModal.module.css';

export interface VendorContactModalProps {
    header: string;
    hideModal: () => void;
    vendorContacts: VendorContact[];
}

export const VendorContactModal = (props: VendorContactModalProps): JSX.Element => {
    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text={props.header} />
                {props.vendorContacts.map((vendorContact, index) => (
                    <Fragment key={index}>
                        <div className={styles.vendorContactAttributeGroup}>
                            <div className={styles.vendorContactAttribute}>
                                <Text noStyles={true}>{vendorContact.name ? vendorContact.name : '-'}</Text>
                            </div>
                            <div className={styles.vendorContactAttributeEmail}>
                                <Text noStyles={true}>{vendorContact.email_address ? vendorContact.email_address : '-'}</Text>
                            </div>
                            <div className={styles.vendorContactAttribute}>
                                <Text noStyles={true}>{vendorContact.phone_number ? vendorContact.phone_number : '-'}</Text>
                            </div>
                        </div>
                        {vendorContact.additional_information && <Text>{vendorContact.additional_information}</Text>}
                    </Fragment>
                ))}
                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                        CLOSE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
