import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import { PrimaryTabs } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import Text from 'Components/Text/Text';

import styles from './PageHeaderTabs.module.css';
import PageContent from '../PageContent/PageContent';

export interface PageHeaderTabsProps {
    title: string;
    defaultActiveTab: string;
    onSelect?: (eventKey?: string) => void;
    breadcrumb?: JSX.Element[] | JSX.Element;
    children: JSX.Element[] | JSX.Element;
}
/**
 *
 * @param {string} title Title of the page header
 * @param {string} defaultActiveTab eventKey of active/open tab at initial render
 * @param {function} onSelect callback after tab is selected
 * @param {object} children Children must be Tab component or the JSX.Element will render permanently
 */
const PageHeaderTabs = (props: PageHeaderTabsProps): JSX.Element => {
    return (
        <PageBackground color="grey">
            <PageContent>
                {props.breadcrumb && props.breadcrumb}
                <div className={styles.titleContainer}>
                    <Text color="darkBlue" variant="Header1">
                        {props.title}
                    </Text>
                </div>
            </PageContent>
            <PrimaryTabs {...props}>{props.children}</PrimaryTabs>
        </PageBackground>
    );
};

export default PageHeaderTabs;
