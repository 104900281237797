import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { LogoutApi } from 'Api/Auth/LogoutApi';
import { SessionApi } from 'Api/Auth/SessionApi';
import { ClientDetailsApi } from 'Api/Client/ClientDetailsApi';
import { useNav } from 'Components/Context/NavContext';
import { RBACProvider, Role } from 'Components/Context/RBACContext';
import Header, { HeaderNetworkingApis, HeaderProps } from 'Components/Header/Header';
import { IdleTimeoutManager, IdleTimeoutManagerProps } from 'Components/IdleTimeoutManager/IdleTimeoutManager';
import { Nav } from 'Components/Nav/Nav';
import { AuthHandler } from 'Helpers/Auth/AuthHandler';

import styles from './TimerNavigation.module.css';

export interface TimerNavigationProps {
    authHandler: AuthHandler & LogoutApi;
    sessionApi: SessionApi;
    clientDetailsApi: ClientDetailsApi;
    headerApis: HeaderNetworkingApis;
    roles: Role[];
    children: ReactNode;
}

/**
 * This is essentially a wrapper for the idle timeout, navigation menu, header, and page.
 * @param props
 * @returns
 */
export const TimerNavigation = (props: TimerNavigationProps): JSX.Element => {
    const navigate = useNavigate();
    const userRoles = props.authHandler.getUserRoles();

    const { isOpen: navIsOpen, setIsOpen: setNavOpen } = useNav();

    const idleTimeoutManagerProps: IdleTimeoutManagerProps = {
        logoutApi: props.authHandler,
        sessionApi: props.sessionApi,
        promptBeforeIdleCountdown: 1000 * 60, // 1 minute (in milliseconds) from when the user is prompted until they are timed out
        timeoutCountdown: 1000 * 60 * 14, // 14 of time left when the user becomes timed out. This is the refresh token lifetime (15 minutes) - keepAliveThrottle. This is because the user and Okta could be at a drift by a maximum of keepAliveThrottle.
        keepAliveThrottle: 1000 * 60, // Send the keep alive api call every 1 minute (in milliseconds) of user action
    };

    const headerProps: HeaderProps & HeaderNetworkingApis = {
        authHandler: props.authHandler,
        clientDetailsApi: props.clientDetailsApi,
        isNavOpen: navIsOpen,
        logoutApi: props.authHandler,
        navigate: navigate,
        setNavOpen: setNavOpen,
        ...props.headerApis,
    };
    return (
        <div>
            <IdleTimeoutManager {...idleTimeoutManagerProps} />
            <RBACProvider roles={userRoles}>
                {navIsOpen && <Nav clientDetailsApi={props.clientDetailsApi} setNavOpen={setNavOpen} />}
                <div className={styles.headerAndContentContainer}>
                    <div className={navIsOpen === true ? styles.mainBody : undefined}>
                        <Header {...headerProps} />
                        {props.children}
                    </div>
                </div>
            </RBACProvider>
        </div>
    );
};
