/*
	MetricDescription.tsx -- A component for rendering a description about a Metric.
*/
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Text from 'Components/Text/Text';
import { Metric, MetricMetadata } from 'Models/ExternalIntegrations';

import styles from './MetricDescription.module.css';

export interface MetricDescriptionProps {
    hideModal: () => void;
    metric: Metric;
}

export class MetricDescription extends Component<MetricDescriptionProps> {
    formatRecommendedControlMapping = (recommendedControlMapping: string): JSX.Element[] => {
        const elements = [];

        const controlFrameworks = recommendedControlMapping.split(';');

        let recommendation;
        for (let i = 0; i < controlFrameworks.length; i++) {
            recommendation = controlFrameworks[i].trim();
            elements.push(
                <li key={recommendation}>
                    <Text noStyles>{recommendation}</Text>
                </li>
            );
        }

        return elements;
    };

    render(): JSX.Element {
        return (
            <Modal show onHide={this.props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    <ModalHeader text={this.props.metric.metric_name} />
                    <Text variant="Header4">Description:</Text>
                    <Text>{MetricMetadata[this.props.metric.integration_name][this.props.metric.metric_name]!.description}</Text>
                    <Text variant="Header4">Recommended Control Mapping:</Text>
                    <div className={styles.text}>
                        <ul>{this.formatRecommendedControlMapping(MetricMetadata[this.props.metric.integration_name][this.props.metric.metric_name]!.recommended_control_mapping)}</ul>
                    </div>
                    <div className={styles.buttonRowContainer}>
                        <Button variant="secondary" onClick={this.props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
