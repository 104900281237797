// Third-party libraries.
import { faBan, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';

// Components.
import OverflowMenu, { OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import SortableTableHeader, { HeaderData, SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody, TableCellDefaultText, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
// Helpers.
import { getUserNameFromSubject, getUserNameFromUser } from 'Helpers/UserUtils';
// Models.
import { ManageUsersSortFilterOptions, Status, UserResponse } from 'Models/User';

export interface ManageUsersTableViewProps {
    users: UserResponse[];
    applySorting: (sortProperty: string, sortDirection: SortDirection) => void;
    currentSort: string;
    currentSortDirection: SortDirection;
    userSelectedForDeletion: (selectedUserForDeletion: UserResponse) => void;
    userSelectedForDeactivation: (selectedUserForDeactivation: UserResponse) => void;
    userSelectedForReactivation: (selectedUserForDeactivation: UserResponse) => void;
    userSelectedForModification: (selectedUserForModification: UserResponse) => void;
}

export default class ManageUsersTableView extends Component<ManageUsersTableViewProps> {
    headerValues: HeaderData[] = [
        { dataKey: ManageUsersSortFilterOptions.LAST_NAME, label: 'NAME' },
        { dataKey: ManageUsersSortFilterOptions.EMAIL, label: 'EMAIL' },
        { dataKey: ManageUsersSortFilterOptions.DEPARTMENT, label: 'DEPARTMENT' },
        { dataKey: ManageUsersSortFilterOptions.ROLES, label: 'ROLES', disableSort: true },
        { dataKey: ManageUsersSortFilterOptions.STATUS, label: 'STATUS' },
        { dataKey: ManageUsersSortFilterOptions.MFA_REQUIRED, label: 'MFA' },
    ];

    tableRow = (user: UserResponse, index: number): JSX.Element => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [],
            accessibilityTitle: `${getUserNameFromUser(user)} Menu`,
        };

        if (user.status === Status.ACTIVE) {
            overflowMenuProps.overflowItems.push({
                text: 'Modify User',
                onClickAction: () => this.props.userSelectedForModification(this.props.users[index]),
                icon: faEdit,
            });
            overflowMenuProps.overflowItems.push({
                text: 'Deactivate User',
                onClickAction: () => this.props.userSelectedForDeactivation(this.props.users[index]),
                icon: faBan,
            });
        }

        if (user.status === Status.INACTIVE) {
            overflowMenuProps.overflowItems.push({
                text: 'Reactivate User',
                onClickAction: () => this.props.userSelectedForReactivation(this.props.users[index]),
                icon: faCheck,
            });

            /**
             * Deleting users is currently feature toggled off
                overflowMenuProps.overflowItems.push({
                    text: 'Delete User',
                    onClickAction: () => this.props.userSelectedForDeletion(this.props.users[index]),
                    icon: faTrash,
                });
            */
        }

        return (
            <TableRow key={index}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{getUserNameFromSubject(user.cognito_subject, this.props.users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.email_address}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.department}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.roles.sort().join(', ').toUpperCase()}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.status.toUpperCase()}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{user.mfa_required === true ? 'ENABLED' : 'DISABLED'}</Text>
                </TableCellDefaultText>

                <TableOverflowCell>
                    <OverflowMenu {...overflowMenuProps} />
                </TableOverflowCell>
            </TableRow>
        );
    };

    render(): JSX.Element {
        const sortableTableProps: SortableTableHeaderProps = {
            headers: this.headerValues,
            applySorting: this.props.applySorting,
            currentSort: this.props.currentSort,
            currentSortDirection: this.props.currentSortDirection,
            tableIncludesOverflowMenu: true,
        };
        return (
            <Table>
                <SortableTableHeader {...sortableTableProps} />
                <TableBody>{this.props.users.map(this.tableRow)}</TableBody>
            </Table>
        );
    }
}
