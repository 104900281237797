import { Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';

import { AdornedDonutChart } from 'Components/BaseCharts/AdornedDonutChart';
import PageCell from 'Components/Containers/PageCell/PageCell';
import Text from 'Components/Text/Text';
import { TprmDashboardServiceExtended } from 'Models/Dashboards';
import { Effectiveness, effectivenessLabelAsNumber } from 'Models/OperationalControls';
import { RiskRating, riskRatingLabelAsNumber } from 'Models/TPRM';

import { SelectedServiceDetails, ServicesDisplayModal } from '../ServicesDisplayModal/ServicesDisplayModal';
import { buildEffectivenessChartData, buildRiskRatingsChartData } from '../TPRMDashboard.helpers';
import styles from '../TPRMDashboard.module.css';

enum Modals {
    NONE,
    DISPLAY_SERVICES,
}

export interface TPRMDonutChartsProps {
    vendorServices?: TprmDashboardServiceExtended[];
    vendorServicesError?: string;
}

export const TPRMDonutCharts = (props: TPRMDonutChartsProps): JSX.Element => {
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [servicesModalSubtitle, setServicesModalSubtitle] = useState<string>();
    const [selectedServices, setSelectedServices] = useState<SelectedServiceDetails[]>();

    const totalServices = props.vendorServices?.length;

    const inherentRiskDonutChart = useMemo(() => {
        if (props.vendorServices) {
            const filteredScores: number[] = props.vendorServices.map((service) => service.inherent_risk_score).filter((score) => score > 0);

            return buildRiskRatingsChartData([RiskRating.LOW, RiskRating.LOWMODERATE, RiskRating.MODERATE, RiskRating.MODERATEHIGH, RiskRating.HIGH], filteredScores);
        }
    }, [props.vendorServices]);

    const controlEffectivenessDonutChart = useMemo(() => {
        if (props.vendorServices) {
            const filteredScores: number[] = props.vendorServices.map((service) => service.assessment_control_effectiveness).filter((score) => score !== undefined) as number[];

            return buildEffectivenessChartData([Effectiveness.INACTIVE, Effectiveness.FAIL, Effectiveness.WEAK, Effectiveness.MODERATE, Effectiveness.STRONG, Effectiveness.ROBUST], filteredScores);
        }
    }, [props.vendorServices]);

    const residualRiskDonutChart = useMemo(() => {
        if (props.vendorServices) {
            const filteredScores: number[] = props.vendorServices.map((service) => service.assessment_residual_risk_score).filter((score) => score !== undefined) as number[];

            return buildRiskRatingsChartData([RiskRating.LOW, RiskRating.LOWMODERATE, RiskRating.MODERATE, RiskRating.MODERATEHIGH, RiskRating.HIGH], filteredScores);
        }
    }, [props.vendorServices]);

    const handleInherentRiskChartClick = (targetRating: string): void => {
        if (targetRating) {
            const targetRatingScore = riskRatingLabelAsNumber(targetRating);
            setServicesModalSubtitle(targetRating);
            setModalState(Modals.DISPLAY_SERVICES);
            setSelectedServices(
                props
                    .vendorServices!.filter((service) => service.inherent_risk_score === targetRatingScore)
                    .map((service) => ({
                        serviceId: service.id,
                        serviceName: service.name,
                        vendorId: service.vendor_id,
                        vendorName: service.vendor_name,
                    }))
            );
        }
    };

    const handleEffectivenessChartClick = (effectiveness: string): void => {
        if (effectiveness) {
            const effectivenessScore = effectivenessLabelAsNumber(effectiveness);
            setServicesModalSubtitle(effectiveness);
            setModalState(Modals.DISPLAY_SERVICES);
            setSelectedServices(
                props
                    .vendorServices!.filter((service) => service.assessment_control_effectiveness === effectivenessScore)
                    .map((service) => ({
                        serviceId: service.id,
                        serviceName: service.name,
                        vendorId: service.vendor_id,
                        vendorName: service.vendor_name,
                    }))
            );
        }
    };

    const handleResidualRiskChartClick = (targetRating: string): void => {
        if (targetRating) {
            const targetRatingScore = riskRatingLabelAsNumber(targetRating);
            setServicesModalSubtitle(targetRating);
            setModalState(Modals.DISPLAY_SERVICES);
            setSelectedServices(
                props
                    .vendorServices!.filter((service) => service.assessment_residual_risk_score === targetRatingScore)
                    .map((service) => ({
                        serviceId: service.id,
                        serviceName: service.name,
                        vendorId: service.vendor_id,
                        vendorName: service.vendor_name,
                    }))
            );
        }
    };

    const handleServicesModalClose = () => {
        setModalState(Modals.NONE);
        setServicesModalSubtitle(undefined);
        setSelectedServices(undefined);
    };

    if (props.vendorServicesError) {
        return (
            <>
                <div className={styles.section3}>
                    <PageCell variant="transparentBlue">
                        <Text variant="Header2" color="white">
                            Inherent Risk
                        </Text>
                        <Text color="white">{props.vendorServicesError}</Text>
                    </PageCell>
                </div>
                <div className={styles.section3}>
                    <PageCell variant="transparentBlue">
                        <div>
                            <Text variant="Header2" color="white">
                                Overall Control Effectiveness
                            </Text>
                            <Text color="white">{props.vendorServicesError}</Text>
                        </div>
                    </PageCell>
                </div>
                <div className={styles.section3}>
                    <PageCell variant="transparentBlue">
                        <div>
                            <Text variant="Header2" color="white">
                                Residual Risk
                            </Text>
                            <Text color="white">{props.vendorServicesError}</Text>
                        </div>
                    </PageCell>
                </div>
            </>
        );
    }

    return (
        <>
            {modalState === Modals.DISPLAY_SERVICES && servicesModalSubtitle && selectedServices && <ServicesDisplayModal hideModal={handleServicesModalClose} subtitle={servicesModalSubtitle} selectedServices={selectedServices} />}
            <div className={styles.section3}>
                <PageCell variant="transparentBlue">
                    <Text variant="Header2" color="white">
                        Inherent Risk
                    </Text>
                    {inherentRiskDonutChart ? (
                        <AdornedDonutChart name="Inherent Risk" data={inherentRiskDonutChart.data} adornment={{ body: { value: inherentRiskDonutChart.average, fontSize: 60 }, subtitle: { value: inherentRiskDonutChart.averageLabel, fontSize: 20 } }} onChartClick={handleInherentRiskChartClick} />
                    ) : (
                        <div className={styles.services}>
                            <Skeleton sx={{ width: '250px', height: '250px' }} variant="circular" />
                        </div>
                    )}
                    <div className={styles.services}>
                        {inherentRiskDonutChart ? (
                            <Text variant="Header4" color="lightGray">
                                {inherentRiskDonutChart.numServices} of {totalServices} Services
                            </Text>
                        ) : (
                            <Skeleton>
                                <Text variant="Header4" color="lightGray">
                                    --- of --- Services
                                </Text>
                            </Skeleton>
                        )}
                    </div>
                </PageCell>
            </div>
            <div className={styles.section3}>
                <PageCell variant="transparentBlue">
                    <div>
                        <Text variant="Header2" color="white">
                            Overall Control Effectiveness
                        </Text>
                        {controlEffectivenessDonutChart ? (
                            <AdornedDonutChart name="Control Effectiveness" data={controlEffectivenessDonutChart.data} adornment={{ body: { value: controlEffectivenessDonutChart.average, fontSize: 60 }, subtitle: { value: controlEffectivenessDonutChart.averageLabel, fontSize: 20 } }} onChartClick={handleEffectivenessChartClick} />
                        ) : (
                            <div className={styles.services}>
                                <Skeleton sx={{ width: '250px', height: '250px' }} variant="circular" />
                            </div>
                        )}
                        <div className={styles.services}>
                            {controlEffectivenessDonutChart ? (
                                <Text variant="Header4" color="lightGray">
                                    {controlEffectivenessDonutChart.numServices} of {totalServices} Services
                                </Text>
                            ) : (
                                <Skeleton>
                                    <Text variant="Header4" color="lightGray">
                                        --- of --- Services
                                    </Text>
                                </Skeleton>
                            )}
                        </div>
                    </div>
                </PageCell>
            </div>
            <div className={styles.section3}>
                <PageCell variant="transparentBlue">
                    <div>
                        <Text variant="Header2" color="white">
                            Residual Risk
                        </Text>
                        {residualRiskDonutChart ? (
                            <AdornedDonutChart name="Residual Risk" data={residualRiskDonutChart.data} adornment={{ body: { value: residualRiskDonutChart.average, fontSize: 60 }, subtitle: { value: residualRiskDonutChart.averageLabel, fontSize: 20 } }} onChartClick={handleResidualRiskChartClick} />
                        ) : (
                            <div className={styles.services}>
                                <Skeleton sx={{ width: '250px', height: '250px' }} variant="circular" />
                            </div>
                        )}
                        <div className={styles.services}>
                            {residualRiskDonutChart ? (
                                <Text variant="Header4" color="lightGray">
                                    {residualRiskDonutChart.numServices} of {totalServices} Services
                                </Text>
                            ) : (
                                <Skeleton>
                                    <Text variant="Header4" color="lightGray">
                                        --- of --- Services
                                    </Text>
                                </Skeleton>
                            )}
                        </div>
                    </div>
                </PageCell>
            </div>
        </>
    );
};
