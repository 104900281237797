import { useEffect, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import Text from 'Components/Text/Text';
import { DashboardType } from 'Models/Dashboards';
import { VendorResponseWithServices } from 'Models/TPRM';

import { TPRMCompletedAssessmentsChart } from './TPRMAssessmentsChart/TPRMCompletedAssessmentsChart';
import { TPRMUpcomingAssessmentsChart } from './TPRMAssessmentsChart/TPRMUpcomingAssessmentsChart';
import { useVendorServicesQuery } from './TPRMDashboard.hooks';
import styles from './TPRMDashboard.module.css';
import { TPRMDonutCharts } from './TPRMDonutCharts/TPRMDonutCharts';
import { RiskPortfolioHistoryChart } from './TPRMRiskPortfolioHistoryChart/TPRMRiskPortfolioHistoryChart';
import { TPRMRiskProgressCharts } from './TPRMRiskProgressCharts/TPRMRiskProgressCharts';
import { TPRMServiceCallouts } from './TPRMServiceCallouts/TPRMServiceCallouts';
import { DashboardClosedExceptions } from '../Components/DashboardsHistoricalExceptions/DashboardsHistoricalExceptions';
import { DashboardClosedIssues } from '../Components/DashboardsHistoricalIssues/DashboardsHistoricalIssues';
import { DashboardsTrackingExceptions } from '../Components/DashboardsTrackingExceptions/DashboardsTrackingExceptions';
import { DashboardOpenIssues } from '../Components/DashboardsTrackingIssues/DashboardsTrackingIssues';

export interface TPRMDashboardProps {
    dashboardApi: DashboardApi;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
    tprmApi: TPRMApi;
}

export const TPRMDashboard = (props: TPRMDashboardProps): JSX.Element => {
    const { vendorCount, vendorServices, overdueAssessmentsCount, vendorServicesError } = useVendorServicesQuery(props.dashboardApi);
    const [vendors, setVendors] = useState<VendorResponseWithServices[]>();
    const [vendorError, setVendorError] = useState<string>();

    useEffect(() => {
        const getVendors = async (): Promise<void> => {
            try {
                const vendorResponse = await props.tprmApi.getVendors();
                const vendors = vendorResponse.data;

                setVendors(vendors);
            } catch (error) {
                setVendorError(error.message);
            }
        };
        getVendors();
    }, [props.tprmApi]);

    return (
        <PageContent>
            <Text variant="Header1" color="white">
                Vendors and Vendor Services
            </Text>
            <div className={styles.vendorServiceSection}>
                <TPRMServiceCallouts overdueAssessments={overdueAssessmentsCount} vendorCount={vendorCount} vendorServicesCount={vendorServices?.length} vendorServiceError={vendorServicesError} />
            </div>
            <div className={styles.vendorServiceSection}>
                <TPRMDonutCharts vendorServices={vendorServices} vendorServicesError={vendorServicesError} />
            </div>
            <div className={styles.vendorServiceSection}>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <TPRMCompletedAssessmentsChart dashboardApi={props.dashboardApi} />
                    </PageCell>
                </div>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <TPRMUpcomingAssessmentsChart vendorServices={vendorServices} vendorServicesError={vendorServicesError} />
                    </PageCell>
                </div>
            </div>
            <div className={styles.vendorServiceSection}>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <TPRMRiskProgressCharts vendorServices={vendorServices} vendorServicesError={vendorServicesError} />
                    </PageCell>
                </div>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <RiskPortfolioHistoryChart dashboardApi={props.dashboardApi} />
                    </PageCell>
                </div>
            </div>
            <div className={styles.cellHeader}>
                <Text variant="Header1" color="white">
                    Issue Management
                </Text>
            </div>
            <div className={styles.overviewSection}>
                <DashboardClosedIssues issuesApi={props.issuesApi} dashboardType={DashboardType.THIRD_PARTY} vendors={vendors} vendorError={vendorError} />
                <DashboardOpenIssues issuesApi={props.issuesApi} dashboardType={DashboardType.THIRD_PARTY} vendors={vendors} vendorError={vendorError} />
            </div>
            <div className={styles.cellHeader}>
                <Text variant="Header1" color="white">
                    Exception Management
                </Text>
            </div>
            <div className={styles.overviewSection}>
                <DashboardClosedExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.THIRD_PARTY} vendors={vendors} vendorError={vendorError} />
                <DashboardsTrackingExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.THIRD_PARTY} vendors={vendors} vendorError={vendorError} />
            </div>
        </PageContent>
    );
};
