import { Component } from 'react';

import { UserResponse } from 'Models/User';

import ServiceCard from './ServiceCard/ServiceCard';
import styles from './ServiceListingGridView.module.css';
import { ServiceDisplayData } from '../ServiceListing';

export interface ServiceListingGridViewProps {
    services: ServiceDisplayData[];
    selectedDeleteService: (service: ServiceDisplayData) => void;
    selectedModifyService: (service: ServiceDisplayData) => void;
    selectedServiceAssessmentDueDateService: (service: ServiceDisplayData) => void;
    users: UserResponse[];
}

export default class ServiceListingGridView extends Component<ServiceListingGridViewProps> {
    render(): JSX.Element {
        return <div className={styles.gridView}>{this.props.services && this.props.services.map((vendor: ServiceDisplayData) => <ServiceCard key={vendor.serviceID} serviceCard={vendor} selectedDeleteService={this.props.selectedDeleteService} selectedModifyService={this.props.selectedModifyService} selectedServiceAssessmentDueDateService={this.props.selectedServiceAssessmentDueDateService} users={this.props.users} />)}</div>;
    }
}
