import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';

import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Link } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import FormFieldMultiOptionSelect from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import Placeholder from 'Components/Placeholder/Placeholder';
import { RiskTable } from 'Components/RiskRegister/RiskTable/RiskTable';
import Text from 'Components/Text/Text';
import { CREATE, RISK_REGISTER } from 'Config/Paths';
import { useDisplayableTagsLookup, useSortedCategorizedTagsOptions } from 'Hooks/Tags';
import { ResponseModel } from 'Models/ResponseModel';
import { RiskCategoryResponse, RiskResponse } from 'Models/RiskRegister';
import { GroupOptionType } from 'Models/Types/GlobalType';

import styles from './RiskListing.module.css';
import { RiskListingCallouts } from './RiskListingCallouts/RiskListingCallouts';

export interface RiskListingProps {
    riskRegisterApi: RiskRegisterApi;
    tagsApi: TagsApi;
}

export const ALL_RISKS = 'All Risks';

export const RiskListing = (props: RiskListingProps) => {
    const [risks, setRisks] = useState<RiskResponse[]>();
    const [riskCategories, setRiskCategories] = useState<RiskCategoryResponse[]>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);
    const [overallControlEffectiveness, setOverallControlEffectiveness] = useState<number>();
    const [error, setError] = useState<Error>();
    const getTagOptionsState = useSortedCategorizedTagsOptions(props.tagsApi);
    const [selectedTagsFilter, setSelectedTagsFilter] = useState<string[]>([]);
    const [selectedRiskCategory, setSelectedRiskCategory] = useState<string>(ALL_RISKS);

    useEffect(() => {
        const fetchOverallControlEffectiveness = async (): Promise<void> => {
            try {
                const effectivenessResponse = await props.riskRegisterApi.getOverallControlEffectiveness();
                setOverallControlEffectiveness(effectivenessResponse.data);
            } catch (error) {
                setError(error);
            }
        };

        fetchOverallControlEffectiveness();
    }, [props.riskRegisterApi]);

    const getAllRisks = useCallback(() => {
        const getRisks = async (): Promise<void> => {
            try {
                const riskResponse: ResponseModel<RiskResponse[]> = await props.riskRegisterApi.getAllRisks();
                setRisks(riskResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };

        const getRiskCategories = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getRiskCategories();
                setRiskCategories([{ title: ALL_RISKS, id: ALL_RISKS, in_use: true }, ...response.data]);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getRisks();
        getRiskCategories();
    }, [props.riskRegisterApi]);

    useEffect(() => {
        getAllRisks();
    }, [getAllRisks]);

    const handleSelectChange = (value: ChangeEventType): void => {
        setSelectedRiskCategory(value as string);
    };

    if (error) {
        return <Text>{error.message}</Text>;
    }

    if (risks && riskCategories && overallControlEffectiveness !== undefined && getDisplayableTagsState.type === 'success' && getTagOptionsState.type === 'success') {
        const filteredRisks = risks.filter((risk) => selectedRiskCategory === ALL_RISKS || risk.category.id === selectedRiskCategory).filter((risk) => selectedTagsFilter.length === 0 || risk.tags.some((tag) => selectedTagsFilter.includes(tag)));

        return (
            <>
                <PageBackground color="blueMountains">
                    <PageContent>
                        <div className={styles.pageHeader}>
                            <div className={styles.titleBar}>
                                <Text variant="Header1" color="white" noStyles>
                                    Dynamic Risk Register
                                </Text>
                                <div className={styles.formContainer}>
                                    <Link variant="primaryButton" to={`/${RISK_REGISTER}/${CREATE}`} fontAwesomeImage={faAdd}>
                                        Create Risk
                                    </Link>
                                </div>
                            </div>
                            <div className={styles.filterContainer}>
                                <div className={styles.selectContainer}>
                                    <FormFieldSelect options={riskCategories.map((category) => ({ label: category.title, value: category.id }))} formFieldId="selectedRiskCategory" formFieldLabel="Filter By Risk Category" handleChange={handleSelectChange} selectedOption={selectedRiskCategory} colorTheme="dark" />
                                </div>
                                <div className={styles.tagsContainer}>
                                    <FormFieldMultiOptionSelect
                                        defaultSelectedOptions={getTagOptionsState.data
                                            .map((group) => group.options)
                                            .flat()
                                            .filter((option) => selectedTagsFilter.includes(option.value as string))}
                                        formFieldLabel="Filter By Tags"
                                        formFieldId="risk_tags"
                                        handleChange={(value: GroupOptionType[]) => setSelectedTagsFilter(value.map((tag) => tag.value as string))}
                                        options={getTagOptionsState.data}
                                        accessibilityLabel="tag selection"
                                        colorTheme="dark"
                                    />
                                </div>
                            </div>
                            <RiskListingCallouts risks={filteredRisks} completeEnvironmentEffectivenessAverage={overallControlEffectiveness} />
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <div className={styles.cell}>
                            <PageCell>
                                <div className={styles.tableHeader}>
                                    <Text variant="Header2">Risk Listing</Text>
                                </div>
                                <hr />
                                <RiskTable type="current risks" risks={filteredRisks} getDisplayableTags={getDisplayableTagsState.data} />
                            </PageCell>
                        </div>
                    </PageContent>
                </PageBackground>
            </>
        );
    } else if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else if (getDisplayableTagsState.type === 'failure') {
        return <Text>{getDisplayableTagsState.message}</Text>;
    } else {
        return <Placeholder />;
    }
};
