import Text from 'Components/Text/Text';
import { GenericTooltip } from 'Components/Tooltips/GenericTooltip';

import styles from './FieldLabel.module.css';

interface FieldLabelProps {
    fieldName: string;
    tooltipText?: string;
}

/**
 * Renders the name of a field and, optionally, a tooltip explaining the field.
 * Use this component on "details" pages. It is not intended to be used in forms.
 */
export const FieldLabel = (props: FieldLabelProps) => {
    return (
        <div className={styles.fieldLabel}>
            <Text variant="Header4" color="darkBlue">
                {props.fieldName}
            </Text>
            {props.tooltipText && <GenericTooltip text={props.tooltipText} />}
        </div>
    );
};
