import Text from 'Components/Text/Text';

import styles from './ControlMappingListItem.module.css';
import { ControlMappingItem } from '../MultipleControlMapping';

export interface ControlMappingListItemProps {
    controlMappingItem: ControlMappingItem;
    handleSelected: (value: ControlMappingItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    selected?: boolean;
}

export const ControlMappingListItem = (props: ControlMappingListItemProps): JSX.Element => {
    return (
        <div className={`${styles.text} ${props.selected && styles.selected}`} onClick={(e) => props.handleSelected(props.controlMappingItem, e)}>
            <Text variant="Text2" noStyles>
                {props.controlMappingItem.title}
            </Text>
            <Text variant="Text4" noStyles>
                {props.controlMappingItem.description}
            </Text>
        </div>
    );
};
