import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ModalHeader } from 'Components/Modal/ModalHeader';

import styles from './CustomDateRangeModal.module.css';

export interface CustomDateRange {
    startDate: Date;
    endDate: Date;
}

export type CustomDateRangeUpdateCallback = (customDateRange: CustomDateRange) => void;

export interface CustomDateRangeModalProps {
    setCustomDateRange: CustomDateRangeUpdateCallback;
    hideModal: () => void;
    datesRestriction?: 'pastDatesOnly' | 'futureDatesOnly';
    startDate: Date;
    endDate: Date;
}

const CustomDateRangeModal = (props: CustomDateRangeModalProps): JSX.Element => {
    const [startDate, setStartDate] = useState<Date>(props.startDate);
    const [endDate, setEndDate] = useState<Date>(props.endDate);
    const [failureMessage, setFailureMessage] = useState<string>();

    const setCustomDate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!startDate || !endDate) {
            setFailureMessage('Select both a start date and an end date.');
        } else if (startDate >= endDate) {
            setFailureMessage('The end date must come after the start date.');
        } else {
            setFailureMessage(undefined);
            props.setCustomDateRange({ startDate, endDate });
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <ModalHeader text="Choose a Custom Date" />
                <Form onSubmit={setCustomDate}>
                    <div className={styles.dateContainer}>
                        <FormFieldDatePicker formFieldLabel="Start Date" formFieldId="startDate" dateFormat="MM/dd/yyyy" handleChange={setStartDate} selected={startDate} datesRestriction={props.datesRestriction} />
                        <FormFieldDatePicker formFieldLabel="End Date" formFieldId="endDate" dateFormat="MM/dd/yyyy" handleChange={setEndDate} selected={endDate} datesRestriction={props.datesRestriction} />
                    </div>
                    <div className={styles.modalFormButtonContainer}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit">SAVE</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CustomDateRangeModal;
