import { Step as MaterialStep, StepLabel as MaterialStepLabel, Stepper as MaterialStepper } from '@mui/material';
export interface StepperProps {
    activeStep: number;
    children?: React.ReactNode;
}

/**
 * A wrapper for the material-ui Stepper.
 *
 * https://material-ui.com/components/steppers/
 *
 * By default, the Stepper will have a width of 100% and no padding to make alignment easier.
 *
 * By default, the stepper is linear rather than vertical.
 */
const Stepper = (props: StepperProps): JSX.Element => {
    return (
        <MaterialStepper activeStep={props.activeStep} alternativeLabel sx={{ backgroundColor: 'transparent', padding: '0px', width: '100%' }}>
            {props.children}
        </MaterialStepper>
    );
};

export default Stepper;

export interface StepProps {
    children?: React.ReactNode;
}

export const Step = (props: StepProps): JSX.Element => {
    return (
        <MaterialStep
            sx={{
                '& .MuiStepLabel-root': {
                    '& .MuiStepLabel-iconContainer': {
                        '& .Mui-completed': { color: 'var(--hps-white)' },
                        '& .Mui-active': {
                            color: 'var(--hps-white)',
                        },
                    },
                },
                '& .Mui-active': { '& .MuiStepIcon-text': { fill: 'var(--hps-dark-blue)' } },
            }}
            {...props}
        >
            {props.children}
        </MaterialStep>
    );
};

export const StepLabel = (props: StepProps): JSX.Element => {
    return <MaterialStepLabel {...props}>{props.children}</MaterialStepLabel>;
};
