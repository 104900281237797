import { Component } from 'react';

import styles from './PageContent.module.css';

/**
 * @param {boolean} overlapHeader Determines if PageContent should shift upward and overlap with the component that appears above it (probably PageBackground), such as with pages that contain Form components.
 */
export interface PageContentProps {
    overlapHeader?: boolean;
    children?: React.ReactNode;
    removePadding?: boolean;
}

export default class PageContent extends Component<PageContentProps> {
    render(): JSX.Element {
        return <div className={`${styles.pageContent} ${this.props.overlapHeader === true && styles.overlapHeader} ${this.props.removePadding === true && styles.removePadding}`}>{this.props.children}</div>;
    }
}
