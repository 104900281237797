import { faEdit, faFolder, faList, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import OverflowMenu, { OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import SortableTableHeader, { HeaderData, SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody, TableCell, TableCellDefaultText, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
import { ISSUES_EXCEPTIONS, TPRM } from 'Config/Paths';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { countOpenExceptions } from 'Models/Exceptions';
import { countOpenIssues } from 'Models/Issues';
import { VendorListingSortProperty, VendorResponseWithServices } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import styles from './VendorListingTableView.module.css';
import { VendorListingServiceListing } from '../VendorListingServiceListing/VendorListingServiceListing';

export interface VendorListingTableViewProps {
    vendors: VendorResponseWithServices[];
    applySorting: (sortProperty: string, sortDirection: SortDirection) => void;
    selectedDeleteVendor: (vendorId: string) => void;
    selectedModifyVendor: (vendorId: string) => void;
    selectedCreateServiceForVendor: (vendor: VendorResponseWithServices) => void;
    selectedViewAllServicesForVendor: (vendor: VendorResponseWithServices) => void;
    selectedViewFolders: (vendorId: string) => void;
    currentlySortedBy: string;
    currentSortDirection: SortDirection;
    users: UserResponse[];
}

export default class VendorListingTableView extends Component<VendorListingTableViewProps> {
    headerValues: HeaderData[] = [
        { dataKey: VendorListingSortProperty.NAME, label: 'NAME' },
        { dataKey: VendorListingSortProperty.WEBSITE, label: 'WEBSITE' },
        { dataKey: VendorListingSortProperty.CREATED_TIME, label: 'DATE CREATED' },
        { dataKey: 'services', label: 'SERVICES', disableSort: true },
        { dataKey: VendorListingSortProperty.VENDOR_MANAGER_USER_ID, label: 'VENDOR MANAGER' },
    ];

    tableRow = (vendor: VendorResponseWithServices, index: number): JSX.Element => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [
                {
                    text: 'Create a Service',
                    onClickAction: () => this.props.selectedCreateServiceForVendor(vendor),
                    icon: faPlus,
                },
                {
                    text: 'Update Vendor',
                    onClickAction: () => this.props.selectedModifyVendor(vendor.id),
                    icon: faEdit,
                },
                {
                    text: 'Manage Documents',
                    onClickAction: () => this.props.selectedViewFolders(vendor.id),
                    icon: faFolder,
                },
                {
                    text: 'Delete Vendor',
                    onClickAction: () => this.props.selectedDeleteVendor(vendor.id),
                    icon: faTrash,
                },
            ],
            accessibilityTitle: `${vendor.name} Menu`,
        };

        if (vendor.services.length > 0) {
            overflowMenuProps.overflowItems.splice(1, 0, {
                text: 'View Services',
                onClickAction: () => this.props.selectedViewAllServicesForVendor(vendor),
                icon: faList,
            });
        }

        const numberOfOpenIssues = countOpenIssues(vendor.issues);
        const numberOfOpenExceptions = countOpenExceptions(vendor.exceptions);

        return (
            <TableRow key={index}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{vendor.name}</Text>
                    <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `vendorId=${vendor.id}`, hash: '#issues' }}>{`Open Issues: ${numberOfOpenIssues}`}</Link>
                    <Link size="sm" to={{ pathname: `/${TPRM}/${ISSUES_EXCEPTIONS}`, search: `vendorId=${vendor.id}`, hash: '#exceptions' }}>{`Open Exceptions: ${numberOfOpenExceptions}`}</Link>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <div className={styles.website}>
                        <Text noStyles={true}>{vendor.website}</Text>
                    </div>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{iso8601ToUsDateLong(vendor.created_time)}</Text>
                </TableCellDefaultText>
                <TableCell className={styles.servicesListCell}>
                    <VendorListingServiceListing vendorId={vendor.id} services={vendor.services} />
                </TableCell>
                <TableCellDefaultText>
                    <Text noStyles={true}>{getUserNameFromSubject(vendor.vendor_manager_user_id, this.props.users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                </TableCellDefaultText>
                <TableOverflowCell>
                    <OverflowMenu {...overflowMenuProps} />
                </TableOverflowCell>
            </TableRow>
        );
    };

    render(): JSX.Element {
        const sortableTableProps: SortableTableHeaderProps = {
            headers: this.headerValues,
            applySorting: this.props.applySorting,
            currentSort: this.props.currentlySortedBy,
            currentSortDirection: this.props.currentSortDirection,
            tableIncludesOverflowMenu: true,
        };

        return (
            <div className={styles.riskTable}>
                <Table>
                    <SortableTableHeader {...sortableTableProps} />
                    <TableBody>{this.props.vendors.map(this.tableRow)}</TableBody>
                </Table>
            </div>
        );
    }
}
