import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import PageHeaderTabs from 'Components/Containers/PageHeaderTabs/PageHeaderTabs';
import { useUsers } from 'Components/Context/UsersContext';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { FrameworkTargetEffectivenessTabProps, TargetEffectivenessTab, TargetEffectivenessTabType } from 'Components/OperationalControls/TargetEffectivenessTab/TargetEffectivenessTab';
import Placeholder from 'Components/Placeholder/Placeholder';
import { Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { FRAMEWORKS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { LimitResponse } from 'Models/Limits';
import { DetailedControlFrameworkResponse } from 'Models/OperationalControls';

import { ControlFrameworkAssessmentTab, ControlFrameworkAssessmentTabProps } from './ControlFrameworkAssessmentTab/ControlFrameworkAssessmentTab';
import FrameworkLimits, { FrameworkLimitsProps } from './FrameworkLimit/FrameworkLimits';
import styles from './FrameworkSettings.module.css';

export interface PathParams {
    controlFramework: string;
}

export interface FrameworkSettingsProps {
    controlsApi: ControlsApi;
    alertsLimitsApi: AlertsLimitsApi;
}

const FrameworkSettings = (props: FrameworkSettingsProps) => {
    const [detailedControlFrameworkResponse, setDetailedControlFrameworkResponse] = useState<DetailedControlFrameworkResponse>();
    const [limitResponseList, setLimitResponseList] = useState<LimitResponse[]>();
    const { users } = useUsers();
    const { controlFramework } = useParams<keyof PathParams>() as PathParams;

    const getFrameworkConfiguration = useCallback(async (): Promise<void> => {
        try {
            const frameworkConfigurationResponse = await props.controlsApi.getControlFrameworkConfiguration(controlFramework);
            if (frameworkConfigurationResponse) {
                const frameworkConfiguration = frameworkConfigurationResponse.data;
                setDetailedControlFrameworkResponse(frameworkConfiguration);
            }
        } catch (err) {
            handleRequestError(err);
        }
    }, [controlFramework, props.controlsApi]);

    const getFrameworkLimits = useCallback(async (): Promise<void> => {
        try {
            const entity_id = controlFramework;
            const controlLimitsResponse = await props.alertsLimitsApi.getControlLimits(entity_id);
            const controlLimits = controlLimitsResponse.data;
            setLimitResponseList(controlLimits);
        } catch (error) {
            handleRequestError(error);
        }
    }, [controlFramework, props.alertsLimitsApi]);

    useEffect(() => {
        getFrameworkConfiguration();
        getFrameworkLimits();
    }, [getFrameworkConfiguration, getFrameworkLimits]);

    const handleRequestError = (error: Error): void => console.error('Error: ', error);

    if (users && detailedControlFrameworkResponse && limitResponseList) {
        const editFrameworkProps: ControlFrameworkAssessmentTabProps = {
            controlsApi: props.controlsApi,
            users: users,
            detailedControlFrameworkResponse: detailedControlFrameworkResponse,
            configurationUpdated: getFrameworkConfiguration,
        };

        const frameworkLimitsProps: FrameworkLimitsProps = {
            alertsLimitsApi: props.alertsLimitsApi,
            detailedControlFrameworkResponse: detailedControlFrameworkResponse,
            getFrameworkLimits: getFrameworkLimits,
            limitResponseList: limitResponseList,
        };

        const frameworkEffectivenessTargetTabProps: FrameworkTargetEffectivenessTabProps = {
            _type: TargetEffectivenessTabType.FRAMEWORK,
            controlsApi: props.controlsApi,
            detailedFrameworkResponse: detailedControlFrameworkResponse,
            targetEffectivenessUpdated: getFrameworkConfiguration,
            linkOnSuccess: getFrameworkGroupControlURL(detailedControlFrameworkResponse.control_framework),
        };

        return (
            <PageHeaderTabs
                title={`${detailedControlFrameworkResponse.control_framework} Settings`}
                defaultActiveTab="frameworkConfiguration"
                breadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}#${detailedControlFrameworkResponse.control_framework}`}>{detailedControlFrameworkResponse.control_framework}</BreadcrumbLink>
                        <BreadcrumbText>Settings</BreadcrumbText>
                    </Breadcrumb>
                }
            >
                <Tab eventKey="frameworkConfiguration" title="Assessment">
                    <PageBackground color="white">
                        <PageContent>
                            <div className={styles.pageSection}>
                                <ControlFrameworkAssessmentTab {...editFrameworkProps} />
                            </div>
                        </PageContent>
                    </PageBackground>
                </Tab>
                <Tab eventKey="frameworkLimits" title="Limits">
                    <PageBackground color="white">
                        <PageContent>
                            <FrameworkLimits {...frameworkLimitsProps} />
                        </PageContent>
                    </PageBackground>
                </Tab>
                <Tab eventKey="target" title="Target">
                    <PageBackground color="white">
                        <PageContent>
                            <TargetEffectivenessTab {...frameworkEffectivenessTargetTabProps} />
                        </PageContent>
                    </PageBackground>
                </Tab>
            </PageHeaderTabs>
        );
    }

    return <Placeholder />;
};

export default FrameworkSettings;
