import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';

import styles from './CircleIndicator.module.css';
import { IndicatorVariant } from './Indicator';

export interface CircleIndicatorProps {
    variant: IndicatorVariant;
}

export default class CircleIndicator extends Component<CircleIndicatorProps> {
    applyVariantColor = (): string => {
        switch (this.props.variant) {
            case IndicatorVariant.RED:
                return styles.red;
            case IndicatorVariant.ORANGE:
                return styles.orange;
            case IndicatorVariant.YELLOW:
                return styles.yellow;
            case IndicatorVariant.LIGHTGREEN:
                return styles.lightgreen;
            case IndicatorVariant.DARKGREEN:
                return styles.darkgreen;
            case IndicatorVariant.BLUE:
                return styles.blue;
            case IndicatorVariant.GRAY:
                return styles.gray;
            case IndicatorVariant.LIGHTBLUE:
                return styles.lightblue;
        }
    };
    render(): JSX.Element {
        return <FontAwesomeIcon icon={faCircle} className={this.applyVariantColor()} />;
    }
}
