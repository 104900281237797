/*
	Sophos.tsx -- Modal for configuring the Sophos integration.
*/

import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';

import styles from './ConfigureIntegration.module.css';
import { FormFieldText } from '../../../../Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from '../../../../Components/Modal/ModalHeader';
import { ValidationError } from '../../../../Models/ErrorTypes';
import { IntegrationName } from '../../../../Models/ExternalIntegrations';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

interface SophosState {
    isSubmitting: boolean;
    isDeleted: boolean;
    isDeleting: boolean;
    successMessage?: string;
    failureMessage?: string;
    sophosClientId?: string;
    clientSecret?: string;
}

export class Sophos extends Component<ConfigureIntegrationProps, SophosState> {
    constructor(props: ConfigureIntegrationProps) {
        super(props);

        this.state = {
            isDeleted: false,
            isDeleting: false,
            isSubmitting: false,
        };
    }

    handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        this.setState({ ...this.state, [event.currentTarget.name]: event.currentTarget.value });
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        this.setState({ isSubmitting: true, successMessage: undefined, failureMessage: undefined });

        try {
            this.validateForm(this.state.sophosClientId, this.state.clientSecret);
            await this.props.externalIntegrationsApi.setExternalIntegration(IntegrationName.SOPHOS, {
                sophos_client_id: this.state.sophosClientId!,
                client_secret: this.state.clientSecret!,
            });
            this.setState({ successMessage: 'Sophos integration configured.' });
            this.props.getExternalIntegrations();
        } catch (err) {
            this.handleRequestError(err);
        } finally {
            this.setState({ isSubmitting: false });
        }
    };

    validateForm = (sophosClientId?: string, clientSecret?: string): void => {
        if (!this.state.sophosClientId || this.state.sophosClientId.length === 0) {
            throw new ValidationError('Invalid client ID.');
        }
        if (!this.state.clientSecret || this.state.clientSecret.length === 0) {
            throw new ValidationError('Invalid client secret.');
        }
    };

    handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm('Are you sure you want to delete this integration? \r\n\r\n All limits created for this integration will also be deleted.');

        if (confirmAlert === false) {
            return;
        }

        this.setState({ isDeleting: true, successMessage: undefined, failureMessage: undefined });
        try {
            await this.props.externalIntegrationsApi.deleteExternalIntegration(IntegrationName.SOPHOS);
            this.setState({ successMessage: 'Sophos integration deleted.', isDeleted: true });
            this.props.getExternalIntegrations();
        } catch (err) {
            this.handleRequestError(err);
        } finally {
            this.setState({ isDeleting: false });
        }
    };

    handleRequestError = (err: Error): void => {
        this.setState({ failureMessage: err.message });
    };

    hideModal = (): void => {
        if (!this.state.isSubmitting) {
            this.props.hideModal();
        }
    };

    render(): JSX.Element {
        return (
            <Modal show onHide={this.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.failureMessage && <Alert variant="danger">{this.state.failureMessage}</Alert>}
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <ModalHeader text="Configure Sophos Integration" />
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="sophosClientId" formFieldLabel="Client ID" required={true} tooltip="Your Sophos client ID." invalidMessage="Please enter a valid Sophos client ID." handleChange={this.handleChange} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="clientSecret" formFieldLabel="Client Secret" formFieldType="password" required={true} tooltip="Your Sophos client secret." invalidMessage="Please enter a valid Sophos client secret." handleChange={this.handleChange} />
                        </div>
                        <div className={styles.buttonRowContainer}>
                            <Button variant="danger" disabled={this.state.isDeleted || this.state.isDeleting} onClick={this.handleDelete} loadingText={'Deleting...'} isLoading={this.state.isDeleting} fontAwesomeImage={faTrash}>
                                DELETE
                            </Button>
                            <div className={styles.buttonsRightContainer}>
                                <div className={styles.buttonPadding}>
                                    <Button variant="secondary" onClick={this.props.hideModal} disabled={this.state.isSubmitting || this.state.isDeleting} fontAwesomeImage={faTimes}>
                                        CLOSE
                                    </Button>
                                </div>
                                <Button variant="submit" disabled={this.state.isDeleted || this.state.isDeleting} isLoading={this.state.isSubmitting} loadingText="Saving...">
                                    SAVE
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}
