import { Skeleton } from '@mui/material';

import { Link } from 'Components/Buttons/Buttons';
import Text from 'Components/Text/Text';
import { AlertTooltipType } from 'Components/Tooltips/AlertsTooltip';
import { ALERTS } from 'Config/Paths';
import { iso8601ToUsTimestampLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { AlertResponse } from 'Models/Alerts';

import styles from './AlertDashlet.module.css';

export interface AlertDashletProps {
    alerts?: AlertResponse[];
    alertTooltipEntityName: string;
    alertTooltipType: AlertTooltipType;
    numberOfLimits: number;
}
/**
 * Used on the Framework, Group, and Control dashboards to display alerts.
 * Relies on a parent element for sizing/padding/margin/etc.
 */
export const AlertDashlet = (props: AlertDashletProps): JSX.Element => {
    return (
        <div className={styles.alerts}>
            <Text color="white" noStyles variant="Header2">
                {props.numberOfLimits === 1 ? `Alerts (1 active limit)` : `Alerts (${props.numberOfLimits} active limits)`}
            </Text>
            <hr />
            <div className={styles.alertContainer}>
                <Alerts alerts={props.alerts} />
            </div>
            <Link size="sm" to={`/${ALERTS}`}>
                VIEW ALL ALERTS
            </Link>
        </div>
    );
};

interface AlertsProps {
    alerts?: AlertResponse[];
}
const Alerts = (props: AlertsProps): JSX.Element => {
    if (props.alerts !== undefined && props.alerts.length === 0) {
        return (
            <ul>
                <li>
                    <Text color="white">No Alerts</Text>
                </li>
            </ul>
        );
    } else if (props.alerts === undefined) {
        return (
            <Skeleton>
                <ul>
                    <li>
                        <Text color="white">No Alerts</Text>
                    </li>
                </ul>
            </Skeleton>
        );
    } else {
        return (
            <ul>
                {props.alerts
                    .sort((a, b) => {
                        if (a.created_time > b.created_time) {
                            return -1;
                        } else if (a.created_time < b.created_time) {
                            return 1;
                        } else {
                            // If the dates are the same, sort by title (Control ID).
                            if (a.title > b.title) {
                                return 1;
                            } else if (a.title < b.title) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }
                    })
                    .slice(0, 5) // Limit the number of alerts shown to prevent the dashlet from growing infinitely.
                    .map((alert: AlertResponse, index: number) => {
                        return (
                            <li key={index}>
                                <Text color="white" noStyles>
                                    {alert.title}
                                </Text>
                                <Text color="darkGray" noStyles>
                                    {iso8601ToUsTimestampLong(alert.created_time)}
                                </Text>
                            </li>
                        );
                    })}
            </ul>
        );
    }
};
