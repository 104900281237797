import { FunctionComponent } from 'react';

import styles from './PageCell.module.css';

/**
 * The Page Cell component is meant for grouping blocks of content with rounded corners. It's often used for forms within a page.
 */

export interface PageCellProps {
    variant?: 'white' | 'transparentBlue';
    children?: React.ReactNode;
}

const PageCell: FunctionComponent<PageCellProps> = (props): JSX.Element => {
    return <div className={`${styles.pageCell} ${props.variant ? styles[props.variant] : styles.white}`}>{props.children}</div>;
};

export default PageCell;
