import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { BarAndLineChart } from 'Components/BaseCharts/BarAndLineChart';
import { CSSColors } from 'Components/Colors';
import PageCell from 'Components/Containers/PageCell/PageCell';
import { ChangeEventType, FormFieldDateOptionSelect } from 'Components/FormField/FormFieldDateOptionSelect/FormFieldDateOptionSelect';
import { FormFieldSelectDark } from 'Components/FormField/FormFieldSelectDark/FormFieldSelectDark';
import Text from 'Components/Text/Text';
import { DashboardType, DateOptionSelectOptions, UpcomingDateRangeSelectOptions, isValidDateOption } from 'Models/Dashboards';
import { ExceptionResponse, ExceptionRiskScore, ExceptionRiskScoreFilter, ExceptionRiskScoreFilterOptions, ExceptionStatus, isValidExceptionRiskScoreFilter } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { VendorResponse } from 'Models/TPRM';
import CustomDateRangeModal, { CustomDateRange, CustomDateRangeUpdateCallback } from 'Pages/Dashboards/Components/CustomDateRangeModal/CustomDateRangeModal';
import { getMonths } from 'Pages/Dashboards/TPRMDashboard/TPRMDashboard.helpers';
import { getDatesFromPreset } from 'Pages/Dashboards/TPRMDashboard/TPRMDashboard.hooks';

import styles from './DashboardsTrackingExceptions.module.css';
import { OpenExceptionDisplayModal } from './TrackingExceptionsDisplayModal/TrackingExceptionsDisplayModal';
import { getOpenExceptionsPerMonth } from '../../OperationalControlsDashboard/OperationalControlsDashboard.helpers';

export interface DashboardsTrackingExceptionsProps {
    exceptionsApi: ExceptionsApi;
    dashboardType: DashboardType;
    vendors?: VendorResponse[];
    vendorError?: string;
}

enum Modals {
    NONE,
    DISPLAY_OPEN_EXCEPTIONS,
    CUSTOM_DATE,
}

/**
 * Renders a bar chart that displays, over a given time period, and grouped by month, the number of exceptions that are coming due.
 * The first bar shows overdue exceptions.
 */
export const DashboardsTrackingExceptions = (props: DashboardsTrackingExceptionsProps): JSX.Element => {
    const [dashboardExceptions, setDashboardExceptions] = useState<ExceptionResponse[]>();
    const [exceptionsByMonth, setExceptionsByMonth] = useState<number[]>();
    const [exceptionsError, setExceptionsError] = useState<string>();
    const [monthLabel, setMonthLabel] = useState<string[]>();
    const [clickedExceptions, setClickedExceptions] = useState<ExceptionResponse[]>();
    const [selectDateOption, setSelectDateOption] = useState<DateOptionSelectOptions>(DateOptionSelectOptions.NEXT_QUARTER);
    const [selectedDates, setSelectedDates] = useState<{ start: Date; end: Date }>(getDatesFromPreset(DateOptionSelectOptions.NEXT_QUARTER));
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [clickedDate, setClickedDate] = useState<string>();
    const [exceptionFilterType, setExceptionFilterType] = useState<ExceptionRiskScoreFilter>(ExceptionRiskScoreFilter.ALL);

    const lineColor = CSSColors.LIGHT_BLUE;

    useEffect(() => {
        const getExceptions = async () => {
            try {
                const exceptionsResponse = await props.exceptionsApi.getAllExceptions();

                if (props.dashboardType === DashboardType.OPERATIONAL_CONTROLS) {
                    const allOCExceptions = exceptionsResponse.data.filter((exception) => exception.type === IssuesExceptionsModule.CONTROLS && (exception.status === ExceptionStatus.APPROVED || exception.status === ExceptionStatus.DRAFT_CLOSE));
                    setDashboardExceptions(allOCExceptions);
                } else {
                    const allTPRMExceptions = exceptionsResponse.data.filter((exception) => exception.type === IssuesExceptionsModule.TPRM && (exception.status === ExceptionStatus.APPROVED || exception.status === ExceptionStatus.DRAFT_CLOSE));
                    setDashboardExceptions(allTPRMExceptions);
                }
            } catch (err) {
                setExceptionsError(err.message);
            }
        };
        getExceptions();
    }, [props.dashboardType, props.exceptionsApi]);

    useEffect(() => {
        const exceptionPriorityFilter = (exception: ExceptionResponse): boolean => {
            if (exceptionFilterType === ExceptionRiskScoreFilter.ALL) {
                return true;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.HIGH) {
                return exception.risk_score === ExceptionRiskScore.HIGH;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.MODERATE_HIGH) {
                return exception.risk_score === ExceptionRiskScore.MODERATE_HIGH;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.MODERATE) {
                return exception.risk_score === ExceptionRiskScore.MODERATE;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.LOW_MODERATE) {
                return exception.risk_score === ExceptionRiskScore.LOW_MODERATE;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.LOW) {
                return exception.risk_score === ExceptionRiskScore.LOW;
            } else {
                return true;
            }
        };
        if (dashboardExceptions) {
            const dashboardExceptionsByRiskScore = dashboardExceptions.filter(exceptionPriorityFilter);

            const months = getMonths(selectedDates.start, selectedDates.end);

            const totalExceptionsByMonth = months.map((month) => {
                return dashboardExceptionsByRiskScore.filter((exceptions) => moment(exceptions.expiration_date).format('MMM YY') === month).length;
            });

            const overDueExceptions = dashboardExceptionsByRiskScore.filter((exception) => moment(exception.expiration_date).isBefore(moment()));

            setExceptionsByMonth([overDueExceptions.length, ...totalExceptionsByMonth]);
            setMonthLabel(['Overdue', ...months]);
        }
    }, [dashboardExceptions, exceptionFilterType, selectedDates.end, selectedDates.start]);

    const handleChartClick = (item: string) => {
        const clickedItem = item.split(' | ');
        const currentFilteredExceptions = dashboardExceptions?.filter((exception) => {
            if (exceptionFilterType === ExceptionRiskScoreFilter.ALL) {
                return true;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.HIGH) {
                return exception.risk_score === ExceptionRiskScore.HIGH;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.MODERATE_HIGH) {
                return exception.risk_score === ExceptionRiskScore.MODERATE_HIGH;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.MODERATE) {
                return exception.risk_score === ExceptionRiskScore.MODERATE;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.LOW_MODERATE) {
                return exception.risk_score === ExceptionRiskScore.LOW_MODERATE;
            } else if (exceptionFilterType === ExceptionRiskScoreFilter.LOW) {
                return exception.risk_score === ExceptionRiskScore.LOW;
            } else {
                return true;
            }
        });
        if (currentFilteredExceptions) {
            setClickedExceptions(getOpenExceptionsPerMonth(currentFilteredExceptions, clickedItem[0]));

            if (clickedItem[0] === 'Overdue') {
                const overdueExceptions = currentFilteredExceptions.filter((exception) => moment(exception.expiration_date).isBefore(moment()));

                setClickedExceptions(overdueExceptions);
            } else {
                setClickedExceptions(getOpenExceptionsPerMonth(currentFilteredExceptions, clickedItem[0]));
            }

            setClickedDate(clickedItem[0]);
            setModalState(Modals.DISPLAY_OPEN_EXCEPTIONS);
        }
    };

    const handleDateFilterChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDateOption(value)) {
            if (value === DateOptionSelectOptions.CUSTOM) {
                setModalState(Modals.CUSTOM_DATE);
            } else {
                setSelectedDates(getDatesFromPreset(value));
                setSelectDateOption(value);
            }
        }
    };

    const handleSelectCustomDateRange: CustomDateRangeUpdateCallback = (customDateRange: CustomDateRange) => {
        setSelectedDates({ start: customDateRange.startDate, end: customDateRange.endDate });
        setSelectDateOption(DateOptionSelectOptions.CUSTOM);
    };

    const handleRiskScoreChange = (value: ChangeEventType, formFieldId: string) => {
        if (isValidExceptionRiskScoreFilter(value)) {
            setExceptionFilterType(value as ExceptionRiskScoreFilter);
        }
    };

    if (exceptionsError) {
        return (
            <>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <div className={styles.cellHeader}>
                            <Text variant="Header2" color="white">
                                Exception Tracking
                            </Text>
                        </div>
                        <Text color="white" variant="Header4">
                            {exceptionsError}
                        </Text>
                    </PageCell>
                </div>
            </>
        );
    }

    if (props.vendorError) {
        return (
            <>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <div className={styles.cellHeader}>
                            <Text variant="Header2" color="white">
                                Exception Tracking
                            </Text>
                        </div>
                        <Text color="white" variant="Header4">
                            {props.vendorError}
                        </Text>
                    </PageCell>
                </div>
            </>
        );
    }

    return (
        <>
            {modalState === Modals.CUSTOM_DATE && <CustomDateRangeModal startDate={selectedDates.start} endDate={selectedDates.end} datesRestriction="futureDatesOnly" hideModal={() => setModalState(Modals.NONE)} setCustomDateRange={handleSelectCustomDateRange} />}
            {modalState === Modals.DISPLAY_OPEN_EXCEPTIONS && clickedDate && clickedExceptions && <OpenExceptionDisplayModal selectedException={clickedExceptions} date={clickedDate} hideModal={() => setModalState(Modals.NONE)} dashboardType={props.dashboardType} vendors={props.vendors} />}
            <div className={styles.section2}>
                <PageCell variant="transparentBlue">
                    <div className={styles.cellHeader}>
                        <Text variant="Header2" color="white">
                            Exception Tracking
                        </Text>
                        <div className={styles.filters}>
                            <FormFieldSelectDark formFieldId="exceptionRiskScore" formFieldLabel="Risk Score..." options={ExceptionRiskScoreFilterOptions} invalidMessage="A risk score must be provided." selectedOption={exceptionFilterType} handleChange={handleRiskScoreChange} />
                            <FormFieldDateOptionSelect formFieldId="date" options={UpcomingDateRangeSelectOptions} selectedOption={selectDateOption} handleChange={handleDateFilterChange} />
                        </div>
                    </div>
                    {monthLabel && exceptionsByMonth ? <BarAndLineChart onChartClick={handleChartClick} xAxisLabels={monthLabel} bar={{ name: 'Total Due', color: lineColor, data: exceptionsByMonth }} yAxisInfo={[{ name: 'OPEN EXCEPTIONS BY MONTH', position: 'left', minInterval: 1 }]} /> : <Skeleton variant="rounded" width={'100%'} height={300} />}
                </PageCell>
            </div>
        </>
    );
};
