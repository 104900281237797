import { faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import Switch from 'Components/Buttons/Switch';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Text from 'Components/Text/Text';
import { ResetAndNotifyVendorRequest, Service, VendorContact } from 'Models/TPRM';

import styles from './ConfirmResetAndNotifyVendorModal.module.css';

export interface ConfirmResetAndNotifyVendorModalProps {
    tprmApi: TPRMApi;
    service: Service;
    hideModal: () => void;
    questionnaireHasQuestions: boolean;
}

/**
 * Present a modal to confirm resetting the Due Diligence Questionnaire and which Vendor Service contacts should have their credentials reset and be sent notification emails.
 */
export const ConfirmResetAndNotifyVendorModal = (props: ConfirmResetAndNotifyVendorModalProps): JSX.Element => {
    const [resetDdq, setResetDdq] = useState<boolean>(true);
    // Keep track of which Vendor Service contacts should receive the DDQ email. By default, all Vendor Service contacts with a valid email address are selected.
    const [selectedVendorContacts, setSelectedVendorContacts] = useState<VendorContact[]>(props.service.vendor_contacts.filter((vendor_contact) => vendor_contact.email_address !== undefined));

    const sendNotification = async (): Promise<string> => {
        const resetAndNotifyVendorRequest: ResetAndNotifyVendorRequest = { reset_ddq: resetDdq, vendor_contacts: selectedVendorContacts };
        await props.tprmApi.resetAndNotifyVendor(props.service.vendor_id, props.service.id, resetAndNotifyVendorRequest);
        return 'Vendor service contact(s) notified.';
    };

    if (props.questionnaireHasQuestions) {
        return (
            <ConfirmationModal operationType="nonDestructive" headerText="Notify Vendor Service Contact(s)" areYouSureText="Are you sure you want to notify the vendor service contact(s)?" buttonText="NOTIFY" buttonLoadingText="Notifying..." buttonIcon={faEnvelope} performOperation={sendNotification} hideModal={props.hideModal}>
                <Text>{`This will send an email inviting the vendor service contact(s) to register and complete the vendor questionnaire for "${props.service.vendor_name} - ${props.service.name}".`}</Text>
                <Text>If the vendor service contact(s) already have access to the vendor questionnaire, this will delete their existing credentials and allow them to re-register.</Text>
                <>
                    {props.service.vendor_contacts
                        .filter((vendorContact) => vendorContact.email_address !== undefined)
                        .map((vendorContact, index) => (
                            <div key={index} className={styles.contactInfo}>
                                <Checkbox
                                    checked={selectedVendorContacts.includes(vendorContact)}
                                    color="default"
                                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                        const isChecked = event.currentTarget.checked;
                                        setSelectedVendorContacts((prevSelectedVendorContacts) => {
                                            if (isChecked) {
                                                return [...prevSelectedVendorContacts, vendorContact];
                                            } else {
                                                return prevSelectedVendorContacts.filter((contact) => contact !== vendorContact);
                                            }
                                        });
                                    }}
                                />
                                <Text noStyles={true}>{vendorContact.email_address}</Text>
                            </div>
                        ))}
                </>

                <Switch checked={resetDdq} name="Reset Questionnaire" onChange={(event: React.FormEvent<HTMLInputElement>): void => setResetDdq(!resetDdq)} />
                <Text noStyles={true}>Clear all progress on the vendor questionnaire and control assessment (recommended).</Text>
                <div className={resetDdq ? styles.hideWarning : undefined}>
                    <Text noStyles variant="Text3" color="red">
                        You selected to keep existing progress on the vendor questionnaire and control assessment. Due to the risk of social engineering, High Peaks Solutions strongly recommends confirming the identities of vendor service contacts using an out-of-band channel before resetting their credentials.
                    </Text>
                </div>
            </ConfirmationModal>
        );
    } else {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className={'modalFromBody'}>
                    <ModalHeader text="Unable to Send Notification" />
                    <Text>The vendor questionnaire does not have any questions configured for the selected risk rating.</Text>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="primary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
};
