import { Component, Fragment } from 'react';

import AlertsTable from 'Components/Alerts/AlertsTable/AlertsTable';
import Text from 'Components/Text/Text';
import { AlertResponse, _NUMBER_OF_DAYS_OF_ALERTS } from 'Models/Alerts';

import styles from './AlertsSummaryTab.module.css';

export interface AlertsSummaryTabProps {
    alertResponseList: AlertResponse[];
}

export default class AlertsSummaryTab extends Component<AlertsSummaryTabProps> {
    render(): JSX.Element {
        return (
            <Fragment>
                {this.props.alertResponseList.length === 0 && <Text>{`No alerts in the past ${_NUMBER_OF_DAYS_OF_ALERTS} days.`}</Text>}
                {this.props.alertResponseList.length > 0 && (
                    <div className={styles.container}>
                        <Text variant="Header2">{`Alerts - Past ${_NUMBER_OF_DAYS_OF_ALERTS} Days`}</Text>
                        <AlertsTable alertsResponseList={this.props.alertResponseList} />
                    </div>
                )}
            </Fragment>
        );
    }
}
