import { Component, Fragment } from 'react';

import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { Link } from 'Components/Buttons/Buttons';
import Text from 'Components/Text/Text';
import { CONTROLS, FRAMEWORKS, GROUPS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { AllCustomControlsControlFrameworkResponse, AllCustomControlsControlGroupResponse, AllCustomControlsControlResponse } from 'Models/OperationalControls';

import styles from './CustomControlReport.module.css';
import CustomControlReportRow from './CustomControlReportRow/CustomControlReportRow';

export interface CustomControlReportProps {
    AllCustomControlsControlFrameworkResponse: AllCustomControlsControlFrameworkResponse[];
}
export default class CustomControlReport extends Component<CustomControlReportProps> {
    render(): JSX.Element {
        return (
            <div className={styles.containerPadding}>
                {this.props.AllCustomControlsControlFrameworkResponse.map((allCustomControlsResponse: AllCustomControlsControlFrameworkResponse, index) => (
                    <Accordion key={index}>
                        <CustomControlReportRow eventKey={allCustomControlsResponse.control_framework} level={1} aria-label={allCustomControlsResponse.control_framework}>
                            <Text variant="Text3" noStyles>
                                {allCustomControlsResponse.control_framework}
                            </Text>
                        </CustomControlReportRow>
                        <AccordionCollapse eventKey={allCustomControlsResponse.control_framework}>
                            <Fragment>
                                {allCustomControlsResponse.control_groups.map((controlGroups: AllCustomControlsControlGroupResponse, index) => (
                                    <Accordion key={index}>
                                        <CustomControlReportRow eventKey={controlGroups.controls[0] ? controlGroups.control_group_name : ''} level={2} aria-label={controlGroups.control_group_name}>
                                            <Link size="sm" to={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${allCustomControlsResponse.control_framework}/${GROUPS}/${controlGroups.control_group_id}`}>
                                                {controlGroups.control_group_name}
                                            </Link>
                                        </CustomControlReportRow>
                                        <AccordionCollapse eventKey={controlGroups.control_group_name}>
                                            <Fragment>
                                                {controlGroups.controls.map((controls: AllCustomControlsControlResponse, index) => (
                                                    <CustomControlReportRow key={index} level={3} aria-label={controls.control_name}>
                                                        <Link size="sm" to={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${controls.control_framework}/${GROUPS}/${controls.control_group_id}/${CONTROLS}/${controls.control_id}`}>
                                                            {controls.control_name}
                                                        </Link>
                                                    </CustomControlReportRow>
                                                ))}
                                            </Fragment>
                                        </AccordionCollapse>
                                    </Accordion>
                                ))}
                            </Fragment>
                        </AccordionCollapse>
                    </Accordion>
                ))}
            </div>
        );
    }
}
