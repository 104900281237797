import { DDQApi } from 'Api/TPRM/DDQApi';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import Text from 'Components/Text/Text';
import { UploadedFile } from 'Models/Files';

import { DDQStateAction, DDQStateActionControlAssessmentDocumentDeleted, DDQStateActionType } from '../DueDiligenceQuestionnaire';

export interface DeleteControlAssessmentDocumentModalProps {
    ddqApi: DDQApi;
    vendorId: string;
    serviceId: string;
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
    document: UploadedFile;
    hideModal: () => void;
    dispatchDdqStateChange: (action: DDQStateAction) => void;
}

const DeleteControlAssessmentDocumentModal = (props: DeleteControlAssessmentDocumentModalProps): JSX.Element => {
    const deleteDocument = async (): Promise<string> => {
        await props.ddqApi.deleteControlAssessmentDocument(props.vendorId, props.serviceId, props.controlFramework, props.controlGroupId, props.controlId, props.document.file_id);

        const action: DDQStateActionControlAssessmentDocumentDeleted = {
            type: DDQStateActionType.ControlAssessmentDocumentDeleted,
            payload: {
                framework: props.controlFramework,
                groupId: props.controlGroupId,
                controlId: props.controlId,
                documentation: props.document,
            },
        };
        props.dispatchDdqStateChange(action);

        return 'Document deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Document" areYouSureText="Are you sure you want to delete the following document?" performOperation={deleteDocument} hideModal={props.hideModal}>
            <Text>{props.document.filename}</Text>
        </ConfirmationModal>
    );
};

export default DeleteControlAssessmentDocumentModal;
