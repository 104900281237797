import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { uniqBy } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { ComplianceRequirementsApi } from 'Api/ComplianceRequirements/ComplianceRequirementsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Link } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { ExceptionsListing } from 'Components/Exceptions/ExceptionsListing/ExceptionsListing';
import ViewGovernanceLibraryDefinitionModal, { ViewGovernanceLibraryDefinitionModalProps } from 'Components/Governance/ViewGovernanceLibraryDefinitionModal/ViewGovernanceLibraryDefinitionModal';
import { IssuesListing } from 'Components/Issues/IssuesListing/IssuesListing';
import AssociatedControlsModal, { AssociatedControlsModalProps } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import Text from 'Components/Text/Text';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { ASSESSMENTS, CONFIGURATION, EVIDENCE } from 'Config/Paths';
import { AuthHandler } from 'Helpers/Auth/AuthHandler';
import { makeComplianceRequirementHumanReadable } from 'Helpers/ComplianceRequirementFormatter/ComplianceRequirementFormatter';
import { getFrameworkGroupControlParts, getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { Navigator } from 'Helpers/Navigator';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { useDisplayableTagsLookup } from 'Hooks/Tags';
import { AlertRequest, AlertResponse } from 'Models/Alerts';
import { ComplianceRequirementForControlResponse } from 'Models/ComplianceRequirements';
import { GetControlEvidenceIntegrationResponse, GetControlEvidenceResponse, ManualEvidence } from 'Models/ControlEvidence';
import { ControlExceptionResponse } from 'Models/Exceptions';
import { GovernanceVersion, TextBasedGovernanceVersion } from 'Models/Governance';
import { ControlIssueResponse, IssuesExceptionsModule } from 'Models/Issues';
import { LimitResponse } from 'Models/Limits';
import { AssessmentDetailsResponse, AssessmentHistoryResponse, OperationalControl } from 'Models/OperationalControls';
import { RiskResponse } from 'Models/RiskRegister';
import { DisplayableTag } from 'Models/Tags';

import { AssessmentDetails, AssessmentDetailsProps } from './AssessmentDetails/AssessmentDetails';
import styles from './ControlDetails.module.css';
import { ControlDetailsHeader } from './ControlDetailsHeader/ControlDetailsHeader';
import { ControlEvidenceListing, ControlEvidenceListingProps } from './ControlEvidenceListing/ControlEvidenceListing';
import { ControlRequirementListing } from './ControlRequirementListing/ControlRequirementListing';
import Governance, { GovernanceProps } from './Governance/Governance';
import { Performance, PerformanceProps } from './Performance/Performance';
import { Risks, RisksProps } from './Risks/Risks';

export interface UrlParams {
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
}

// TODO: remove the -Modal suffix from these enum values. It's redundant.
export enum Modals {
    GovernanceCommentModal,
    AssociatedControlsModal,
    RequirementAssociatedControlModal, // TODO: combine this with the above AssociatedControlsModal and evaluate code related to these enums. There's no reason to have separate enums, state, functions, etc. for each case of showing controls in a modal. The only exception is the secondary modal text shown for Compliance Requirements.
    None,
}

const tabKeys = ['requirements', 'evidence', 'issues', 'assessment', 'governance', 'risks'];

/**
 * Represents the state of a tab that requires additional data (beyond the DetailedControlResponse) to load.
 * Tabs handle loading and error scenarios individually, so that the user can immediately use a tab whose content is loaded, even if loading data for other tabs has failed or is still in progress.
 *
 * `T` is the data required to render the tab whose state is being represented.
 */
type TabState<T> = { state: 'loading' } | { state: 'error' } | { state: 'success'; data: T };

export interface ControlDetailsProps {
    controlsApi: ControlsApi;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    complianceRequirementsApi: ComplianceRequirementsApi;
    riskRegisterApi: RiskRegisterApi;
    governanceApi: GovernanceApi;
    alertsLimitsApi: AlertsLimitsApi;
    tagsApi: TagsApi;
    authHandler: AuthHandler;
    navigator: Navigator;
}

export const ControlDetails = (props: ControlDetailsProps): JSX.Element => {
    const location = useLocation();
    const params = useParams<keyof UrlParams>() as UrlParams;
    const { users } = useUsers();
    const navigate = useNavigate();

    const [detailedControlResponse, setDetailedControlResponse] = useState<OperationalControl>();
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);
    const [error, setError] = useState<string>();
    const [textBasedGovernanceBeingViewed, setTextBasedGovernanceBeingViewed] = useState<TextBasedGovernanceVersion>();
    const [associatedControls, setAssociatedControls] = useState<OperationalControl[]>();
    const [alertsResponse, setAlertsResponse] = useState<AlertResponse[]>();
    const [limitResponse, setLimitResponse] = useState<LimitResponse[]>();

    const [assessmentHistoryList, setAssessmentHistoryList] = useState<AssessmentHistoryResponse[]>();
    const [assessmentDetailsResponse, setAssessmentDetailsResponse] = useState<AssessmentDetailsResponse>();

    const [selectedComplianceRequirement, setSelectedComplianceRequirement] = useState<ComplianceRequirementForControlResponse>();

    /**
     * This error is set if either or both of the calls to retrieve assessment details and assessment history fail.
     * If this error is present, the Assessment Details tab will include the error message--and if only the assessment history call has failed, it will still allow the user to advance the assessment workflow (if they have access to do so).
     */
    const [assessmentDetailsError, setAssessmentDetailsError] = useState<string>();

    const [complianceTabState, setComplianceTabState] = useState<TabState<ComplianceRequirementForControlResponse[]>>({ state: 'loading' });
    const [evidenceTabState, setEvidenceTabState] = useState<TabState<{ automated: GetControlEvidenceIntegrationResponse[]; manual: ManualEvidence[] }>>({ state: 'loading' });
    const [issuesTabState, setIssuesTabState] = useState<TabState<ControlIssueResponse[]>>({ state: 'loading' });
    const [exceptionsTabState, setExceptionsTabState] = useState<TabState<ControlExceptionResponse[]>>({ state: 'loading' });
    const [governanceTabState, setGovernanceTabState] = useState<TabState<GovernanceVersion[]>>({ state: 'loading' });
    const [risksTabStateRisks, setRisksTabStateRisks] = useState<TabState<RiskResponse[]>>({ state: 'loading' });
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);

    // Combine the two independent network calls for the Risks tab into a single TabState.
    const risksTabState: TabState<{ risks: RiskResponse[]; getDisplayableTags: (tagIds: string[]) => DisplayableTag[] }> = (() => {
        if (risksTabStateRisks.state === 'error' || getDisplayableTagsState.type === 'failure') {
            return { state: 'error' };
        } else if (risksTabStateRisks.state === 'loading' || getDisplayableTagsState.type === 'loading') {
            return { state: 'loading' };
        } else {
            return {
                state: 'success',
                data: {
                    risks: risksTabStateRisks.data,
                    getDisplayableTags: getDisplayableTagsState.data,
                },
            };
        }
    })();

    // Combine the two independent network calls for the Issues and Exceptions tab into a single TabState.
    const issuesAndExceptionsTabState: TabState<{ issues: ControlIssueResponse[]; exceptions: ControlExceptionResponse[] }> = (() => {
        if (issuesTabState.state === 'error' || exceptionsTabState.state === 'error') {
            return { state: 'error' };
        } else if (issuesTabState.state === 'loading' || exceptionsTabState.state === 'loading') {
            return { state: 'loading' };
        } else {
            return {
                state: 'success',
                data: {
                    issues: issuesTabState.data,
                    exceptions: exceptionsTabState.data,
                },
            };
        }
    })();

    const [selectedTab, setSelectedTab] = useState<string>(() => {
        if (location.hash && location.hash.length > 0) {
            const strippedHash = location.hash.substring(1);
            if (tabKeys.includes(strippedHash)) {
                return strippedHash;
            }
        }

        return 'requirements';
    });

    const getControlDetails = useCallback(async (): Promise<void> => {
        try {
            const controlDetailsResponse = await props.controlsApi.getControlDetails(params.controlFramework, params.controlGroupId, params.controlId);
            const controlDetails = controlDetailsResponse.data;
            setDetailedControlResponse(controlDetails);
        } catch (error) {
            handleRequestError(error);
        }
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.controlsApi]);

    useEffect(() => {
        getControlDetails();
    }, [getControlDetails]);

    useEffect(() => {
        const getControlEvidence = async (): Promise<void> => {
            try {
                const controlEvidenceResponse = await props.controlsApi.getControlEvidence(params.controlFramework, params.controlGroupId, params.controlId);
                const controlEvidence: GetControlEvidenceResponse = controlEvidenceResponse.data;
                setEvidenceTabState({
                    state: 'success',
                    data: {
                        automated: controlEvidence.automated,
                        manual: controlEvidence.manual,
                    },
                });
            } catch (error) {
                setEvidenceTabState({ state: 'error' });
            }
        };
        getControlEvidence();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.controlsApi]);

    useEffect(() => {
        const getControlAlerts = async (): Promise<void> => {
            try {
                const entityId = getOperationalControlIdentifierString(params.controlFramework, params.controlGroupId, params.controlId);
                const alertsRequest: AlertRequest = { entity_id: entityId };
                const alertsResponse = await props.alertsLimitsApi.getAllAlerts(alertsRequest);
                setAlertsResponse(alertsResponse.data);
            } catch (err) {
                handleRequestError(err);
            }
        };
        getControlAlerts();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.alertsLimitsApi]);

    useEffect(() => {
        const getControlLimits = async (): Promise<void> => {
            try {
                const entityId = getOperationalControlIdentifierString(params.controlFramework, params.controlGroupId, params.controlId);
                const response = await props.alertsLimitsApi.getControlLimits(entityId);
                setLimitResponse(response.data);
            } catch (err) {
                handleRequestError(err);
            }
        };
        getControlLimits();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.alertsLimitsApi]);

    useEffect(() => {
        const getIssues = async (): Promise<void> => {
            try {
                const response = await props.issuesApi.getIssuesByControl(getOperationalControlIdentifierString(params.controlFramework, params.controlGroupId, params.controlId));
                setIssuesTabState({ state: 'success', data: response.data });
            } catch (error) {
                setIssuesTabState({ state: 'error' });
            }
        };
        getIssues();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.issuesApi]);

    useEffect(() => {
        const getExceptions = async (): Promise<void> => {
            try {
                const response = await props.exceptionsApi.getExceptionsByControl(getOperationalControlIdentifierString(params.controlFramework, params.controlGroupId, params.controlId));
                setExceptionsTabState({ state: 'success', data: response.data });
            } catch (error) {
                setExceptionsTabState({ state: 'error' });
            }
        };
        getExceptions();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.exceptionsApi]);

    useEffect(() => {
        const getRisks = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getAllRisksForControl(params.controlFramework, params.controlGroupId, params.controlId);
                setRisksTabStateRisks({ state: 'success', data: response.data });
            } catch (error) {
                setRisksTabStateRisks({ state: 'error' });
            }
        };
        getRisks();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.riskRegisterApi]);

    useEffect(() => {
        const getGovernanceVersions = async (): Promise<void> => {
            try {
                const entityId = getOperationalControlIdentifierString(params.controlFramework, params.controlGroupId, params.controlId);
                const getGovernanceItemsForControlResponse = await props.governanceApi.getGovernanceItemsForControl(entityId);
                const governanceItems = getGovernanceItemsForControlResponse.data;
                setGovernanceTabState({ state: 'success', data: governanceItems });
            } catch (error) {
                setGovernanceTabState({ state: 'error' });
            }
        };
        getGovernanceVersions();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.governanceApi]);

    useEffect(() => {
        const getComplianceRequirements = async (): Promise<void> => {
            try {
                const getComplianceRequirementsResponse = await props.complianceRequirementsApi.getComplianceRequirementsForControlDetails(params.controlFramework, params.controlGroupId, params.controlId);
                setComplianceTabState({ state: 'success', data: getComplianceRequirementsResponse.data });
            } catch (error) {
                setComplianceTabState({ state: 'error' });
            }
        };
        getComplianceRequirements();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.complianceRequirementsApi]);

    useEffect(() => {
        const getAssessmentDetails = async (): Promise<void> => {
            try {
                const assessmentDetailsResponse = await props.controlsApi.getAssessmentDetails(params.controlFramework, params.controlGroupId, params.controlId);
                setAssessmentDetailsResponse(assessmentDetailsResponse.data);
            } catch (error) {
                setAssessmentDetailsError(error.message);
            }
        };
        getAssessmentDetails();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.controlsApi]);

    useEffect(() => {
        const getAssessmentHistory = async (): Promise<void> => {
            try {
                const assessmentHistoryResponse = await props.controlsApi.getAssessmentHistory(params.controlFramework, params.controlGroupId, params.controlId);
                setAssessmentHistoryList(assessmentHistoryResponse.data);
            } catch (error) {
                setAssessmentDetailsError(error.message);
            }
        };
        getAssessmentHistory();
    }, [params.controlFramework, params.controlGroupId, params.controlId, props.controlsApi]);

    const handleRequestError = (error: Error): void => {
        setError(error.message);
    };

    const displayGovernanceTextModal = (governance: TextBasedGovernanceVersion) => {
        setTextBasedGovernanceBeingViewed(governance);
        setDisplayedModal(Modals.GovernanceCommentModal);
    };

    const displayAssociatedControlsModal = (associatedControls: OperationalControl[]): void => {
        setAssociatedControls(associatedControls);
        setDisplayedModal(Modals.AssociatedControlsModal);
    };

    const getSettingsLinkData = () => {
        return {
            to: `${getFrameworkGroupControlURL(detailedControlResponse!.identifier)}/${CONFIGURATION}`,
            state: { controlDetailsTab: selectedTab },
        };
    };

    const getAssessmentLinkData = () => {
        return {
            to: `${getFrameworkGroupControlURL(detailedControlResponse!.identifier)}/${ASSESSMENTS}`,
            state: { controlDetailsTab: selectedTab },
        };
    };

    const viewGovernanceLibraryDefinitionModalProps = (governance: TextBasedGovernanceVersion): ViewGovernanceLibraryDefinitionModalProps => {
        return {
            hideModal: () => setDisplayedModal(Modals.None),
            governance: governance,
        };
    };

    const associatedControlsModalProps = (associatedControls: OperationalControl[]): AssociatedControlsModalProps => {
        return {
            hideModal: () => setDisplayedModal(Modals.None),
            associatedControls: associatedControls,
        };
    };

    const associatedControlsForComplianceRequirementModalProps = (assignedControls: OperationalControl[][]): AssociatedControlsModalProps => {
        // Flatten the Compliance Requirement's assigned_control_sets attribute.
        const associatedControls: OperationalControl[] = [];
        if (selectedComplianceRequirement) {
            for (const control_list of assignedControls) {
                for (const control of control_list) {
                    associatedControls.push(control);
                }
            }
        }

        // This filters the list for duplicates by a unique value (identifier). A new array is returned.
        const filteredControls = uniqBy(associatedControls, (control) => {
            return control.identifier;
        });

        return {
            hideModal: () => {
                setDisplayedModal(Modals.None);
                setSelectedComplianceRequirement(undefined);
            },
            associatedControls: filteredControls,
            headerText: {
                text: 'Controls Mapped',
                secondaryText: makeComplianceRequirementHumanReadable(selectedComplianceRequirement!.regulation, selectedComplianceRequirement!.identifier), // This are non-optional here because when this is used below we check if it is undefined
            },
        };
    };

    const displayMappedControlsModal = (selectedComplianceRequirement: ComplianceRequirementForControlResponse): void => {
        setDisplayedModal(Modals.RequirementAssociatedControlModal);
        setSelectedComplianceRequirement(selectedComplianceRequirement);
    };

    const onSelectTab = (key?: string): void => {
        if (key) {
            navigate(`${location.pathname}#${key}`, { replace: true });
            setSelectedTab(key);
        }
    };

    if (error) {
        return <Text color="darkBlue">{error}</Text>;
    }

    if (limitResponse !== undefined && detailedControlResponse !== undefined && alertsResponse !== undefined) {
        const assessmentDetailsProps: AssessmentDetailsProps = {
            detailedControlResponse: detailedControlResponse,
            assessmentDetailsResponse: assessmentDetailsResponse,
            assessmentDetailsError: assessmentDetailsError,
            assessmentHistoryList: assessmentHistoryList,
            assessmentLinkData: getAssessmentLinkData(),
            authenticatedUserSubject: props.authHandler.getAuthenticatedUserSubject(),
            authenticatedUserRoles: props.authHandler.getUserRoles(),
        };

        const complianceTabContent = (() => {
            switch (complianceTabState.state) {
                case 'loading':
                    return <Placeholder />;
                case 'error':
                    return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
                case 'success':
                    return <ControlRequirementListing complianceRequirements={complianceTabState.data} displayMappedControlsModal={displayMappedControlsModal} />;
            }
        })();

        const evidenceTabContent = (() => {
            switch (evidenceTabState.state) {
                case 'loading':
                    return <Placeholder />;
                case 'error':
                    return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
                case 'success':
                    const performanceProps: PerformanceProps = {
                        automatedEvidence: evidenceTabState.data.automated,
                    };

                    const controlEvidenceListingProps: ControlEvidenceListingProps = {
                        documentApi: props.documentApi,
                        hideModal: () => setDisplayedModal(Modals.None),
                        manualEvidenceList: evidenceTabState.data.manual,
                        users: users,
                    };

                    return (
                        <>
                            {evidenceTabState.data.automated.length > 0 && (
                                <>
                                    <div className={styles.pageSectionHeader}>
                                        <Text variant="Header2">Metrics</Text>
                                    </div>
                                    <Performance {...performanceProps} />
                                </>
                            )}
                            <div className={styles.pageSectionHeader}>
                                <Text variant="Header2">Evidence</Text>
                                <Link variant="primaryButton" to={`${getFrameworkGroupControlURL(detailedControlResponse!.identifier)}/${EVIDENCE}`} fontAwesomeImage={faFileUpload}>
                                    SUBMIT EVIDENCE
                                </Link>
                            </div>
                            <ControlEvidenceListing {...controlEvidenceListingProps} />
                        </>
                    );
            }
        })();

        const issuesAndExceptionsTabContent = (() => {
            switch (issuesAndExceptionsTabState.state) {
                case 'loading':
                    return <Placeholder />;
                case 'error':
                    return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
                case 'success':
                    return (
                        <>
                            <IssuesListing users={users} issues={issuesAndExceptionsTabState.data.issues} type={IssuesExceptionsModule.CONTROLS} preselectedControlIdForCreate={getOperationalControlIdentifierString(params.controlFramework, params.controlGroupId, params.controlId)} displayMappedControlsModal={displayAssociatedControlsModal} />
                            <div className={styles.issuesExceptionsSeparator}>
                                <hr />
                            </div>
                            <ExceptionsListing users={users} exceptions={issuesAndExceptionsTabState.data.exceptions} type={IssuesExceptionsModule.CONTROLS} preselectedControlIdForCreate={getOperationalControlIdentifierString(params.controlFramework, params.controlGroupId, params.controlId)} displayMappedControlsModal={displayAssociatedControlsModal} />
                        </>
                    );
            }
        })();

        const risksTabContent = (() => {
            switch (risksTabState.state) {
                case 'loading':
                    return <Placeholder />;
                case 'error':
                    return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
                case 'success':
                    const risksProps: RisksProps = {
                        users: users,
                        risks: risksTabState.data.risks,
                        controlIdentifier: getOperationalControlIdentifierString(params.controlFramework, params.controlGroupId, params.controlId),
                        getDisplayableTags: risksTabState.data.getDisplayableTags,
                    };

                    return <Risks {...risksProps} />;
            }
        })();

        const governanceTabContent = (() => {
            switch (governanceTabState.state) {
                case 'loading':
                    return <Placeholder />;
                case 'error':
                    return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
                case 'success':
                    const governanceProps: GovernanceProps = {
                        governanceApi: props.governanceApi,
                        documentApi: props.documentApi,
                        users: users,
                        governanceItems: governanceTabState.data,
                        displayGovernanceTextModal: displayGovernanceTextModal,
                        displayGovernanceWithAssociatedControlsModal: (governance) => displayAssociatedControlsModal(governance.associated_controls),
                        controlIdentifier: getOperationalControlIdentifierString(params.controlFramework, params.controlGroupId, params.controlId),
                        openExternalUrl: (url) => props.navigator.openExternalUrl(url, true),
                    };

                    return <Governance {...governanceProps} />;
            }
        })();

        return (
            <div>
                {displayedModal === Modals.GovernanceCommentModal && textBasedGovernanceBeingViewed && <ViewGovernanceLibraryDefinitionModal {...viewGovernanceLibraryDefinitionModalProps(textBasedGovernanceBeingViewed)} />}
                {displayedModal === Modals.AssociatedControlsModal && associatedControls && <AssociatedControlsModal {...associatedControlsModalProps(associatedControls)} />}
                {displayedModal === Modals.RequirementAssociatedControlModal && selectedComplianceRequirement && selectedComplianceRequirement.assigned_control_sets && <AssociatedControlsModal {...associatedControlsForComplianceRequirementModalProps(selectedComplianceRequirement.assigned_control_sets)} />}
                <ControlDetailsHeader
                    detailedControlResponse={detailedControlResponse}
                    settingsLinkData={getSettingsLinkData()}
                    alertsResponse={alertsResponse}
                    limitResponse={limitResponse}
                    assessmentDetailsResponse={assessmentDetailsResponse}
                    breadcrumb={(() => {
                        const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(detailedControlResponse);
                        return (
                            <Breadcrumb textColor="white">
                                <BreadcrumbLink link={getFrameworkGroupControlURL(controlFramework)}>{controlFramework}</BreadcrumbLink>
                                <BreadcrumbLink link={getFrameworkGroupControlURL(`${controlFramework}#${controlGroupId}`)}>{`${detailedControlResponse.metadata.control_group_name}`}</BreadcrumbLink>
                                <BreadcrumbText>{detailedControlResponse.metadata.is_custom ? detailedControlResponse.metadata.control_name : controlId}</BreadcrumbText>
                            </Breadcrumb>
                        );
                    })()}
                />
                <PrimaryTabs defaultActiveTab={selectedTab} onSelect={onSelectTab}>
                    <Tab eventKey="requirements" title="Compliance">
                        <PageBackground color="white">
                            <PageContent>{complianceTabContent}</PageContent>
                        </PageBackground>
                    </Tab>
                    <Tab eventKey="evidence" title="Evidence and Metrics">
                        <PageBackground color="white">
                            <PageContent>{evidenceTabContent}</PageContent>
                        </PageBackground>
                    </Tab>
                    <Tab eventKey="issues" title="Issues and Exceptions">
                        <PageBackground color="white">
                            <PageContent>{issuesAndExceptionsTabContent}</PageContent>
                        </PageBackground>
                    </Tab>
                    <Tab eventKey="assessment" title="Control Assessments">
                        <PageBackground color="white">
                            <PageContent>
                                <AssessmentDetails {...assessmentDetailsProps} />
                            </PageContent>
                        </PageBackground>
                    </Tab>
                    <Tab eventKey="governance" title="Governance">
                        <PageBackground color="white">
                            <PageContent>{governanceTabContent}</PageContent>
                        </PageBackground>
                    </Tab>
                    <Tab eventKey="risks" title="Risks">
                        <PageBackground color="white">
                            <PageContent>{risksTabContent}</PageContent>
                        </PageBackground>
                    </Tab>
                </PrimaryTabs>
            </div>
        );
    } else return <Placeholder />;
};
