/*
	ControlEvidenceListing.tsx -- Presentation of control evidence listing
*/
import { useState } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { Link } from 'Components/Buttons/Buttons';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import SortableTableHeader, { SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { ManualEvidence, ManualEvidenceSortFilterOptions } from 'Models/ControlEvidence';
import { numberAsEffectivenessString } from 'Models/OperationalControls';
import { UserResponse } from 'Models/User';

import styles from './ControlEvidenceListing.module.css';

export interface ControlEvidenceListingProps {
    documentApi: DocumentApi;
    hideModal: () => void;
    manualEvidenceList: ManualEvidence[];
    users: UserResponse[];
}

export const ControlEvidenceListing = (props: ControlEvidenceListingProps) => {
    const [currentSort, setCurrentSort] = useState(ManualEvidenceSortFilterOptions.METRIC_TIMESTAMP);
    const [currentSortDirection, setCurrentSortDirection] = useState(SortDirection.DESC);

    const sortEvidence = (): ManualEvidence[] => {
        return props.manualEvidenceList.sort((manualEvidenceItemA, manualEvidenceItemB) => {
            let propertyA;
            let propertyB;

            if (currentSort === ManualEvidenceSortFilterOptions.FILENAME) {
                propertyA = manualEvidenceItemA.file?.filename;
                propertyB = manualEvidenceItemB.file?.filename;
            } else {
                propertyA = manualEvidenceItemA[currentSort];
                propertyB = manualEvidenceItemB[currentSort];
            }

            let sortCompareResult = 0;
            if (propertyA === undefined && propertyB !== undefined) {
                sortCompareResult = -1;
            } else if (propertyA !== undefined && propertyB === undefined) {
                sortCompareResult = 1;
            } else if (propertyA === undefined && propertyB === undefined) {
                sortCompareResult = 0;
            } else {
                switch (currentSort) {
                    case ManualEvidenceSortFilterOptions.EVIDENCE_EFFECTIVENESS:
                        sortCompareResult = propertyA! < propertyB! ? -1 : 1;
                        break;
                    default:
                        sortCompareResult = (propertyA as string).localeCompare(propertyB as string);
                        break;
                }
            }

            return currentSortDirection === SortDirection.ASC ? sortCompareResult : -sortCompareResult;
        });
    };

    const applySorting = (newSort: string, newSortDirection: SortDirection): void => {
        setCurrentSort(newSort as ManualEvidenceSortFilterOptions);
        setCurrentSortDirection(newSortDirection);
    };

    const tableRow = (manualEvidence: ManualEvidence, index: number): JSX.Element => {
        const fileCell = manualEvidence.file ? <UploadedFileAndState documentApi={props.documentApi} file={manualEvidence.file} onDownloadError={console.log} /> : <></>;

        return (
            <TableRow key={index}>
                <TableCellDefaultText>
                    <Text noStyles>{getUserNameFromSubject(manualEvidence.submitter_subject, props.users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{iso8601ToUsDateLong(manualEvidence.metric_timestamp)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <div className={styles.fileNameContainer}>{fileCell}</div>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    {manualEvidence.external_url && (
                        <div className={styles.externalUrlContainer}>
                            <Link openInNewTab size="sm" to={manualEvidence.external_url}>
                                Link
                            </Link>
                        </div>
                    )}
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{manualEvidence.evidence_comment}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{manualEvidence.evidence_effectiveness ? numberAsEffectivenessString(manualEvidence.evidence_effectiveness) : ''}</Text>
                </TableCellDefaultText>
            </TableRow>
        );
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: [
            { dataKey: ManualEvidenceSortFilterOptions.SUBMITTER_SUBJECT, label: 'SUBMITTER' },
            { dataKey: ManualEvidenceSortFilterOptions.METRIC_TIMESTAMP, label: 'DATE' },
            { dataKey: ManualEvidenceSortFilterOptions.FILENAME, label: 'FILE NAME' },
            { dataKey: ManualEvidenceSortFilterOptions.LINK, label: 'LINK' },
            { dataKey: ManualEvidenceSortFilterOptions.EVIDENCE_COMMENT, label: 'COMMENT' },
            { dataKey: ManualEvidenceSortFilterOptions.EVIDENCE_EFFECTIVENESS, label: 'EFFECTIVENESS' },
        ],
        applySorting: applySorting,
        currentSort: currentSort,
        currentSortDirection: currentSortDirection,
        tableIncludesOverflowMenu: false,
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortEvidence().map(tableRow)}</TableBody>
        </Table>
    );
};
