import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { IssuesSortFilterOptions } from 'Models/Issues';
import { VendorResponse } from 'Models/TPRM';
import { ColorTheme, Filter, OptionType } from 'Models/Types/GlobalType';

export interface VendorFilterProps {
    vendors: VendorResponse[];
    selectedVendorIds: string[];
    selectedFilterOptions: (selectedFilterOptions: Filter | Filter[]) => void;
    disabled?: boolean;

    /**
     * The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
     */
    colorTheme?: ColorTheme;
}

/**
 * Renders a drop-down that allows the user to filter by vendor.
 */
export const VendorFilter = (props: VendorFilterProps) => {
    const handleVendorSelected = (vendors: ChangeEventType): void => {
        const filters: Filter = {
            key: IssuesSortFilterOptions.VENDOR,
            value: vendors ? (vendors as string[]) : [],
        };
        props.selectedFilterOptions(filters);
    };

    const formatVendorOptions = (): OptionType[] => {
        const vendorOptions: OptionType[] = props.vendors.map((vendor) => ({
            value: vendor.id,
            label: vendor.name,
        }));
        return vendorOptions;
    };

    return <FormFieldSelect colorTheme={props.colorTheme} disabled={props.disabled} selectMultiple selectedOption={props.selectedVendorIds} formFieldId="vendorsFilter" formFieldLabel="Filter by Vendor" handleChange={handleVendorSelected} options={formatVendorOptions()} />;
};
