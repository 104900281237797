import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';

import IconButton from 'Components/Buttons/IconButton';
import ServiceMultiOptionFilter from 'Components/Filters/ServiceMultiOptionFilter/ServiceMultiOptionFilter';
import UserFilter from 'Components/Filters/UserFilter/UserFilter';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { ServiceListingSortFilterOptions } from 'Models/TPRM';
import { Filter, GroupOptionType, GroupedOptions, OptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './ServiceListingBodyToolbar.module.css';
import { ServiceListingView } from '../ServiceListing';

export interface ServiceListingBodyToolbarProps {
    switchListingView: (activeServiceListingView: ServiceListingView) => void;
    activeServiceListingView: ServiceListingView;
    filterOptions: GroupedOptions[];
    defaultSelectedOptions?: GroupOptionType[];
    vendorFilter: (selectedFilterOptions: Filter | Filter[]) => void;
    sortCardsBy: (sortProperty: string, sortDirection: SortDirection) => void;
    currentSort: string;
    users: UserResponse[];
}

const ServiceListingSortOptions: OptionType[] = [
    {
        label: 'Assessment Due Date',
        value: ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE,
    },
    {
        label: 'Date Created',
        value: ServiceListingSortFilterOptions.DATE_CREATED,
    },
    {
        label: 'Residual Risk',
        value: ServiceListingSortFilterOptions.RESIDUAL_RISK,
    },
    {
        label: 'Risk Rating',
        value: ServiceListingSortFilterOptions.RISKRATING,
    },
    {
        label: 'Service',
        value: ServiceListingSortFilterOptions.SERVICE,
    },
    {
        label: 'Service Manager',
        value: ServiceListingSortFilterOptions.VENDOR_SERVICE_MANAGER_USER_ID,
    },
    {
        label: 'Vendor',
        value: ServiceListingSortFilterOptions.VENDOR,
    },
];

export default class ServiceListingBodyToolbar extends Component<ServiceListingBodyToolbarProps> {
    handleSelectChange = (value: ChangeEventType): void => {
        const sortOption = value as ServiceListingSortFilterOptions;
        if (sortOption) {
            this.props.sortCardsBy(sortOption, SortDirection.ASC);
        }
    };
    render(): JSX.Element {
        return (
            <div className={styles.vendorListingFilter}>
                <div className={styles.filter}>
                    {this.props.activeServiceListingView === ServiceListingView.GRID && (
                        <div className={styles.sortSelect}>
                            <FormFieldSelect options={ServiceListingSortOptions} handleChange={this.handleSelectChange} formFieldId="isSortingCards" formFieldLabel="Sort" selectedOption={this.props.currentSort} />
                        </div>
                    )}
                    <div className={styles.filterSelectServiceManager}>
                        <UserFilter filterLabel="Filter by Service Manager" onUsersSelected={this.props.vendorFilter} users={this.props.users} userFilterId="vendorManagerId" formFieldId="serviceManagerFilter" />
                    </div>
                    <div className={styles.filterSelectServiceAttributes}>
                        <ServiceMultiOptionFilter filterOptions={this.props.filterOptions} defaultSelectedOptions={this.props.defaultSelectedOptions} vendorFilter={this.props.vendorFilter} />
                    </div>
                </div>
                <div className={styles.tableAndGridButtonPanel}>
                    <IconButton aria-label="Switch to Service Grid View" onClick={() => this.props.switchListingView(ServiceListingView.GRID)} fontAwesomeImage={faThLarge} disabled={this.props.activeServiceListingView === ServiceListingView.GRID} />
                    <IconButton aria-label="Switch to Service List View" onClick={() => this.props.switchListingView(ServiceListingView.LIST)} fontAwesomeImage={faBars} disabled={this.props.activeServiceListingView === ServiceListingView.LIST} />
                </div>
            </div>
        );
    }
}
