import { Link } from 'Components/Buttons/Buttons';
import { CollapsibleModal } from 'Components/Modal/CollapsibleModal/CollapsibleModal';
import { RISKS, RISK_REGISTER } from 'Config/Paths';
import { AssociatedRisk } from 'Models/Actions';

import styles from './MappedRisksModal.module.css';

export interface MappedRisksModalProps {
    hideModal: () => void;
    associatedRisks: AssociatedRisk[];
}

const MappedRisksModal = (props: MappedRisksModalProps): JSX.Element => {
    const risksList = props.associatedRisks.map((risk, index) => (
        <div key={index} className={styles.linkContainer}>
            <Link size="sm" to={`/${RISK_REGISTER}/${RISKS}/${risk.id}`}>{`${risk.title} - ${risk.category.title}`}</Link>
        </div>
    ));
    return <CollapsibleModal hideModal={props.hideModal} headerText={{ text: 'Risks Mapped' }} displayItems={risksList} />;
};

export default MappedRisksModal;
