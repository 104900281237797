import { Switch as MaterialSwitch } from '@mui/material';

export interface SwitchProps {
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    name?: string;
}

const switchStyle = {
    '& :hover': {
        backgroundColor: '#053c5910',
    },
    '& .Mui-checked': {
        '& .MuiSwitch-thumb': {
            color: '#053c59',
        },
        '& + .MuiSwitch-track': {
            backgroundColor: '#053c59',
        },
        '& :hover': {
            backgroundColor: '#053c59',
        },
    },
    '& .Mui-checked:hover': {
        backgroundColor: '#053c5910',
    },
} as const;

export default function Switch(props: SwitchProps): JSX.Element {
    return <MaterialSwitch checked={props.checked} onChange={props.onChange} name={props.name} sx={switchStyle} disableRipple />;
}
