import { useEffect, useState } from 'react';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { TprmVendorRiskScores } from 'Models/ExternalIntegrations';

export const useVendorMonitoringMetricValues = (vendorId: string, externalIntegrationsApi: ExternalIntegrationsApi): [TprmVendorRiskScores[] | undefined, Error | undefined] => {
    const [vendorRiskScores, setVendorRiskScores] = useState<TprmVendorRiskScores[] | undefined>(undefined);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        const getVendorMonitoringMetricValues = async () => {
            try {
                const getVendorMonitoringMetricValuesResponse = await externalIntegrationsApi.getVendorMonitoringMetricValues(vendorId);
                setVendorRiskScores(getVendorMonitoringMetricValuesResponse.data);
            } catch (error) {
                setError(error);
            }
        };

        getVendorMonitoringMetricValues();
    }, [externalIntegrationsApi, vendorId]);

    return [vendorRiskScores, error];
};
