import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ValidationError } from 'Models/ErrorTypes';
import { IntegrationName } from 'Models/ExternalIntegrations';

import styles from './ConfigureIntegration.module.css';
import { ConfigureIntegrationProps } from '../ExternalIntegrations';

const BitSight = (props: ConfigureIntegrationProps): JSX.Element => {
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();

    const [apiToken, setApiToken] = useState<string>();
    const [criticalThirdPartyFolder, setCriticalThirdPartyFolder] = useState<string>();
    const [peerFolder, setPeerFolder] = useState<string>();

    const handleDelete = async (): Promise<void> => {
        const confirmAlert = window.confirm('Are you sure you want to delete this integration? \r\n\r\n All limits created for this integration will also be deleted.');

        if (confirmAlert === false) {
            return;
        }

        setIsDeleting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        try {
            await props.externalIntegrationsApi.deleteExternalIntegration(IntegrationName.BITSIGHT);
            setSuccessMessage(`${IntegrationName.BITSIGHT} integration deleted.`);
            setIsDeleted(true);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleRequestError = (err: Error): void => {
        setFailureMessage(err.message);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        setIsSubmitting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            validateForm(apiToken);
            await props.externalIntegrationsApi.setExternalIntegration(IntegrationName.BITSIGHT, {
                api_token: apiToken!,
                critical_third_party_folder: criticalThirdPartyFolder,
                peer_folder: peerFolder,
            });
            setSuccessMessage(`${IntegrationName.BITSIGHT} integration configured.`);
            props.getExternalIntegrations();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const hideModal = (): void => {
        if (!isSubmitting) {
            props.hideModal();
        }
    };

    const validateForm = (apiToken?: string): void => {
        if (!apiToken || apiToken.length === 0) {
            throw new ValidationError('Invalid API token.');
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text={`Configure ${IntegrationName.BITSIGHT} Integration`} />
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="apiToken" formFieldLabel="API Token" formFieldType="password" handleChange={(event: React.FormEvent<HTMLInputElement>): void => setApiToken(event.currentTarget.value)} required={true} tooltip="The API token provided by BitSight." />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="criticalThirdPartyFolder" formFieldLabel="Critical Third-Party Folder" handleChange={(event: React.FormEvent<HTMLInputElement>): void => setCriticalThirdPartyFolder(event.currentTarget.value)} tooltip="The name or GUID of the 'folder' in BitSight where your critical third-parties are listed. Required if the 'Critical Third-Party Security Ratings' metric is enabled." />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isDeleted || isDeleting || isSubmitting} formFieldId="peerFolder" formFieldLabel="Peer Folder" handleChange={(event: React.FormEvent<HTMLInputElement>): void => setPeerFolder(event.currentTarget.value)} tooltip="The name or GUID of the 'folder' in BitSight where your peer organizations are listed. Required if the 'Peer Security Ratings' metric is enabled." />
                    </div>
                    <div className={styles.buttonRowContainer}>
                        <Button variant="danger" disabled={isDeleted || isDeleting || isSubmitting} onClick={handleDelete} loadingText={'Deleting...'} isLoading={isDeleting} fontAwesomeImage={faTrash}>
                            DELETE
                        </Button>
                        <div className={styles.buttonsRightContainer}>
                            <div className={styles.buttonPadding}>
                                <Button variant="secondary" onClick={props.hideModal} disabled={isSubmitting || isDeleting} fontAwesomeImage={faTimes}>
                                    CLOSE
                                </Button>
                            </div>
                            <Button variant="submit" disabled={isDeleted || isDeleting || isSubmitting} isLoading={isSubmitting} loadingText="Saving...">
                                SAVE
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default BitSight;
