/*
	FormFieldText.tsx - Component for drop-down menu within an HTML form.
*/
import { FormControl, FormGroup } from 'react-bootstrap';
import Select, { OnChangeValue, Props } from 'react-select';

import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { Chip } from 'Components/Text/Chip';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { ColorTheme, OptionType } from 'Models/Types/GlobalType';

import styles from '../FormField.module.css';

type OptionTypes = string | number | Array<string | number> | undefined | null;
export type ChangeEventType = string | number | Array<string | number> | undefined;

interface FormFieldSelectProps {
    formFieldId: string;
    formFieldLabel: string;
    isRequiredField?: boolean;
    tooltip?: string | JSX.Element;
    invalidMessage?: string;
    handleChange?: (value: ChangeEventType, formFieldId: string) => void;
    selectedOption?: OptionTypes;
    disabled?: boolean;
    options?: OptionType[];
    selectMultiple?: boolean;
    badge?: string;
    isClearable?: boolean;

    /**
     * The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
     */
    colorTheme?: ColorTheme;
}

const FormFieldSelect = (props: FormFieldSelectProps) => {
    const colorTheme = props.colorTheme ?? 'light';

    const convertSelectedOption = (selectedOption: OptionTypes): OptionType | OptionType[] | undefined | null => {
        if (selectedOption === undefined || selectedOption === null) {
            return selectedOption;
        }

        if (typeof selectedOption === 'string' || typeof selectedOption === 'number') {
            return props.options?.find((option) => option.value === selectedOption);
        }
        return props.options?.filter((option) => selectedOption?.includes(option.value));
    };

    const handleChange = (value: OnChangeValue<OptionType, true | false>): void => {
        if (props.handleChange) {
            if (Array.isArray(value)) {
                props.handleChange(
                    value.map((option) => option.value),
                    props.formFieldId
                );
            } else if (value && 'value' in value) {
                props.handleChange(value['value'], props.formFieldId);
            } else {
                props.handleChange(undefined, props.formFieldId);
            }
        }
    };

    const selectProps: Props<OptionType, true | false> = {
        options: props.options,
        onChange: handleChange,
        isMulti: props.selectMultiple,
        value: convertSelectedOption(props.selectedOption),
        isDisabled: props.disabled,
        inputId: props.formFieldId,
        isClearable: props.isClearable,
    };

    return (
        <FormGroup controlId={props.formFieldId} className={styles.formField}>
            <div className={styles.flexDirectionRow}>
                <div className={props.badge ? `${styles.flexDirectionRow} ${styles.alignTextBottom}` : `${styles.flexDirectionRow}`}>
                    {props.formFieldLabel && (
                        <FormLabel color={colorTheme === 'light' ? undefined : 'white'} required={props.isRequiredField}>
                            {props.formFieldLabel}
                        </FormLabel>
                    )}
                    {props.badge && (
                        <div className={styles.badge}>
                            <Chip>{props.badge}</Chip>
                        </div>
                    )}
                </div>
                {props.tooltip && <FormFieldTooltip text={props.tooltip} />}
            </div>
            <Select
                {...selectProps}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 9,
                    colors: {
                        ...theme.colors,
                        primary25: '#05263717',
                        text: 'var(--hps-blue)',
                        primary: colorTheme === 'light' ? 'var(--hps-blue)' : 'transparent',
                    },
                })}
                styles={{
                    ...styles,
                    control: (base) => ({
                        ...base,
                        borderColor: '#053c591a',
                        color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-white)',
                        backgroundColor: colorTheme === 'light' ? base.backgroundColor : 'var(--hps-blue)',
                    }),
                    multiValueRemove: (styles, { data }) => ({
                        ...styles,
                        color: colorTheme === 'light' ? data.color : 'var(--hps-blue)',
                        ':hover': {
                            color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-dark-blue)',
                        },
                    }),
                    singleValue: (styles) => ({
                        ...styles,
                        color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-white)',
                    }),
                    option: (styles, { isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? '#05263717' : '',
                        color: isSelected ? 'var(--hps-blue)' : '',
                    }),
                    input: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-white)',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? base.color : 'var(--hps-white)',
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: colorTheme === 'light' ? base.color : 'var(--hps-white)',
                    }),
                }}
            />
            <FormControl.Feedback type="invalid">{props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
        </FormGroup>
    );
};

export default FormFieldSelect;
