/*
	AWSExceptionsApi.ts -- AWS-specific interface to making Exceptions related network calls.
*/
import { EXCEPTIONS, HISTORY } from 'Config/Paths';
import { ControlExceptionResponse, CreateExceptionRequest, ExceptionHistoryResponse, ExceptionResponse, UpdateExceptionRequest, VendorExceptionResponse } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiDelete, restApiGet, restApiPut } from 'RestApiConnector';

import { ExceptionsApi } from './ExceptionsApi';

export class AWSExceptionsApi implements ExceptionsApi {
    getAllExceptions = (): Promise<ResponseModel<ExceptionResponse[]>> => {
        return restApiGet(`/${EXCEPTIONS}`, {});
    };
    getAllControlExceptions = (): Promise<ResponseModel<ControlExceptionResponse[]>> => {
        return restApiGet(`/${EXCEPTIONS}`, { exception_type: IssuesExceptionsModule.CONTROLS });
    };
    getAllVendorExceptions = (): Promise<ResponseModel<VendorExceptionResponse[]>> => {
        return restApiGet(`/${EXCEPTIONS}`, { exception_type: IssuesExceptionsModule.TPRM });
    };
    getExceptionsByControl = (controlIdentifier: string): Promise<ResponseModel<ControlExceptionResponse[]>> => {
        return restApiGet(`/${EXCEPTIONS}`, { control: controlIdentifier });
    };
    getException = (id: string): Promise<ResponseModel<ExceptionResponse>> => {
        return restApiGet(`/${EXCEPTIONS}/${id}`, {});
    };
    getExceptionHistory = (issueId: string): Promise<ResponseModel<ExceptionHistoryResponse[]>> => {
        return restApiGet(`/${EXCEPTIONS}/${HISTORY}/${issueId}`, {});
    };
    createException = (createExceptionRequest: CreateExceptionRequest): Promise<ResponseModel<ExceptionResponse>> => {
        return restApiPut(`/${EXCEPTIONS}`, createExceptionRequest);
    };
    updateException = (id: string, updateExceptionRequest: UpdateExceptionRequest): Promise<ResponseModel<ExceptionResponse>> => {
        return restApiPut(`/${EXCEPTIONS}/${id}`, updateExceptionRequest);
    };
    deleteException = (id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${EXCEPTIONS}/${id}`);
    };
}
