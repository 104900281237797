import { useContext } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { RBACContext, Role, userHasAuthorizedRole } from 'Components/Context/RBACContext';
import CircleIndicator from 'Components/Indicator/CircleIndicator';
import { TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { TagListing } from 'Components/Tags/TagListing/TagListing';
import Text from 'Components/Text/Text';
import { RISKS, RISK_REGISTER } from 'Config/Paths';
import { TreatmentPlanStatus } from 'Models/Actions';
import { getEffectivenessVariantColor, numberAsEffectiveness } from 'Models/OperationalControls';
import { RiskHistory, RiskResponse, formatAverageWeightedEffectiveness, formatTotalImpactOrProbability } from 'Models/RiskRegister';
import { DisplayableTag } from 'Models/Tags';
import { RiskDetailsLocationState, RiskTab } from 'Pages/RiskRegister/RiskDetails/RiskDetails';

import styles from './RiskTableRow.module.css';

export interface RiskTableRowProps {
    risk: RiskResponse | RiskHistory;
    tags: DisplayableTag[];
    controlWeight?: number;
    openLinksInNewTabs?: boolean;

    /**
     * If this prop is defined, the risk table will link to the history tab of the details pages for risks, passing along the timestamp so that the best-fit risk rating history will be auto-expanded.
     */
    assessmentTimestamp?: string;
}

export const RiskTableRow = (props: RiskTableRowProps) => {
    const { risk, controlWeight, tags } = props;
    const rbacContext = useContext(RBACContext);

    const userHasRiskDetailsAccess = userHasAuthorizedRole(rbacContext!.roles, [Role.ADMIN, Role.RISK_MANAGER, Role.RISK_USER]);

    const linkButtonTo = props.assessmentTimestamp ? `/${RISK_REGISTER}/${RISKS}/${risk.id}#${RiskTab.HISTORY}` : `/${RISK_REGISTER}/${RISKS}/${risk.id}`;
    const linkButtonState: RiskDetailsLocationState | undefined = props.assessmentTimestamp ? { assessmentTimestamp: props.assessmentTimestamp } : undefined;

    return (
        <TableRow key={risk.id}>
            <TableCellDefaultText>
                <div className={styles.riskNameContainer}>
                    {userHasRiskDetailsAccess ? (
                        <Link size="sm" to={linkButtonTo} state={linkButtonState} openInNewTab={props.openLinksInNewTabs}>
                            {risk.title}
                        </Link>
                    ) : (
                        <Text noStyles>{risk.title}</Text>
                    )}
                </div>
                <Text color="darkGray" variant="Text3" noStyles>
                    {risk.category.title}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{formatTotalImpactOrProbability(risk.total_inherent_risk)}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <div className={styles.controlEnvironmentContainer}>
                    <div className={styles.controlEnvironmentEffectivenessContainer}>
                        <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(risk.control_environment_effectiveness))} />
                        <Text noStyles>{formatAverageWeightedEffectiveness(risk.control_environment_effectiveness)}</Text>
                    </div>
                    {controlWeight !== undefined && <Text noStyles>{`${controlWeight}%`}</Text>}
                </div>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{formatTotalImpactOrProbability(risk.total_current_risk)}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{risk.treatment_plan_status !== TreatmentPlanStatus.MISSING ? risk.treatment_plan_status : undefined}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{formatTotalImpactOrProbability(risk.total_target_risk)}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{risk.strategy?.title}</Text>
            </TableCellDefaultText>
            <TableCell className={risk.tags.length > 0 ? styles.tags : ''}>
                <TagListing tags={tags} />
            </TableCell>
        </TableRow>
    );
};
