import { Component, ReactNode } from 'react';

import styles from './NavSubHeader.module.css';

export interface NavSubHeaderProps {
    children: ReactNode;
}
export class NavSubHeader extends Component<NavSubHeaderProps> {
    render(): JSX.Element {
        return <div className={styles.navHeader}>{this.props.children}</div>;
    }
}
