import { DocumentApi } from 'Api/Document/DocumentApi';
import { useUsers } from 'Components/Context/UsersContext';
import { FieldLabel } from 'Components/FieldLabel/FieldLabel';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import Text from 'Components/Text/Text';
import { UserDefinedReference } from 'Components/UserDefinedHyperlink/UserDefinedHyperlink';
import { EXCEPTIONS_COMPENSATING_CONTROLS, ISSUES_EXCEPTIONS_REFERENCE } from 'Config/Tooltips';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { ExceptionResponse, ExceptionStatus, titleCaseExceptionStatus, titleCaseImpact, titleCaseLikelihood, titleCaseRiskScore } from 'Models/Exceptions';

import styles from '../../../../../Styles/DetailsPage.module.css';

interface ExceptionDetailsSnapshotProps {
    exception: ExceptionResponse;
    documentApi: DocumentApi;
}

/**
 * Renders details for an exception (current or historical), minus the impacted controls or impacted vendor.
 * Used in the "main" area of the Exception Details page, and in historical modals on the same page.
 */
export const ExceptionDetailsSnapshot = (props: ExceptionDetailsSnapshotProps) => {
    const { users } = useUsers();

    return (
        <div className={styles.detailsContainer}>
            <div className={styles.detailsGridSection}>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Status" />
                        <Text variant="Text2">{titleCaseExceptionStatus(props.exception.status)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Owner" />
                        <Text>{getUserNameFromSubject(props.exception.owner_subject, users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Created" />
                        <Text>{`${iso8601ToUsDateShort(props.exception.created_timestamp)} by ${getUserNameFromSubject(props.exception.created_by, users, UserNameFormat.FIRST_SPACE_LAST)}`}</Text>
                    </div>
                </div>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Expiration Date" />
                        <Text variant="Text2">{iso8601ToUsDateShort(props.exception.expiration_date)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Reviewer" />
                        <Text>{getUserNameFromSubject(props.exception.reviewer_subject, users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Likelihood" />
                        <Text>{titleCaseLikelihood(props.exception.likelihood)}</Text>
                    </div>
                </div>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Risk Score" />
                        <Text variant="Text2">{titleCaseRiskScore(props.exception.risk_score)}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Delegates" />
                        <Text>{props.exception.delegates.length === 0 ? '-' : props.exception.delegates.map((delegate) => getUserNameFromSubject(delegate, users, UserNameFormat.FIRST_SPACE_LAST)).join(', ')}</Text>
                    </div>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Impact" />
                        <Text>{titleCaseImpact(props.exception.impact)}</Text>
                    </div>
                </div>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Reference" tooltipText={ISSUES_EXCEPTIONS_REFERENCE} />
                <UserDefinedReference reference={props.exception.reference} />
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Description" />
                <Text>{props.exception.description}</Text>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Risk Assessment" />
                <Text>{props.exception.risk_assessment}</Text>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Remediation Plan" />
                <Text>{props.exception.remediation_plan}</Text>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Compensating Controls" tooltipText={EXCEPTIONS_COMPENSATING_CONTROLS} />
                <Text>{props.exception.compensating_controls ?? '-'}</Text>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Files" />
                {props.exception.files.length === 0 ? <Text>-</Text> : [...props.exception.files].sort((a, b) => (a.filename > b.filename ? 1 : -1)).map((file) => <UploadedFileAndState key={file.file_id} file={file} documentApi={props.documentApi} />)}
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Closure Statement" />
                <Text>{props.exception.status === ExceptionStatus.DRAFT_CLOSE || props.exception.status === ExceptionStatus.CLOSED ? props.exception.closure_statement : '-'}</Text>
            </div>
        </div>
    );
};
