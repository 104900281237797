import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Link } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { ControlTable } from 'Components/ControlTable/ControlTable';
import { IssueHistoryTable, IssueHistoryTableProps } from 'Components/Issues/IssueHistoryTable/IssueHistoryTable';
import AssociatedControlsModal from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import Text from 'Components/Text/Text';
import { VendorSummary } from 'Components/VendorSummary/VendorSummary';
import { ISSUES, ISSUES_EXCEPTIONS, TPRM } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUpdateIssueUrl } from 'Helpers/URLBuilder/URLBuilder';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { ControlIssueHistoryResponse, IssueHistoryResponse, IssueResponse, IssueStatus, IssuesExceptionsModule, VendorIssueHistoryResponse } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { VendorResponseWithServices } from 'Models/TPRM';

import styles from '../../../../Styles/DetailsPage.module.css';
import { IssueDetailsSnapshot } from '../Components/IssueDetailsSnapshot/IssueDetailsSnapshot';
import { IssueHistoryModal } from '../Components/IssueHistoryModal/IssueHistoryModal';

enum IssueTab {
    CONTROLS = 'Controls',
    VENDOR = 'Vendor',
    HISTORY = 'History',
}

interface UrlParams {
    issueId: string;
}

interface IssueDetailsOperaionalControlsProps {
    type: IssuesExceptionsModule.CONTROLS;
    issuesApi: IssuesApi;
    documentApi: DocumentApi;
    tprmApi?: never; // Typed like this so that `tprmApi` can be used unconditionally as a `useEffect` dependency.
}

interface IssueDetailsTprmProps {
    type: IssuesExceptionsModule.TPRM;
    issuesApi: IssuesApi;
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
}

export type IssueDetailsProps = IssueDetailsOperaionalControlsProps | IssueDetailsTprmProps;

/**
 * Renders a page of detailed information for a specific issue.
 * This includes: current details; a tab containing a table of historical snapshots; and, depending on the issue type, a tab containing either a table of impacted controls or a summary of the impacted vendor.
 */
export const IssueDetails = (props: IssueDetailsProps) => {
    const { users } = useUsers();
    const { issueId } = useParams<keyof UrlParams>() as UrlParams;
    const [errorMessage, setErrorMessage] = useState<string>();
    const [issue, setIssue] = useState<IssueResponse>();
    const [vendorIdToVendorMap, setVendorIdToVendorMap] = useState<Map<string, VendorResponseWithServices>>(); // This isn't actually used when the issue belongs to the Operational Controls module.
    const [issueHistory, setIssueHistory] = useState<IssueHistoryResponse[]>();
    const [issueHistoryToShowInModal, setIssueHistoryToShowInModal] = useState<IssueHistoryResponse>();
    const [impactedControlsToShow, setImpactedControlsToShow] = useState<OperationalControl[]>();

    useEffect(() => {
        const getIssueDetails = async (issueId: string): Promise<void> => {
            try {
                const getIssueDetailsResponse = await props.issuesApi.getIssue(issueId);
                const issue = getIssueDetailsResponse.data;
                setIssue(issue);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getIssueDetails(issueId);
    }, [issueId, props.issuesApi]);

    useEffect(() => {
        const getIssueHistory = async (issueId: string): Promise<void> => {
            try {
                const response = await props.issuesApi.getIssueHistory(issueId);
                setIssueHistory(response.data);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getIssueHistory(issueId);
    }, [issueId, props.issuesApi]);

    useEffect(() => {
        const getVendors = async (): Promise<void> => {
            if (props.type !== IssuesExceptionsModule.TPRM) {
                setVendorIdToVendorMap(new Map());
                return;
            }

            try {
                // TODO: `getVendorDetails` should be used if/when `VendorResponseWithServices` and `VendorResponse` are consolidated.
                const response = await props.tprmApi.getVendors();
                setVendorIdToVendorMap(new Map(response.data.map((vendor) => [vendor.id, vendor])));
            } catch (error) {
                handleRequestError(error);
            }
        };

        getVendors();
    }, [props.tprmApi, props.type]);

    const handleRequestError = (error: Error): void => setErrorMessage(error.message);

    if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    }

    if (!(issue && issueHistory && vendorIdToVendorMap)) {
        return <Placeholder />;
    }

    const buttons: JSX.Element = (() => {
        switch (issue.status) {
            case IssueStatus.DRAFT_OPEN:
                return (
                    <Link variant="primaryButton" to={getUpdateIssueUrl(issue.id, issue.type, false)} fontAwesomeImage={faEdit}>
                        MANAGE ISSUE
                    </Link>
                );
            case IssueStatus.OPEN:
                return (
                    <>
                        <Link variant="primaryButton" to={getUpdateIssueUrl(issue.id, issue.type, false)} fontAwesomeImage={faEdit}>
                            MANAGE ISSUE
                        </Link>
                        <Link variant="primaryButton" to={getUpdateIssueUrl(issue.id, issue.type, true)} fontAwesomeImage={faCheck}>
                            CLOSE ISSUE
                        </Link>
                    </>
                );
            case IssueStatus.CLOSED:
                return <></>;
        }
    })();

    const getVendorName = (vendorId: string) => vendorIdToVendorMap.get(vendorId)!.name;

    const issueHistoryTableProps: IssueHistoryTableProps = (() => {
        switch (issue.type) {
            case IssuesExceptionsModule.CONTROLS:
                return { type: IssuesExceptionsModule.CONTROLS, histories: issueHistory as ControlIssueHistoryResponse[], displayMappedControlsModal: setImpactedControlsToShow, onSelectHistory: (history: IssueHistoryResponse) => setIssueHistoryToShowInModal(history) };
            case IssuesExceptionsModule.TPRM:
                return { type: IssuesExceptionsModule.TPRM, histories: issueHistory as VendorIssueHistoryResponse[], getVendorName: getVendorName, onSelectHistory: (history: IssueHistoryResponse) => setIssueHistoryToShowInModal(history) };
        }
    })();

    return (
        <>
            {issueHistoryToShowInModal && <IssueHistoryModal history={issueHistoryToShowInModal} documentApi={props.documentApi} hideModal={() => setIssueHistoryToShowInModal(undefined)} />}
            {impactedControlsToShow && <AssociatedControlsModal associatedControls={impactedControlsToShow} hideModal={() => setImpactedControlsToShow(undefined)} />}
            <PageBackground color="grey">
                <PageContent>
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${issue.type === IssuesExceptionsModule.TPRM ? `${TPRM}/` : ''}${ISSUES_EXCEPTIONS}#${ISSUES}`}>Issues</BreadcrumbLink>
                        <BreadcrumbText>{issue.title}</BreadcrumbText>
                    </Breadcrumb>
                    <div className={styles.headerContainer}>
                        <Text variant="Header1" color="darkBlue" noStyles>
                            {issue.title}
                        </Text>
                    </div>
                </PageContent>
            </PageBackground>
            <PageContent>
                <PageCell>
                    <div className={styles.pageCellHeaderContainer}>
                        <div className={styles.pageCellHeaderContainerText}>
                            <Text variant="Header2" color="darkBlue">
                                {issue.title}
                            </Text>
                            <Text noStyles variant="Text4">
                                {issue.status === IssueStatus.CLOSED ? `Closed: ${iso8601ToUsDateShort(issue.closed_timestamp)} by ${getUserNameFromSubject(issue.closed_by, users, UserNameFormat.FIRST_SPACE_LAST)}` : `Last updated: ${iso8601ToUsDateShort(issue.last_updated_timestamp)} by ${getUserNameFromSubject(issue.last_updated_by, users, UserNameFormat.FIRST_SPACE_LAST)}`}
                            </Text>
                        </div>
                        <div className={styles.buttonContainer}>{buttons}</div>
                    </div>
                    <hr />
                    <IssueDetailsSnapshot issue={issue} documentApi={props.documentApi} />
                </PageCell>
                <div className={styles.secondPageCell}>
                    <PageCell>
                        <PrimaryTabs defaultActiveTab={issue.type === IssuesExceptionsModule.CONTROLS ? IssueTab.CONTROLS : IssueTab.VENDOR} removePadding transparent>
                            {issue.type === IssuesExceptionsModule.CONTROLS ? (
                                <Tab eventKey={IssueTab.CONTROLS} title={IssueTab.CONTROLS}>
                                    <div className={styles.tabContent}>
                                        <Text variant="Header2" color="darkBlue" noStyles>
                                            Controls
                                        </Text>
                                        <hr />
                                        <ControlTable controls={issue.impacted_controls} />
                                    </div>
                                </Tab>
                            ) : (
                                <Tab eventKey={IssueTab.VENDOR} title={IssueTab.VENDOR}>
                                    <div className={styles.tabContent}>
                                        <Text variant="Header2" color="darkBlue" noStyles>
                                            {vendorIdToVendorMap.get(issue.impacted_vendor)!.name}
                                        </Text>
                                        <hr />
                                        <VendorSummary vendor={vendorIdToVendorMap.get(issue.impacted_vendor)!} />
                                    </div>
                                </Tab>
                            )}
                            <Tab eventKey={IssueTab.HISTORY} title={IssueTab.HISTORY}>
                                <div className={styles.tabContent} data-testid="issueHistoryTable">
                                    <Text variant="Header2" color="darkBlue" noStyles>
                                        History
                                    </Text>
                                    <hr />
                                    {issue.status !== IssueStatus.DRAFT_OPEN ? <IssueHistoryTable {...issueHistoryTableProps} /> : <Text>History will not be recorded until the issue is opened.</Text>}
                                </div>
                            </Tab>
                        </PrimaryTabs>
                    </PageCell>
                </div>
            </PageContent>
        </>
    );
};
