import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { IssuesApi } from 'Api/Issues/IssuesApi';
import { BarAndLineChart } from 'Components/BaseCharts/BarAndLineChart';
import { CSSColors } from 'Components/Colors';
import PageCell from 'Components/Containers/PageCell/PageCell';
import { ChangeEventType, FormFieldDateOptionSelect } from 'Components/FormField/FormFieldDateOptionSelect/FormFieldDateOptionSelect';
import { FormFieldSelectDark } from 'Components/FormField/FormFieldSelectDark/FormFieldSelectDark';
import Text from 'Components/Text/Text';
import { DashboardType, DateOptionSelectOptions, UpcomingDateRangeSelectOptions, isValidDateOption } from 'Models/Dashboards';
import { IssuePriority, IssuePriorityFilter, IssuePriorityFilterOptions, IssueResponse, IssueStatus, IssuesExceptionsModule, isValidIssuePriorityFilter } from 'Models/Issues';
import { VendorResponse } from 'Models/TPRM';
import CustomDateRangeModal, { CustomDateRange, CustomDateRangeUpdateCallback } from 'Pages/Dashboards/Components/CustomDateRangeModal/CustomDateRangeModal';
import { getMonths } from 'Pages/Dashboards/TPRMDashboard/TPRMDashboard.helpers';
import { getDatesFromPreset } from 'Pages/Dashboards/TPRMDashboard/TPRMDashboard.hooks';

import styles from './DashboardsTrackingIssues.module.css';
import { TrackingIssuesDisplayModal } from './TrackingIssuesDisplayModal/TrackingIssuesDisplayModal';
import { getOpenIssuesPerMonth } from '../../OperationalControlsDashboard/OperationalControlsDashboard.helpers';

export interface DashboardOpenIssuesProps {
    issuesApi: IssuesApi;
    dashboardType: DashboardType;
    vendors?: VendorResponse[];
    vendorError?: string;
}

enum Modals {
    NONE,
    DISPLAY_OPEN_ISSUES,
    CUSTOM_DATE,
}

/**
 * Renders a bar chart that displays, over a given time period, and grouped by month, the number of issues that are coming due.
 * The first bar shows overdue issues.
 */
export const DashboardOpenIssues = (props: DashboardOpenIssuesProps): JSX.Element => {
    const [dashboardIssues, setDashboardIssues] = useState<IssueResponse[]>();
    const [issuesByMonth, setIssuesByMonth] = useState<number[]>();
    const [issuesError, setIssuesError] = useState<string>();
    const [monthLabel, setMonthLabel] = useState<string[]>();
    const [clickedIssues, setClickedIssue] = useState<IssueResponse[]>();
    const [selectDateOption, setSelectDateOption] = useState<DateOptionSelectOptions>(DateOptionSelectOptions.NEXT_QUARTER);
    const [selectedDates, setSelectedDates] = useState<{ start: Date; end: Date }>(getDatesFromPreset(DateOptionSelectOptions.NEXT_QUARTER));
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [clickedDate, setClickedDate] = useState<string>();
    const [issueFilterType, setIssueFilterType] = useState<IssuePriorityFilter>(IssuePriorityFilter.ALL);

    const lineColor = CSSColors.LIGHT_BLUE;

    useEffect(() => {
        const getIssues = async () => {
            try {
                const issuesResponse = await props.issuesApi.getAllIssues();

                if (props.dashboardType === DashboardType.OPERATIONAL_CONTROLS) {
                    const allOCIssues = issuesResponse.data.filter((issue) => issue.type === IssuesExceptionsModule.CONTROLS && issue.status === IssueStatus.OPEN);
                    setDashboardIssues(allOCIssues);
                } else {
                    const allTPRMIssues = issuesResponse.data.filter((issue) => issue.type === IssuesExceptionsModule.TPRM && issue.status === IssueStatus.OPEN);
                    setDashboardIssues(allTPRMIssues);
                }
            } catch (err) {
                setIssuesError(err.message);
            }
        };
        getIssues();
    }, [props.dashboardType, props.issuesApi]);

    useEffect(() => {
        const issuesPriorityFilter = (issue: IssueResponse): boolean => {
            if (issueFilterType === IssuePriorityFilter.ALL) {
                return true;
            } else if (issueFilterType === IssuePriorityFilter.HIGH) {
                return issue.priority === IssuePriority.HIGH;
            } else if (issueFilterType === IssuePriorityFilter.MEDIUM) {
                return issue.priority === IssuePriority.MEDIUM;
            } else if (issueFilterType === IssuePriorityFilter.LOW) {
                return issue.priority === IssuePriority.LOW;
            } else {
                return true;
            }
        };
        if (dashboardIssues) {
            const issuesByPriority = dashboardIssues.filter(issuesPriorityFilter);

            const months = getMonths(selectedDates.start, selectedDates.end);

            const totalIssuesByMonth = months.map((month) => {
                return issuesByPriority.filter((issue) => moment(issue.due_date).format('MMM YY') === month).length;
            });

            const overDueIssues = issuesByPriority.filter((issue) => moment(issue.due_date).isBefore(moment()));

            setIssuesByMonth([overDueIssues.length, ...totalIssuesByMonth]);
            setMonthLabel(['Overdue', ...months]);
        }
    }, [dashboardIssues, issueFilterType, selectedDates.end, selectedDates.start]);

    const handleChartClick = (item: string) => {
        const clickedItem = item.split(' | ');
        const currentFilteredIssues = dashboardIssues?.filter((issue) => {
            if (issueFilterType === IssuePriorityFilter.ALL) {
                return true;
            } else if (issueFilterType === IssuePriorityFilter.HIGH) {
                return issue.priority === IssuePriority.HIGH;
            } else if (issueFilterType === IssuePriorityFilter.MEDIUM) {
                return issue.priority === IssuePriority.MEDIUM;
            } else if (issueFilterType === IssuePriorityFilter.LOW) {
                return issue.priority === IssuePriority.LOW;
            } else {
                return true;
            }
        });
        if (currentFilteredIssues) {
            if (clickedItem[0] === 'Overdue') {
                const overdueIssues = currentFilteredIssues.filter((issue) => moment(issue.due_date).isBefore(moment()));

                setClickedIssue(overdueIssues);
            } else {
                setClickedIssue(getOpenIssuesPerMonth(currentFilteredIssues, clickedItem[0]));
            }
            setClickedDate(clickedItem[0]);
            setModalState(Modals.DISPLAY_OPEN_ISSUES);
        }
    };

    const handleDateFilterChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDateOption(value)) {
            if (value === DateOptionSelectOptions.CUSTOM) {
                setModalState(Modals.CUSTOM_DATE);
            } else {
                setSelectedDates(getDatesFromPreset(value));
                setSelectDateOption(value);
            }
        }
    };

    const handleSelectCustomDateRange: CustomDateRangeUpdateCallback = (customDateRange: CustomDateRange) => {
        setSelectedDates({ start: customDateRange.startDate, end: customDateRange.endDate });
        setSelectDateOption(DateOptionSelectOptions.CUSTOM);
    };

    const handleRiskTypeChange = (value: ChangeEventType, formFieldId: string) => {
        if (isValidIssuePriorityFilter(value)) {
            setIssueFilterType(value as IssuePriorityFilter);
        }
    };

    if (issuesError) {
        return (
            <>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <div className={styles.cellHeader}>
                            <Text variant="Header2" color="white">
                                Issue Tracking
                            </Text>
                        </div>
                        <Text color="white" variant="Header4">
                            {issuesError}
                        </Text>
                    </PageCell>
                </div>
            </>
        );
    }

    if (props.vendorError) {
        return (
            <>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <div className={styles.cellHeader}>
                            <Text variant="Header2" color="white">
                                Issue Tracking
                            </Text>
                        </div>
                        <Text color="white" variant="Header4">
                            {props.vendorError}
                        </Text>
                    </PageCell>
                </div>
            </>
        );
    }

    return (
        <>
            {modalState === Modals.CUSTOM_DATE && <CustomDateRangeModal startDate={selectedDates.start} endDate={selectedDates.end} datesRestriction="futureDatesOnly" hideModal={() => setModalState(Modals.NONE)} setCustomDateRange={handleSelectCustomDateRange} />}
            {modalState === Modals.DISPLAY_OPEN_ISSUES && clickedDate && clickedIssues && <TrackingIssuesDisplayModal selectedIssue={clickedIssues} date={clickedDate} hideModal={() => setModalState(Modals.NONE)} dashboardType={props.dashboardType} vendors={props.vendors} />}
            <div className={styles.section2}>
                <PageCell variant="transparentBlue">
                    <div className={styles.cellHeader}>
                        <Text variant="Header2" color="white">
                            Issue Tracking
                        </Text>
                        <div className={styles.filters}>
                            <FormFieldSelectDark formFieldId="issuePriority" formFieldLabel="Priority..." options={IssuePriorityFilterOptions} invalidMessage="A priority must be provided." selectedOption={issueFilterType} handleChange={handleRiskTypeChange} />
                            <FormFieldDateOptionSelect formFieldId="date" options={UpcomingDateRangeSelectOptions} selectedOption={selectDateOption} handleChange={handleDateFilterChange} />
                        </div>
                    </div>
                    {monthLabel && issuesByMonth ? <BarAndLineChart onChartClick={handleChartClick} xAxisLabels={monthLabel} bar={{ name: 'Total Due', color: lineColor, data: issuesByMonth }} yAxisInfo={[{ name: 'OPEN ISSUES BY MONTH', position: 'left', minInterval: 1 }]} /> : <Skeleton variant="rounded" width={'100%'} height={300} />}
                </PageCell>
            </div>
        </>
    );
};
