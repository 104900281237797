import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { Button } from 'Components/Buttons/Buttons';
import IconButton from 'Components/Buttons/IconButton';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Placeholder from 'Components/Placeholder/Placeholder';
import { RiskCategoryRequest, RiskCategoryResponse } from 'Models/RiskRegister';

import styles from './ManageRiskCategoriesModal.module.css';

export interface ManageRiskCategoriesModalProps {
    hideModal: () => void;
    riskRegisterApi: RiskRegisterApi;
}

/**
 * Modal for managing risk categories.
 * Note that there are separate state variables and functions for existing and new risk categories. This is because existing risk categories can only be deleted, not modified. Whereas new risk categories can be added, modified, or deleted (from state).
 */
export const ManageRiskCategoriesModal = (props: ManageRiskCategoriesModalProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [existingRiskCategories, setExistingRiskCategories] = useState<RiskCategoryResponse[]>([]);
    const [newRiskCategories, setNewRiskCategories] = useState<RiskCategoryRequest[]>([]);

    useEffect(() => {
        const getExistingRiskCategories = async () => {
            try {
                const response = await props.riskRegisterApi.getRiskCategories();
                setExistingRiskCategories(response.data);
            } catch (error) {
                setFailureMessage(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        getExistingRiskCategories();
    }, [props.riskRegisterApi]);

    /**
     * Adds a new (empty) risk category for the user to fill in.
     */
    const handleNewRiskCategoryAdd = (): void => {
        setNewRiskCategories([...newRiskCategories, { title: '' }]);
    };

    /**
     * Removes a risk category from the list of existing risk categories.
     */
    const handleExistingRiskCategoryRemove = (index: number): void => {
        const riskCategories = [...existingRiskCategories];
        riskCategories.splice(index, 1);
        setExistingRiskCategories(riskCategories);
    };

    /**
     * Removes a risk category from the list of new risk categories.
     */
    const handleNewRiskCategoryRemove = (index: number): void => {
        const riskCategories = [...newRiskCategories];
        riskCategories.splice(index, 1);
        setNewRiskCategories(riskCategories);
    };

    /**
     * Sets the name for a new risk category.
     */
    const handleNewRiskCategoryChange = (index: number, value: string): void => {
        const riskCategories = [...newRiskCategories];
        riskCategories[index].title = value;
        setNewRiskCategories([...riskCategories]);
    };

    /**
     * Save the risk category additions/deletions.
     */
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFailureMessage(undefined);
        setSuccessMessage(undefined);

        // Trim the title of the new risk categories.
        let combinedRiskCategories: RiskCategoryRequest[] = newRiskCategories.map((riskCategory) => {
            return { ...riskCategory, title: riskCategory.title.trim() };
        });
        // Combine the existing and new risk categories.
        combinedRiskCategories = [...existingRiskCategories, ...combinedRiskCategories];
        // Eliminate risk categories with duplicate titles.
        combinedRiskCategories = combinedRiskCategories.filter((riskCategory, index, self) => index === self.findIndex((rc) => rc.title === riskCategory.title));
        // Eliminate new risk categories that are empty strings.
        combinedRiskCategories = combinedRiskCategories.filter((riskCategory) => riskCategory.title !== '');
        // Sort the combined list of existing and new risk categories.
        combinedRiskCategories.sort((a, b) => a.title.localeCompare(b.title));

        try {
            setIsSubmitting(true);
            await props.riskRegisterApi.updateRiskCategories(combinedRiskCategories);
            setSuccessMessage('Risk categories saved.');
        } catch (error) {
            setFailureMessage(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className={'modalFromBody'}>
                    <Placeholder />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Risk Categories" secondaryText="Define categories that risks are grouped by, such as Financial, Legal, Operational, Reputational, and Technology. Risk categories cannot be deleted if they are in use (risks are assigned to them)." />
                    {existingRiskCategories.length > 0 && (
                        <>
                            {existingRiskCategories.map((riskCategory, index) => {
                                return (
                                    <div className={styles.riskCategoryContainer} key={index}>
                                        <div className={styles.riskCategoryName}>
                                            <FormFieldText disabled={true} formFieldType="text" formFieldId={`existingRiskCategory${index}`} formFieldLabel={`Risk Category`} value={riskCategory.title} />
                                        </div>
                                        <IconButton disabled={riskCategory.in_use} onClick={() => handleExistingRiskCategoryRemove(index)} aria-label={`delete ${riskCategory.title}`} fontAwesomeImage={faTrash} />
                                    </div>
                                );
                            })}
                        </>
                    )}
                    {newRiskCategories.length > 0 && (
                        <>
                            {newRiskCategories.map((riskCategory, index) => {
                                return (
                                    <div className={styles.riskCategoryContainer} key={index}>
                                        <div className={styles.riskCategoryName}>
                                            <FormFieldText formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNewRiskCategoryChange(index, event.currentTarget.value)} formFieldId={`newRiskCategory${index}`} formFieldLabel={`Risk Category`} required={true} value={riskCategory.title || ''} />
                                        </div>
                                        <IconButton onClick={() => handleNewRiskCategoryRemove(index)} aria-label={`delete ${riskCategory.title}`} fontAwesomeImage={faTrash} />
                                    </div>
                                );
                            })}
                        </>
                    )}
                    <div>
                        <Button variant="linkText" size="lg" onClick={handleNewRiskCategoryAdd}>
                            {'+ Add New Risk Category'}
                        </Button>
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit" isLoading={isSubmitting} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
