import { ASSESSMENTS, COMMON, CONFIGURATION, CONTROLS, CURRENT, DDQ, DOCUMENTS, FOLDERS, FRAMEWORKS, GROUPS, IRQ, NOTIFICATIONS, POTENTIAL_PARENTS, QUESTIONS, REPORTS, SCHEDULE, SERVICES, SUMMARY, TPRM, VENDORS, VERSIONS } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { ControlFrameworkInstanceResponse, CreateControlConfigurationRequest, CreateControlFrameworkConfigurationRequest, CreateControlGroupConfigurationRequest, CreateNewFolderRequest, FolderAllVersions, FolderTypes, FolderVersion, InherentRiskEvidenceResponse, ModifyServiceAssessmentDueDateRequest, QuestionnaireAggregationResponse, QuestionnaireConfigurationResponse, QuestionnaireGroupControlsResponse, QuestionnaireReport, ResetAndNotifyVendorRequest, RiskWorkflowArchive, SaveVendorRequest, SaveVendorServiceRequest, Service, ServiceAssessmentSchedule, ServiceSummary, SetInherentRiskEvidenceRequest, UpdateCommonAssessmentRequest, UpdateConfigurationRequest, UpdateControlInstanceQuestionsRequest, UpdateControlInstanceRequest, UpdateServiceAssessmentRequest, VendorResponse, VendorResponseWithServices } from 'Models/TPRM';
import { restApiDelete, restApiGet, restApiPost } from 'RestApiConnector';

import { TPRMApi } from './TPRMApi';

export class AWSTPRMApi implements TPRMApi {
    deleteVendor = (vendorId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${VENDORS}/${vendorId}`);
    };

    /**
     * @returns A list of all Vendors and their Services. The list is sorted by Vendor name.
     */
    getVendors = (): Promise<ResponseModel<VendorResponseWithServices[]>> => {
        return restApiGet(`/${TPRM}/${VENDORS}`, {}).then((response): ResponseModel<VendorResponseWithServices[]> => {
            return {
                ...response,
                data: response.data.sort((vendorA: VendorResponseWithServices, vendorB: VendorResponseWithServices) => vendorA.name.localeCompare(vendorB.name)),
            };
        });
    };

    getVendorDetails = (vendorId: string): Promise<ResponseModel<VendorResponse>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendorId}`, {});
    };

    saveVendor = (vendorRequest: SaveVendorRequest, vendorId?: string): Promise<ResponseModel<void>> => {
        if (vendorId !== undefined) {
            // Modify an existing Vendor.
            return restApiPost(`/${TPRM}/${VENDORS}/${vendorId}`, vendorRequest);
        } else {
            // Create a new Vendor.
            return restApiPost(`/${TPRM}/${VENDORS}`, vendorRequest);
        }
    };

    saveVendorService = (request: SaveVendorServiceRequest, vendorId: string, serviceId?: string): Promise<ResponseModel<Service>> => {
        if (serviceId !== undefined) {
            // Modify an existing Vendor Service.
            return restApiPost(`/${TPRM}/${VENDORS}/${vendorId}/${SERVICES}/${serviceId}`, request);
        } else {
            // Create a new Vendor Service.
            return restApiPost(`/${TPRM}/${VENDORS}/${vendorId}/${SERVICES}`, request);
        }
    };

    modifyServiceAssessmentDueDate = (modifyServiceAssessmentDueDateRequest: ModifyServiceAssessmentDueDateRequest, vendorId: string, serviceId: string): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${VENDORS}/${vendorId}/${SERVICES}/${serviceId}`, modifyServiceAssessmentDueDateRequest);
    };

    modifyServiceAssessment = (serviceRequest: UpdateServiceAssessmentRequest, vendorId: string, serviceId: string): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${VENDORS}/${vendorId}/${SERVICES}/${serviceId}/${ASSESSMENTS}`, serviceRequest);
    };

    updateCommonAssessment = (request: UpdateCommonAssessmentRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${ASSESSMENTS}/${COMMON}`, request);
    };

    deleteService = (vendorId: string, serviceId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${VENDORS}/${vendorId}/${SERVICES}/${serviceId}`);
    };

    deleteServiceAssessmentDocument = (vendorId: string, serviceId: string, documentId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${VENDORS}/${vendorId}/${SERVICES}/${serviceId}/${ASSESSMENTS}/${DOCUMENTS}/${documentId}`);
    };

    getServiceDetails = (vendor_id: string, service_id: string): Promise<ResponseModel<Service>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}`, {});
    };

    deleteIRQ = (vendor_id: string, service_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${IRQ}`);
    };

    getIRQEvidence = (vendor_id: string, service_id: string): Promise<ResponseModel<InherentRiskEvidenceResponse>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${IRQ}`, {});
    };

    setIRQ = (vendor_id: string, service_id: string, setIRQRequest: SetInherentRiskEvidenceRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${IRQ}`, setIRQRequest);
    };

    getCommonAssessmentPotentialParents = (vendor_id: string, service_id: string): Promise<ResponseModel<ServiceSummary[]>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DDQ}/${POTENTIAL_PARENTS}/`);
    };

    getQuestionnaireConfiguration = (): Promise<ResponseModel<QuestionnaireConfigurationResponse>> => {
        return restApiGet(`/${TPRM}/${DDQ}/${CONFIGURATION}`);
    };

    getQuestionnaireFrameworks = (vendor_id: string, service_id: string): Promise<ResponseModel<ControlFrameworkInstanceResponse[]>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DDQ}/${FRAMEWORKS}/`);
    };
    getQuestionnaireGroupControls = (vendor_id: string, service_id: string, control_framework: string, control_group_id: string): Promise<ResponseModel<QuestionnaireGroupControlsResponse>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DDQ}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}`);
    };

    getQuestionnaireAggregation = (vendor_id: string, service_id: string): Promise<ResponseModel<QuestionnaireAggregationResponse> | undefined> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DDQ}/${SUMMARY}/`);
    };

    getQuestionnaireReport = (vendor_id: string, service_id: string): Promise<ResponseModel<QuestionnaireReport>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DDQ}/${REPORTS}/`);
    };
    vendorSaveControl = (vendor_id: string, service_id: string, control_framework: string, control_group_id: string, control_id: string, request: UpdateControlInstanceQuestionsRequest): Promise<ResponseModel<void>> => {
        return Promise.reject(new Error('This should only be called by public DDQs'));
    };
    clientSaveControl = (vendor_id: string, service_id: string, control_framework: string, control_group_id: string, control_id: string, request: UpdateControlInstanceRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DDQ}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}`, request);
    };
    deleteControlAssessmentDocument = (vendor_id: string, service_id: string, control_framework: string, control_group_id: string, control_id: string, document_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DDQ}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${ASSESSMENTS}/${DOCUMENTS}/${document_id}`);
    };
    deleteAnswerDocument = (vendor_id: string, service_id: string, control_framework: string, control_group_id: string, control_id: string, question_id: string, document_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DDQ}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}/${QUESTIONS}/${question_id}/${DOCUMENTS}/${document_id}`);
    };

    /**
     * Reset the Due Diligence Questionnaire. Reset credentials and notify the specified Vendor Service contacts.
     */
    resetAndNotifyVendor = (vendor_id: string, service_id: string, resetAndNotifyVendorRequest: ResetAndNotifyVendorRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${DDQ}/${NOTIFICATIONS}`, resetAndNotifyVendorRequest);
    };

    // Vendor (Due Diligence) Questionnaire Configuration
    createControlFrameworkConfiguration = (createControlFrameworkConfigurationRequest: CreateControlFrameworkConfigurationRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}`, createControlFrameworkConfigurationRequest);
    };
    deleteControlFrameworkConfiguration = (control_framework: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${control_framework}`);
    };

    createControlGroupConfiguration = (control_framework: string, controlcreateControlGroupConfigurationRequest: CreateControlGroupConfigurationRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${control_framework}/${GROUPS}`, controlcreateControlGroupConfigurationRequest);
    };
    deleteControlGroupConfiguration = (control_framework: string, control_group_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}`);
    };

    createControlConfiguration = (control_framework: string, control_group_id: string, createControlConfigurationRequest: CreateControlConfigurationRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}`, createControlConfigurationRequest);
    };
    deleteControlConfiguration = (control_framework: string, control_group_id: string, control_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${control_framework}/${GROUPS}/${control_group_id}/${CONTROLS}/${control_id}`);
    };
    updateConfiguration = (updateConfigurationRequest: Map<string, UpdateConfigurationRequest>): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${DDQ}/${CONFIGURATION}`, Object.fromEntries(updateConfigurationRequest));
    };

    // Service Assessment Schedule
    getServiceAssessmentSchedule = (): Promise<ResponseModel<ServiceAssessmentSchedule>> => {
        return restApiGet(`/${TPRM}/${ASSESSMENTS}/${SCHEDULE}/`);
    };

    saveServiceAssessmentSchedule = (serviceAssessmentSchedule: ServiceAssessmentSchedule): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${ASSESSMENTS}/${SCHEDULE}/`, serviceAssessmentSchedule);
    };
    // Vendor Service Reporting
    getAssessmentSummaryReport = (vendor_id: string, service_id: string, timestamp: string): Promise<ResponseModel<RiskWorkflowArchive>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${REPORTS}/${timestamp}`, {});
    };
    getAllAssessmentSummaryReports = (vendor_id: string, service_id: string): Promise<ResponseModel<RiskWorkflowArchive[]>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${SERVICES}/${service_id}/${REPORTS}`, {});
    };
    //TPRM Documents/Folders
    getAllFolderTypes = (): Promise<ResponseModel<FolderTypes>> => {
        return restApiGet(`/${TPRM}/${FOLDERS}`, {});
    };

    updateFolderTypes = (folderTypes: FolderTypes): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${FOLDERS}`, folderTypes);
    };

    getFolders = (vendor_id: string): Promise<ResponseModel<FolderVersion[]>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}`, {});
    };

    createNewFolder = (vendor_id: string, createNewFolderRequest: CreateNewFolderRequest): Promise<ResponseModel<string>> => {
        return restApiPost(`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}`, createNewFolderRequest);
    };

    getAllFolderVersions = (vendor_id: string, folder_id: string): Promise<ResponseModel<FolderAllVersions>> => {
        return restApiGet(`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}/${folder_id}`);
    };

    createNewFolderVersion = (vendor_id: string, folder_id: string, createNewFolderVersionRequest: CreateNewFolderRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}/${folder_id}/${VERSIONS}`, createNewFolderVersionRequest);
    };

    manageFolder = (vendor_id: string, folder_id: string, createNewFolderVersionRequest: CreateNewFolderRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}/${folder_id}/${VERSIONS}/${CURRENT}`, createNewFolderVersionRequest);
    };

    deleteFolder = (vendor_id: string, folder_id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}/${folder_id}`);
    };
}
