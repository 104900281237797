/*
	ControlFrameworkAssessmentTab.tsx -- Application logic for editing certain aspects of a framework.
*/
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import PageCell from 'Components/Containers/PageCell/PageCell';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import FormFieldUserMultiSelect from 'Components/FormField/FormFieldUserSelect/FormFieldUserMultiSelect';
import FormFieldUserSelect from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Chip } from 'Components/Text/Chip';
import Text from 'Components/Text/Text';
import { TextToast } from 'Components/Toast/Toast';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getNextDueDate } from 'Helpers/ScheduleFrequency/ScheduleFrequency';
import { UpdateAssessmentConfigurationRequest } from 'Models/Configuration';
import { ValidationError } from 'Models/ErrorTypes';
import { DetailedControlFrameworkResponse } from 'Models/OperationalControls';
import { ScheduleFrequency, ScheduleFrequencySelectOptions } from 'Models/ScheduleFrequency';
import { UserResponse } from 'Models/User';

import styles from './ControlFrameworkAssessmentTab.module.css';

export enum Modals {
    ConfirmationModal,
    Delete,
    None,
}

export interface ControlFrameworkAssessmentTabProps {
    controlsApi: ControlsApi;
    users: UserResponse[];
    detailedControlFrameworkResponse: DetailedControlFrameworkResponse;
    configurationUpdated: () => Promise<void>;
}
interface FormFieldsState {
    reviewer?: UserResponse;
    reviewScheduleNumber?: string;
    reviewScheduleFrequency?: ScheduleFrequency;
    reviewScheduleStartDate?: Date;
    owner?: UserResponse;
    delegates?: UserResponse[];
}

export const ControlFrameworkAssessmentTab = (props: ControlFrameworkAssessmentTabProps) => {
    const [isUpdatingFramework, setIsUpdatingFramework] = useState(false);
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);
    const [failureMessage, setFailureMessage] = useState<string>();
    const [scheduleWasChanged, setScheduleWasChanged] = useState<boolean>(false); // This is used to render a badge showing the user the next assessment due date when a change to the schedule is made. We don't want it present all the time since the purpose is to serve as a warning/confirmation that they intend to change the schedule and verify that the next due date is what they expect.

    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({});

    useEffect(() => {
        setFormFieldsState({
            reviewer: props.users.find((user) => user.cognito_subject === props.detailedControlFrameworkResponse.reviewer_subject),
            reviewScheduleNumber: props.detailedControlFrameworkResponse.review_schedule_number,
            reviewScheduleFrequency: props.detailedControlFrameworkResponse.review_schedule_frequency,
            reviewScheduleStartDate: props.detailedControlFrameworkResponse.review_schedule_start_date ? new Date(props.detailedControlFrameworkResponse.review_schedule_start_date) : undefined,
            owner: props.users.find((user) => user.cognito_subject === props.detailedControlFrameworkResponse.owner_subject),
            delegates: props.users.filter((user) => props.detailedControlFrameworkResponse.delegate_subjects?.includes(user.cognito_subject)),
        });
    }, [props.detailedControlFrameworkResponse, props.users]);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
        setScheduleWasChanged(true);
    };

    const handleChangeDate = (reviewScheduleStartDate: Date): void => {
        setFormFieldsState({ ...formFieldsState, reviewScheduleStartDate });
        setScheduleWasChanged(true);
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
        setScheduleWasChanged(true);
    };

    const handleSelectUserChange = (user: UserResponse | undefined, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: user });
    };

    const handleSelectUsersChange = (users: UserResponse[] | undefined, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: users });
    };

    const validateAndDisplayConfirmationModal = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFailureMessage(undefined);
        try {
            validateConfiguration(formFieldsState.owner, formFieldsState.reviewer, formFieldsState.reviewScheduleNumber, formFieldsState.reviewScheduleFrequency, formFieldsState.reviewScheduleStartDate);
        } catch (error) {
            setFailureMessage(error.message);
            return '';
        }

        setDisplayedModal(Modals.ConfirmationModal);
    };

    /**
     * Update the Framework's configuration.
     */
    const handleSubmit = async (): Promise<string> => {
        const frameworkConfiguration: UpdateAssessmentConfigurationRequest = {
            reviewer_subject: formFieldsState.reviewer?.cognito_subject,
            owner_subject: formFieldsState.owner!.cognito_subject,
            delegate_subjects: formFieldsState.delegates?.map((user) => user.cognito_subject),
            review_schedule_number: formFieldsState.reviewScheduleNumber!,
            review_schedule_frequency: formFieldsState.reviewScheduleFrequency!,
            review_schedule_start_date: jsDateToIso8601(formFieldsState.reviewScheduleStartDate!),
        };
        setIsUpdatingFramework(true);
        await props.controlsApi.updateFramework(props.detailedControlFrameworkResponse.control_framework, frameworkConfiguration);
        await props.configurationUpdated();
        setIsUpdatingFramework(false);
        return 'Control framework configuration updated.';
    };

    /**
     * Validate the values in the Edit Framework form.
     */
    const validateConfiguration = (owner?: UserResponse, reviewer?: UserResponse, reviewScheduleNumber?: string, reviewScheduleFrequency?: ScheduleFrequency, reviewScheduleStartDate?: Date): void => {
        if (!owner || !owner.cognito_subject) {
            throw new ValidationError('Owner is required.');
        }
        if (owner.cognito_subject === reviewer?.cognito_subject) {
            throw new ValidationError('Owner and Reviewer cannot be the same user.');
        }
        if (!reviewScheduleNumber) {
            throw new ValidationError('Review Schedule is required.');
        }
        if (!reviewScheduleFrequency) {
            throw new ValidationError('Review Schedule is required.');
        }
        if (!reviewScheduleStartDate) {
            throw new ValidationError('Review Schedule is required.');
        }
    };

    const nextAssessmentDate = () => {
        if (formFieldsState.reviewScheduleStartDate && formFieldsState.reviewScheduleNumber && formFieldsState.reviewScheduleFrequency) {
            return getNextDueDate(formFieldsState.reviewScheduleStartDate, formFieldsState.reviewScheduleNumber, formFieldsState.reviewScheduleFrequency);
        }
    };

    const deleteControlFrameworkConfiguration = async (): Promise<string> => {
        await props.controlsApi.deleteControlFrameworkConfiguration(props.detailedControlFrameworkResponse.control_framework);
        await props.configurationUpdated();
        return 'Control assessment settings deleted.';
    };
    return (
        <>
            {displayedModal === Modals.ConfirmationModal && (
                <ConfirmationModal operationType="nonDestructive" headerText="Save Assessment Settings" areYouSureText="Are you sure you want to overwrite assessment settings for all groups and controls within this framework?" buttonText="SAVE" buttonLoadingText="Saving..." buttonIcon={faCheck} performOperation={handleSubmit} hideModal={() => setDisplayedModal(Modals.None)}>
                    <Text>Changes may take up to one minute to cascade.</Text>
                    <Text>If a control within this control framework has an assessment that is currently under review, the changes will not apply to that control.</Text>
                </ConfirmationModal>
            )}
            {displayedModal === Modals.Delete && (
                <ConfirmationModal operationType="delete" headerText="Delete Assessment Settings" areYouSureText="Are you sure you want to delete assessment settings for all groups and controls within this framework?" performOperation={deleteControlFrameworkConfiguration} hideModal={() => setDisplayedModal(Modals.None)}>
                    <Text>Changes may take up to one minute to cascade.</Text>
                </ConfirmationModal>
            )}
            <PageCell>
                <Form noValidate onSubmit={validateAndDisplayConfirmationModal}>
                    {failureMessage && <TextToast variant="failure" clearToast={() => setFailureMessage(undefined)} text={failureMessage} />}
                    <ModalHeader text="Roles" />
                    <Text color="red">WARNING: Saving changes here will cascade and overwrite assessment settings for all groups and controls within this framework.</Text>
                    <Text>If a reviewer is assigned, there will be an approval step when assessing controls within the control framework.</Text>
                    <div className={styles.formRoleContainer}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldUserSelect users={props.users ? props.users : []} onUserSelected={handleSelectUserChange} formFieldId="owner" selectedUser={formFieldsState.owner} formFieldLabel="Framework Owner" isRequiredField={true} tooltip={'The individual responsible for ensuring the effectiveness of the framework.'} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldUserSelect users={props.users ? props.users : []} onUserSelected={handleSelectUserChange} formFieldId="reviewer" selectedUser={formFieldsState.reviewer} formFieldLabel="Framework Reviewer" tooltip={'The individual responsible for continuous oversight of the framework.'} isClearable />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldUserMultiSelect users={props.users ? props.users : []} onUsersSelected={handleSelectUsersChange} formFieldId="delegates" selectedUsers={formFieldsState.delegates} formFieldLabel="Framework Delegates" tooltip={'A delegate of the owner. Responsible for testing control effectiveness.'} />
                        </div>
                    </div>
                    <ModalHeader text="Schedule" />
                    <div className={styles.formReviewContainer}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldDatePicker dateFormat="MM/dd/yyyy" selected={formFieldsState.reviewScheduleStartDate} handleChange={handleChangeDate} formFieldId="reviewScheduleStartDate" formFieldLabel="Start Date" placeholder={'MM/DD/YYYY'} invalidMessage={'Please enter a valid date (MM/DD/YYYY)'} required={true} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText formFieldId="reviewScheduleNumber" formFieldLabel="Frequency" required={true} tooltip="An integer representing how often the framework is reviewed." handleChange={handleChange} value={formFieldsState.reviewScheduleNumber || ''} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldSelect options={ScheduleFrequencySelectOptions} handleChange={handleSelectChange} formFieldId="reviewScheduleFrequency" selectedOption={formFieldsState.reviewScheduleFrequency} formFieldLabel="Interval" isRequiredField={true} tooltip="The frequency (month, year, etc.) that the framework must be reviewed." isClearable />
                        </div>
                    </div>
                    <div className={styles.badgeContainer}>{scheduleWasChanged && nextAssessmentDate() && <Chip>{`Next Assessment Due Date: ${nextAssessmentDate()}`}</Chip>}</div>
                    <div className={styles.buttonContainer}>
                        <Button variant="danger" onClick={() => setDisplayedModal(Modals.Delete)} fontAwesomeImage={faTrash}>
                            CLEAR SETTINGS
                        </Button>
                        <div className={styles.submitButton}>
                            <Button variant="submit" isLoading={isUpdatingFramework} loadingText="Saving...">
                                SAVE
                            </Button>
                        </div>
                    </div>
                </Form>
            </PageCell>
        </>
    );
};
