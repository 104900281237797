import { StringRepository } from './StringRepository';

export default class LocalStorageStringRepository implements StringRepository {
    setItem = (key: string, value: string): void => {
        window.localStorage.setItem(key, value);
    };
    getItem = (key: string): string | null => {
        return window.localStorage.getItem(key);
    };
    removeItem = (key: string): void => {
        window.localStorage.removeItem(key);
    };
}
