/*
	ExternalIntegrations.tsx -- Tab that contains configurations for external integrations.
*/
import { useCallback, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { ClientDetailsApi } from 'Api/Client/ClientDetailsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { DropdownItem, DropdownItemClickType, PrimaryAndSecondaryDropdownButtonProps, PrimaryDropdownButton } from 'Components/Buttons/DropdownButton';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import AssociatedControlsModal, { AssociatedControlsModalProps } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { CONFIGURATION } from 'Config/Paths';
import { useControlMappingItems } from 'Hooks/ControlMapping';
import { AwsConfigMisconfigurationDetailResponse, IntegrationName, IntegrationResponse, Metric, MetricName } from 'Models/ExternalIntegrations';
import { OperationalControl } from 'Models/OperationalControls';

import { AwsConfig } from './ConfigureIntegration/AwsConfig/AwsConfig';
import { AwsConfigMisconfigurationInfo } from './ConfigureIntegration/AwsConfig/AwsConfigMisconfigurationInfo';
import { AwsConfigSetupWizard } from './ConfigureIntegration/AwsConfig/AwsConfigSetupWizard';
import BitSight from './ConfigureIntegration/BitSight';
import Microsoft from './ConfigureIntegration/Microsoft';
import ProofpointSecurityAwarenessTraining from './ConfigureIntegration/ProofpointSecurityAwarenessTraining';
import { ProofpointTargetedAttackProtection } from './ConfigureIntegration/ProofpointTargetedAttackProtection';
import { Qualys } from './ConfigureIntegration/Qualys';
import Rapid7InsightPlatform from './ConfigureIntegration/Rapid7InsightPlatform';
import { ConfigureIntegrationRiskReconProps, RiskRecon } from './ConfigureIntegration/RiskRecon';
import SecurityScorecard from './ConfigureIntegration/SecurityScorecard';
import { Sophos } from './ConfigureIntegration/Sophos';
import { Tenable } from './ConfigureIntegration/Tenable';
import styles from './ExternalIntegrations.module.css';
import { Integration, IntegrationProps } from './Integration/Integration';
import { MetricDescription, MetricDescriptionProps } from './Integration/Metric/MetricDescription';
import { MetricControlMappingModal, MetricControlMappingModalProps } from './MetricControlMappingModal/MetricControlMappingModal';

export interface ConfigureIntegrationProps {
    externalIntegrationsApi: ExternalIntegrationsApi;
    getExternalIntegrations: () => void;
    hideModal: () => void;
}

// The Integrations here must match the values of the IntegrationName enum exactly (i.e. letter case), but without spaces.
export enum Modal {
    None,
    AssociatedControls, // View the Controls that are currently mapped to a Metric.
    AWSConfig,
    AWSConfigSetup,
    AWSConfigSetupStatus,
    BitSight,
    ControlAssociation, // Map a Metric to Controls.
    MetricDescription,
    Microsoft,
    ProofpointTargetedAttackProtection,
    ProofpointSecurityAwarenessTraining,
    Qualys,
    Rapid7InsightPlatform,
    RiskRecon,
    SecurityScorecard,
    Sophos,
    Tenable,
}

export interface ExternalIntegrationsProps {
    clientDetailsApi: ClientDetailsApi;
    controlsApi: ControlsApi;
    externalIntegrationsApi: ExternalIntegrationsApi;
}

interface ExternalIntegrationsState {
    displayedModal: Modal;
    selectedMetric?: Metric;
    controlAssociationSelectedControls: string[];
    integrationMetricIdentifier?: { integrationName: IntegrationName; metricName: MetricName };
    controlAssociationTitleSecondary?: string;
    selectedMetricAssociatedControls: OperationalControl[];
}

interface FormState {
    successMessage?: string;
    failureMessage?: string;
}

const ExternalIntegrations = (props: ExternalIntegrationsProps): JSX.Element => {
    const [awsConfigMisconfigurations, setAwsConfigMisconfigurations] = useState<AwsConfigMisconfigurationDetailResponse[]>();
    const [clientName, setClientName] = useState<string>('');
    const [controlMappingItems, controlMappingItemsError] = useControlMappingItems(props.controlsApi);
    const [existingIntegrations, setExistingIntegrations] = useState<string[]>([]);
    const [externalIntegrationState, setExternalIntegrationState] = useState<ExternalIntegrationsState>({ displayedModal: Modal.None, controlAssociationSelectedControls: [], selectedMetricAssociatedControls: [] });
    const [formState, setFormState] = useState<FormState>({ successMessage: undefined, failureMessage: undefined });
    const [integrations, setIntegrations] = useState<IntegrationResponse[]>();

    useEffect(() => {
        const getClientDetails = async (): Promise<void> => {
            try {
                const clientDetailsResponse = await props.clientDetailsApi.getClientDetails();
                setClientName(clientDetailsResponse.data.client_name);
            } catch (error) {
                setFormState({ failureMessage: error.message });
            }
        };

        getClientDetails();
    }, [props.clientDetailsApi]);

    const getExternalIntegrations = useCallback(async (): Promise<void> => {
        setFormState({ successMessage: undefined, failureMessage: undefined });
        try {
            const externalIntegrationsResponse = await props.externalIntegrationsApi.getExternalIntegrations();
            setIntegrations(externalIntegrationsResponse.data);

            const existingIntegrations = [];
            for (let i = 0; i < externalIntegrationsResponse.data.length; i++) {
                existingIntegrations.push(externalIntegrationsResponse.data[i].integration_name);
            }
            setExistingIntegrations(existingIntegrations);
        } catch (err) {
            setFormState({ failureMessage: err.message });
        }
    }, [props.externalIntegrationsApi]);

    const getAwsConfigMisconfiguration = useCallback(async (): Promise<void> => {
        try {
            const data = (await props.externalIntegrationsApi.getAwsConfigMisconfiguration()).data;
            setAwsConfigMisconfigurations(data);
        } catch (err) {
            setFormState({ failureMessage: err.message });
        }
    }, [props.externalIntegrationsApi]);

    const awsConfigMetrics = integrations?.find((e) => e.integration_name === IntegrationName.AWS_CONFIG)?.metrics;

    useEffect(() => {
        getExternalIntegrations();
        getAwsConfigMisconfiguration();
    }, [getExternalIntegrations, getAwsConfigMisconfiguration]);

    /**
     * Dynamically populate the "Add [Integration]" drop-down menu. Ensures that existing Integrations are not shown in the menu.
     */
    const populateDropdown = (): PrimaryAndSecondaryDropdownButtonProps => {
        const dropdownItems: DropdownItem[] = [];

        const dropdown_integrations: [IntegrationName, Modal][] = [
            [IntegrationName.AWS_CONFIG, Modal.AWSConfig],
            [IntegrationName.BITSIGHT, Modal.BitSight],
            [IntegrationName.MICROSOFT, Modal.Microsoft],
            [IntegrationName.PROOFPOINT_TARGETED_ATTACK_PROTECTION, Modal.ProofpointTargetedAttackProtection],
            [IntegrationName.PROOFPOINT_SECURITY_AWARENESS_TRAINING, Modal.ProofpointSecurityAwarenessTraining],
            [IntegrationName.QUALYS, Modal.Qualys],
            [IntegrationName.RAPID7_INSIGHT_PLATFORM, Modal.Rapid7InsightPlatform],
            [IntegrationName.RISKRECON, Modal.RiskRecon],
            [IntegrationName.SECURITYSCORECARD, Modal.SecurityScorecard],
            [IntegrationName.SOPHOS, Modal.Sophos],
            [IntegrationName.TENABLE, Modal.Tenable],
        ];
        dropdown_integrations.forEach((element) => {
            if (!existingIntegrations.includes(element[0])) {
                dropdownItems.push({
                    _type: DropdownItemClickType.ONCLICK,
                    text: element[0].toString(),
                    onClick: () => displayIntegrationModal(element[1]),
                });
            }
        });

        return { dropdownItems: dropdownItems };
    };

    /**
     * Sets state to display a modal showing the Controls that the Metric is mapped to.
     */
    const displayAssociatedControls = (metric: Metric): void => {
        setExternalIntegrationState({ ...externalIntegrationState, displayedModal: Modal.AssociatedControls, selectedMetric: metric, selectedMetricAssociatedControls: metric.control_mapping ?? [] });
    };

    /**
     * Sets state to display a modal for mapping a Metric to Controls.
     */
    const displayControlAssociation = (controlAssociationTitleSecondary: string, controlAssociationIdentifier: { integrationName: IntegrationName; metricName: MetricName }, control_mapping: OperationalControl[]): void => {
        const controlAssociationSelectedControls: string[] = [];
        for (let i = 0; i < control_mapping.length; i++) {
            controlAssociationSelectedControls.push(control_mapping[i].identifier);
        }
        setExternalIntegrationState({ ...externalIntegrationState, displayedModal: Modal.ControlAssociation, integrationMetricIdentifier: controlAssociationIdentifier, controlAssociationSelectedControls, controlAssociationTitleSecondary });
    };

    /**
     * Sets state to display a modal for configuring an Integration.
     */
    const displayIntegrationModal = (modal: Modal): void => {
        setExternalIntegrationState({ ...externalIntegrationState, displayedModal: modal });
        getExternalIntegrations();
    };

    /**
     * Sets state to display a modal with information about a Metric.
     */
    const displayMetricModal = (modal: Modal, metric: Metric): void => {
        setExternalIntegrationState({ ...externalIntegrationState, selectedMetric: metric, displayedModal: modal });
    };

    const setSuccessFailureMessages = (newSuccessMessage: string | undefined, newFailureMessage: string | undefined): void => {
        setFormState({ successMessage: newSuccessMessage, failureMessage: newFailureMessage });
    };

    const renderIntegration = (integration: IntegrationResponse): JSX.Element => {
        const integrationProps: IntegrationProps = {
            externalIntegrationsApi: props.externalIntegrationsApi,
            displayAssociatedControls: displayAssociatedControls,
            displayAwsConfigSetup: () => displayIntegrationModal(Modal.AWSConfigSetup),
            displayAwsConfigSetupStatus: () => displayIntegrationModal(Modal.AWSConfigSetupStatus),
            displayControlAssociation: displayControlAssociation,
            displayIntegrationModal: displayIntegrationModal,
            displayMetricModal: displayMetricModal,
            setSuccessFailureMessages: setSuccessFailureMessages,
            integration: integration,
            awsConfigConfigurationErrorsPresent: awsConfigMisconfigurations !== undefined && awsConfigMisconfigurations.length > 0,
        };
        return <Integration key={integration.integration_name} {...integrationProps} />;
    };

    const associatedControlsModalProps: AssociatedControlsModalProps = {
        hideModal: () => setExternalIntegrationState({ ...externalIntegrationState, displayedModal: Modal.None }),
        associatedControls: externalIntegrationState.selectedMetric && externalIntegrationState.selectedMetricAssociatedControls ? externalIntegrationState.selectedMetricAssociatedControls : [],
    };

    const configureIntegrationProps: ConfigureIntegrationProps = {
        externalIntegrationsApi: props.externalIntegrationsApi,
        getExternalIntegrations: getExternalIntegrations,
        hideModal: () => setExternalIntegrationState({ ...externalIntegrationState, displayedModal: Modal.None }),
    };

    const configureIntegrationRiskReconProps: ConfigureIntegrationRiskReconProps = {
        externalIntegrationsApi: props.externalIntegrationsApi,
        clientName: clientName,
        getExternalIntegrations: getExternalIntegrations,
        hideModal: () => setExternalIntegrationState({ ...externalIntegrationState, displayedModal: Modal.None }),
    };

    const metricDescriptionProps: MetricDescriptionProps = {
        hideModal: () => setExternalIntegrationState({ ...externalIntegrationState, displayedModal: Modal.None }),
        metric: externalIntegrationState.selectedMetric!,
    };

    const metricControlMappingModalProps: MetricControlMappingModalProps | undefined = controlMappingItems
        ? {
              controlMappingItems: controlMappingItems,
              currentMappedControlIdentifiers: externalIntegrationState.controlAssociationSelectedControls,
              externalIntegrationsApi: props.externalIntegrationsApi,
              hideModal: () => setExternalIntegrationState({ ...externalIntegrationState, displayedModal: Modal.None }),
              integrationMetricIdentifier: externalIntegrationState.integrationMetricIdentifier!,
              refreshIntegrations: getExternalIntegrations,
              secondaryTitle: externalIntegrationState.controlAssociationTitleSecondary,
          }
        : undefined;

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    } else if (integrations === undefined || metricControlMappingModalProps === undefined) {
        return <Placeholder />;
    } else {
        return (
            <RBACComponent roles={[Role.ADMIN]}>
                <PageBackground color="grey">
                    <PageContent>
                        <div className={styles.headerContainer}>
                            <Breadcrumb textColor="blue">
                                <BreadcrumbLink link={`/${CONFIGURATION}`}>Settings</BreadcrumbLink>
                                <BreadcrumbText>External Integrations</BreadcrumbText>
                            </Breadcrumb>
                            <Text variant="Header1" color="darkBlue">
                                External Integrations
                            </Text>
                            <Text variant="Header3" color="blue">
                                Configure integrations with external applications and services to automate data collection.
                            </Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        {/* Manage mappings of Metrics to Controls. */}
                        {externalIntegrationState.displayedModal === Modal.AssociatedControls && <AssociatedControlsModal {...associatedControlsModalProps} />}
                        {externalIntegrationState.displayedModal === Modal.ControlAssociation && <MetricControlMappingModal {...metricControlMappingModalProps} />}
                        {/* View a Metric Description. */}
                        {externalIntegrationState.displayedModal === Modal.MetricDescription && externalIntegrationState.selectedMetric && <MetricDescription {...metricDescriptionProps} />}
                        {/* Manage Integrations. */}
                        {externalIntegrationState.displayedModal === Modal.AWSConfig && <AwsConfig {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.AWSConfigSetup && awsConfigMetrics !== undefined && <AwsConfigSetupWizard metrics={awsConfigMetrics} {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.AWSConfigSetupStatus && awsConfigMisconfigurations !== undefined && <AwsConfigMisconfigurationInfo misconfigurationDetails={awsConfigMisconfigurations} {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.BitSight && <BitSight {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.Microsoft && <Microsoft {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.ProofpointTargetedAttackProtection && <ProofpointTargetedAttackProtection {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.ProofpointSecurityAwarenessTraining && <ProofpointSecurityAwarenessTraining {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.Qualys && <Qualys {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.Rapid7InsightPlatform && <Rapid7InsightPlatform {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.RiskRecon && <RiskRecon {...configureIntegrationRiskReconProps} />}
                        {externalIntegrationState.displayedModal === Modal.SecurityScorecard && <SecurityScorecard {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.Sophos && <Sophos {...configureIntegrationProps} />}
                        {externalIntegrationState.displayedModal === Modal.Tenable && <Tenable {...configureIntegrationProps} />}

                        <div className={styles.contentContainer}>
                            {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                            {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                            <div className={styles.buttonContainer}>
                                <PrimaryDropdownButton {...populateDropdown()}>ADD INTEGRATION</PrimaryDropdownButton>
                            </div>
                            <hr className={styles.hrModifier} />
                            {integrations.length > 0 ? integrations.map(renderIntegration) : <Text>No integrations have been configured.</Text>}
                        </div>
                    </PageContent>
                </PageBackground>
            </RBACComponent>
        );
    }
};

export default ExternalIntegrations;
