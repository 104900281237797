import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ListItemIcon } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import IconButton from 'Components/Buttons/IconButton';
import { Colors } from 'Components/Colors';
import Table, { TableBody, TableCell, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';

import styles from './FileDragAndDrop.module.css';

interface BaseFileDragAndDropDropzoneProps {
    inputId?: string;
    labelText: string;
    labelColor?: Colors;
    required?: boolean;
    tooltip?: JSX.Element;
    disabled?: boolean;
}

interface FileDragAndDropDropzoneProps extends BaseFileDragAndDropDropzoneProps {
    onAddFiles: (files: File[]) => void;
}

interface FileDragAndDropDropzoneSingleModeProps extends BaseFileDragAndDropDropzoneProps {
    onSelectFile: (file: File) => void;
}

const FileDragAndDropDropzone = ({ disabled = false, ...props }: FileDragAndDropDropzoneProps & { singleFileMode: boolean; hasSelectedFiles: boolean }): JSX.Element => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: props.onAddFiles, disabled: disabled, multiple: props.singleFileMode !== true });
    const dragClassName = isDragActive ? styles.dropzoneDragActive : '';
    const disabledClassName = disabled ? styles.disabled : '';

    const promptText = (() => {
        if (props.singleFileMode) {
            if (props.hasSelectedFiles) {
                return 'Drag and drop a file, or click here to select a file. Selecting a new file will replace the current file.';
            } else {
                return 'Drag and drop a file, or click here to select a file. Only a single file can be selected.';
            }
        } else {
            return 'Drag and drop files, or click here to select files.';
        }
    })();

    return (
        <>
            <label htmlFor={props.inputId}>
                <VisualLabel color={props.labelColor} required={props.required} tooltip={props.tooltip}>
                    {props.labelText}
                </VisualLabel>
            </label>
            <div {...getRootProps({ className: `${styles.dropzone} ${dragClassName} ${disabledClassName}` })}>
                {/* Properties explicitly defined are not passed through by react-dropzone */}
                <input id={props.inputId} disabled={disabled} {...getInputProps()} />
                <Text color="darkGray">{promptText}</Text>
            </div>
        </>
    );
};

interface BaseDropzoneTableProps {
    hideRemoveNewDocumentButton?: boolean;
}

interface DropzoneTableProps extends BaseDropzoneTableProps {
    files: File[];
    onRemoveFile: (file: File) => void;
}

interface DropzoneTableSingleModeProps extends BaseDropzoneTableProps {
    file?: File;
    onRemoveFile: () => void;
}

const FileDragAndDropTable = ({ hideRemoveNewDocumentButton = false, ...props }: DropzoneTableProps): JSX.Element => {
    if (props.files.length === 0) {
        return <></>;
    }

    return (
        <>
            <Table>
                <TableBody>
                    {props.files.map((file, index) => {
                        const tableCellStyle = props.files.length > 0 ? styles.removeBottomBorder : undefined;

                        return (
                            <TableRow key={index}>
                                <TableCell className={tableCellStyle}>
                                    <Text noStyles>{file.name}</Text>
                                </TableCell>
                                {!hideRemoveNewDocumentButton && (
                                    <TableOverflowCell className={tableCellStyle}>
                                        <ListItemIcon
                                            sx={{
                                                color: 'var(--hps-gray)',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                minWidth: 0,
                                                padding: '12px',
                                            }}
                                        >
                                            <IconButton aria-label={`Remove ${file.name}`} onClick={() => props.onRemoveFile(file)} fontAwesomeImage={faTrash} />
                                        </ListItemIcon>
                                    </TableOverflowCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export type FileDragAndDropProps = FileDragAndDropDropzoneProps & DropzoneTableProps;
export type FileDragAndDropSingleModeProps = FileDragAndDropDropzoneSingleModeProps & DropzoneTableSingleModeProps;

/**
 * Renders an input that allows users to drag and drop files, or click to select files.
 * Use the `useFileDragAndDrop` hook when using this component.
 *
 * TODO: The way we display "existing files" alongside this component is inconsistent across several pages in the app. Either expand the functionality of this component, or create a new component that makes use of this one, and standardize our pages.
 */
export const FileDragAndDrop = (props: FileDragAndDropProps): JSX.Element => {
    const dropzoneProps = { singleFileMode: false, hasSelectedFiles: props.files.length > 0, ...props };

    return (
        <>
            <FileDragAndDropDropzone {...dropzoneProps} />
            <FileDragAndDropTable {...props} />
        </>
    );
};

/**
 * Renders/functions the same as `FileDragAndDrop`, but allows only a single file to be selected (if a file has been selected, selecting a second file will replace the first file).
 * Use the `useFileDragAndDropSingleMode` hook when using this component.
 */
export const FileDragAndDropSingleMode = (props: FileDragAndDropSingleModeProps): JSX.Element => {
    const dropzoneProps = { singleFileMode: true, hasSelectedFiles: props.file !== undefined, onAddFiles: (files: File[]) => props.onSelectFile(files[0]), ...props };
    const dropzoneTableProps = { files: props.file ? [props.file] : [], ...props };

    return (
        <>
            <FileDragAndDropDropzone {...dropzoneProps} />
            <FileDragAndDropTable {...dropzoneTableProps} />
        </>
    );
};
