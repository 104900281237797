import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Link } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { ControlTable } from 'Components/ControlTable/ControlTable';
import { ExceptionHistoryTable, ExceptionHistoryTableProps } from 'Components/Exceptions/ExceptionHistoryTable/ExceptionHistoryTable';
import AssociatedControlsModal from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import Text from 'Components/Text/Text';
import { VendorSummary } from 'Components/VendorSummary/VendorSummary';
import { EXCEPTIONS, ISSUES_EXCEPTIONS, TPRM } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUpdateExceptionUrl } from 'Helpers/URLBuilder/URLBuilder';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { ControlExceptionHistoryResponse, ExceptionHistoryResponse, ExceptionResponse, ExceptionStatus, VendorExceptionHistoryResponse } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { VendorResponseWithServices } from 'Models/TPRM';

import styles from '../../../../Styles/DetailsPage.module.css';
import { ExceptionDetailsSnapshot } from '../Components/ExceptionDetailsSnapshot/ExceptionDetailsSnapshot';
import { ExceptionHistoryModal } from '../Components/ExceptionHistoryModal/ExceptionHistoryModal';

enum ExceptionTab {
    CONTROLS = 'Controls',
    VENDOR = 'Vendor',
    HISTORY = 'History',
}

interface UrlParams {
    exceptionId: string;
}

interface ExceptionDetailsOperaionalControlsProps {
    type: IssuesExceptionsModule.CONTROLS;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    tprmApi?: never; // Typed like this so that `tprmApi` can be used unconditionally as a `useEffect` dependency.
}

interface ExceptionDetailsTprmProps {
    type: IssuesExceptionsModule.TPRM;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
}

export type ExceptionDetailsProps = ExceptionDetailsOperaionalControlsProps | ExceptionDetailsTprmProps;
/**
 * Renders a page of detailed information for a specific exception.
 * This includes: current details; a tab containing a table of historical snapshots; and, depending on the exception type, a tab containing either a table of impacted controls or a summary of the impacted vendor.
 */
export const ExceptionDetails = (props: ExceptionDetailsProps) => {
    const { users } = useUsers();
    const { exceptionId } = useParams<keyof UrlParams>() as UrlParams;
    const [errorMessage, setErrorMessage] = useState<string>();
    const [exception, setException] = useState<ExceptionResponse>();
    const [vendorIdToVendorMap, setVendorIdToVendorMap] = useState<Map<string, VendorResponseWithServices>>(); // This isn't actually used when the exception belongs to the Operational Controls module.
    const [exceptionHistory, setExceptionHistory] = useState<ExceptionHistoryResponse[]>();
    const [exceptionHistoryToShowInModal, setExceptionHistoryToShowInModal] = useState<ExceptionHistoryResponse>();
    const [impactedControlsToShow, setImpactedControlsToShow] = useState<OperationalControl[]>();

    useEffect(() => {
        const getExceptionDetails = async (exceptionId: string): Promise<void> => {
            try {
                const getExceptionDetailsResponse = await props.exceptionsApi.getException(exceptionId);
                const exception = getExceptionDetailsResponse.data;
                setException(exception);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getExceptionDetails(exceptionId);
    }, [exceptionId, props.exceptionsApi]);

    useEffect(() => {
        const getExceptionHistory = async (exceptionId: string): Promise<void> => {
            try {
                const response = await props.exceptionsApi.getExceptionHistory(exceptionId);
                setExceptionHistory(response.data);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getExceptionHistory(exceptionId);
    }, [exceptionId, props.exceptionsApi]);

    useEffect(() => {
        const getVendors = async (): Promise<void> => {
            if (props.type !== IssuesExceptionsModule.TPRM) {
                setVendorIdToVendorMap(new Map());
                return;
            }

            try {
                // TODO: `getVendorDetails` should be used if/when `VendorResponseWithServices` and `VendorResponse` are consolidated.
                const response = await props.tprmApi.getVendors();
                setVendorIdToVendorMap(new Map(response.data.map((vendor) => [vendor.id, vendor])));
            } catch (error) {
                handleRequestError(error);
            }
        };

        getVendors();
    }, [props.tprmApi, props.type]);

    const handleRequestError = (error: Error): void => setErrorMessage(error.message);

    if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    }

    if (!(exception && exceptionHistory && vendorIdToVendorMap)) {
        return <Placeholder />;
    }

    const buttons: JSX.Element = (() => {
        switch (exception.status) {
            case ExceptionStatus.DRAFT_OPEN:
                return (
                    <Link variant="primaryButton" to={getUpdateExceptionUrl(exception.id, exception.type, false)} fontAwesomeImage={faEdit}>
                        MANAGE EXCEPTION
                    </Link>
                );
            case ExceptionStatus.APPROVED:
                return (
                    <>
                        <Link variant="primaryButton" to={getUpdateExceptionUrl(exception.id, exception.type, false)} fontAwesomeImage={faEdit}>
                            MANAGE EXCEPTION
                        </Link>
                        <Link variant="primaryButton" to={getUpdateExceptionUrl(exception.id, exception.type, true)} fontAwesomeImage={faCheck}>
                            DRAFT CLOSURE
                        </Link>
                    </>
                );
            case ExceptionStatus.DRAFT_CLOSE:
                return (
                    <Link variant="primaryButton" to={getUpdateExceptionUrl(exception.id, exception.type, false)} fontAwesomeImage={faEdit}>
                        MANAGE EXCEPTION
                    </Link>
                );
            case ExceptionStatus.CLOSED:
                return <></>;
        }
    })();

    const getVendorName = (vendorId: string) => vendorIdToVendorMap.get(vendorId)!.name;

    const exceptionHistoryTableProps: ExceptionHistoryTableProps = (() => {
        switch (exception.type) {
            case IssuesExceptionsModule.CONTROLS:
                return { type: IssuesExceptionsModule.CONTROLS, histories: exceptionHistory as ControlExceptionHistoryResponse[], displayMappedControlsModal: setImpactedControlsToShow, onSelectHistory: (history: ExceptionHistoryResponse) => setExceptionHistoryToShowInModal(history) };
            case IssuesExceptionsModule.TPRM:
                return { type: IssuesExceptionsModule.TPRM, histories: exceptionHistory as VendorExceptionHistoryResponse[], getVendorName: getVendorName, onSelectHistory: (history: ExceptionHistoryResponse) => setExceptionHistoryToShowInModal(history) };
        }
    })();

    return (
        <>
            {exceptionHistoryToShowInModal && <ExceptionHistoryModal history={exceptionHistoryToShowInModal} documentApi={props.documentApi} hideModal={() => setExceptionHistoryToShowInModal(undefined)} />}
            {impactedControlsToShow && <AssociatedControlsModal associatedControls={impactedControlsToShow} hideModal={() => setImpactedControlsToShow(undefined)} />}
            <PageBackground color="grey">
                <PageContent>
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${exception.type === IssuesExceptionsModule.TPRM ? `${TPRM}/` : ''}${ISSUES_EXCEPTIONS}#${EXCEPTIONS}`}>Exceptions</BreadcrumbLink>
                        <BreadcrumbText>{exception.title}</BreadcrumbText>
                    </Breadcrumb>
                    <div className={styles.headerContainer}>
                        <Text variant="Header1" color="darkBlue" noStyles>
                            {exception.title}
                        </Text>
                    </div>
                </PageContent>
            </PageBackground>
            <PageContent>
                <PageCell>
                    <div className={styles.pageCellHeaderContainer}>
                        <div className={styles.pageCellHeaderContainerText}>
                            <Text variant="Header2" color="darkBlue">
                                {exception.title}
                            </Text>
                            <Text noStyles variant="Text4">
                                {exception.status === ExceptionStatus.CLOSED ? `Closed: ${iso8601ToUsDateShort(exception.closed_timestamp)} by ${getUserNameFromSubject(exception.closed_by, users, UserNameFormat.FIRST_SPACE_LAST)}` : `Last updated: ${iso8601ToUsDateShort(exception.last_updated_timestamp)} by ${getUserNameFromSubject(exception.last_updated_by, users, UserNameFormat.FIRST_SPACE_LAST)}`}
                            </Text>
                        </div>
                        <div className={styles.buttonContainer}>{buttons}</div>
                    </div>
                    <hr />
                    <ExceptionDetailsSnapshot exception={exception} documentApi={props.documentApi} />
                </PageCell>
                <div className={styles.secondPageCell}>
                    <PageCell>
                        <PrimaryTabs defaultActiveTab={exception.type === IssuesExceptionsModule.CONTROLS ? ExceptionTab.CONTROLS : ExceptionTab.VENDOR} removePadding transparent>
                            {exception.type === IssuesExceptionsModule.CONTROLS ? (
                                <Tab eventKey={ExceptionTab.CONTROLS} title={ExceptionTab.CONTROLS}>
                                    <div className={styles.tabContent}>
                                        <Text variant="Header2" color="darkBlue" noStyles>
                                            Controls
                                        </Text>
                                        <hr />
                                        <ControlTable controls={exception.impacted_controls} />
                                    </div>
                                </Tab>
                            ) : (
                                <Tab eventKey={ExceptionTab.VENDOR} title={ExceptionTab.VENDOR}>
                                    <div className={styles.tabContent}>
                                        <Text variant="Header2" color="darkBlue" noStyles>
                                            {vendorIdToVendorMap.get(exception.impacted_vendor)!.name}
                                        </Text>
                                        <hr />
                                        <VendorSummary vendor={vendorIdToVendorMap.get(exception.impacted_vendor)!} />
                                    </div>
                                </Tab>
                            )}
                            <Tab eventKey={ExceptionTab.HISTORY} title={ExceptionTab.HISTORY}>
                                <div className={styles.tabContent} data-testid="exceptionHistoryTable">
                                    <Text variant="Header2" color="darkBlue" noStyles>
                                        History
                                    </Text>
                                    <hr />
                                    {exception.status !== ExceptionStatus.DRAFT_OPEN ? <ExceptionHistoryTable {...exceptionHistoryTableProps} /> : <Text>History will not be recorded until the exception is approved.</Text>}
                                </div>
                            </Tab>
                        </PrimaryTabs>
                    </PageCell>
                </div>
            </PageContent>
        </>
    );
};
