import { useState } from 'react';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { DropdownItemClickType, PrimaryAndSecondaryDropdownButtonProps, PrimaryDropdownButton } from 'Components/Buttons/DropdownButton';
import DeleteLimitModal, { DeleteLimitModalProps } from 'Components/Limits/DeleteLimitModal/DeleteLimitModal';
import CreateEffectivenessLimitModal, { CreateEffectivenessLimitModalProps } from 'Components/Limits/Effectiveness/CreateEffectivenessLimitModal/CreateEffectivenessLimitModal';
import ModifyEffectivenessLimitModal, { ModifyEffectivenessLimitModalProps } from 'Components/Limits/Effectiveness/ModifyEffectivenessLimitModal/ModifyEffectivenessLimitModal';
import LimitsTables, { LimitsTablesProps } from 'Components/Limits/LimitsTables/LimitsTables';
import { EffectivenessLimit, Limit, LimitResponse } from 'Models/Limits';
import { DetailedControlFrameworkResponse } from 'Models/OperationalControls';

import styles from './FrameworkLimits.module.css';

export interface FrameworkLimitsProps {
    limitResponseList: LimitResponse[];
    alertsLimitsApi: AlertsLimitsApi;
    getFrameworkLimits: () => void;
    detailedControlFrameworkResponse: DetailedControlFrameworkResponse;
}

export enum Modals {
    CreateEffectivenessLimitModal,
    DeleteLimitModal,
    ModifyEffectivenessLimitModal,
    None,
}

const FrameworkLimits = (props: FrameworkLimitsProps) => {
    const [displayedModal, setDisplayedModal] = useState(Modals.None);
    const [deleteLimitId, setDeleteLimitId] = useState<string>();
    const [deleteLimitTitle, setDeleteLimitTitle] = useState<string>();
    const [modifyEffectivenessLimit, setModifyEffectivenessLimit] = useState<EffectivenessLimit>();

    const getFrameworkLimitsDropdownButton = (): JSX.Element | null => {
        const dropdownButtonProps: PrimaryAndSecondaryDropdownButtonProps = {
            dropdownItems: [],
        };

        if (props.limitResponseList.length === 0) {
            dropdownButtonProps.dropdownItems.push({
                _type: DropdownItemClickType.ONCLICK,
                text: 'Create Effectiveness Limit',
                onClick: () => displayModal(Modals.CreateEffectivenessLimitModal),
            });
        }

        if (dropdownButtonProps.dropdownItems.length > 0) {
            return <PrimaryDropdownButton {...dropdownButtonProps}>ADD LIMITS</PrimaryDropdownButton>;
        } else {
            return null;
        }
    };

    const displayModal = (modal: Modals): void => {
        setDisplayedModal(modal);
    };

    const displayDeleteLimitModal = (deleteLimitId: string, deleteLimitTitle: string): void => {
        setDeleteLimitId(deleteLimitId);
        setDeleteLimitTitle(deleteLimitTitle);
        setDisplayedModal(Modals.DeleteLimitModal);
    };

    const displayModifyLimitModal = (modifyLimit: Limit): void => {
        setModifyEffectivenessLimit(modifyLimit as EffectivenessLimit);
        setDisplayedModal(Modals.ModifyEffectivenessLimitModal);
    };

    const createEffectivenessLimitModalProps: CreateEffectivenessLimitModalProps = {
        hideModal: () => displayModal(Modals.None),
        alertsLimitsApi: props.alertsLimitsApi,
        onLimitCreated: props.getFrameworkLimits,
        controlIdentifier: props.detailedControlFrameworkResponse.control_framework,
    };
    const limitsTableProps: LimitsTablesProps = {
        limitResponseList: props.limitResponseList,
        displayDeleteLimitModal: displayDeleteLimitModal,
        displayModifyLimitModal: displayModifyLimitModal,
        alertsLimitsApi: props.alertsLimitsApi,
    };
    const deleteLimitModalProps: DeleteLimitModalProps = {
        alertsLimitsApi: props.alertsLimitsApi,
        deleteLimitId: deleteLimitId!,
        deleteLimitTitle: deleteLimitTitle!,
        hideModal: () => displayModal(Modals.None),
        updateLimitListing: props.getFrameworkLimits,
    };
    const modifyLimitModalProps: ModifyEffectivenessLimitModalProps = {
        alertsLimitsApi: props.alertsLimitsApi,
        hideModal: () => displayModal(Modals.None),
        limit: modifyEffectivenessLimit!,
        onLimitModified: props.getFrameworkLimits,
    };
    return (
        <div className={styles.container}>
            {displayedModal === Modals.CreateEffectivenessLimitModal && <CreateEffectivenessLimitModal {...createEffectivenessLimitModalProps} />}
            {displayedModal === Modals.ModifyEffectivenessLimitModal && <ModifyEffectivenessLimitModal {...modifyLimitModalProps} />}
            {displayedModal === Modals.DeleteLimitModal && <DeleteLimitModal {...deleteLimitModalProps} />}
            <div className={styles.header}>{getFrameworkLimitsDropdownButton()}</div>
            <LimitsTables {...limitsTableProps} />
        </div>
    );
};

export default FrameworkLimits;
