import { faCalendar, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Card } from 'react-bootstrap';

import { Link } from 'Components/Buttons/Buttons';
import OverflowMenu, { OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import CircleIndicator from 'Components/Indicator/CircleIndicator';
import Text from 'Components/Text/Text';
import { DASHBOARDS, SERVICES, TPRM, VENDORS } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { NumberAsRiskScore, getRiskRatingVariantColor, numberAsRiskRatingString } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import styles from './ServiceCard.module.css';
import { ServiceDisplayData } from '../../ServiceListing';

export interface ServiceCardProps {
    serviceCard: ServiceDisplayData;
    selectedDeleteService: (service: ServiceDisplayData) => void;
    selectedModifyService: (service: ServiceDisplayData) => void;
    selectedServiceAssessmentDueDateService: (service: ServiceDisplayData) => void;
    users: UserResponse[];
}

export default class ServiceCard extends Component<ServiceCardProps> {
    render(): JSX.Element {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [
                {
                    text: 'Update Service',
                    onClickAction: () => this.props.selectedModifyService(this.props.serviceCard),
                    icon: faEdit,
                },
                {
                    text: 'Set Assessment Due Date',
                    onClickAction: () => this.props.selectedServiceAssessmentDueDateService(this.props.serviceCard),
                    icon: faCalendar,
                },
                {
                    text: 'Delete Service',
                    onClickAction: () => this.props.selectedDeleteService(this.props.serviceCard),
                    icon: faTrash,
                },
            ],
            accessibilityTitle: `${this.props.serviceCard.vendorName} - ${this.props.serviceCard.serviceName} Menu`,
        };

        return (
            <Card className={styles.vendorCard}>
                <div className={styles.cardHeader}>
                    <div>
                        <Text variant="Header2" noStyles>
                            {this.props.serviceCard.serviceName}
                        </Text>

                        <Text variant="Text3" color="darkGray">
                            {this.props.serviceCard.vendorName}
                        </Text>
                    </div>
                    <div className={styles.overflowMenu}>
                        <OverflowMenu {...overflowMenuProps} />
                    </div>
                </div>
                <div className={styles.horizontalLine} />
                <div className={styles.cardSection}>
                    <div className={styles.row}>
                        <div>
                            <Text variant="Text4" color="darkGray" toUppercase>
                                Inherent Risk
                            </Text>
                            <div className={styles.riskRatingContainer}>
                                <CircleIndicator variant={getRiskRatingVariantColor(NumberAsRiskScore(this.props.serviceCard.riskRating))} />
                                <div className={styles.riskText}>
                                    <Text variant="Text3">{numberAsRiskRatingString(this.props.serviceCard.riskRating)}</Text>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Text variant="Text4" color="darkGray" toUppercase>
                                Residual Risk
                            </Text>
                            <div className={styles.riskRatingContainer}>
                                <CircleIndicator variant={getRiskRatingVariantColor(NumberAsRiskScore(this.props.serviceCard.residualRisk))} />
                                <div className={styles.riskText}>
                                    <Text variant="Text3">{numberAsRiskRatingString(this.props.serviceCard.residualRisk)}</Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>
                            <Text variant="Text4" color="darkGray" toUppercase>
                                Service Manager
                            </Text>
                            <div>
                                <Text variant="Text3">{getUserNameFromSubject(this.props.serviceCard.vendorManagerId, this.props.users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                            </div>
                        </div>
                        <div>
                            <Text variant="Text4" color="darkGray" toUppercase>
                                Date Created
                            </Text>
                            <div>
                                <Text variant="Text3">{iso8601ToUsDateShort(this.props.serviceCard.dateCreated)}</Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>
                            <Text variant="Text4" color="darkGray" toUppercase>
                                Assessment Due Date
                            </Text>
                            <div>
                                <Text variant="Text3">{this.props.serviceCard.assessmentDueDate ? iso8601ToUsDateShort(this.props.serviceCard.assessmentDueDate) : '-'}</Text>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.horizontalLine} />
                <div className={styles.footerBlock}>
                    <Link size="lg" to={`/${TPRM}/${VENDORS}/${this.props.serviceCard.vendorId}/${SERVICES}/${this.props.serviceCard.serviceID}/${DASHBOARDS}`}>
                        MANAGE SERVICE
                    </Link>
                </div>
            </Card>
        );
    }
}
