import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { TPRM } from 'Config/Paths';
import { useControlMappingItems } from 'Hooks/ControlMapping';
import { ControlIssueResponse, IssuesExceptionsModule, VendorIssueResponse } from 'Models/Issues';
import { VendorResponse, VendorResponseWithServices } from 'Models/TPRM';

import { ManageIssueForm, ManageIssueFormProps } from '../Components/ManageIssueForm/ManageIssueForm';

export interface ManageIssueProps {
    issuesApi: IssuesApi;
    controlsApi: ControlsApi;
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
}

/**
 * Ultimately renders a page that can be used to create, update, or delete an issue in the Operational Controls module or in the TPRM module.
 * This component is responsible for examining the URL to determine the type of issue being managed. It determines which single child needs to be rendered; the child is responsible for fetching data specific to the user's desired action and for passing that data into `ManageIssueForm`.
 *
 * This structure/flow is used for two main reasons:
 * 1. To avoid fetching data that is not needed for the user's desired action. (For example, if the user is managing a TPRM issue, we do not want to use `useControlMappingItems`.)
 * 2. To improve readability. Without these intermediary components, it becomes very difficult to understand which data is supposed to be fetched and when it will be available.
 */
export const ManageIssue = (props: ManageIssueProps) => {
    const { issueId } = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const impactedEntityId = query.get('impactedEntityId') ?? undefined;
    const issueType = location.pathname.startsWith(`/${TPRM}`) ? IssuesExceptionsModule.TPRM : IssuesExceptionsModule.CONTROLS;
    const isClosingIssue = query.get('closing') === String(true);

    switch (issueType) {
        case IssuesExceptionsModule.CONTROLS:
            if (issueId) {
                return <EditControlIssue {...props} issueId={issueId} isClosingIssue={isClosingIssue} />;
            } else {
                return <CreateControlIssue {...props} preselectedControlId={impactedEntityId} />;
            }
        case IssuesExceptionsModule.TPRM:
            if (issueId) {
                return <EditVendorIssue {...props} issueId={issueId} isClosingIssue={isClosingIssue} />;
            } else {
                return <CreateVendorIssue {...props} preselectedVendorId={impactedEntityId} />;
            }
    }
};

/**
 * Renders `ManageIssueForm` for the use case of creating an Operational Controls issue, after fetching all controls to which the issue can be mapped.
 */
const CreateControlIssue = (props: ManageIssueProps & { preselectedControlId?: string }) => {
    const [controlMappingItems, controlMappingItemsError] = useControlMappingItems(props.controlsApi);

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    } else if (controlMappingItems === undefined) {
        return <Placeholder />;
    } else {
        const manageIssueFormProps: ManageIssueFormProps = {
            type: 'creatingControlIssue',
            issuesApi: props.issuesApi,
            documentApi: props.documentApi,
            controls: controlMappingItems,
            preselectedControlId: props.preselectedControlId,
        };
        return <ManageIssueForm {...manageIssueFormProps} />;
    }
};

/**
 * Renders `ManageIssueForm` for the use case of managing an Operational Controls issue, after fetching details for the issue and all controls to which the issue can be mapped.
 */
const EditControlIssue = (props: ManageIssueProps & { issueId: string; isClosingIssue: boolean }) => {
    const [controlMappingItems, controlMappingItemsError] = useControlMappingItems(props.controlsApi);
    const [issue, setIssue] = useState<ControlIssueResponse>();
    const [requestError, setRequestError] = useState<Error>();

    useEffect(() => {
        const getIssue = async (): Promise<void> => {
            try {
                const response = await props.issuesApi.getIssue(props.issueId);
                setIssue(response.data as ControlIssueResponse);
            } catch (error) {
                setRequestError(error);
            }
        };

        getIssue();
    }, [props.issuesApi, props.issueId]);

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    } else if (requestError) {
        return <Text>{requestError.message}</Text>;
    } else if (controlMappingItems === undefined || issue === undefined) {
        return <Placeholder />;
    } else {
        const manageIssueFormProps: ManageIssueFormProps = {
            type: 'editingControlIssue',
            issuesApi: props.issuesApi,
            documentApi: props.documentApi,
            controls: controlMappingItems,
            issue: issue,
            isClosingIssue: props.isClosingIssue,
        };
        return <ManageIssueForm {...manageIssueFormProps} />;
    }
};

/**
 * Renders `ManageIssueForm` for the use case of creating a TPRM issue, after fetching all vendors to which the issue can be mapped.
 */
const CreateVendorIssue = (props: ManageIssueProps & { preselectedVendorId?: string }) => {
    const [vendors, setVendors] = useState<VendorResponseWithServices[]>();
    const [requestError, setRequestError] = useState<Error>();

    useEffect(() => {
        const getVendors = async (): Promise<void> => {
            try {
                const response = await props.tprmApi.getVendors();
                setVendors(response.data);
            } catch (error) {
                setRequestError(error);
            }
        };

        getVendors();
    }, [props.tprmApi]);

    if (requestError) {
        return <Text>{requestError.message}</Text>;
    } else if (vendors === undefined) {
        return <Placeholder />;
    } else {
        const manageIssueFormProps: ManageIssueFormProps = {
            type: 'creatingVendorIssue',
            issuesApi: props.issuesApi,
            documentApi: props.documentApi,
            vendors: vendors,
            preselectedVendorId: props.preselectedVendorId,
        };
        return <ManageIssueForm {...manageIssueFormProps} />;
    }
};

/**
 * Renders `ManageIssueForm` for the use case of managing a TPRM issue, after fetching details for the issue and its impacted vendor.
 */
const EditVendorIssue = (props: ManageIssueProps & { issueId: string; isClosingIssue: boolean }) => {
    const [issue, setIssue] = useState<VendorIssueResponse>();
    const [vendor, setVendor] = useState<VendorResponse>();
    const [requestError, setRequestError] = useState<Error>();

    useEffect(() => {
        const getIssue = async (): Promise<void> => {
            try {
                const response = await props.issuesApi.getIssue(props.issueId);
                setIssue(response.data as VendorIssueResponse);
            } catch (error) {
                setRequestError(error);
            }
        };

        getIssue();
    }, [props.issuesApi, props.issueId]);

    useEffect(() => {
        const getVendor = async (vendorId: string): Promise<void> => {
            try {
                const response = await props.tprmApi.getVendorDetails(vendorId);
                setVendor(response.data);
            } catch (error) {
                setRequestError(error);
            }
        };

        if (issue) {
            getVendor(issue.impacted_vendor);
        }
    }, [props.tprmApi, issue]);

    if (requestError) {
        return <Text>{requestError.message}</Text>;
    } else if (vendor === undefined || issue === undefined) {
        return <Placeholder />;
    } else {
        const manageIssueFormProps: ManageIssueFormProps = {
            type: 'editingVendorIssue',
            issuesApi: props.issuesApi,
            documentApi: props.documentApi,
            issue: issue,
            vendor: vendor,
            isClosingIssue: props.isClosingIssue,
        };
        return <ManageIssueForm {...manageIssueFormProps} />;
    }
};
