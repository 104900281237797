import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare, faChevronCircleRight, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as BootstrapButton } from 'react-bootstrap';
import { Link as ReactRouterLink, To } from 'react-router-dom';

import Text from 'Components/Text/Text';

import styles from './Button.module.css';

interface LinkStyledAsButtonProps {
    /**
     * See the `Link` documentation.
     */
    variant: 'primaryButton' | 'secondaryButton';

    /**
     * The icon that is paired with the link text (i.e. the `children`).
     */
    fontAwesomeImage: IconProp;

    /**
     * The location to be navigated to when the link is clicked.
     */
    to: To;

    /**
     * State to pass to the target location via React Router.
     */
    state?: Record<string, any>;

    /**
     * If `true`, the user will be unable to use the link; it will instead be rendered as a disabled button. See the `Link` documentation for more details.
     */
    disabled?: boolean;

    /**
     * The link's text.
     */
    children: string;
}

export type LinkSize = 'sm' | 'lg';

interface LinkStyledAsLinkProps {
    /**
     * See the `Link` documentation.
     */
    variant?: 'link';

    /**
     * The size of the link. `sm` corresponds with our Header4; `lg` corresponds with our Text2.
     */
    size: LinkSize;

    /**
     * The location to be navigated to when the link is clicked.
     */
    to: To;

    /**
     * State to pass to the target location via React Router.
     */
    state?: Record<string, any>;

    /**
     * If `true`, the browser will automatically open a new tab when the link is clicked. An "opens in new tab" icon will be paired with the link's text (i.e. the `children`).
     */
    openInNewTab?: boolean;

    /**
     * The link's text.
     */
    children: string;
}

export type LinkProps = LinkStyledAsButtonProps | LinkStyledAsLinkProps;

/**
 * Renders a hyperlink to facilitate navigating the user to a new location upon user click. If a non-navigational action is needed, use `Button` instead.
 *
 * The link is styled based on the specified `variant`:
 * * "primaryButton" variant: The link looks like the "primary" variant of the `Button` component. Use this when the primary action on a page navigates the user to a new location.
 * * "secondaryButton" variant: The link looks like the "secondary" variant of the `Button` component. Use this when a secondary action on a page navigates the user to a new location.
 * * "link" variant: The default. If no `variant` is supplied, this is the variant used. The link looks like blue text. Use this for text on a page that navigates the user to a new location.
 *
 * NOTE: In the rare case that a `Link` is `disabled`, it actually renders a button, rather than an anchor; HTML/CSS does not support the concept of a disabled link. Nevertheless, sometimes it's necessary to change how a `Link` is rendered--for example, a link to the details of an entity should be disabled after the entity has been deleted.
 *
 * TODO: Update the "sm" `Link` variant. It should not be rendered as a header (unless it's actually a header) and it should not be automatically capitalized.
 */
export const Link = (props: LinkProps) => {
    switch (props.variant) {
        case 'primaryButton':
        case 'secondaryButton':
            const className = (() => {
                switch (props.variant) {
                    case 'primaryButton':
                        return props.disabled ? styles.primary : styles.primaryAsLink;
                    case 'secondaryButton':
                        return props.disabled ? styles.secondary : styles.secondaryAsLink;
                }
            })();

            if (props.disabled) {
                return (
                    <button type="button" className={className} disabled>
                        <div className={styles.icon}>
                            <FontAwesomeIcon icon={props.fontAwesomeImage} />
                        </div>
                        {props.children}
                    </button>
                );
            }

            return (
                <div className={className}>
                    <ReactRouterLink className={styles.linkStyledAsButton} to={props.to} state={props.state}>
                        <div className={styles.icon}>{<FontAwesomeIcon icon={props.fontAwesomeImage} />}</div>
                        {props.children}
                    </ReactRouterLink>
                </div>
            );
        case 'link':
        case undefined:
            return (
                <ReactRouterLink className={styles.buttonLink} to={props.to} state={props.state} target={props.openInNewTab ? '_blank' : undefined} rel={props.openInNewTab ? 'noopener noreferrer' : undefined}>
                    <Text color="lightBlue" noStyles={true} variant={props.size === 'sm' ? 'Header4' : 'Text2'}>
                        {props.children}
                    </Text>
                    {props.openInNewTab && <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={props.size === 'sm' ? styles.newTabIconSmall : styles.newTabIconLarge} />}
                </ReactRouterLink>
            );
    }
};

interface ButtonBaseProps {
    /**
     * See the `Button` documentation.
     */
    variant: 'primary' | 'secondary' | 'danger';

    /**
     * The function that will be called when the user clicks the button.
     */
    onClick: () => void;

    /**
     * The icon that is paired with the button text (i.e. the `children`).
     */
    fontAwesomeImage: IconProp;

    /**
     * If `true`, the user cannot interact with the button.
     */
    disabled?: boolean;

    /**
     * The button's text.
     */
    children: string;
}

type ButtonLoadingProps =
    | {
          isLoading?: never;
          loadingText?: never;
      }
    | {
          /**
           * Whether the action triggered by the click of the button is in progress. If `true`, the button displays its `loadingText` and an animated loading icon.
           */
          isLoading: boolean;

          /**
           * The text of the button while its action is in progress (for example, "Saving..." or "Submitting...").
           */
          loadingText: string;
      };

type GenericButtonProps = ButtonBaseProps & ButtonLoadingProps;

type SubmitButtonProps = {
    /**
     * See the `Button` documentation.
     */
    variant: 'submit';

    /**
     * If `true`, the user cannot interact with the button.
     */
    disabled?: boolean;

    /**
     * The ID of a form element to associate the button with. Use this if the button submits a form that is not an ancestor of the button in the DOM.
     */
    form?: string;

    /**
     * The button's text.
     */
    children: string;
} & ButtonLoadingProps;

interface ButtonStyledAsLinkProps {
    /**
     * See the `Button` documentation.
     */
    variant: 'linkText';

    /**
     * The size of the button. `sm` corresponds with our Header4; `lg` corresponds with our Text2.
     */
    size: 'sm' | 'lg';

    /**
     * The function that will be called when the user clicks the button.
     */
    onClick: () => void;

    /**
     * The text (or rarely, element) that makes up the content of the button.
     */
    children: string | JSX.Element;
}

export type ButtonProps = GenericButtonProps | SubmitButtonProps | ButtonStyledAsLinkProps;

/**
 * Renders a button that runs an action upon user click. If the action is navigational, use `Link` instead.
 *
 * The button is styled based on the specified `variant`:
 * * "primary" variant: The button is styled with primary theme colors. Use this when the primary action on a page is non-navigational.
 * * "secondary" variant: The button is styled with secondary theme colors. Use this when a secondary action on a page is non-navigational.
 * * "danger" variant: The button is styled with warning colors. Use this when the button facilitates a destructive action (for example, deleting some entity).
 * * "submit" variant: The button is styled the same as the "primary" variant button. Use this when the button facilitates the submission of a form.
 * * "linkText" variant: The button is styled like the default variant of the `Link` component (i.e. blue text). Use this when text should look like a hyperlink, but behave like a button.
 */
export const Button = (props: ButtonProps) => {
    switch (props.variant) {
        case 'linkText':
            return (
                <BootstrapButton onClick={props.onClick} className={styles.buttonLink}>
                    <Text color="lightBlue" noStyles={true} variant={props.size === 'sm' ? 'Header4' : 'Text2'}>
                        {props.children}
                    </Text>
                </BootstrapButton>
            );
        case 'primary':
        case 'secondary':
        case 'danger':
        case 'submit':
            const type = props.variant === 'submit' ? 'submit' : 'button';
            const form = props.variant === 'submit' ? props.form : undefined;

            const className = (() => {
                switch (props.variant) {
                    case 'primary':
                    case 'submit':
                        return styles.primary;
                    case 'secondary':
                        return styles.secondary;
                    case 'danger':
                        return styles.danger;
                }
            })();

            const onClick = (() => {
                switch (props.variant) {
                    case 'primary':
                    case 'secondary':
                    case 'danger':
                        return props.onClick;
                    case 'submit':
                        return undefined;
                }
            })();

            const fontAwesomeImage = (() => {
                switch (props.variant) {
                    case 'primary':
                    case 'secondary':
                    case 'danger':
                        return props.fontAwesomeImage;
                    case 'submit':
                        return faChevronCircleRight;
                }
            })();

            return (
                <button type={type} form={form} className={props.isLoading ? `${className} ${styles.isLoading}` : className} onClick={onClick} disabled={props.disabled || props.isLoading}>
                    <div className={styles.icon}>{props.isLoading ? <FontAwesomeIcon icon={faSyncAlt} /> : <FontAwesomeIcon icon={fontAwesomeImage} />}</div>
                    {!props.isLoading ? props.children : props.loadingText}
                </button>
            );
    }
};
