import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { QuestionConfiguration, QuestionType, UpdateControlConfigurationRequest, identifierMappedToRequest } from 'Models/TPRM';
import EditableQuestionOptions from 'Pages/TPRM/Components/EditableQuestionOptions/EditableQuestionOptions';

import { QuestionnaireConfigurationModal, SelectedQuestionWrapper } from '../DueDiligenceQuestionnaireConfiguration';
import styles from '../DueDiligenceQuestionnaireConfiguration.module.css';

export interface UpdateQuestionModalProps extends QuestionnaireConfigurationModal {
    selectedQuestionWrapper: SelectedQuestionWrapper;
}

interface FormState {
    isLoading: boolean;
    successMessage?: string;
    failureMessage?: string;
    controlToUpdate?: UpdateControlConfigurationRequest;
    complete: boolean;
}

const UpdateQuestionModal = (props: UpdateQuestionModalProps) => {
    const [formState, setFormState] = useState<FormState>({ isLoading: false, complete: false });
    const [formFieldsState, setFormFieldsState] = useState<Partial<QuestionConfiguration>>({ text: props.selectedQuestionWrapper.controlQuestions.get(props.selectedQuestionWrapper.selectedQuestionIndex)?.text, _type: props.selectedQuestionWrapper.controlQuestions.get(props.selectedQuestionWrapper.selectedQuestionIndex)?._type, options: props.selectedQuestionWrapper.controlQuestions.get(props.selectedQuestionWrapper.selectedQuestionIndex)?.options });
    const [isValid, setIsValid] = useState<boolean>();
    const [newOptionText, setNewOptionText] = useState('');

    useEffect(() => {
        if (formFieldsState?.text) {
            if ((formFieldsState._type === QuestionType.SINGLE_SELECT || formFieldsState._type === QuestionType.MULTIPLE_SELECT) && (!formFieldsState.options || formFieldsState.options.length < 2)) {
                setIsValid(false);
                return;
            }
            setIsValid(true);
            return;
        }
        setIsValid(false);
    }, [formFieldsState]);

    useEffect(() => {
        // Questions are added by appending to the existing list of questions on a control
        const updateControl = async (request: UpdateControlConfigurationRequest) => {
            setFormState({ isLoading: true, complete: false });
            try {
                await props.tprmApi.updateConfiguration(new Map([identifierMappedToRequest(request, props.selectedQuestionWrapper.framework, props.selectedQuestionWrapper.groupId, props.selectedQuestionWrapper.controlId)]));
                setFormState({ isLoading: false, successMessage: 'Question updated.', complete: false });
                props.onModalActionComplete();
            } catch (error) {
                setFormState({ isLoading: false, failureMessage: error.message, complete: false });
            }
        };

        if (formState.controlToUpdate && isValid) {
            updateControl(formState.controlToUpdate);
        }
    }, [props.tprmApi, formState.controlToUpdate, isValid, props.selectedQuestionWrapper, props]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (formFieldsState?.text) {
            const controlToUpdate: UpdateControlConfigurationRequest = {
                questions: [
                    ...Array.from(props.selectedQuestionWrapper.controlQuestions.values()).map((question, index) => {
                        if (index === props.selectedQuestionWrapper.selectedQuestionIndex) {
                            return {
                                _type: question._type,
                                text: formFieldsState.text!,
                                mapped_risk_ratings: question.riskRatings,
                                options: formFieldsState.options,
                            };
                        }
                        return {
                            _type: question._type,
                            text: question.text,
                            mapped_risk_ratings: question.riskRatings,
                            options: question.options,
                        };
                    }),
                ],
            };
            setFormState({ isLoading: false, controlToUpdate: controlToUpdate, complete: false });
        }
    };

    const addNewOption = (): void => {
        const multipleChoiceItems = formFieldsState.options ? cloneDeep(formFieldsState.options) : [];
        if (newOptionText) {
            multipleChoiceItems.push(newOptionText);
        }
        setFormFieldsState({ ...formFieldsState, options: multipleChoiceItems });
        setNewOptionText('');
    };

    const removeOption = (itemToRemove: string): void => {
        const multipleChoiceItems = cloneDeep(formFieldsState.options);
        if (multipleChoiceItems) {
            const index = multipleChoiceItems.indexOf(itemToRemove);
            multipleChoiceItems.splice(index, 1);
        }
        setFormFieldsState({ ...formFieldsState, options: multipleChoiceItems });
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Update Question Configuration" />
                    <div className={styles.marginTopBottom10}>
                        <FormFieldText value={formFieldsState?.text} formFieldId="text" formFieldLabel="Text" required handleChange={handleChange} />
                    </div>
                    {(formFieldsState._type === QuestionType.SINGLE_SELECT || formFieldsState._type === QuestionType.MULTIPLE_SELECT) && <EditableQuestionOptions options={formFieldsState.options} newOptionText={newOptionText} newOptionTextChanged={(text: string) => setNewOptionText(text)} removeOption={removeOption} addNewOption={addNewOption} />}
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={formState.isLoading || !isValid || formState.complete} isLoading={formState.isLoading} loadingText="Updating...">
                            UPDATE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateQuestionModal;
