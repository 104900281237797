import { Component } from 'react';

import CircleIndicator from 'Components/Indicator/CircleIndicator';
import Text from 'Components/Text/Text';
import { NumberAsRiskScore, getRiskRatingVariantColor, numberAsRiskRatingString } from 'Models/TPRM';

import styles from './TableRiskStatusBar.module.css';

export interface TableRiskStatusBarProps {
    risk: number;
}

export default class TableRiskStatusBar extends Component<TableRiskStatusBarProps> {
    render(): JSX.Element {
        return (
            <div className={styles.riskContainer}>
                <CircleIndicator variant={getRiskRatingVariantColor(NumberAsRiskScore(this.props.risk))} />
                <div className={styles.riskTextContainer}>
                    <Text noStyles={true}>{numberAsRiskRatingString(this.props.risk)}</Text>
                </div>
            </div>
        );
    }
}
