import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import { Link } from 'Components/Buttons/Buttons';
import PageContent from 'Components/Containers/PageContent/PageContent';
import GovernanceLibraryTable, { GovernanceLibraryTableProps } from 'Components/Governance/GovernanceLibraryTable/GovernanceLibraryTable';
import Text from 'Components/Text/Text';
import { CREATE, GOVERNANCE } from 'Config/Paths';
import { GovernanceVersion, TextBasedGovernanceVersion } from 'Models/Governance';
import { UserResponse } from 'Models/User';

import styles from '../GovernanceLibrary.module.css';

export interface ActiveGovernanceTabProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    governanceItems: GovernanceVersion[];
    users: UserResponse[];
    displayGovernanceTextModal: (governance: TextBasedGovernanceVersion) => void;
    displayGovernanceWithAssociatedControlsModal: (governanceWithAssociatedControls: GovernanceVersion) => void;
    openExternalUrl: (url: string) => void;
}

const ActiveGovernanceTab = (props: ActiveGovernanceTabProps): JSX.Element => {
    const governanceListingTableProps: GovernanceLibraryTableProps = {
        governanceApi: props.governanceApi,
        documentApi: props.documentApi,
        users: props.users,
        governanceItems: props.governanceItems,
        openExternalUrl: props.openExternalUrl,
        governanceItemsAreManageable: true,
        displayGovernanceTextModal: props.displayGovernanceTextModal,
        displayGovernanceMappedControlsModal: props.displayGovernanceWithAssociatedControlsModal,
    };

    return (
        <PageContent>
            <div className={styles.header}>
                <div className={styles.pageSectionHeaderText}>
                    <Text variant="Header2">Policies, Standards, and Procedures</Text>
                </div>
                <Link variant="primaryButton" to={`/${GOVERNANCE}/${CREATE}`} fontAwesomeImage={faPlus}>
                    Add New
                </Link>
            </div>
            <GovernanceLibraryTable {...governanceListingTableProps} />
        </PageContent>
    );
};

export default ActiveGovernanceTab;
