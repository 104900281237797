import { Component } from 'react';

import { ColorTheme, Filter } from '../../../Models/Types/GlobalType';
import { UserResponse } from '../../../Models/User';
import FormFieldUserMultiSelect from '../../FormField/FormFieldUserSelect/FormFieldUserMultiSelect';

export interface UserFilterProps {
    users: UserResponse[];
    filterLabel: string;
    tooltip?: string;
    onUsersSelected: (filter: Filter) => void;
    userFilterId: string;
    formFieldId: string;

    /**
     * The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
     */
    colorTheme?: ColorTheme;
}

export default class UserFilter extends Component<UserFilterProps> {
    handleSelectUserChange = (users: UserResponse[] | undefined, _: string): void => {
        const userFilterList: string[] = [];
        if (users) {
            users.forEach((user) => {
                userFilterList.push(user.cognito_subject);
            });
        }
        const userFilter: Filter = {
            key: this.props.userFilterId,
            value: userFilterList,
        };

        this.props.onUsersSelected(userFilter);
    };

    render(): JSX.Element {
        return <FormFieldUserMultiSelect colorTheme={this.props.colorTheme} users={this.props.users} onUsersSelected={this.handleSelectUserChange} formFieldId={this.props.formFieldId} formFieldLabel={this.props.filterLabel} tooltip={this.props.tooltip} />;
    }
}
