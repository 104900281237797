import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { DataGrid, GridColumn } from 'Components/DataGrid/DataGrid';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { UserNameFormat, getDepartmentFromSubject, getUserNameFromSubject } from 'Helpers/UserUtils';
import { TPRMVendorExportRow } from 'Models/Exports';
import { VendorContact } from 'Models/TPRM';

import styles from '../DataExports.module.css';

export interface VendorsExportProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const VendorsExport = (props: VendorsExportProps): JSX.Element => {
    const [vendors, setVendors] = useState<TPRMVendorExportRow[]>();
    const [error, setError] = useState<string>();
    const { users } = useUsers();

    useEffect(() => {
        const getVendorsExport = async (): Promise<void> => {
            try {
                const vendorResponse = await props.exportsApi.getVendorsExport();
                setVendors(vendorResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getVendorsExport();
    }, [props.exportsApi]);

    const columns: GridColumn<TPRMVendorExportRow>[] = [
        { field: 'name', headerName: 'Vendor Name', width: 300 },
        { field: 'website', headerName: 'Website', width: 300 },
        { field: 'ein', headerName: 'Employer ID number', width: 300 },
        { field: 'country', headerName: 'Country/Region', width: 300 },
        { field: 'address_line_1', headerName: 'Address line 1', width: 300 },
        { field: 'address_line_2', headerName: 'Address line 2', width: 300 },
        { field: 'city', headerName: 'City', width: 300 },
        { field: 'state', headerName: 'State/territory', width: 300 },
        { field: 'zip_code', headerName: 'Zip Code', width: 300 },
        {
            field: 'vendor_contacts',
            headerName: 'Vendor Contacts',
            width: 300,
            valueGetter: (value) =>
                value
                    // Only show the name for each contact. Omit their email address and phone number.
                    .filter((vendorContact: VendorContact) => vendorContact.name !== undefined)
                    .sort((vendorContactA: VendorContact, vendorContactB: VendorContact) => (vendorContactA?.name || '').localeCompare(vendorContactB?.name || ''))
                    .map((vendorContact: VendorContact) => vendorContact.name)
                    .join(', '),
        },
        { field: 'vendor_manager_department', headerName: 'Vendor Manager Department', width: 300, valueGetter: (_value, row) => getDepartmentFromSubject(row.vendor_manager_user_id, users) },
        { field: 'vendor_manager_user_id', headerName: 'Vendor Manager', width: 300, valueGetter: (value) => getUserNameFromSubject(value, users, UserNameFormat.FIRST_SPACE_LAST) },
    ];

    if (vendors) {
        return (
            <>
                <PageBackground color="grey">
                    <PageContent>
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                            <BreadcrumbText>Vendors</BreadcrumbText>
                        </Breadcrumb>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1">Vendors Export</Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <DataGrid columns={columns} getRowId={(vendor: TPRMVendorExportRow) => vendor.id} rows={vendors} title="Vendors Export Preview" fileName="Vendors Export" disableVirtualization={props.disableVirtualization} />
                    </PageContent>
                </PageBackground>
            </>
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
