import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { Button, Link } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { CONFIGURATION, FOLDERS, TPRM, VENDORS, VERSIONS } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { UploadedFile } from 'Models/Files';
import { FolderAllVersions, VendorResponse } from 'Models/TPRM';

import DeleteFolderModal, { DeleteFolderModalProps } from './DeleteFolderModal/DeleteFolderModal';
import styles from './FolderDetails.module.css';

export interface FolderDetailsProps {
    tprmApi: TPRMApi;
    documentApi: DocumentApi;
}

interface UrlParams {
    vendor_id: string;
    folder_id: string;
}

export const FolderDetails = (props: FolderDetailsProps) => {
    const { vendor_id, folder_id } = useParams<keyof UrlParams>() as UrlParams;
    const [errorMessage, setErrorMessage] = useState<string>();
    const [folderDeleted, setFolderDeleted] = useState<boolean>();
    const [showModal, setShowModal] = useState<boolean>();
    const [vendorDetails, setVendorDetails] = useState<VendorResponse>();
    const [versions, setVersions] = useState<FolderAllVersions>();
    const { users } = useUsers();

    useEffect(() => {
        const getAllVersions = async (): Promise<void> => {
            try {
                const response = await props.tprmApi.getAllFolderVersions(vendor_id, folder_id);
                setVersions(response.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getAllVersions();
    }, [props.tprmApi, vendor_id, folder_id]);

    useEffect(() => {
        const getVendorDetails = async (): Promise<void> => {
            try {
                const detailedVendorResponse = await props.tprmApi.getVendorDetails(vendor_id);
                setVendorDetails(detailedVendorResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getVendorDetails();
    }, [props.tprmApi, vendor_id]);

    const getModalProps = (): DeleteFolderModalProps => {
        return {
            tprmApi: props.tprmApi,
            folder: versions!.current_version,
            hideModal: () => setShowModal(false),
            vendorId: vendor_id,
            onFolderDeleted: () => setFolderDeleted(true),
        };
    };

    const getFileName = (file: UploadedFile, isTable: boolean): JSX.Element => {
        return (
            <div key={file.file_id} className={isTable ? styles.uploadedFileInTable : styles.uploadedFileInCurrentSection}>
                <UploadedFileAndState file={file} documentApi={props.documentApi} />;
            </div>
        );
    };

    if (vendorDetails && versions) {
        return (
            <>
                <PageBackground color="grey">
                    <PageContent>
                        {showModal && <DeleteFolderModal {...getModalProps()} />}
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${TPRM}/${VENDORS}`}>{vendorDetails.name}</BreadcrumbLink>
                            <BreadcrumbLink link={`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}`}>Vendor Documents</BreadcrumbLink>
                            <BreadcrumbText>{versions.current_version.name}</BreadcrumbText>
                        </Breadcrumb>
                        <div className={styles.headerContainer}>
                            <div className={styles.headerTextContainer}>
                                <Text variant="Header1" color="darkBlue" noStyles>
                                    {versions.current_version.name}
                                </Text>
                            </div>
                            <div className={styles.buttonContainer}>
                                <Link variant="primaryButton" to={`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}/${folder_id}/${VERSIONS}`} fontAwesomeImage={faAdd} disabled={folderDeleted}>
                                    add new version
                                </Link>
                                <Button variant="danger" onClick={() => setShowModal(true)} fontAwesomeImage={faTrash} disabled={folderDeleted}>
                                    delete
                                </Button>
                            </div>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageContent>
                    <PageCell>
                        <div className={styles.versionContainer}>
                            <Text variant="Header2" noStyles>
                                Current Folder Version
                            </Text>
                            <Link variant="primaryButton" to={`/${TPRM}/${VENDORS}/${vendor_id}/${FOLDERS}/${folder_id}/${CONFIGURATION}`} fontAwesomeImage={faEdit} disabled={folderDeleted}>
                                manage
                            </Link>
                        </div>
                        <hr />
                        <div className={styles.currentFolder}>
                            <div className={styles.column}>
                                <Text variant="Header4">documents</Text>
                                {versions.current_version.files?.map((file) => getFileName(file, false))}
                            </div>
                            <div className={styles.column}>
                                <Text variant="Header4" color="darkBlue">
                                    folder type
                                </Text>
                                <Text color="blue">{versions.current_version.type}</Text>
                            </div>
                            <div className={styles.column}>
                                <div className={styles.column}>
                                    <Text variant="Header4" color="darkBlue">
                                        last updated by
                                    </Text>
                                    <Text color="blue">{getUserNameFromSubject(versions.current_version.last_updated_by, users)}</Text>
                                </div>
                                <div className={styles.column}>
                                    <Text variant="Header4" color="darkBlue">
                                        last updated date
                                    </Text>
                                    <Text color="blue">{iso8601ToUsDateShort(versions.current_version.last_updated)}</Text>
                                </div>
                            </div>
                            <div className={styles.column}>
                                <div className={styles.column}>
                                    <Text variant="Header4" color="darkBlue">
                                        effective date
                                    </Text>
                                    {versions.current_version.effective_date ? <Text color="blue">{iso8601ToUsDateShort(versions.current_version.effective_date)}</Text> : <Text color="blue">N/A</Text>}
                                </div>
                                <div className={styles.column}>
                                    <Text variant="Header4" color="darkBlue">
                                        expiration date
                                    </Text>
                                    {versions.current_version.expiration_date ? <Text color="blue">{iso8601ToUsDateShort(versions.current_version.expiration_date)}</Text> : <Text color="blue">N/A</Text>}
                                </div>
                            </div>
                        </div>
                        <div className={styles.accordionColumnComments}>
                            <Text variant="Header4" color="darkBlue">
                                comments
                            </Text>
                            {versions.current_version.comments ? <Text color="blue">{versions.current_version.comments}</Text> : <Text color="blue">N/A</Text>}
                        </div>
                    </PageCell>
                </PageContent>
                <PageContent>
                    <PageCell>
                        <>
                            <ModalHeader text="Past Folder Versions" />
                            <div className={styles.accordionHeaderContainer}>
                                <div className={styles.accordionHeaderEffective}>
                                    <Text variant="Text3" noStyles>
                                        EFFECTIVE DATE
                                    </Text>
                                </div>
                                <div className={styles.accordionHeaderExpiration}>
                                    <Text variant="Text3" noStyles>
                                        EXPIRATION DATE
                                    </Text>
                                </div>
                                <div className={styles.accordionHeaderFiles}>
                                    <Text variant="Text3" noStyles>
                                        DOCUMENTS
                                    </Text>
                                </div>
                                <div className={styles.accordionHeaderLastUpdatedBy}>
                                    <Text variant="Text3" noStyles>
                                        LAST UPDATED BY
                                    </Text>
                                </div>
                                <div className={styles.accordionHeaderUpdated}>
                                    <Text variant="Text3" noStyles>
                                        LAST UPDATED DATE
                                    </Text>
                                </div>
                            </div>

                            {versions.past_versions.length > 0 &&
                                versions.past_versions.map((pastVersion, index) => {
                                    return (
                                        <Accordion key={index}>
                                            <div className={styles.accordionContainer}>
                                                <div className={styles.accordionColumnEffective}>
                                                    <AccordionToggle eventKey={pastVersion.id} ariaLabelSuffix={'Effective'} />
                                                    {pastVersion.effective_date ? (
                                                        <Text variant="Text3" noStyles>
                                                            {iso8601ToUsDateShort(pastVersion.effective_date)}
                                                        </Text>
                                                    ) : (
                                                        <Text>N/A</Text>
                                                    )}
                                                </div>
                                                <div className={styles.accordionColumnExpiration}>
                                                    {pastVersion.expiration_date ? (
                                                        <Text variant="Text3" noStyles>
                                                            {iso8601ToUsDateShort(pastVersion.expiration_date)}
                                                        </Text>
                                                    ) : (
                                                        <Text>N/A</Text>
                                                    )}
                                                </div>
                                                <div className={styles.accordionColumnFiles}>{pastVersion.files?.length === 0 ? <Text>N/A</Text> : pastVersion.files?.map((file) => getFileName(file, true))}</div>
                                                <div className={styles.accordionColumnLastUpdatedBy}>
                                                    <Text variant="Text3" noStyles>
                                                        {getUserNameFromSubject(pastVersion.last_updated_by, users)}
                                                    </Text>
                                                </div>
                                                <div className={styles.accordionColumnLastUpdatedDate}>
                                                    <Text variant="Text3" noStyles>
                                                        {iso8601ToUsDateShort(pastVersion.last_updated)}
                                                    </Text>
                                                </div>
                                            </div>
                                            <AccordionCollapse eventKey={pastVersion.id}>
                                                <div className={styles.accordionCollapseComments}>
                                                    <Text variant="Header4">Comments</Text>
                                                    <Text variant="Text3" noStyles>
                                                        {pastVersion.comments}
                                                    </Text>
                                                </div>
                                            </AccordionCollapse>
                                        </Accordion>
                                    );
                                })}
                        </>
                    </PageCell>
                </PageContent>
            </>
        );
    } else if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else {
        return <Placeholder />;
    }
};
