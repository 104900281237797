import { Component } from 'react';
import { FormLabel as BootstrapFormLabel } from 'react-bootstrap';

import { Colors } from 'Components/Colors';

import { VisualLabel } from '../../VisualLabel/VisualLabel';

interface FormLabelProps {
    color?: Colors;
    required?: boolean;
    hideRequiredSymbol?: boolean;
    tooltip?: JSX.Element;
    children: string;
}

export class FormLabel extends Component<FormLabelProps> {
    render(): JSX.Element {
        return (
            <BootstrapFormLabel>
                <VisualLabel color={this.props.color} required={this.props.required && !this.props.hideRequiredSymbol} tooltip={this.props.tooltip}>
                    {this.props.children}
                </VisualLabel>
            </BootstrapFormLabel>
        );
    }
}
