import { Component, createContext } from 'react';

/**
 * List of available user roles. If a new role is added to this, it likely needs to be added to Landing.tsx, which determines the homepage for each role.
 */
export enum Role {
    ADMIN = 'admin',
    CONTROL_USER = 'control user',
    REPORTING_USER = 'reporting user',
    RISK_MANAGER = 'risk manager',
    RISK_USER = 'risk user',
    THIRD_PARTY_USER = 'third party user',
}

/**
 * Compare a list of roles that a user has with another list of roles. Used to determine if a user should have access to something.
 * If simply wrapping a component for conditional rendering, use <RBACComponent>.
 * @param userRoles list of roles the user has.
 * @param authorizedRoles list of roles to compare the user's roles with.
 * @returns true if a user has at least one role in authorizedRoles, else false.
 */
export const userHasAuthorizedRole = (userRoles: Role[], authorizedRoles: Role[]): boolean => {
    return authorizedRoles.filter((role) => userRoles.includes(role)).length > 0;
};

export interface ContextProps {
    roles: Role[];
}

export const RBACContext = createContext<ContextProps | undefined>(undefined);

interface UserProviderProps {
    children: React.ReactNode;
    roles: Role[];
}

interface UserProviderState {
    roles: Role[];
}

export class RBACProvider extends Component<UserProviderProps, UserProviderState> {
    render(): JSX.Element {
        return <RBACContext.Provider value={{ roles: this.props.roles }}>{this.props.children}</RBACContext.Provider>;
    }
}
