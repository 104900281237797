/*
	AssessmentDetails.tsx -- Displays the history of an assessment.
*/

import { faClipboard, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { Component, Fragment } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { Role } from 'Components/Context/RBACContext';
import SortableTableHeader, { SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
import { undefinedComparator } from 'Helpers/Compare';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { AssessmentDetailsResponse, AssessmentHistoryResponse, AssessmentHistorySortFilterOptions, AssessmentRole, AssessmentState, OperationalControl, assessmentStateAsString, determineUserAssessmentRoles } from 'Models/OperationalControls';

import styles from './AssessmentDetails.module.css';

export interface AssessmentDetailsProps {
    detailedControlResponse: OperationalControl;
    assessmentDetailsResponse?: AssessmentDetailsResponse;
    assessmentDetailsError?: string;
    assessmentHistoryList?: AssessmentHistoryResponse[];
    assessmentLinkData: { to: string; state: Record<string, any> };
    authenticatedUserSubject?: string;
    authenticatedUserRoles: Role[];
}

interface AssessmentDetailsState {
    currentSort: string;
    currentSortDirection: SortDirection;
    userAssessmentRoles: Set<AssessmentRole>;
}

export class AssessmentDetails extends Component<AssessmentDetailsProps, AssessmentDetailsState> {
    constructor(props: AssessmentDetailsProps) {
        super(props);

        this.state = {
            currentSort: AssessmentHistorySortFilterOptions.TIMESTAMP,
            currentSortDirection: SortDirection.DESC,
            userAssessmentRoles: determineUserAssessmentRoles(this.props.authenticatedUserSubject, this.props.authenticatedUserRoles, this.props.detailedControlResponse.configuration.review_configuration?.owner_subject, this.props.detailedControlResponse.configuration.review_configuration?.reviewer_subject),
        };
    }

    assessmentHasStarted = (): boolean => {
        if (this.props.assessmentDetailsResponse?.assessment_state === AssessmentState.UNDER_REVIEW) {
            return true;
        }

        return this.props.assessmentDetailsResponse?.assessment_state === AssessmentState.IN_PROGRESS && ((this.props.assessmentDetailsResponse.owner_comments && this.props.assessmentDetailsResponse.owner_comments.length > 0) || this.props.assessmentDetailsResponse.assessment_effectiveness !== undefined);
    };

    assessmentButton = (): JSX.Element | null => {
        const assessmentConfigured = this.props.assessmentDetailsResponse !== undefined;
        const userCanSubmitForReview = this.state.userAssessmentRoles.has(AssessmentRole.ADMIN) || this.state.userAssessmentRoles.has(AssessmentRole.OWNER);
        const userCanApprove = this.state.userAssessmentRoles.has(AssessmentRole.ADMIN) || this.state.userAssessmentRoles.has(AssessmentRole.REVIEWER);

        if (!assessmentConfigured) {
            return null;
        }

        let buttonText = 'ASSESSMENT';

        if (!this.assessmentHasStarted()) {
            if (userCanSubmitForReview) {
                buttonText = 'BEGIN ASSESSMENT';
            }
            return (
                <Link variant="primaryButton" to={this.props.assessmentLinkData.to} state={this.props.assessmentLinkData.state} fontAwesomeImage={faClipboard}>
                    {buttonText}
                </Link>
            );
        }

        if (this.props.assessmentDetailsResponse?.assessment_state === AssessmentState.IN_PROGRESS) {
            return (
                <Link variant="primaryButton" to={this.props.assessmentLinkData.to} state={this.props.assessmentLinkData.state} fontAwesomeImage={faClipboard}>
                    ASSESSMENT
                </Link>
            );
        }

        if (userCanApprove) {
            buttonText = 'REVIEW ASSESSMENT';
        }

        return (
            <Link variant="primaryButton" to={this.props.assessmentLinkData.to} state={this.props.assessmentLinkData.state} fontAwesomeImage={faClipboardCheck}>
                {buttonText}
            </Link>
        );
    };

    sortEvidence = (): AssessmentHistoryResponse[] => {
        if (!this.props.assessmentHistoryList) {
            return [];
        }
        const copiedList = [...this.props.assessmentHistoryList];

        copiedList.sort((assessmentHistoryItemA, assessmentHistoryItemB) => {
            let propertyA;
            let propertyB;

            if (this.state.currentSort === AssessmentHistorySortFilterOptions.OWNER_COMMENTS) {
                propertyA = this.getOwnerCommentString(assessmentHistoryItemA);
                propertyB = this.getOwnerCommentString(assessmentHistoryItemB);
            } else if (this.state.currentSort === AssessmentHistorySortFilterOptions.REVIEWER_COMMENT) {
                propertyA = assessmentHistoryItemA[this.state.currentSort]?.text;
                propertyB = assessmentHistoryItemB[this.state.currentSort]?.text;
            } else {
                propertyA = assessmentHistoryItemA[this.state.currentSort];
                propertyB = assessmentHistoryItemB[this.state.currentSort];
            }

            return undefinedComparator(propertyA, propertyB, (propertyA, propertyB) => {
                const sortCompareResult = (propertyA as string).localeCompare(propertyB as string);

                return this.state.currentSortDirection === SortDirection.ASC ? sortCompareResult : -sortCompareResult;
            });
        });

        return copiedList;
    };

    setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        this.setState({ currentSort: newSort as AssessmentHistorySortFilterOptions, currentSortDirection: newSortDirection });
    };

    tableRow = (assessmentHistory: AssessmentHistoryResponse): JSX.Element => {
        return (
            <TableRow key={assessmentHistory.timestamp}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{iso8601ToUsDateLong(assessmentHistory.timestamp)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{assessmentStateAsString(assessmentHistory.assessment_state)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{this.getOwnerCommentString(assessmentHistory)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{assessmentHistory.reviewer_comment?.text !== undefined ? assessmentHistory.reviewer_comment.text : '-'}</Text>
                </TableCellDefaultText>
            </TableRow>
        );
    };

    getOwnerCommentString = (assessmentHistory: AssessmentHistoryResponse): string | undefined => {
        const comments = assessmentHistory.owner_comments;
        if (!comments || comments.length === 0) {
            return;
        }

        return comments[comments.length - 1].text;
    };

    render(): JSX.Element {
        const sortableTableProps: SortableTableHeaderProps = {
            headers: [
                { dataKey: AssessmentHistorySortFilterOptions.TIMESTAMP, label: 'TIME' },
                { dataKey: AssessmentHistorySortFilterOptions.ASSESSMENT_STATE, label: 'STATE' },
                { dataKey: AssessmentHistorySortFilterOptions.OWNER_COMMENTS, label: 'OWNER COMMENT' },
                { dataKey: AssessmentHistorySortFilterOptions.REVIEWER_COMMENT, label: 'REVIEWER COMMENT' },
            ],
            applySorting: this.setCurrentSort,
            currentSort: this.state.currentSort,
            currentSortDirection: this.state.currentSortDirection,
            tableIncludesOverflowMenu: false,
        };

        return (
            <Fragment>
                <div className={styles.formHeaderContainer}>
                    <Text color="blue" variant="Header2">
                        Assessment History
                    </Text>
                    {this.assessmentButton()}
                </div>
                {this.props.assessmentDetailsError && <Text color="red">{this.props.assessmentDetailsError}</Text>}
                <div className={styles.tableContainer}>
                    <Table>
                        <SortableTableHeader {...sortableTableProps} />
                        <TableBody>{this.sortEvidence().map(this.tableRow)}</TableBody>
                    </Table>
                </div>
            </Fragment>
        );
    }
}
