import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { useUsers } from 'Components/Context/UsersContext';
import CircleIndicator from 'Components/Indicator/CircleIndicator';
import Text from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { getEffectivenessVariantColor, numberAsEffectiveness } from 'Models/OperationalControls';
import { RiskRatingHistoryResponse, formatAverageWeightedEffectiveness, formatImpactOrProbability, formatTotalImpactOrProbability } from 'Models/RiskRegister';

import styles from './RiskRatingHistoryAccordion.module.css';

export interface RiskRatingHistoryAccordionProps {
    histories: RiskRatingHistoryResponse[];
    timestampOfHistoryToAutoExpand?: string;
}

export const RiskRatingHistoryAccordion = (props: RiskRatingHistoryAccordionProps) => {
    const { users } = useUsers();

    const scoresSection = (impact: number | undefined, probability: number | undefined) => {
        if (impact === undefined || probability === undefined) {
            return <></>;
        }

        return (
            <div className={styles.scoreInfo}>
                <div className={styles.scoreFactors}>
                    <Text noStyles>{`Impact: ${formatImpactOrProbability(impact)}`}</Text>
                    <Text noStyles>{`Probability: ${formatImpactOrProbability(probability)}`}</Text>
                </div>
            </div>
        );
    };

    const rowForHistory = (history: RiskRatingHistoryResponse): JSX.Element => {
        const key = history.timestamp;
        const autoExpand = history.timestamp === props.timestampOfHistoryToAutoExpand;
        const formattedTimestamp = iso8601ToUsDateShort(history.timestamp);

        return (
            <Accordion defaultActiveKey={autoExpand ? key : undefined} key={key}>
                <div className={styles.accordionContainer}>
                    <div className={styles.accordionColumnDate}>
                        <AccordionToggle eventKey={key} ariaLabelSuffix={formattedTimestamp} />
                        <Text noStyles>{formattedTimestamp}</Text>
                    </div>
                    <div className={styles.evenlySpacedAccordionItemsContainer}>
                        <div className={styles.accordionColumn}>
                            <Text noStyles>{history.total_inherent_risk !== undefined ? formatTotalImpactOrProbability(history.total_inherent_risk) : '-'}</Text>
                        </div>
                        <div className={styles.accordionColumn}>
                            {history.control_environment_effectiveness && (
                                <div className={styles.controlEnvironmentEffectivenessContainer}>
                                    <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(history.control_environment_effectiveness))} />
                                    <Text noStyles>{formatAverageWeightedEffectiveness(history.control_environment_effectiveness)}</Text>
                                </div>
                            )}
                        </div>
                        <div className={styles.accordionColumn}>
                            <Text noStyles>{history.total_current_risk !== undefined ? formatTotalImpactOrProbability(history.total_current_risk) : '-'}</Text>
                        </div>
                        <div className={styles.accordionColumn}>
                            <Text noStyles>{history.total_target_risk !== undefined ? formatTotalImpactOrProbability(history.total_target_risk) : '-'}</Text>
                        </div>
                    </div>
                </div>
                <AccordionCollapse eventKey={key} accordionFor={key}>
                    <div className={styles.additionalInfo}>
                        <div className={styles.additionalInfoRowsContainer}>
                            <div className={styles.accordionColumnDate} />
                            <div className={styles.evenlySpacedAccordionItemsContainer}>
                                <div className={styles.accordionColumn}>{scoresSection(history.inherent_risk_impact, history.inherent_risk_probability)}</div>
                                <div className={styles.accordionColumn} />
                                <div className={styles.accordionColumn}>{scoresSection(history.current_risk_impact, history.current_risk_probability)}</div>
                                <div className={styles.accordionColumn}>{scoresSection(history.target_risk_impact, history.target_risk_probability)}</div>
                            </div>
                        </div>
                        <div className={styles.lastUpdatedInfo}>
                            <Text noStyles>
                                <b>Updated By:</b>
                            </Text>
                            <Text noStyles>{getUserNameFromSubject(history.updated_by, users)}</Text>
                        </div>
                        <div className={styles.lastUpdatedInfo}>
                            <Text noStyles>
                                <b>Comment:</b>
                            </Text>
                            <Text noStyles>{history.scores_comment ?? '-'}</Text>
                        </div>
                    </div>
                </AccordionCollapse>
                <hr className={styles.hrModifier} />
            </Accordion>
        );
    };

    return (
        <>
            <div className={styles.accordionHeaderContainer}>
                <div className={styles.accordionHeaderDate}>
                    <div className={styles.accordionCollapseOffset}>
                        <Text noStyles>DATE</Text>
                    </div>
                </div>
                <div className={styles.evenlySpacedAccordionItemsContainer}>
                    <div className={styles.accordionHeader}>
                        <Text noStyles>
                            INHERENT
                            <br />
                            RISK SCORE
                        </Text>
                    </div>
                    <div className={styles.accordionHeader}>
                        <Text noStyles>
                            CONTROL
                            <br />
                            ENVIRONMENT
                        </Text>
                    </div>
                    <div className={styles.accordionHeader}>
                        <Text noStyles>
                            CURRENT RESIDUAL
                            <br />
                            RISK SCORE
                        </Text>
                    </div>
                    <div className={styles.accordionHeader}>
                        <Text noStyles>
                            TARGET RESIDUAL
                            <br />
                            RISK SCORE
                        </Text>
                    </div>
                </div>
            </div>
            {props.histories.map(rowForHistory)}
        </>
    );
};
