import { Role } from '../../Components/Context/RBACContext';
import { UserResponse } from '../../Models/User';

export const DESTINATION = 'destination';

export interface AuthHandler {
    pageLoaded: () => boolean;
    setAccessToken: (newAccessToken: string) => void;
    userUnauthorized: () => void;
    getAuthenticatedUserFullName: (users: UserResponse[]) => string;
    getAuthenticatedUserSubject: () => string | undefined;
    getAccessToken: () => string | null;
    getUserRoles: () => Role[];
    logIn: () => Promise<void>;
}
