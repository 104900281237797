import { Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';

import { SegmentedProgressBarChart } from 'Components/BaseCharts/SegmentedProgressBarChart';
import Text from 'Components/Text/Text';
import { TprmDashboardServiceExtended } from 'Models/Dashboards';
import { RiskRating, riskRatingLabelAsNumber } from 'Models/TPRM';

import styles from './TPRMRiskProgressCharts.module.css';
import { SelectedServiceDetails, ServicesDisplayModal } from '../ServicesDisplayModal/ServicesDisplayModal';
import { buildRiskRatingDataArray } from '../TPRMDashboard.helpers';

enum Modals {
    NONE,
    DISPLAY_SERVICES,
}

export interface TPRMRiskProgressChartsProps {
    vendorServices?: TprmDashboardServiceExtended[];
    vendorServicesError?: string;
}

export const TPRMRiskProgressCharts = (props: TPRMRiskProgressChartsProps): JSX.Element => {
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [servicesModalSubtitle, setServicesModalSubtitle] = useState<string>();
    const [selectedServices, setSelectedServices] = useState<SelectedServiceDetails[]>();

    const inherentRiskProgressChartData = useMemo(() => {
        if (props.vendorServices) {
            const filteredScores: number[] = props.vendorServices.filter((service) => service.assessment_due_date === undefined).map((service) => service.inherent_risk_score);
            return buildRiskRatingDataArray([RiskRating.INACTIVE, RiskRating.LOW, RiskRating.LOWMODERATE, RiskRating.MODERATE, RiskRating.MODERATEHIGH, RiskRating.HIGH], filteredScores);
        }
    }, [props.vendorServices]);

    const residualRiskProgressChartData = useMemo(() => {
        if (props.vendorServices) {
            const filteredScores: number[] = props.vendorServices.filter((service) => service.assessment_due_date === undefined).map((service) => service.assessment_residual_risk_score ?? 0);
            return buildRiskRatingDataArray([RiskRating.INACTIVE, RiskRating.LOW, RiskRating.LOWMODERATE, RiskRating.MODERATE, RiskRating.MODERATEHIGH, RiskRating.HIGH], filteredScores);
        }
    }, [props.vendorServices]);

    const handleInherentRiskChartClick = (targetRating: string): void => {
        if (targetRating) {
            const targetRatingScore = riskRatingLabelAsNumber(targetRating);
            setServicesModalSubtitle(targetRating);
            setModalState(Modals.DISPLAY_SERVICES);
            setSelectedServices(
                props
                    .vendorServices!.filter((service) => service.assessment_due_date === undefined)
                    .filter((service) => service.inherent_risk_score === targetRatingScore)
                    .map((service) => ({
                        serviceId: service.id,
                        serviceName: service.name,
                        vendorId: service.vendor_id,
                        vendorName: service.vendor_name,
                    }))
            );
        }
    };

    const handleResidualRiskChartClick = (targetRating: string): void => {
        if (targetRating) {
            const targetRatingScore = riskRatingLabelAsNumber(targetRating);
            setServicesModalSubtitle(targetRating);
            setModalState(Modals.DISPLAY_SERVICES);
            setSelectedServices(
                props
                    .vendorServices!.filter((service) => service.assessment_due_date === undefined)
                    .filter((service) => service.assessment_residual_risk_score === targetRatingScore)
                    .map((service) => ({
                        serviceId: service.id,
                        serviceName: service.name,
                        vendorId: service.vendor_id,
                        vendorName: service.vendor_name,
                    }))
            );
        }
    };

    const handleServicesModalClose = () => {
        setModalState(Modals.NONE);
        setServicesModalSubtitle(undefined);
        setSelectedServices(undefined);
    };

    if (props.vendorServicesError) {
        return (
            <>
                <Text variant="Header2" color="white">
                    Assessments Not Scheduled
                </Text>
                <div className={styles.notScheduledCharts}>
                    <div>
                        <Text variant="Text2" color="darkGray">
                            INHERENT RISK
                        </Text>
                        <Text color="white">{props.vendorServicesError}</Text>
                    </div>
                    <div>
                        <Text variant="Text2" color="darkGray">
                            RESIDUAL RISK
                        </Text>

                        <Text color="white">{props.vendorServicesError}</Text>
                    </div>
                </div>
            </>
        );
    }

    const content = (() => {
        const hasNoServices = props.vendorServices && props.vendorServices.length === 0;
        const hasServicesAndAllAreScheduled = inherentRiskProgressChartData !== undefined && residualRiskProgressChartData !== undefined && inherentRiskProgressChartData.every((data) => data.value === 0) && residualRiskProgressChartData.every((data) => data.value === 0);

        if (hasNoServices) {
            return (
                <Text variant="Text3" color="white">
                    No vendor services have been created.
                </Text>
            );
        } else if (hasServicesAndAllAreScheduled) {
            return (
                <Text variant="Text3" color="white">
                    All vendor services have been scheduled for assessment.
                </Text>
            );
        } else {
            return (
                <div className={styles.notScheduledCharts}>
                    <div>
                        <Text variant="Text2" color="darkGray">
                            INHERENT RISK
                        </Text>
                        {inherentRiskProgressChartData ? <SegmentedProgressBarChart name="Inherent Risk Score" data={inherentRiskProgressChartData} onChartClick={handleInherentRiskChartClick} chartDimensions={{ width: '100%', height: 50 }} /> : <Skeleton variant="rounded" width={'100%'} height={50} />}
                    </div>
                    <div>
                        <Text variant="Text2" color="darkGray">
                            RESIDUAL RISK
                        </Text>
                        {residualRiskProgressChartData ? <SegmentedProgressBarChart name="Residual Risk Score" data={residualRiskProgressChartData} onChartClick={handleResidualRiskChartClick} chartDimensions={{ width: '100%', height: 50 }} /> : <Skeleton variant="rounded" width={'100%'} height={50} />}{' '}
                    </div>
                </div>
            );
        }
    })();

    return (
        <>
            {modalState === Modals.DISPLAY_SERVICES && servicesModalSubtitle && selectedServices && <ServicesDisplayModal hideModal={handleServicesModalClose} subtitle={servicesModalSubtitle} selectedServices={selectedServices} />}
            <Text variant="Header2" color="white">
                Assessments Not Scheduled
            </Text>
            {content}
        </>
    );
};
