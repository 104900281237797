import { ASSESSMENTS, COMPLIANCE_REQUIREMENTS, CURRENT, DASHBOARDS, DETAILS, FFIEC_CAT, HISTORY, OPERATIONAL_CONTROLS, SCHEDULE, SUMMARY, TARGET_MATURITY, TPRM } from 'Config/Paths';
import { AssessmentsResponse, ComplianceDetails, ComplianceDetailsSnapshot, ComplianceHistoryFFIECCAT, ComplianceSummary, ComplianceSummarySnapshot, ControlStrengthResponse, ControlsSnapshot, FFIECCATMaturityLevel, MonthNumber, SetTargetMaturityFFIECCATRequest, TprmDashboardCompletedRiskAssessment, TprmDashboardMonthlyRiskPortfolioSummary, TprmDashboardVendorsAndServicesInfo } from 'Models/Dashboards';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiGet, restApiPost } from 'RestApiConnector';

import { DashboardApi } from './DashboardApi';

export class AWSDashboardApi implements DashboardApi {
    getVendorsAndServicesInfo = (): Promise<ResponseModel<TprmDashboardVendorsAndServicesInfo>> => {
        return restApiGet(`/${DASHBOARDS}/${TPRM}`, {});
    };

    getCompletedRiskAssessments = (startTime: string, endTime: string): Promise<ResponseModel<TprmDashboardCompletedRiskAssessment[]>> => {
        return restApiGet(`/${DASHBOARDS}/${TPRM}/${ASSESSMENTS}?start_time=${startTime}&end_time=${endTime}`, {});
    };

    getRiskPortfolioHistory = (startTime: string, endTime: string): Promise<ResponseModel<TprmDashboardMonthlyRiskPortfolioSummary[]>> => {
        return restApiGet(`/${DASHBOARDS}/${TPRM}/${HISTORY}?start_time=${startTime}&end_time=${endTime}`, {});
    };

    getControlStrength = (): Promise<ResponseModel<ControlStrengthResponse>> => {
        return restApiGet(`/${DASHBOARDS}/${OPERATIONAL_CONTROLS}/${SUMMARY}`, {});
    };

    getCurrentComplianceSummary = (): Promise<ResponseModel<ComplianceSummary[]>> => {
        return restApiGet(`/${DASHBOARDS}/${COMPLIANCE_REQUIREMENTS}/${CURRENT}/${SUMMARY}`, {});
    };

    getCurrentComplianceDetails = (regulation: string): Promise<ResponseModel<ComplianceDetails>> => {
        return restApiGet(`/${DASHBOARDS}/${COMPLIANCE_REQUIREMENTS}/${CURRENT}/${DETAILS}/${regulation}`, {});
    };

    getControlsHistory = (startTime: string, endTime: string): Promise<ResponseModel<ControlsSnapshot[]>> => {
        return restApiGet(`/${DASHBOARDS}/${OPERATIONAL_CONTROLS}/${HISTORY}?start_time=${startTime}&end_time=${endTime}`, {});
    };

    getComplianceHistorySummary = (regulation: string): Promise<ResponseModel<ComplianceSummarySnapshot[]>> => {
        return restApiGet(`/${DASHBOARDS}/${COMPLIANCE_REQUIREMENTS}/${HISTORY}/${regulation}`, {});
    };

    getComplianceHistoryDetails = (regulation: string, month: MonthNumber, year: number): Promise<ResponseModel<ComplianceDetailsSnapshot>> => {
        return restApiGet(`/${DASHBOARDS}/${COMPLIANCE_REQUIREMENTS}/${HISTORY}/${regulation}/${DETAILS}?month=${month}&year=${year}`, {});
    };

    getComplianceHistorySummaryFFIECCAT = (regulation: string, maturityLevel: FFIECCATMaturityLevel): Promise<ResponseModel<ComplianceHistoryFFIECCAT>> => {
        return restApiGet(`/${DASHBOARDS}/${COMPLIANCE_REQUIREMENTS}/${HISTORY}/${regulation}?target_maturity=${maturityLevel}`, {});
    };

    getComplianceHistoryDetailsFFIECCAT = (regulation: string, month: MonthNumber, year: number, maturityLevel: FFIECCATMaturityLevel): Promise<ResponseModel<ComplianceDetailsSnapshot>> => {
        return restApiGet(`/${DASHBOARDS}/${COMPLIANCE_REQUIREMENTS}/${HISTORY}/${regulation}/${DETAILS}?month=${month}&year=${year}&target_maturity=${maturityLevel}`, {});
    };

    getAssessments = (): Promise<ResponseModel<AssessmentsResponse>> => {
        return restApiGet(`/${DASHBOARDS}/${OPERATIONAL_CONTROLS}/${ASSESSMENTS}/${SCHEDULE}`, {});
    };

    getTargetMaturity = (): Promise<ResponseModel<FFIECCATMaturityLevel>> => {
        return restApiGet(`/${DASHBOARDS}/${COMPLIANCE_REQUIREMENTS}/${FFIEC_CAT}/${TARGET_MATURITY}`, {});
    };

    setTargetMaturity = (request: SetTargetMaturityFFIECCATRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${DASHBOARDS}/${COMPLIANCE_REQUIREMENTS}/${FFIEC_CAT}/${TARGET_MATURITY}`, request);
    };
}
