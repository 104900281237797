/*
	FormFieldTextArea.tsx - Component for text fields within an HTML form.
*/
import { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';

interface FormFieldTextAreaProps {
    formFieldId: string;
    formFieldLabel: string;
    required?: boolean;
    hideRequiredSymbol?: boolean;
    tooltip?: string;
    placeholder?: string;
    handleChange?: any;
    invalidMessage?: string;
    disabled?: boolean;
    value?: string;
    rows?: number;
}

export default class FormFieldTextArea extends Component<FormFieldTextAreaProps> {
    render(): JSX.Element {
        return (
            <FormGroup controlId={this.props.formFieldId} className={styles.formField}>
                <FormLabel required={this.props.required} hideRequiredSymbol={this.props.hideRequiredSymbol}>
                    {this.props.formFieldLabel}
                </FormLabel>
                {this.props.tooltip && <FormFieldTooltip text={this.props.tooltip} />}
                <FormControl as="textarea" rows={this.props.rows ? this.props.rows : 5} name={this.props.formFieldId} className={styles.customFormControl} required={this.props.required} placeholder={this.props.placeholder} onChange={this.props.handleChange} disabled={this.props.disabled} value={this.props.value} />
                <FormControl.Feedback type="invalid">{this.props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
            </FormGroup>
        );
    }
}
