import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep, isEqual } from 'lodash-es';
import { Fragment, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import IconButton from 'Components/Buttons/IconButton';
import { useUsers } from 'Components/Context/UsersContext';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import FormFieldTextArea from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import FormFieldUserSelect from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import Text from 'Components/Text/Text';
import { countryOptions, getStateFieldLabel, getStateFieldOptions } from 'Config/CountryStateList';
import { TPRM_VENDOR_ADDRESS, TPRM_VENDOR_CITY, TPRM_VENDOR_CONTACT_EMAIL, TPRM_VENDOR_CONTACT_NAME, TPRM_VENDOR_CONTACT_PHONE_NUMBER, TPRM_VENDOR_EIN, TPRM_VENDOR_MANAGER, TPRM_VENDOR_NAME, TPRM_VENDOR_WEBSITE, TPRM_VENDOR_ZIP_CODE } from 'Config/Tooltips';
import { validateEin, validateEmail } from 'Helpers/InputValidation';
import { ValidationError } from 'Models/ErrorTypes';
import { SaveVendorRequest, VendorContact, VendorResponse } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import styles from './SaveTPRMVendorModal.module.css';

export interface SaveTPRMVendorModalProps {
    hideModal: () => void;
    tprmApi: TPRMApi;
    vendor?: VendorResponse; // If this is present, then we are modifying an existing Vendor. Else, we are creating a new one.
    vendorSaved: () => void;
}

interface FormFieldsState {
    name?: string;
    ein?: string;
    country?: string;
    vendorManagerUserId?: string;
    vendor_contacts: VendorContact[];
    state?: string;
    city?: string;
    addressLine1?: string;
    addressLine2?: string;
    zipCode?: string;
    website?: string;
}

const SaveTPRMVendorModal = (props: SaveTPRMVendorModalProps): JSX.Element => {
    const { users } = useUsers();

    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [isSavingVendor, setIsSavingVendor] = useState<boolean>(false);
    const [vendorManager, setVendorManager] = useState<UserResponse>(); // Keeps track of the currently selected User in the Vendor Manager drop-down menu.
    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({
        name: props.vendor?.name,
        ein: props.vendor?.ein,
        country: props.vendor?.country,
        vendorManagerUserId: props.vendor?.vendor_manager_user_id,
        vendor_contacts: props.vendor?.vendor_contacts || [],
        state: props.vendor?.state,
        city: props.vendor?.city,
        addressLine1: props.vendor?.address_line_1,
        addressLine2: props.vendor?.address_line_2,
        zipCode: props.vendor?.zip_code,
        website: props.vendor?.website,
    });

    useEffect(() => {
        const getSelectedUser = async (): Promise<void> => {
            const vendorManager = users.find((user) => user.cognito_subject === props.vendor?.vendor_manager_user_id);
            setVendorManager(vendorManager);
        };
        getSelectedUser();
    }, [props.vendor?.vendor_manager_user_id, users]);

    const saveVendor = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        setIsSavingVendor(true);
        try {
            validateForm();
            await props.tprmApi.saveVendor(getMinimalRequest(), props.vendor?.id);
            if (props.vendor !== undefined) {
                setSuccessMessage('Vendor updated.');
            } else {
                setSuccessMessage('Vendor created.');
            }
            props.vendorSaved();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSavingVendor(false);
        }
    };

    /**
     * This ensures only necessary fields are included in the request to the API.
     */
    const getMinimalRequest = (): SaveVendorRequest => {
        const request: SaveVendorRequest = {
            name: formFieldsState.name,
            ein: formFieldsState.ein,
            country: formFieldsState.country,
            vendor_manager_user_id: formFieldsState.vendorManagerUserId,
            vendor_contacts: cloneDeep(formFieldsState.vendor_contacts), // cloneDeep() is used so that we can set empty strings to undefined in the next step without modifying the original state, which would throw a React error about switching from controlled to uncontrolled components.
            state: formFieldsState.state,
            city: formFieldsState.city,
            address_line_1: formFieldsState.addressLine1,
            address_line_2: formFieldsState.addressLine2,
            zip_code: formFieldsState.zipCode,
            website: formFieldsState.website,
        };

        const minimalRequest: SaveVendorRequest = {};

        // Compare the form fields to the existing Vendor and only include fields that have changed.
        Object.keys(request).forEach((key) => {
            // We are updating an existing Vendor, only include fields that have changed.
            if (props.vendor !== undefined) {
                // Need special handling for vendor_contacts since JavaScript can't easily check arrays and objects for equality.
                if (key === 'vendor_contacts') {
                    if (!isEqual(props.vendor[key].sort(), request[key]?.sort())) {
                        minimalRequest[key] = request[key];
                    }
                }
                // Everything else can be directly checked for equality.
                else if (props.vendor[key] !== request[key]) {
                    minimalRequest[key] = request[key];
                }
            }
            // We are creating a new Vendor Service, include everything.
            else {
                minimalRequest[key] = request[key];
            }
        });

        // Discard any Vendor contacts where all attributes are "empty."
        // This is necessary because the API will save empty Vendor contacts to the database (as {}) and then empty Vendor contact "rows" will persist in the UI.
        minimalRequest.vendor_contacts = minimalRequest.vendor_contacts?.filter((vendorContact) => {
            return Object.values(vendorContact).some((value) => {
                if (typeof value === 'string' && value.trim().length === 0) {
                    return false;
                }
                return true;
            });
        });

        // Set any attributes of Vendor contacts that are "empty" strings to undefined before submitting the request.
        // This is necessary because the API will return an error if email_address is an empty string. The other attributes are included simply for completeness.
        minimalRequest.vendor_contacts?.forEach((vendorContact) => {
            if ((vendorContact.email_address?.trim().length ?? 0) === 0) {
                vendorContact.email_address = undefined;
            }
            if ((vendorContact.name?.trim().length ?? 0) === 0) {
                vendorContact.name = undefined;
            }
            if ((vendorContact.phone_number?.trim().length ?? 0) === 0) {
                vendorContact.phone_number = undefined;
            }
            if ((vendorContact.additional_information?.trim().length ?? 0) === 0) {
                vendorContact.additional_information = undefined;
            }
        });

        return minimalRequest;
    };

    const validateForm = (): void => {
        if (!formFieldsState.name) {
            throw new ValidationError('Vendor name is required.');
        }
        if (!formFieldsState.country) {
            throw new ValidationError('Vendor country/region is required.');
        }
        if (formFieldsState.ein && !validateEin(formFieldsState.ein)) {
            throw new ValidationError('Employer ID Number must only contain numbers and dashes.');
        }
        formFieldsState.vendor_contacts.forEach((vendor_contact) => {
            if (vendor_contact.email_address && !validateEmail(vendor_contact.email_address)) {
                throw new ValidationError('Invalid email address.');
            }
        });
        if (!formFieldsState.vendorManagerUserId) {
            throw new ValidationError('Vendor Manager is required.');
        }
    };

    /** Handles error responses from the API. */
    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    /** Handles changes made to general text fields. */
    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    /** Handles changes made to general drop-down (select) fields. */
    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        if (formFieldId === 'country') {
            setFormFieldsState({ ...formFieldsState, [formFieldId]: value as string, state: '' });
        } else {
            setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
        }
    };

    /** Handles changes made to the Vendor Manager drop-down (select) field. */
    const handleSelectUserChange = (user: UserResponse, formFieldId: string): void => {
        if (user !== vendorManager) {
            setVendorManager(user);
            setFormFieldsState({ ...formFieldsState, [formFieldId]: user.cognito_subject });
        }
    };

    /** Adds the fields (empty) for a new Vendor contact. */
    const handleAddVendorContact = (): void => {
        const vendorContacts = [...formFieldsState.vendor_contacts];
        vendorContacts.push({ name: '', email_address: '', phone_number: '', additional_information: '' });
        setFormFieldsState({ ...formFieldsState, vendor_contacts: vendorContacts });
    };

    /** Deletes an existing Vendor contact. */
    const handleDeleteVendorContact = (index: number): void => {
        const vendorContacts = [...formFieldsState.vendor_contacts];
        vendorContacts.splice(index, 1);
        setFormFieldsState({ ...formFieldsState, vendor_contacts: vendorContacts });
    };

    /** Handles changes made to an existing Vendor contact. */
    const handleVendorContactChange = (index: number, attribute: 'name' | 'email_address' | 'phone_number' | 'additional_information', value: string): void => {
        const vendorContacts = [...formFieldsState.vendor_contacts];
        vendorContacts[index] = {
            ...vendorContacts[index],
            [attribute]: value,
        };
        setFormFieldsState({ ...formFieldsState, vendor_contacts: vendorContacts });
    };

    const stateField = (): JSX.Element => {
        const formFieldLabel = getStateFieldLabel(formFieldsState.country);
        const tooltip = `The ${formFieldLabel} in which this vendor resides.`;

        if (!formFieldsState.country) {
            // If a country has not yet been selected, return a disabled text field.
            return <FormFieldText value="Select a Country first" disabled formFieldType="text" formFieldId="state" formFieldLabel={formFieldLabel} required={false} tooltip={tooltip} />;
        }

        const stateFieldOptions = getStateFieldOptions(formFieldsState.country);

        if (stateFieldOptions === undefined) {
            // If the selected country does not have corresponding states/etc. from which to select, return a free-form text field.
            // The value defaults to the empty string to avoid transitioning the component from uncontrolled to controlled.
            return <FormFieldText value={formFieldsState.state || ''} formFieldType="text" handleChange={handleChange} formFieldId="state" formFieldLabel={formFieldLabel} required={false} tooltip={tooltip} />;
        }

        // If the selected country has corresponding states/etc. from which to select, return a select component with those values.
        // The value defaults to null to avoid transitioning the component from uncontrolled to controlled, and to clear out the selection when a new country is selected.
        return <FormFieldSelect selectedOption={formFieldsState.state || null} options={stateFieldOptions} handleChange={handleSelectChange} formFieldId="state" formFieldLabel={formFieldLabel} tooltip={tooltip} />;
    };

    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={saveVendor}>
                    {props.vendor ? <Text variant="Header2">Update Vendor</Text> : <Text variant="Header2">Create Vendor</Text>}
                    <div className={styles.formFieldContainer}>
                        <FormFieldText value={formFieldsState.name || ''} formFieldType="text" handleChange={handleChange} formFieldId="name" formFieldLabel="Legal Name" required={true} tooltip={TPRM_VENDOR_NAME} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText value={formFieldsState.website || ''} formFieldType="text" handleChange={handleChange} formFieldId="website" formFieldLabel="Website URL" required={false} tooltip={TPRM_VENDOR_WEBSITE} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldUserSelect selectedUser={vendorManager} users={users ? users : []} onUserSelected={handleSelectUserChange} formFieldId="vendorManagerUserId" formFieldLabel="Vendor Manager" tooltip={TPRM_VENDOR_MANAGER} isRequiredField />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldSelect selectedOption={formFieldsState.country} options={countryOptions} handleChange={handleSelectChange} formFieldId="country" formFieldLabel="Country/Region" isRequiredField />
                    </div>
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText value={formFieldsState.addressLine1 || ''} formFieldType="text" handleChange={handleChange} formFieldId="addressLine1" formFieldLabel="Address Line 1" required={false} tooltip={TPRM_VENDOR_ADDRESS} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText value={formFieldsState.addressLine2 || ''} formFieldType="text" handleChange={handleChange} formFieldId="addressLine2" formFieldLabel="Address Line 2" required={false} tooltip={TPRM_VENDOR_ADDRESS} />
                        </div>
                    </div>
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText value={formFieldsState.city || ''} formFieldType="text" handleChange={handleChange} formFieldId="city" formFieldLabel="City" required={false} tooltip={TPRM_VENDOR_CITY} />
                        </div>
                        <div className={styles.formFieldContainer}>{stateField()}</div>
                    </div>
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText value={formFieldsState.zipCode || ''} formFieldType="text" handleChange={handleChange} formFieldId="zipCode" formFieldLabel="Zip Code" required={false} tooltip={TPRM_VENDOR_ZIP_CODE} />
                        </div>
                        <div className={styles.fieldContainer}>
                            <FormFieldText value={formFieldsState.ein || ''} formFieldType="text" handleChange={handleChange} formFieldId="ein" formFieldLabel="Employer ID Number" tooltip={TPRM_VENDOR_EIN} />
                        </div>
                    </div>
                    <Text variant="Header3" color="darkGray">
                        Vendor Contact Information
                    </Text>
                    {formFieldsState.vendor_contacts.map((vendorContact, index) => (
                        <Fragment key={index}>
                            <div className={styles.formFieldGroup}>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVendorContactChange(index, 'name', event.currentTarget.value)} formFieldId={`vendorContactName${index}`} formFieldLabel={`Name`} tooltip={TPRM_VENDOR_CONTACT_NAME} value={vendorContact.name || ''} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText formFieldType="email" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVendorContactChange(index, 'email_address', event.currentTarget.value)} formFieldId={`vendorContactEmailAddress${index}`} formFieldLabel={`Email Address`} tooltip={TPRM_VENDOR_CONTACT_EMAIL} value={vendorContact.email_address || ''} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVendorContactChange(index, 'phone_number', event.currentTarget.value)} formFieldId={`vendorContactPhoneNumber${index}`} formFieldLabel={`Phone Number`} tooltip={TPRM_VENDOR_CONTACT_PHONE_NUMBER} value={vendorContact.phone_number || ''} />
                                </div>
                                <div className={styles.trashIconContainer}>
                                    <IconButton aria-label={`delete vendor contact ${index}`} onClick={() => handleDeleteVendorContact(index)} fontAwesomeImage={faTrash} />
                                </div>
                            </div>
                            <div className={styles.formFieldContainer}>
                                <FormFieldTextArea handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVendorContactChange(index, 'additional_information', event.currentTarget.value)} formFieldId={`vendorContactAdditionalInformation${index}`} formFieldLabel={`Additional Information`} tooltip="Any additional information for the contact at this vendor." value={vendorContact.additional_information || ''} />
                            </div>
                        </Fragment>
                    ))}
                    <div className={styles.formFieldContainer}>
                        <Button variant="linkText" size="lg" onClick={handleAddVendorContact}>
                            {'+ Add Contact'}
                        </Button>
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes} disabled={isSavingVendor}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={isSavingVendor} isLoading={isSavingVendor} loadingText="Saving...">
                            SAVE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SaveTPRMVendorModal;
