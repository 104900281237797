import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { controlComparator } from 'Helpers/Compare';
import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { UserNameFormat, getDepartmentFromSubject, getUserNameFromSubject } from 'Helpers/UserUtils';
import { titleCaseExceptionStatus, titleCaseImpact, titleCaseLikelihood, titleCaseRiskScore } from 'Models/Exceptions';
import { ExceptionExportRow } from 'Models/Exports';
import { OperationalControl } from 'Models/OperationalControls';

import styles from '../DataExports.module.css';

export interface ExceptionsExportProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const ExceptionsExport = (props: ExceptionsExportProps): JSX.Element => {
    const [exceptions, setExceptions] = useState<ExceptionExportRow[]>();
    const [error, setError] = useState<string>();
    const { users } = useUsers();

    useEffect(() => {
        const getExceptions = async (): Promise<void> => {
            try {
                const exceptionResponse = await props.exportsApi.getExceptionsExport();
                setExceptions(exceptionResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getExceptions();
    }, [props.exportsApi]);

    const columns: GridColumn<ExceptionExportRow>[] = [
        { field: 'title', headerName: 'Title', width: 300 },
        { field: 'owner', headerName: 'Owner', width: 300, valueGetter: (value) => getUserNameFromSubject(value, users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'owner_department', headerName: 'Owner Department', width: 300, valueGetter: (_value, row) => getDepartmentFromSubject(row.owner, users) },
        { field: 'reviewer', headerName: 'Reviewer', width: 300, valueGetter: (value) => getUserNameFromSubject(value, users, UserNameFormat.FIRST_SPACE_LAST) },
        {
            field: 'delegates',
            headerName: 'Delegates',
            width: 300,
            valueGetter: (value) =>
                value
                    .map((userId: string) => getUserNameFromSubject(userId, users, UserNameFormat.FIRST_SPACE_LAST))
                    .sort()
                    .join(', '),
        },
        { field: 'status', headerName: 'Status', width: 300, valueGetter: (value) => titleCaseExceptionStatus(value) },
        { field: 'likelihood', headerName: 'Likelihood', width: 300, valueGetter: (value) => titleCaseLikelihood(value) },
        { field: 'impact', headerName: 'Impact', width: 300, valueGetter: (value) => titleCaseImpact(value) },
        { field: 'risk_score', headerName: 'Risk Score', width: 300, valueGetter: (value) => titleCaseRiskScore(value) },
        { field: 'reference', headerName: 'Reference', width: 300 },
        { field: 'description', headerName: 'Description', width: 300 },
        { field: 'risk_assessment', headerName: 'Risk Assessment', width: 300 },
        { field: 'remediation_plan', headerName: 'Remediation Plan', width: 300 },
        { field: 'compensating_controls', headerName: 'Compensating Controls', width: 300 },
        {
            field: 'impacted_controls',
            headerName: 'Impacted Controls',
            width: 300,
            valueGetter: (_value, row) =>
                row.impacted_controls
                    .sort(controlComparator)
                    .map((control: OperationalControl) => getHumanReadableControlIdFromControl(control))
                    .join(', '),
        },
        { field: 'impacted_vendor', headerName: 'Impacted Vendor', width: 300 },
        dateColumn('created_timestamp', 'Open Date'),
        { field: 'created_by', headerName: 'Created By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('last_updated_timestamp', 'Last Updated'),
        { field: 'last_updated_by', headerName: 'Last Updated By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('expiration_date', 'Expiration Date'),
        dateColumn('closed_timestamp', 'Closure Date'),
        { field: 'closed_by', headerName: 'Closed By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'closure_statement', headerName: 'Closure Statement', width: 300 },
        {
            field: 'file_names',
            headerName: 'File Names',
            width: 300,
            valueGetter: (value) => value.sort().join(', '),
        },
        { field: 'id', headerName: 'ID', width: 300 },
    ];

    if (exceptions) {
        return (
            <>
                <PageBackground color="grey">
                    <PageContent>
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                            <BreadcrumbText>Exceptions</BreadcrumbText>
                        </Breadcrumb>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1">Exceptions Export</Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <DataGrid columns={columns} getRowId={(exception: ExceptionExportRow) => exception.id} rows={exceptions} title="Exceptions Export Preview" fileName="Exceptions" disableVirtualization={props.disableVirtualization} />
                    </PageContent>
                </PageBackground>
            </>
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
