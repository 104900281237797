/*
	ControlGroupListView.tsx presentation for a collection of control group rendered as a "list."
*/

import { useEffect, useState } from 'react';

import SortableTableHeader, { HeaderData, SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody } from 'Components/Table/Table/Table';
import { controlGroupComparator } from 'Helpers/Compare';
import { ControlGroupResponse } from 'Models/OperationalControls';

import styles from './ControlGroupListView.module.css';
import ControlGroupTableRow from './ControlGroupTableRow/ControlGroupTableRow';

const COLUMN_CONTROL_GROUP_ID = 'COLUMN_CONTROL_GROUP_ID';
const COLUMN_FRAMEWORK = 'COLUMN_FRAMEWORK';
const COLUMN_EFFECTIVENESS = 'COLUMN_EFFECTIVENESS';

export interface ControlGroupListViewProps {
    controlGroups: ControlGroupResponse[];
}

export const ControlGroupListView = (props: ControlGroupListViewProps) => {
    const [sortBy, setSortBy] = useState(COLUMN_CONTROL_GROUP_ID);
    const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
    const [sortedControlGroups, setSortedControlGroups] = useState<ControlGroupResponse[]>([]);

    useEffect(() => {
        setSortedControlGroups([
            ...props.controlGroups.sort((groupA, groupB) => {
                let sortResult = 0;

                switch (sortBy) {
                    case COLUMN_CONTROL_GROUP_ID:
                        sortResult = controlGroupComparator(groupA, groupB);
                        break;
                    case COLUMN_EFFECTIVENESS:
                        sortResult = groupA.control_group_effectiveness > groupB.control_group_effectiveness ? 1 : -1;
                        break;
                }

                return sortDirection === SortDirection.ASC ? sortResult : -sortResult;
            }),
        ]);
    }, [props.controlGroups, sortBy, sortDirection]);

    const headerValues: HeaderData[] = [
        { dataKey: COLUMN_CONTROL_GROUP_ID, label: 'CONTROL GROUP ID' },
        { dataKey: COLUMN_FRAMEWORK, label: 'FRAMEWORK', disableSort: true },
        { dataKey: COLUMN_EFFECTIVENESS, label: 'EFFECTIVENESS' },
    ];

    const tableRow = (group: ControlGroupResponse, index: number): JSX.Element => {
        return <ControlGroupTableRow key={index} controlGroup={group} />;
    };

    const applySorting = (sortBy: string, sortDirection: SortDirection): void => {
        setSortBy(sortBy);
        setSortDirection(sortDirection);
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: applySorting,
        currentSort: sortBy,
        currentSortDirection: sortDirection,
    };

    return (
        <div className={styles.listingTableContainer}>
            <Table>
                <SortableTableHeader {...sortableTableProps} />
                <TableBody>{sortedControlGroups.map(tableRow)}</TableBody>
            </Table>
        </div>
    );
};
