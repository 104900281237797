import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Text from 'Components/Text/Text';

export interface RoleDescriptionsModalProps {
    hideModal: () => void;
}

export const RoleDescriptionsModal = (props: RoleDescriptionsModalProps) => {
    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                <ModalHeader text="Role Descriptions" />
                <Text>ADMIN: Grants access to all features, including company-wide settings and user management.</Text>
                <Text>CONTROL USER: Grants access to the "Control Automation" module.</Text>
                <Text>REPORTING USER: Grants access to the "Reporting and Exports" module.</Text>
                <Text>RISK MANAGER: Grants access to the "Risk Management" module, including the "Risk Review" feature.</Text>
                <Text>RISK USER: Grants access to the "Risk Management" module, excluding the "Risk Review" feature.</Text>
                <Text>THIRD PARTY USER: Grants access to the "Third-Party Risk Management" module.</Text>
                <div className={'modalFormButtonContainer'}>
                    <Button variant="primary" onClick={() => props.hideModal()} fontAwesomeImage={faTimes}>
                        CLOSE
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
