import { Button, Link } from 'Components/Buttons/Buttons';
import { TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getExceptionDetailsUrl } from 'Helpers/URLBuilder/URLBuilder';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { ControlExceptionResponse, VendorExceptionResponse, titleCaseExceptionStatus, titleCaseRiskScore } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ColorTheme } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './ExceptionsTableRow.module.css';

interface ExceptionsTableRowBaseProps {
    users: UserResponse[];

    /**
     * The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
     */
    colorTheme?: ColorTheme;
}

interface ExceptionsTableRowControlProps extends ExceptionsTableRowBaseProps {
    type: IssuesExceptionsModule.CONTROLS;
    exception: ControlExceptionResponse;
    displayMappedControlsModal: (mappedControls: OperationalControl[]) => void;
}

interface ExceptionsTableRowVendorProps extends ExceptionsTableRowBaseProps {
    type: IssuesExceptionsModule.TPRM;
    exception: VendorExceptionResponse;
    getVendorName: (vendorId: string) => string;
}

export type ExceptionsTableRowProps = ExceptionsTableRowControlProps | ExceptionsTableRowVendorProps;

export const ExceptionsTableRow = (props: ExceptionsTableRowProps) => {
    const textColor = (props.colorTheme ?? 'light') === 'light' ? 'blue' : 'white';

    return (
        <TableRow>
            <TableCell className={styles.titleCell}>
                <Link size="sm" to={getExceptionDetailsUrl(props.exception.id, props.exception.type)}>
                    {props.exception.title}
                </Link>
            </TableCell>
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {iso8601ToUsDateShort(props.exception.expiration_date)}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {getUserNameFromSubject(props.exception.owner_subject, props.users)}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {getUserNameFromSubject(props.exception.reviewer_subject, props.users)}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {titleCaseRiskScore(props.exception.risk_score)}
                </Text>
            </TableCellDefaultText>
            {props.type === IssuesExceptionsModule.CONTROLS && (
                <TableCellDefaultText>
                    <Button variant="linkText" size="sm" onClick={() => props.displayMappedControlsModal(props.exception.impacted_controls)}>{`${props.exception.impacted_controls.length} ${props.exception.impacted_controls.length === 1 ? 'Control' : 'Controls'}`}</Button>
                </TableCellDefaultText>
            )}
            {props.type === IssuesExceptionsModule.TPRM && (
                <TableCellDefaultText>
                    <Text color={textColor} noStyles>
                        {props.getVendorName(props.exception.impacted_vendor)}
                    </Text>
                </TableCellDefaultText>
            )}
            <TableCellDefaultText>
                <Text color={textColor} noStyles>
                    {titleCaseExceptionStatus(props.exception.status)}
                </Text>
            </TableCellDefaultText>
        </TableRow>
    );
};
