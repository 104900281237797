import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { Button } from 'Components/Buttons/Buttons';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Text from 'Components/Text/Text';
import { validateLimitValue } from 'Helpers/Limits';
import { ValidationError } from 'Models/ErrorTypes';
import { MetricName } from 'Models/ExternalIntegrations';
import { MetricAlertPreference, MetricAlertPreferenceSelectOptions, MetricLimit, MetricLimitDescription, ModifyLimitRequest } from 'Models/Limits';

import styles from './ModifyMetricLimitModal.module.css';
import { MetricOptionsTooltip } from '../MetricOptionsTooltip';

export interface ModifyMetricLimitModalProps {
    hideModal: () => void;
    alertsLimitsApi: AlertsLimitsApi;
    onLimitModified: () => void;
    metricLimit: MetricLimit;
}
interface FormFieldsState {
    limitValue?: number;
    alertPreference?: MetricAlertPreference;
}

const ModifyMetricLimitModal = (props: ModifyMetricLimitModalProps) => {
    const [isModifyingLimit, setIsModifyingLimit] = useState(false);
    const [failureMessage, setFailureMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({ limitValue: props.metricLimit.alert_limit, alertPreference: props.metricLimit.alert_preference });

    const modifyLimit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFailureMessage(undefined);
        setSuccessMessage(undefined);
        try {
            validateForm();

            setIsModifyingLimit(true);
            const modifyLimitRequest: ModifyLimitRequest = { alert_limit: formFieldsState.limitValue, alert_preference: formFieldsState.alertPreference };
            await props.alertsLimitsApi.modifyLimit(props.metricLimit.id, modifyLimitRequest);
            setSuccessMessage('Limit updated.');
            props.onLimitModified();
        } catch (error) {
            handleRequestError(error);
        } finally {
            setIsModifyingLimit(false);
        }
    };

    const validateForm = (): void => {
        if (!formFieldsState.alertPreference || formFieldsState.limitValue === undefined || formFieldsState.limitValue.toString().trim().length === 0) {
            throw new ValidationError('Please select an Alert Preference and Limit Value.');
        }

        validateLimitValue(props.metricLimit.metric_name, formFieldsState.limitValue);
    };

    const addMetricDescription = (): string => {
        if (props.metricLimit.metric_name === undefined) {
            return '';
        } else {
            // MetricName and MetricLimitDescription have the same keys, so this converts the metricName variable (which is of type MetricName) to its enum key, so the corresponding value in MetricLimitDescription can be obtained.
            const metricNameEnumKey = Object.keys(MetricName)[Object.values(MetricName).indexOf(props.metricLimit.metric_name)];
            return MetricLimitDescription[metricNameEnumKey as keyof typeof MetricLimitDescription];
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
    };

    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    const hideModal = (): void => {
        if (!isModifyingLimit) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={modifyLimit}>
                    <ModalHeader text={`Modify Limit: ${props.metricLimit.title}`} />
                    <div className={styles.fieldContainer}>
                        <FormFieldSelect options={MetricAlertPreferenceSelectOptions} handleChange={handleSelectChange} formFieldId="alertPreference" formFieldLabel="Alert Preference" tooltip={MetricOptionsTooltip} selectedOption={formFieldsState.alertPreference} isRequiredField />
                    </div>
                    <div className={styles.fieldContainer}>
                        <FormFieldText formFieldType="number" handleChange={handleChange} formFieldId="limitValue" formFieldLabel="Limit Value" required={true} tooltip="Set a numeric limit that determines when an alert is generated." invalidMessage="Please enter a valid number." value={formFieldsState.limitValue} />
                    </div>
                    <div className={styles.metricDescription}>
                        <Text>{addMetricDescription()}</Text>
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} disabled={isModifyingLimit} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={successMessage ? true : false} isLoading={isModifyingLimit} loadingText="Saving...">
                            SAVE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModifyMetricLimitModal;
