/*
	FrameworkSummary.tsx -- Main information content for framework.
*/

import { Component } from 'react';

import PageContent from 'Components/Containers/PageContent/PageContent';
import Text from 'Components/Text/Text';
import { ControlGroupResponse, ControlListingView, DetailedControlFrameworkResponse } from 'Models/OperationalControls';

import { ControlGroupGridView } from './ControlGroupGridView/ControlGroupGridView';
import { ControlGroupListView } from './ControlGroupListView/ControlGroupListView';
import { ControlGroupListingBodyToolbar, ControlGroupListingBodyToolbarProps } from './ControlGroupListingBodyToolbar/ControlGroupListingBodyToolbar';

export interface IndependentControlListingProps {
    controlGroupResponse?: ControlGroupResponse[];
    detailedControlFrameworkResponse?: DetailedControlFrameworkResponse;
}
export interface IndependentControlListingState {
    activeControlGroupListingView: ControlListingView;
}

export interface ControlGroupListingFilterTags {
    [key: string]: string[];
}

export default class IndependentControlListing extends Component<IndependentControlListingProps, IndependentControlListingState> {
    constructor(props: IndependentControlListingProps) {
        super(props);
        // Initialize state.
        this.state = {
            activeControlGroupListingView: ControlListingView.GRID,
        };
    }

    switchControlListingView = (activeControlGroupListingView: ControlListingView): void => {
        this.setState({ activeControlGroupListingView });
    };

    render(): JSX.Element | null {
        if (this.props.controlGroupResponse && this.props.detailedControlFrameworkResponse) {
            const controlListingBodyToolbarProps: ControlGroupListingBodyToolbarProps = {
                switchListingView: this.switchControlListingView,
                activeControlListingView: this.state.activeControlGroupListingView,
            };

            const controlGroupListingView = this.state.activeControlGroupListingView;

            if (this.props.controlGroupResponse.length === 0) {
                return (
                    <PageContent>
                        <Text>There are no enabled control groups.</Text>
                    </PageContent>
                );
            } else {
                return (
                    <>
                        <ControlGroupListingBodyToolbar {...controlListingBodyToolbarProps} />
                        {{ list: <ControlGroupListView controlGroups={this.props.controlGroupResponse} />, grid: <ControlGroupGridView controlGroupResponse={this.props.controlGroupResponse} /> }[controlGroupListingView]}
                    </>
                );
            }
        }
        return null;
    }
}
