import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import Text from 'Components/Text/Text';
import { Service } from 'Models/TPRM';

import styles from './DeleteTPRMServiceModal.module.css';

export interface DeleteTPRMServiceModalProps {
    hideModal: () => void;
    service: Service;
    tprmApi: TPRMApi;
    serviceDeleted: () => void;
}

const DeleteTPRMServiceModal = (props: DeleteTPRMServiceModalProps): JSX.Element => {
    const deleteService = async (): Promise<string> => {
        await props.tprmApi.deleteService(props.service.vendor_id, props.service.id);
        props.serviceDeleted();
        return 'Vendor service deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Vendor Service" areYouSureText={`Are you sure you want to delete "${props.service.vendor_name} - ${props.service.name}"?`} performOperation={deleteService} hideModal={props.hideModal}>
            <ul className={styles.deleteConfirmationList}>
                <li>
                    <Text>The in-progress risk workflow (inherent risk questionnaire, due diligence questionnaire, control assessment, and final review) for the vendor service will be deleted.</Text>
                </li>
                <li>
                    <Text>All historical risk workflows for the vendor service will be deleted.</Text>
                </li>
                {props.service.common_assessment_children.length > 0 && (
                    <>
                        <li>
                            <Text>
                                <b>This vendor service is providing a common assessment.</b> If you delete it, new vendor questionnaires will be generated for the following vendor services (based on their inherent risk scores):
                            </Text>
                        </li>
                        <ul>
                            <Text>
                                {props.service.common_assessment_children.map((child) => {
                                    return <li key={child.id}>{child.name}</li>;
                                })}
                            </Text>
                        </ul>
                    </>
                )}
            </ul>
        </ConfirmationModal>
    );
};

export default DeleteTPRMServiceModal;
