import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import Text from 'Components/Text/Text';
import { VendorResponseWithServices } from 'Models/TPRM';

import styles from './DeleteTPRMVendorModal.module.css';

export interface DeleteTPRMVendorModalProps {
    hideModal: () => void;
    vendor: VendorResponseWithServices;
    tprmApi: TPRMApi;
    vendorDeleted: () => void;
}

export const DeleteTPRMVendorModal = (props: DeleteTPRMVendorModalProps) => {
    const deleteVendor = async (): Promise<string> => {
        await props.tprmApi.deleteVendor(props.vendor.id);
        props.vendorDeleted();
        return 'Vendor deleted.';
    };

    return (
        <ConfirmationModal operationType="delete" headerText="Delete Vendor" areYouSureText={`Are you sure you want to delete "${props.vendor.name}"?`} hideModal={props.hideModal} performOperation={deleteVendor}>
            <ul className={styles.deleteConfirmationList}>
                <li>
                    <Text>All of the vendor's services will be deleted.</Text>
                </li>
                <li>
                    <Text>All issues mapped to the vendor will be deleted.</Text>
                </li>
                <li>
                    <Text>All exceptions mapped to the vendor will be deleted.</Text>
                </li>
            </ul>
        </ConfirmationModal>
    );
};

export default DeleteTPRMVendorModal;
