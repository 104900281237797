import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useMemo, useState } from 'react';

import { StackedBarAndLineChart } from 'Components/BaseCharts/StackedBarAndLineChart';
import { ChartAxis } from 'Components/BaseCharts/charts.common';
import { FormFieldDateOptionSelect } from 'Components/FormField/FormFieldDateOptionSelect/FormFieldDateOptionSelect';
import { ChangeEventType, FormFieldSelectDark } from 'Components/FormField/FormFieldSelectDark/FormFieldSelectDark';
import Text from 'Components/Text/Text';
import { DashboardRiskOptions, DateOptionSelectOptions, TprmDashboardRiskType, TprmDashboardServiceExtended, UpcomingDateRangeSelectOptions, isValidDashboardRiskType, isValidDateOption } from 'Models/Dashboards';
import { Effectiveness, effectivenessAsString } from 'Models/OperationalControls';
import { RiskRating, RiskRatingAsString, ServiceAssessmentState } from 'Models/TPRM';
import CustomDateRangeModal, { CustomDateRange, CustomDateRangeUpdateCallback } from 'Pages/Dashboards/Components/CustomDateRangeModal/CustomDateRangeModal';

import { SelectedServiceDetails, ServicesDisplayModal } from '../ServicesDisplayModal/ServicesDisplayModal';
import { AssessmentsBarChartDataRow, buildAssessmentsBarChartByMonth, getMonths } from '../TPRMDashboard.helpers';
import { getDatesFromPreset, useDateFilter } from '../TPRMDashboard.hooks';
import styles from '../TPRMDashboard.module.css';

enum Modals {
    NONE,
    DISPLAY_SERVICES,
    CUSTOM_DATE,
}

interface ServiceDataItem {
    monthKey: string;
    riskScore: number | undefined;
    serviceId: string;
    serviceName: string;
    vendorId: string;
    vendorName: string;
}

interface UpcomingAssessmentsChartData {
    assessmentDataRows: AssessmentsBarChartDataRow[];
    xAxisLabels: string[];
    yAxisInfo: ChartAxis[];
    servicesData: ServiceDataItem[];
}

interface UpcomingAssessmentsChartProps {
    vendorServices?: TprmDashboardServiceExtended[];
    vendorServicesError?: string;
}

export const TPRMUpcomingAssessmentsChart = (props: UpcomingAssessmentsChartProps): JSX.Element => {
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [servicesModalSubtitle, setServicesModalSubtitle] = useState<string>();
    const [selectedServices, setSelectedServices] = useState<SelectedServiceDetails[]>();
    const [selectedDates] = useState<{ start: Date; end: Date }>(getDatesFromPreset(DateOptionSelectOptions.NEXT_QUARTER));
    const [riskType, setRiskType] = useState<TprmDashboardRiskType>(TprmDashboardRiskType.RESIDUAL);
    const {
        dateFilter,
        updateDateFilter: { setPreset, setDateRange },
    } = useDateFilter(DateOptionSelectOptions.NEXT_QUARTER);

    const upcomingAssessmentsChartData = useMemo<UpcomingAssessmentsChartData | undefined>(() => {
        if (props.vendorServices) {
            const upcomingAssessmentsData: ServiceDataItem[] = props.vendorServices
                .filter((service) => service.assessment_state !== ServiceAssessmentState.ARCHIVING && service.assessment_due_date !== undefined)
                .map((service) => ({
                    monthKey: service.is_overdue ? ('overdue' as const) : moment(service.assessment_due_date).format('MMM YY'),
                    riskScore: (() => {
                        switch (riskType) {
                            case TprmDashboardRiskType.INHERENT:
                                return service.inherent_risk_score;
                            case TprmDashboardRiskType.CONTROL_EFFECTIVENESS:
                                return service.assessment_control_effectiveness ?? Effectiveness.INACTIVE;
                            case TprmDashboardRiskType.RESIDUAL:
                            default:
                                return service.assessment_residual_risk_score ?? RiskRating.INACTIVE;
                        }
                    })(),
                    serviceId: service.id,
                    serviceName: service.name,
                    vendorId: service.vendor_id,
                    vendorName: service.vendor_name,
                }));

            const baseMonths = getMonths(dateFilter.start, dateFilter.end);
            const months = ['overdue' as const, ...baseMonths];

            const assessmentDataRows = buildAssessmentsBarChartByMonth(upcomingAssessmentsData, riskType, months);
            const yAxisInfo: ChartAxis[] = [{ name: 'NUMBER OF ASSESSMENTS', position: 'left', minInterval: 1 }];

            return { assessmentDataRows, xAxisLabels: months.map((month) => month.toUpperCase()), yAxisInfo, servicesData: upcomingAssessmentsData };
        }
    }, [props.vendorServices, riskType, dateFilter]);

    const handleChartClick = (item: string): void => {
        const clickTargetDetails = item.split(' | ');
        const targetRating = clickTargetDetails[1];
        const targetDate = clickTargetDetails[0];

        if (targetRating && targetDate && upcomingAssessmentsChartData?.servicesData) {
            setServicesModalSubtitle(`${targetRating} - ${targetDate}`);
            setModalState(Modals.DISPLAY_SERVICES);
            const selectedServices = upcomingAssessmentsChartData.servicesData
                .filter((service) => {
                    if (service.monthKey.toUpperCase() === targetDate) {
                        if (riskType === TprmDashboardRiskType.CONTROL_EFFECTIVENESS) {
                            if (service.riskScore !== undefined && effectivenessAsString(service.riskScore) === targetRating) {
                                return service;
                            } else {
                                return null;
                            }
                        } else if (service.riskScore !== undefined && RiskRatingAsString(service.riskScore) === targetRating) {
                            return service;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                })
                .map((service) => ({
                    serviceId: service.serviceId,
                    serviceName: service.serviceName,
                    vendorId: service.vendorId,
                    vendorName: service.vendorName,
                }));
            setSelectedServices(selectedServices);
        }
    };

    const handleServicesModalClose = () => {
        setModalState(Modals.NONE);
        setServicesModalSubtitle(undefined);
        setSelectedServices(undefined);
    };

    const handleRiskTypeChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDashboardRiskType(value)) {
            setRiskType(value);
        }
    };

    const handleSelectCustomDateRange: CustomDateRangeUpdateCallback = (customDateRange: CustomDateRange) => {
        setDateRange(customDateRange);
    };

    const handleDateFilterChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDateOption(value)) {
            if (value === DateOptionSelectOptions.CUSTOM) {
                setModalState(Modals.CUSTOM_DATE);
            }

            setPreset(value);
        }
    };

    if (props.vendorServicesError) {
        return (
            <>
                <div className={styles.cellHeader}>
                    <Text variant="Header2" color="white">
                        Upcoming Assessments
                    </Text>
                </div>
                <Text color="white">{props.vendorServicesError}</Text>
            </>
        );
    }

    return (
        <>
            {modalState === Modals.CUSTOM_DATE && <CustomDateRangeModal startDate={selectedDates.start} endDate={selectedDates.end} datesRestriction="futureDatesOnly" hideModal={() => setModalState(Modals.NONE)} setCustomDateRange={handleSelectCustomDateRange} />}
            {modalState === Modals.DISPLAY_SERVICES && servicesModalSubtitle && selectedServices && <ServicesDisplayModal hideModal={handleServicesModalClose} subtitle={servicesModalSubtitle} selectedServices={selectedServices} />}
            <div className={styles.cellHeader}>
                <Text variant="Header2" color="white">
                    Upcoming Assessments
                </Text>
                <div className={styles.filters}>
                    <FormFieldSelectDark formFieldId="riskType" formFieldLabel="Risk Type" options={DashboardRiskOptions} selectedOption={riskType} handleChange={handleRiskTypeChange} />
                    <FormFieldDateOptionSelect formFieldId="date" options={UpcomingDateRangeSelectOptions} selectedOption={dateFilter.preset} handleChange={handleDateFilterChange} />
                </div>
            </div>
            {upcomingAssessmentsChartData ? <StackedBarAndLineChart onChartClick={handleChartClick} xAxisLabels={upcomingAssessmentsChartData.xAxisLabels} bars={upcomingAssessmentsChartData.assessmentDataRows} yAxisInfo={upcomingAssessmentsChartData.yAxisInfo} /> : <Skeleton variant="rounded" width={'100%'} height={300} />}{' '}
        </>
    );
};
