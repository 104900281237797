import { Radio } from '@mui/material';
import { Component } from 'react';

import Text from 'Components/Text/Text';

import styles from './RadioButtonGroup.module.css';
import { FormFieldTooltip } from '../../Tooltips/FormFieldTooltip';
import { GenericTooltip } from '../../Tooltips/GenericTooltip';
import { FormLabel } from '../FormLabel/FormLabel';

type RadioButtonOptionTypes = string | number | Array<string | number> | undefined;
export interface RadioButtonGroupProps {
    formFieldId: string;
    formFieldLabel?: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, formFieldId: string) => void;
    tooltip?: string;
    options: RadioButtonOptionType[];
    selectedOption?: RadioButtonOptionTypes;
    disabled?: boolean;
}

export interface RadioButtonOptionType {
    value: string | number;
    label: string;
    tooltip?: string;
    [key: string]: any;
}

export default class RadioButtonGroup extends Component<RadioButtonGroupProps> {
    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.handleChange(event, this.props.formFieldId);
    };
    render(): JSX.Element {
        const { disabled = false } = this.props;

        return (
            <>
                {this.props.formFieldLabel && <FormLabel tooltip={this.props.tooltip ? <FormFieldTooltip text={this.props.tooltip} /> : undefined}>{this.props.formFieldLabel}</FormLabel>}
                {this.props.options.map((option, index) => (
                    <div className={styles.radioContainer} key={index}>
                        <div>
                            <Radio
                                disabled={disabled}
                                checked={this.props.selectedOption === option.value}
                                onChange={this.handleChange}
                                value={option.value}
                                disableRipple
                                color="default"
                                sx={{
                                    '&.Mui-checked': {
                                        color: 'var(--hps-blue)',
                                    },
                                }}
                            />
                        </div>
                        <div className={styles.answerText}>
                            <Text noStyles>{option.label}</Text>
                        </div>
                        {option.tooltip && (
                            <div className={styles.toolTip}>
                                <GenericTooltip text={option.tooltip} />
                            </div>
                        )}
                    </div>
                ))}
            </>
        );
    }
}
