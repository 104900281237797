import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import Breadcrumb, { BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { UserNameFormat, getDepartmentFromSubject, getUserNameFromSubject } from 'Helpers/UserUtils';
import { TPRMServiceAssessmentExportRow } from 'Models/Exports';
import { UploadedFile } from 'Models/Files';
import { effectivenessAsString } from 'Models/OperationalControls';
import { numberAsRiskRatingString } from 'Models/TPRM';

import styles from '../DataExports.module.css';

interface VendorServiceAssessmentsProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const VendorServiceAssessments = (props: VendorServiceAssessmentsProps): JSX.Element => {
    const [vendorAssessments, setVendorAssessments] = useState<TPRMServiceAssessmentExportRow[]>();
    const [error, setError] = useState<string>();
    const { users } = useUsers();

    useEffect(() => {
        const getVendorServiceAssessmentsResponse = async (): Promise<void> => {
            try {
                const vendorServiceAssessmentsResponse = await props.exportsApi.getVendorServiceAssessmentsExport();
                setVendorAssessments(vendorServiceAssessmentsResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getVendorServiceAssessmentsResponse();
    }, [props.exportsApi]);

    const columns: GridColumn<TPRMServiceAssessmentExportRow>[] = [
        { field: 'vendor_name', headerName: 'Vendor Name', width: 300 },
        { field: 'service_name', headerName: 'Service Name', width: 300 },
        { field: 'status', headerName: 'Status', width: 300 },
        { field: 'submitter_department', headerName: 'Submitter Department', width: 300, valueGetter: (_value, row) => getDepartmentFromSubject(row.submitter, users) },
        { field: 'submitter', headerName: 'Submitter', width: 300, valueGetter: (value) => getUserNameFromSubject(value, users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('completion_time', 'Completion Date'),
        { field: 'inherent_risk', headerName: 'Inherent Risk', width: 300, valueGetter: (value) => numberAsRiskRatingString(value) },
        { field: 'assessment_documents', headerName: 'Assessment Documents', width: 300, valueGetter: (_value, row) => row.assessment_documents.map((file?: UploadedFile) => file?.filename).join(', ') },
        { field: 'control_effectiveness', headerName: 'Control Effectiveness', width: 300, valueGetter: (value) => effectivenessAsString(value) },
        { field: 'residual_risk_score', headerName: 'Residual Risk Score', width: 300, valueGetter: (value) => numberAsRiskRatingString(value) },
        { field: 'additional_info', headerName: 'Additional Info', width: 300 },
        { field: 'common_assessment_parent', headerName: 'Common Assessment Parent', width: 300, valueGetter: (_value, row) => (row.common_assessment_parent ? row.common_assessment_parent.name : '') },
        dateColumn('assessment_due_date', 'Assessment Due Date'),
    ];

    if (vendorAssessments) {
        return (
            <>
                <PageBackground color="grey">
                    <PageContent>
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                            <BreadcrumbText>Vendor Service Assessments</BreadcrumbText>
                        </Breadcrumb>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1">Vendor Service Assessments Export</Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <DataGrid columns={columns} getRowId={(vendorServiceAssessment: TPRMServiceAssessmentExportRow) => vendorServiceAssessment.id} rows={vendorAssessments} title="Vendor Service Assessments Export Preview" fileName="Vendor Services Assessments" disableVirtualization={props.disableVirtualization} />
                    </PageContent>
                </PageBackground>
            </>
        );
    } else if (error) return <Text color="darkBlue">{error}</Text>;
    else return <Placeholder />;
};
