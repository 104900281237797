/*
	ControlListingFilter.tsx -- Component for viewing controls (TSPs) in a Filter format.
*/

import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';

import IconButton from 'Components/Buttons/IconButton';

import styles from './ControlListingBodyToolbar.module.css';
import { ControlListingView } from '../../../Models/OperationalControls';

export interface ControlListingBodyToolbarProps {
    switchListingView: (view: ControlListingView) => void;
    activeControlListingView: ControlListingView;
}

export default class ControlListingBodyToolbar extends Component<ControlListingBodyToolbarProps> {
    // Constructor. Initialize state.
    constructor(props: ControlListingBodyToolbarProps) {
        super(props);

        this.state = {};
    }

    render(): JSX.Element {
        return (
            <div className={styles.controlListingFilter}>
                <div className={styles.tableAndGridButtonPanel}>
                    <IconButton aria-label="Switch to Control Grid View" onClick={() => this.props.switchListingView(ControlListingView.GRID)} fontAwesomeImage={faThLarge} disabled={this.props.activeControlListingView === ControlListingView.GRID} />
                    <IconButton aria-label="Switch to Control List View" onClick={() => this.props.switchListingView(ControlListingView.LIST)} fontAwesomeImage={faBars} disabled={this.props.activeControlListingView === ControlListingView.LIST} />
                </div>
            </div>
        );
    }
}
