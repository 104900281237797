import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { UpdateControlFrameworkConfigurationRequest, identifierMappedToRequest } from 'Models/TPRM';

import { Framework, QuestionnaireConfigurationModal } from '../DueDiligenceQuestionnaireConfiguration';
import styles from '../DueDiligenceQuestionnaireConfiguration.module.css';

export interface UpdateControlFrameworkModalProps extends QuestionnaireConfigurationModal {
    originalFramework: Framework;
}

interface FormState {
    isLoading: boolean;
    successMessage?: string;
    failureMessage?: string;
    frameworkUpdates?: UpdateControlFrameworkConfigurationRequest;
    complete: boolean;
}

const UpdateControlFrameworkModal = (props: UpdateControlFrameworkModalProps) => {
    const [formState, setFormState] = useState<FormState>({ isLoading: false, complete: false });
    const [formFieldsState, setFormFieldsState] = useState<UpdateControlFrameworkConfigurationRequest>({ control_framework_name: props.originalFramework.frameworkName });
    const [isValid, setIsValid] = useState<boolean>();

    useEffect(() => {
        if (formFieldsState.control_framework_name && formFieldsState.control_framework_name !== props.originalFramework.frameworkName) {
            setIsValid(true);
            return;
        }
        setIsValid(false);
    }, [formFieldsState, props.originalFramework]);

    useEffect(() => {
        const updateFramework = async (request: UpdateControlFrameworkConfigurationRequest) => {
            setFormState({ isLoading: true, complete: false });
            try {
                await props.tprmApi.updateConfiguration(new Map([identifierMappedToRequest(request, props.originalFramework.framework)]));
                setFormState({ isLoading: false, successMessage: 'Framework updated.', complete: true });
                props.onModalActionComplete();
            } catch (error) {
                setFormState({ isLoading: false, failureMessage: error.message, complete: false });
            }
        };

        if (formState.frameworkUpdates && isValid) {
            updateFramework(formState.frameworkUpdates);
        }
    }, [props.tprmApi, formState.frameworkUpdates, isValid, props.originalFramework.framework, props]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (formFieldsState?.control_framework_name) {
            const frameworkUpdates: UpdateControlFrameworkConfigurationRequest = {
                control_framework_name: formFieldsState.control_framework_name,
            };
            setFormState({ isLoading: false, frameworkUpdates: frameworkUpdates, complete: false });
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Update Framework Configuration" />
                    <div className={styles.marginTopBottom10}>
                        <FormFieldText value={formFieldsState?.control_framework_name} formFieldId="control_framework_name" formFieldLabel="Name" required handleChange={handleChange} tooltip="The name used to identify this control framework." />
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={formState.isLoading || !isValid || formState.complete} isLoading={formState.isLoading} loadingText="Updating...">
                            UPDATE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateControlFrameworkModal;
