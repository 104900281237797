import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import FormFieldSelect, { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import Text from 'Components/Text/Text';
import { ValidationError } from 'Models/ErrorTypes';
import { Service, ServiceSummary, UpdateCommonAssessmentRequest } from 'Models/TPRM';

import styles from './CommonAssessmentSelectionModal.module.css';

export interface CommonAssessmentSelectionModalProps {
    tprmApi: TPRMApi;
    service: Service;
    hideModal: () => void;
    onComplete: () => void;
}

interface FormState {
    isLoading: boolean;
    isComplete: boolean;
    successMessage?: string;
    failureMessage?: string;
}

export const CommonAssessmentSelectionModal = (props: CommonAssessmentSelectionModalProps): JSX.Element => {
    const [selectableServices, setSelectableServices] = useState<ServiceSummary[] | undefined>(undefined);
    const [selectedService, setSelectedService] = useState<ServiceSummary>();
    const [formState, setFormState] = useState<FormState>({ isLoading: false, isComplete: false });

    useEffect(() => {
        const getSelectableServices = async (): Promise<void> => {
            try {
                const servicesSummaries = (await props.tprmApi.getCommonAssessmentPotentialParents(props.service.vendor_id, props.service.id)).data;
                setSelectableServices(
                    servicesSummaries.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    })
                );
            } catch (error) {
                setFormState({ isLoading: false, isComplete: false, failureMessage: error.message });
            }
        };

        getSelectableServices();
    }, [props.service, props.tprmApi]);

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        const selectedService = selectableServices!.find((service) => service.id === value)!;
        setSelectedService(selectedService);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        try {
            validateForm();
            setFormState({ isLoading: true, isComplete: false });

            const request: UpdateCommonAssessmentRequest = {
                parent_identifier: { vendor_id: selectedService!.vendor_id, service_id: selectedService!.id },
                child_identifier: { vendor_id: props.service.vendor_id, service_id: props.service.id },
                linked: true,
            };
            await props.tprmApi.updateCommonAssessment(request);

            setFormState({ isLoading: false, isComplete: true, successMessage: 'Common assessment updated.' });
            props.onComplete();
        } catch (error) {
            setFormState({ isLoading: false, isComplete: false, failureMessage: error.message });
        }
    };

    const serviceOptions = selectableServices?.map((service) => {
        return {
            value: service.id,
            label: service.name,
        };
    });

    const selectionPossible = selectableServices && selectableServices.length > 0;

    const validateForm = () => {
        if (!selectedService) {
            throw new ValidationError('You must select a vendor service.');
        }
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Common Assessment" />
                    {selectableServices ? (
                        <>
                            <Text>Vendor services sharing a common assessment will share the same:</Text>
                            <ul>
                                <Text>
                                    <li>Vendor questionnaire and answers</li>
                                    <li>Control assessment</li>
                                    <li>Overall control effectiveness</li>
                                </Text>
                            </ul>
                            {selectionPossible && (
                                <>
                                    <Text>{`Select the vendor service that will provide a common assessment for ${props.service.name}.`}</Text>
                                    <div className={styles.serviceSelect}>
                                        <FormFieldSelect formFieldId="commonAssessmentVendorService" formFieldLabel="Vendor Service" options={serviceOptions} handleChange={handleSelectChange} />
                                    </div>
                                </>
                            )}
                            {!selectionPossible && <Text>{`There are no vendor services available to provide a common assessment for ${props.service.name}.`}</Text>}
                            <div className={'modalFormButtonContainer'}>
                                <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                                    CLOSE
                                </Button>
                                {selectionPossible && (
                                    <Button variant="submit" disabled={formState.isComplete} isLoading={formState.isLoading} loadingText="Saving...">
                                        SAVE
                                    </Button>
                                )}
                            </div>
                        </>
                    ) : (
                        <Text>Loading...</Text>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
};
