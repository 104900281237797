// Third-party libraries.
import { faArrowUpRightFromSquare, faComment, faDownload, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

// APIs.
import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
// Components.
import { Button } from 'Components/Buttons/Buttons';
import OverflowMenu, { OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { TableCellDefaultText, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
import FutureGovernanceVersionTooltip from 'Components/Tooltips/FutureGovernanceVersionTooltip';
// Configs.
import { GOVERNANCE } from 'Config/Paths';
// Helpers.
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { downloadDocument } from 'Helpers/FileUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
// Models.
import { FileState, UploadedFile } from 'Models/Files';
import { GovernanceContentType, GovernanceVersion, TextBasedGovernanceVersion, getHumanReadableGovernanceType } from 'Models/Governance';
import { UserResponse } from 'Models/User';

// Local code.
import styles from './GovernanceLibraryTableRow.module.css';

export interface GovernanceLibraryTableRowProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    governanceItem: GovernanceVersion;
    governanceItemIsManageable: boolean;
    users: UserResponse[];
    displayGovernanceTextModal: (governance: TextBasedGovernanceVersion) => void;
    displayGovernanceMappedControlsModal: (governanceWithAssociatedControls: GovernanceVersion) => void;
    controlIdentifier?: string;
    openExternalUrl: (url: string) => void;
}

const GovernanceLibraryTableRow = (props: GovernanceLibraryTableRowProps): JSX.Element => {
    const navigate = useNavigate();

    const menuText = getHumanReadableGovernanceType(props.governanceItem.type);

    const downloadGovernanceFile = async (file: UploadedFile): Promise<void> => {
        try {
            downloadDocument(props.documentApi, file);
        } catch (error) {
            console.log(error);
        }
    };

    const getOverflowItems = (): OverflowMenuProps => {
        const manageLocation = props.controlIdentifier !== undefined ? `/${GOVERNANCE}/${encodeURIComponent(props.governanceItem.id)}?controlId=${encodeURIComponent(props.controlIdentifier!)}` : `/${GOVERNANCE}/${encodeURIComponent(props.governanceItem.id)}`;
        const manageVerb = props.governanceItemIsManageable ? 'Manage' : 'View';
        const manageIcon = props.governanceItemIsManageable ? faEdit : faEye;

        const overflowItemsProp: OverflowMenuProps = {
            overflowItems: [
                {
                    text: `${manageVerb} ${menuText}`,
                    onClickAction: () => navigate(manageLocation),
                    icon: manageIcon,
                },
            ],
            accessibilityTitle: `${props.governanceItem.title} Menu`,
        };

        if (props.governanceItemIsManageable) {
            const governanceItem = props.governanceItem; // Without this line, the `onClickAction`s below close over the props object, so TS will (correctly) complain that the specific type of governance can't be guaranteed when `onClickAction` is called.
            if (governanceItem.content_type === GovernanceContentType.DOCUMENT && governanceItem.file.file_state === FileState.PASSED) {
                overflowItemsProp.overflowItems.push({
                    text: `Download ${menuText}`,
                    onClickAction: () => downloadGovernanceFile(governanceItem.file),
                    icon: faDownload,
                });
            } else if (governanceItem.content_type === GovernanceContentType.TEXT) {
                overflowItemsProp.overflowItems.push({
                    text: `View ${menuText}`,
                    onClickAction: () => props.displayGovernanceTextModal(governanceItem),
                    icon: faComment,
                });
            } else if (governanceItem.content_type === GovernanceContentType.EXTERNAL_URL) {
                overflowItemsProp.overflowItems.push({
                    text: `Open ${menuText} Link`,
                    onClickAction: () => props.openExternalUrl(governanceItem.external_url),
                    icon: faArrowUpRightFromSquare,
                });
            }
        }

        return overflowItemsProp;
    };

    return (
        <TableRow>
            <TableCellDefaultText>
                <div className={styles.governanceTitleContainer}>
                    {props.governanceItem.content_type === GovernanceContentType.DOCUMENT ? (
                        <UploadedFileAndState unclickable textVariant="Text2" title={props.governanceItem.title} documentApi={props.documentApi} file={props.governanceItem.file} />
                    ) : (
                        <Text noStyles variant="Text2">
                            {props.governanceItem.title}
                        </Text>
                    )}
                    <div className={styles.tooltipContainer}>
                        <FutureGovernanceVersionTooltip version={props.governanceItem} />
                    </div>
                </div>
                <Text variant="Text3" noStyles color="darkGray">
                    {menuText}
                </Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles={true}>{getUserNameFromSubject(props.governanceItem.owner_user_id, props.users)}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Button variant="linkText" size="sm" onClick={() => props.displayGovernanceMappedControlsModal(props.governanceItem)}>{`${props.governanceItem.associated_controls.length || '0'} ${props.governanceItem.associated_controls.length === 1 ? 'Control' : 'Controls'}`}</Button>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles={true}>{iso8601ToUsDateLong(props.governanceItem.effective_date)}</Text>
            </TableCellDefaultText>
            <TableOverflowCell>
                <OverflowMenu {...getOverflowItems()} />
            </TableOverflowCell>
        </TableRow>
    );
};

export default GovernanceLibraryTableRow;
