import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';

import styles from './ModifyEffectivenessLimitModal.module.css';
import { AlertsLimitsApi } from '../../../../Api/AlertsLimits/AlertsLimitsApi';
import { EffectivenessLimit, EffectivenessLimitsSelectOptions, ModifyLimitRequest } from '../../../../Models/Limits';
import FormFieldSelect, { ChangeEventType } from '../../../FormField/FormFieldSelect/FormFieldSelect';
import { ModalHeader } from '../../../Modal/ModalHeader';

export interface ModifyEffectivenessLimitModalProps {
    hideModal: () => void;
    alertsLimitsApi: AlertsLimitsApi;
    onLimitModified: () => void;
    limit: EffectivenessLimit;
}

const ModifyEffectivenessLimitModal = (props: ModifyEffectivenessLimitModalProps) => {
    const [isModifyingLimit, setIsModifyingLimit] = useState(false);
    const [effectiveness, setEffectiveness] = useState(props.limit.alert_limit);
    const [failureMessage, setFailureMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();

    const modifyLimit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFailureMessage(undefined);
        setSuccessMessage(undefined);
        if (effectiveness) {
            try {
                setIsModifyingLimit(true);
                const modifyLimitRequest: ModifyLimitRequest = { alert_limit: effectiveness };
                await props.alertsLimitsApi.modifyLimit(props.limit.id, modifyLimitRequest);
                setSuccessMessage('Limit updated.');
                props.onLimitModified();
            } catch (error) {
                handleRequestError(error);
            } finally {
                setIsModifyingLimit(false);
            }
        } else {
            setFailureMessage('Please select an Effectiveness Limit');
        }
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setEffectiveness(value as number);
    };

    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    const hideModal = (): void => {
        if (!isModifyingLimit) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={modifyLimit}>
                    <ModalHeader text={`Modify Limit: ${props.limit.title}`} />
                    <div className={styles.fieldContainer}>
                        <FormFieldSelect options={EffectivenessLimitsSelectOptions} handleChange={handleSelectChange} formFieldId="effectiveness" formFieldLabel="Effectiveness" tooltip="Every time the effectiveness level drops below the selected effectiveness limit, you will be notified by email." selectedOption={effectiveness} />
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes} disabled={isModifyingLimit}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={successMessage ? true : false} isLoading={isModifyingLimit} loadingText="Saving...">
                            SAVE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModifyEffectivenessLimitModal;
