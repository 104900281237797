import { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';

import { IndicatorVariant } from './Indicator';
import styles from './ProgressBarIndicator.module.css';

export interface ProgressBarIndicatorProps {
    size: 'large' | 'small';
    percent: number;
    variant: IndicatorVariant;
}

export default class ProgressBarIndicator extends Component<ProgressBarIndicatorProps> {
    applyVariantColor = (): string => {
        switch (this.props.variant) {
            case IndicatorVariant.RED:
                return styles.red;
            case IndicatorVariant.ORANGE:
                return styles.orange;
            case IndicatorVariant.YELLOW:
                return styles.yellow;
            case IndicatorVariant.LIGHTGREEN:
                return styles.lightgreen;
            case IndicatorVariant.DARKGREEN:
                return styles.darkgreen;
            case IndicatorVariant.BLUE:
                return styles.blue;
            case IndicatorVariant.GRAY:
                return styles.gray;
            case IndicatorVariant.LIGHTBLUE:
                return styles.lightblue;
        }
    };

    render(): JSX.Element {
        if (this.props.size === 'large') {
            return <ProgressBar className={`${styles.large} ${this.applyVariantColor()}`} now={this.props.percent} />;
        } else {
            return <ProgressBar className={`${styles.small} ${this.applyVariantColor()}`} now={this.props.percent} />;
        }
    }
}
