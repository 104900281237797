import { useCallback, useEffect, useState } from 'react';

import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import PageHeaderTabs from 'Components/Containers/PageHeaderTabs/PageHeaderTabs';
import Placeholder from 'Components/Placeholder/Placeholder';
import { Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';

import AlertsSummaryTab, { AlertsSummaryTabProps } from './AlertsSummayTab/AlertsSummaryTab';
import ManageLimitsTab, { ManageLimitsTabProps } from './ManageLimitsTab/ManageLimitsTab';
import { AlertsLimitsApi } from '../../Api/AlertsLimits/AlertsLimitsApi';
import { AlertResponse } from '../../Models/Alerts';
import { LimitResponse } from '../../Models/Limits';

export interface AlertsDashboardProps {
    alertsLimitsApi: AlertsLimitsApi;
}

const AlertsDashboard = (props: AlertsDashboardProps) => {
    const [alerts, setAlerts] = useState<AlertResponse[]>();
    const [limits, setLimits] = useState<LimitResponse[]>();

    useEffect(() => {
        const getAlerts = async (): Promise<void> => {
            try {
                const alertResponse = await props.alertsLimitsApi.getAllAlerts();
                setAlerts(alertResponse.data);
            } catch (error) {
                handleRequestError(error);
            }
        };

        getAlerts();
    }, [props.alertsLimitsApi]);

    const getLimits = useCallback(async (): Promise<void> => {
        try {
            const limitResponse = await props.alertsLimitsApi.getAllLimits();
            setLimits(limitResponse.data);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.alertsLimitsApi]);

    useEffect(() => {
        getLimits();
    }, [getLimits]);

    const handleRequestError = (error: Error): void => console.error('Error: ', error);

    if (alerts && limits) {
        const manageLimitsTabProps: ManageLimitsTabProps = {
            limitResponseList: limits,
            alertsLimitsApi: props.alertsLimitsApi,
            updateLimitListing: getLimits,
        };

        const alertSummaryTabProps: AlertsSummaryTabProps = {
            alertResponseList: alerts,
        };

        return (
            <PageHeaderTabs title="Alerts and Limits" defaultActiveTab="alertsSummary">
                <Tab title="Generated Alerts" eventKey="alertsSummary">
                    <PageBackground color="white">
                        <PageContent>
                            <AlertsSummaryTab {...alertSummaryTabProps} />
                        </PageContent>
                    </PageBackground>
                </Tab>
                <Tab title="Manage Limits" eventKey="manageLimits">
                    <PageBackground color="white">
                        <PageContent>
                            <ManageLimitsTab {...manageLimitsTabProps} />
                        </PageContent>
                    </PageBackground>
                </Tab>
            </PageHeaderTabs>
        );
    }

    return <Placeholder />;
};

export default AlertsDashboard;
