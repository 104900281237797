import { ControlText, OperationalControl } from 'Models/OperationalControls';

export const getHumanReadableControlIdFromControlIdMissingCustomNameError = 'A name must be provided for custom groups and controls.'; // Exported so it can be imported in tests.

/**
 * Convert ControlText[] to a string by recursively concatenating the nested ControlText objects within it.
 * @param initialCall has a default value and should not be set by the caller. It is used to eliminate leading whitespace from the final string, since each concatentation includes a space.
 */
export const controlTextToString = (controlTexts: ControlText[], initialCall = true): string => {
    let controlTextString = '';
    controlTexts.forEach((controlText) => {
        controlTextString += ` ${controlText.text}`;
        controlTextString += controlTextToString(controlText.children, false);
    });

    if (initialCall) {
        return controlTextString.trim();
    } else {
        return controlTextString;
    }
};

/**
 * Converts a canonical identifier for a Framework, Group, or Control to a more user-friendly string.
 * There is no point in passing just a Framework identifier to this function, but it supports it anyways in the interest of avoiding unexpected bugs (will simply return what was passed in).
 * NOTE: If you have an `OperationalControl` available, use `getHumanReadableControlIdFromControl` instead.
 * @param frameworkGroupControl For example, "CIS CSC (v8.0)#1" or "CIS CSC (v8.0)#1#1.1"
 * @param name Must be provided for custom Groups and Controls so that it is used instead of the GUID.
 * @returns For example, "CIS CSC (v8.0): 1" or "CIS CSC (v8.0): 1.1"
 */
export const getHumanReadableControlIdFromControlId = (frameworkGroupControl: string, name?: string): string => {
    if (frameworkGroupControl.includes('#')) {
        const parts = frameworkGroupControl.split('#');
        // If the entity is Custom (this RegEx checks if the ID is a GUID), then use the name provided by the API to make a "human readable" label.
        if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(parts[parts.length - 1])) {
            if (name === undefined) {
                throw Error(getHumanReadableControlIdFromControlIdMissingCustomNameError);
            }
            return parts[0] + ': ' + name;
            // Else, it's a standard entity, so use the Group or Control ID (depending on what is provided as input) to make a "human readable" label.
        } else {
            return parts[0] + ': ' + parts[parts.length - 1];
        }
    } else {
        return frameworkGroupControl;
    }
};

/**
 * Converts an `OperationalControl` into a user-friendly string.
 * This function is preferred over `getHumanReadableControlIdFromControlId` for `OperationalControl`s, since the user of `getHumanReadableControlIdFromControlId` can neglect to pass in `name`.
 */
export const getHumanReadableControlIdFromControl = (control: OperationalControl): string => {
    return getHumanReadableControlIdFromControlId(control.identifier, control.metadata.control_name);
};

/**
 * Forms the canonical identifier for a Framework, Group, or Control.
 * @param framework For example, "CIS CSC (v8.0)""
 * @param controlGroup For example, "1"
 * @param control For example, "1.1"
 * @returns For example, "CIS CSC (v8.0)#1#1.1"
 */
export const getOperationalControlIdentifierString = (framework: string, controlGroup?: string, control?: string): string => {
    let name = framework;
    if (controlGroup) {
        name = name + '#' + controlGroup;
        if (control) {
            name = name + '#' + control;
        }
    }

    return name;
};

/**
 * Extrapolates the control framework, control group ID, and control ID from a control's canonical string identifier.
 * For example, given a control with `identifier` equal to "CIS CSC (v7.1)#1#1.1", returns `{ controlFramework: 'CIS CSC (v7.1)', controlGroupId: '1', controlId: '1.1' }`.
 */
export const getFrameworkGroupControlParts = (control: OperationalControl) => {
    const [controlFramework, controlGroupId, controlId] = control.identifier.split('#');

    return {
        controlFramework,
        controlGroupId,
        controlId,
    };
};
