// Components.
import { Role } from 'Components/Context/RBACContext';

export enum Status {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export interface UserResponse {
    client_id: string;
    cognito_subject: string;
    department: string;
    email_address: string;
    first_name: string;
    last_name: string;
    mfa_required: boolean;
    roles: Role[];
    status: Status;
    [index: string]: boolean | string | Role[] | Status;
}

export interface CreateUserRequest {
    department: string;
    email_address: string;
    first_name: string;
    last_name: string;
    mfa_required: boolean;
    roles: Role[];
    send_registration_email: boolean;
}

export interface ModifyUserRequest {
    department?: string;
    email_address?: string;
    first_name?: string;
    last_name?: string;
    mfa_required: boolean;
    roles?: Role[];
}

/**
 * Note that the values in this enum must correspond to the property names of UserResponse.
 * This is because the user sorting function uses index access based on these values.
 */
export enum ManageUsersSortFilterOptions {
    LAST_NAME = 'last_name',
    EMAIL = 'email_address',
    DEPARTMENT = 'department',
    MFA_REQUIRED = 'mfa_required',
    ROLES = 'roles',
    STATUS = 'status',
}
