import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import Text from 'Components/Text/Text';

import styles from './ClearIRQModal.module.css';
import { TPRMApi } from '../../../../Api/TPRM/TPRMApi';
import { ModalHeader } from '../../../../Components/Modal/ModalHeader';

export interface ClearIRQModalProps {
    hideModal: () => void;
    tprmApi: TPRMApi;
    irqCleared: () => void;
    vendorId: string;
    serviceID: string;
}

interface ClearIRQModalState {
    successMessage?: string;
    failureMessage?: string;
    isClearingIRQ: boolean;
}

export default class ClearIRQModal extends Component<ClearIRQModalProps, ClearIRQModalState> {
    constructor(props: ClearIRQModalProps) {
        super(props);

        this.state = {
            isClearingIRQ: false,
        };
    }

    deleteIRQ = async (): Promise<void> => {
        try {
            this.setState({ isClearingIRQ: true, successMessage: undefined, failureMessage: undefined });
            await this.props.tprmApi.deleteIRQ(this.props.vendorId, this.props.serviceID);
            this.setState({ successMessage: 'Inherent risk questionnaire cleared.' });
            this.props.irqCleared();
        } catch (error) {
            this.setState({ failureMessage: error.message, successMessage: undefined });
        } finally {
            this.setState({ isClearingIRQ: false });
        }
    };

    hideModal = (): void => {
        if (!this.state.isClearingIRQ) {
            this.props.hideModal();
        }
    };

    render(): JSX.Element {
        return (
            <Modal show onHide={this.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="modalFromBody">
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.failureMessage && <Alert variant="danger">{this.state.failureMessage}</Alert>}
                    <ModalHeader text="Clear" />
                    <div className={styles.deleteText}>
                        <Text>Are you sure you want to clear the inherent risk questionnaire?</Text>
                        <Text>This will make the service inactive, and any progress on the vendor questionnaire will be lost.</Text>
                    </div>

                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={this.hideModal} fontAwesomeImage={faTimes} disabled={this.state.isClearingIRQ}>
                            CLOSE
                        </Button>
                        <Button variant="danger" onClick={() => this.deleteIRQ()} fontAwesomeImage={faTrash} loadingText={'Clearing...'} isLoading={this.state.isClearingIRQ} disabled={this.state.successMessage ? true : false}>
                            CLEAR
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
