import { Skeleton } from '@mui/material';
import { useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import PageCell from 'Components/Containers/PageCell/PageCell';
import Text from 'Components/Text/Text';
import { ComplianceSummary } from 'Models/Dashboards';

import { ComplianceCarousel } from './ComplianceCarousel/ComplianceCarousel';
import styles from './RequirementsOverview.module.css';
import { ComplianceRequirementDisplayInformation, ComplianceRequirementDisplayModal } from '../ComplianceRequirements/ComplianceRequirementDisplayModal/ComplianceRequirementDisplayModal';
import { stringToComplianceModalType } from '../OperationalControlsDashboard.helpers';

export interface RequirementsOverviewProps {
    complianceSummary?: ComplianceSummary[];
    complianceSummaryError?: Error;
    api: DashboardApi;
}

export const RequirementsOverview = (props: RequirementsOverviewProps): JSX.Element => {
    const [displayModalProps, setDisplayModalProps] = useState<ComplianceRequirementDisplayInformation>();

    const handleChartClick = (item: string, regulation: string): void => {
        if (stringToComplianceModalType(item)) {
            const regulationTypeSelected = stringToComplianceModalType(item);
            setDisplayModalProps({ regulationName: regulation, regulationTypeSelected: regulationTypeSelected! });
        }
    };

    if (props.complianceSummaryError) {
        return (
            <div className={styles.requirementsOverview}>
                <PageCell variant="transparentBlue">
                    <Text variant="Header2" color="white">
                        Compliance Requirements
                    </Text>
                    <Text variant="Header4" color="lightGray">
                        {props.complianceSummaryError.message}
                    </Text>
                </PageCell>
            </div>
        );
    } else {
        return (
            <>
                {displayModalProps && <ComplianceRequirementDisplayModal hideModal={() => setDisplayModalProps(undefined)} regulationInformation={displayModalProps} api={props.api} />}
                <div className={styles.requirementsOverview}>
                    <PageCell variant="transparentBlue">
                        <div>
                            <Text variant="Header2" color="white">
                                Compliance Requirements
                            </Text>

                            {props.complianceSummary ? (
                                <ComplianceCarousel complianceSummary={props.complianceSummary} handleChartClick={handleChartClick} />
                            ) : (
                                <>
                                    <Skeleton variant="text">
                                        <Text variant="Text2" color="darkGray">
                                            Regulation 1
                                        </Text>
                                    </Skeleton>
                                    <Skeleton height={50} width={'100%'} variant="rounded" />
                                    <Skeleton variant="text">
                                        <Text variant="Text2" color="darkGray">
                                            Regulation 2
                                        </Text>
                                    </Skeleton>
                                    <Skeleton height={50} width={'100%'} variant="rounded" />
                                    <Skeleton variant="text">
                                        <Text variant="Text2" color="darkGray">
                                            Regulation 3
                                        </Text>
                                    </Skeleton>
                                    <Skeleton height={50} width={'100%'} variant="rounded" />
                                </>
                            )}
                        </div>
                    </PageCell>
                </div>
            </>
        );
    }
};
