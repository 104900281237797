import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { GovernanceStatus, GovernanceVersion, getHumanReadableGovernanceType } from 'Models/Governance';

import { GenericTooltip, IconColor } from './GenericTooltip';

const FutureGovernanceVersionTooltip = (props: { version: GovernanceVersion }) => {
    if (props.version.status === GovernanceStatus.ACTIVE && props.version.has_future_effective_date) {
        return <GenericTooltip title="Future Version" text={`This ${getHumanReadableGovernanceType(props.version.type)} will not become active until ${iso8601ToUsDateLong(props.version.effective_date)}.`} fontAwesomeIcon={faExclamationCircle} iconColor={IconColor.YELLOW} />;
    } else {
        return <Fragment />;
    }
};

export default FutureGovernanceVersionTooltip;
