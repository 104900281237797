/*
    AWSExternalIntegrationsApi.ts -- AWS-specific interface used to CRUD the configuration of external integrations.
*/
import { AWS_CONFIG, CONFIGURATION, ERRORS, EXTERNAL_INTEGRATIONS, INTEGRATIONS, MAPPING, METRICS, STATUS, TEMPLATES, TOGGLE, VENDOR_MONITORING } from 'Config/Paths';
import { AwsConfigIntegration, AwsConfigIntegrationRequest, AwsConfigIntegrationSetupProgress, AwsConfigMisconfigurationDetailResponse, BitSightIntegrationRequest, IntegrationName, IntegrationResponse, MetricName, MicrosoftIntegrationRequest, ProofpointSecurityAwarenessTrainingIntegrationRequest, ProofpointTargetedAttackProtectionIntegrationRequest, QualysIntegrationRequest, Rapid7InsightPlatformIntegrationRequest, RiskReconIntegrationRequest, SecurityScorecardIntegrationRequest, SetMetricControlMappingRequest, SophosIntegrationRequest, TenableIntegrationRequest, ToggleMetricRequest, TprmVendorRiskScores } from 'Models/ExternalIntegrations';
import { FileDownloadResponse } from 'Models/Files';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiDelete, restApiGet, restApiPut } from 'RestApiConnector';

import { ExternalIntegrationsApi } from './ExternalIntegrationsApi';

export class AWSExternalIntegrationsApi implements ExternalIntegrationsApi {
    performAwsConfigAutomatedSetup = (): Promise<ResponseModel<void>> => {
        return restApiPut(`/${EXTERNAL_INTEGRATIONS}/${INTEGRATIONS}/${AWS_CONFIG}/${CONFIGURATION}`, {});
    };

    // Create or update an Integration (i.e. a user will set/update their credentials for the API of the External Integration).
    setExternalIntegration = (integrationName: IntegrationName, integrationConfiguration: AwsConfigIntegrationRequest | BitSightIntegrationRequest | MicrosoftIntegrationRequest | ProofpointTargetedAttackProtectionIntegrationRequest | ProofpointSecurityAwarenessTrainingIntegrationRequest | QualysIntegrationRequest | Rapid7InsightPlatformIntegrationRequest | RiskReconIntegrationRequest | SecurityScorecardIntegrationRequest | SophosIntegrationRequest | TenableIntegrationRequest): Promise<ResponseModel<void>> => {
        return restApiPut(`/${EXTERNAL_INTEGRATIONS}/${INTEGRATIONS}/${integrationName}`, integrationConfiguration);
    };

    // Delete an Integration (e.g. a user is no longer a customer of the vendor/product that the Integration applies to).
    deleteExternalIntegration = (integrationName: IntegrationName): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${EXTERNAL_INTEGRATIONS}/${INTEGRATIONS}/${integrationName}`);
    };

    // Get all configuration settings for all Integrations and Metrics. Used to render the Settings --> ExternalIntegrations tab.
    getExternalIntegrations = (): Promise<ResponseModel<IntegrationResponse[]>> => {
        return restApiGet(`/${EXTERNAL_INTEGRATIONS}`);
    };

    getIntegrationAwsConfig = (): Promise<ResponseModel<AwsConfigIntegration | void>> => {
        return restApiGet(`/${EXTERNAL_INTEGRATIONS}/${INTEGRATIONS}/${AWS_CONFIG}`);
    };

    getManagementPermissionsTemplate = (): Promise<ResponseModel<FileDownloadResponse>> => {
        return restApiGet(`/${EXTERNAL_INTEGRATIONS}/${INTEGRATIONS}/${AWS_CONFIG}/${CONFIGURATION}/${TEMPLATES}`);
    };

    getAwsConfigIntegrationSetupProgress = (): Promise<ResponseModel<AwsConfigIntegrationSetupProgress>> => {
        return restApiGet(`/${EXTERNAL_INTEGRATIONS}/${INTEGRATIONS}/${AWS_CONFIG}/${CONFIGURATION}/${STATUS}`);
    };

    getAwsConfigMisconfiguration = (): Promise<ResponseModel<AwsConfigMisconfigurationDetailResponse[]>> => {
        return restApiGet(`/${EXTERNAL_INTEGRATIONS}/${INTEGRATIONS}/${AWS_CONFIG}/${ERRORS}`);
    };

    // Set the Control mapping for a Metric.
    setMetricControlMapping = (integrationName: IntegrationName, metricName: MetricName, controlMapping: SetMetricControlMappingRequest): Promise<ResponseModel<void>> => {
        return restApiPut(`/${EXTERNAL_INTEGRATIONS}/${INTEGRATIONS}/${integrationName}/${METRICS}/${metricName}/${MAPPING}`, controlMapping);
    };

    // Toggle a Metric on/off.
    toggleMetric = (integrationName: IntegrationName, metricName: MetricName, toggleMetricRequest: ToggleMetricRequest): Promise<ResponseModel<void>> => {
        return restApiPut(`/${EXTERNAL_INTEGRATIONS}/${INTEGRATIONS}/${integrationName}/${METRICS}/${metricName}/${TOGGLE}`, toggleMetricRequest);
    };

    getVendorMonitoringMetricValues = (vendorId: string): Promise<ResponseModel<TprmVendorRiskScores[]>> => {
        return restApiGet(`/${EXTERNAL_INTEGRATIONS}/${METRICS}/${VENDOR_MONITORING}/${vendorId}`);
    };
}
