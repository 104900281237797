/*
	RequirementRow.tsx -- Component to render a single "row" in the listing of Compliance Requirements.
*/
import { faAngleRight, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';

import { AccordionItem } from 'Components/Accordion/AccordionItem/AccordionItem';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { Button, Link } from 'Components/Buttons/Buttons';
import { ComplianceRequirementStatus, ComplianceRequirementStatusProps } from 'Components/ComplianceRequirements/ComplianceRequirementStatus';
import Text from 'Components/Text/Text';
import { COMPLIANCE_REQUIREMENTS, DETAILS, REQUIREMENTS } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { ComplianceRequirement, ComplianceRequirementsForRegulationRow } from 'Models/ComplianceRequirements';

import styles from './Listing.module.css';

export interface RequirementRowProps {
    displayMappedControlsModal: (complianceRequirement: ComplianceRequirement) => void;
    requirementData: ComplianceRequirementsForRegulationRow;
    eventKey: string;
    indentLevel: number;
    regulationName: string;
}

export const RequirementRow = (props: RequirementRowProps): JSX.Element => {
    const getIndentStyle = (): string => {
        switch (props.indentLevel) {
            case 1:
                return styles.indentLevel0;
            case 2:
                return styles.indentLevel1;
            case 3:
                return styles.indentLevel2;
            case 4:
                return styles.indentLevel3;
            default:
                return styles.indentLevel4;
        }
    };

    const renderRequirementRow = (): JSX.Element[] => {
        const elements = [];
        const complianceRequirementStatusProps: ComplianceRequirementStatusProps = {
            status: props.requirementData['attributes']['status'] ? props.requirementData['attributes']['status'] : undefined,
        };

        /**
         * Render the hyperlink that pops a modal showing the Controls that the Compliance Requirement is mapped to.
         */
        const renderAssignedControlCountText = (): JSX.Element => {
            if (props.requirementData.attributes.managed) {
                let numberOfMappedControls = 0;
                for (const control_list of props.requirementData.attributes.assigned_control_sets) {
                    numberOfMappedControls += control_list.length;
                }
                return (
                    <Button variant="linkText" size="sm" onClick={() => props.displayMappedControlsModal(props.requirementData.attributes)}>
                        {`${numberOfMappedControls} ${numberOfMappedControls === 1 ? 'Control' : 'Controls'}`}
                    </Button>
                );
            } else {
                return (
                    <Text variant="Text3" noStyles>
                        -
                    </Text>
                );
            }
        };

        // Render the current Compliance Requirement's attributes, and an Accordion Toggle if it has children.
        elements.push(
            <Fragment key={props.requirementData['attributes']['identifier']}>
                <div className={`${styles.requirementContainer} ${getIndentStyle()}`}>
                    {Object.keys(props.requirementData['children']).length === 0 ? (
                        <div className={styles.arrowContainer}>
                            <FontAwesomeIcon icon={faAngleRight} className={styles.arrowIconHidden} />
                        </div>
                    ) : (
                        <AccordionToggle eventKey={props.eventKey} ariaLabelSuffix={props.requirementData['attributes']['identifier']} />
                    )}
                    <div className={styles.requirementIdentifierContainer}>
                        <Text variant="Text3" noStyles>
                            {props.requirementData['attributes']['identifier']}
                        </Text>
                    </div>
                    <div className={styles.requirementTextContainer}>
                        <Text variant="Text3" noStyles>
                            {props.requirementData['attributes']['text']}
                        </Text>
                    </div>
                    <div className={styles.requirementStatusContainer}>
                        <ComplianceRequirementStatus {...complianceRequirementStatusProps} />
                    </div>
                    <div className={styles.requirementLastAssessedContainer}>
                        <Text variant="Text3" noStyles>
                            {props.requirementData['attributes']['last_updated'] ? iso8601ToUsDateShort(props.requirementData['attributes']['last_updated']) : '-'}
                        </Text>
                    </div>
                    <div className={styles.requirementControlContainer}>{renderAssignedControlCountText()}</div>
                    <div className={styles.requirementOverflowMenu}>
                        {props.requirementData.attributes.managed && (
                            <Link variant="secondaryButton" to={`/${COMPLIANCE_REQUIREMENTS}/${props.regulationName}/${REQUIREMENTS}/${props.requirementData['attributes']['identifier']}/${DETAILS}`} fontAwesomeImage={faLink}>
                                Map Controls
                            </Link>
                        )}
                    </div>
                </div>
                <hr className={styles.hrModifier} />
            </Fragment>
        );

        return elements;
    };

    return <AccordionItem eventKey={props.eventKey}>{renderRequirementRow()}</AccordionItem>;
};
