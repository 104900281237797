/*
	FormFieldText.tsx - Component for text fields within an HTML form.
*/

import { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

import { Colors } from 'Components/Colors';
import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';

interface FormFieldTextProps {
    color?: Colors;
    formFieldId: string;
    formFieldLabel: string;
    formFieldType?: string;
    required?: boolean;
    hideRequiredSymbol?: boolean;
    tooltip?: string;
    placeholder?: string;
    pattern?: string;
    handleChange?: any;
    value?: string | number;
    disabled?: boolean;
    invalidMessage?: string;
    max?: number;
    step?: number;
    min?: number;
    onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export class FormFieldText extends Component<FormFieldTextProps> {
    render(): JSX.Element {
        return (
            <FormGroup controlId={this.props.formFieldId} className={styles.formField}>
                <FormLabel color={this.props.color} required={this.props.required} hideRequiredSymbol={this.props.hideRequiredSymbol}>
                    {this.props.formFieldLabel}
                </FormLabel>
                {this.props.tooltip && <FormFieldTooltip text={this.props.tooltip} />}
                <FormControl name={this.props.formFieldId} className={styles.customFormControl} type={this.props.formFieldType || 'text'} required={this.props.required} placeholder={this.props.placeholder} pattern={this.props.pattern} onChange={this.props.handleChange} value={this.props.value} disabled={this.props.disabled} onInput={this.props.onInput} max={this.props.max} min={this.props.min} step={this.props.step} />
                <FormControl.Feedback type="invalid">{this.props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
            </FormGroup>
        );
    }
}
