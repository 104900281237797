import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import { ActionsApi } from 'Api/Actions/ActionsApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Link } from 'Components/Buttons/Buttons';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageCell from 'Components/Containers/PageCell/PageCell';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import UserFilter from 'Components/Filters/UserFilter/UserFilter';
import FormFieldMultiOptionSelect from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import FormFieldSelect from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { ACTIONS, CREATE } from 'Config/Paths';
import { useDisplayableTagsLookup, useSortedCategorizedTagsOptions } from 'Hooks/Tags';
import { Action, ActionStatus, ActionStatusFilterOptions } from 'Models/Actions';
import { DisplayableTag } from 'Models/Tags';
import { GroupOptionType } from 'Models/Types/GlobalType';

import styles from './ActionsListing.module.css';
import { ActionsTable, ActionsTableProps } from './ActionsTable/ActionsTable';
import MappedRisksModal, { MappedRisksModalProps } from './MappedRisksModal/MappedRisksModal';

export interface ActionsListingProps {
    actionsApi: ActionsApi;
    tagsApi: TagsApi;
}

export const ActionsListing = (props: ActionsListingProps) => {
    const { users } = useUsers();
    const [actions, setActions] = useState<Action[]>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [selectedAction, setSelectedAction] = useState<Action>();
    const [selectedActionStatus, setSelectedActionStatus] = useState<ActionStatus | 'ALL_ACTIONS'>(ActionStatus.OPEN);
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);
    const getTagOptionsState = useSortedCategorizedTagsOptions(props.tagsApi);
    const [selectedTagsFilter, setSelectedTagsFilter] = useState<string[]>([]);
    const [selectedOwnerIds, setSelectedOwnerIds] = useState<string[]>([]);

    useEffect(() => {
        const getActions = async (): Promise<void> => {
            try {
                const actionsResponse = await props.actionsApi.getAllActions();
                setActions(actionsResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getActions();
    }, [props.actionsApi]);

    const getActionsTableProps = (actions: Action[], getDisplayableTags: (tagIds: string[]) => DisplayableTag[]) => {
        const actionsTableProps: ActionsTableProps = {
            actions: actions
                .filter((action) => {
                    if (action.status === selectedActionStatus) {
                        return true;
                    } else if (selectedActionStatus === 'ALL_ACTIONS') {
                        return true;
                    } else return false;
                })
                .filter((action) => selectedTagsFilter.length === 0 || action.tags.some((tag) => selectedTagsFilter.includes(tag)))
                .filter((action) => selectedOwnerIds.length === 0 || selectedOwnerIds.includes(action.owner)),
            showModal: showModal,
            getDisplayableTags: getDisplayableTags,
        };
        return actionsTableProps;
    };

    const showModal = (action: Action) => {
        setSelectedAction(action);
    };

    const mappedRisksModalProps: MappedRisksModalProps | undefined =
        actions && selectedAction !== undefined
            ? {
                  hideModal: () => setSelectedAction(undefined),
                  associatedRisks: selectedAction.associated_risks,
              }
            : undefined;

    if (actions && getDisplayableTagsState.type === 'success' && getTagOptionsState.type === 'success') {
        return (
            <>
                {mappedRisksModalProps && <MappedRisksModal {...mappedRisksModalProps} />}
                <PageBackground color="grey">
                    <PageContent>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1" color="darkBlue" noStyles>
                                Action Planning
                            </Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <PageCell>
                            <div className={styles.buttonContainer}>
                                <Text variant="Header2">Actions</Text>
                                <Link variant="primaryButton" to={`/${ACTIONS}/${CREATE}`} fontAwesomeImage={faAdd}>
                                    Create Action
                                </Link>
                            </div>
                            <div className={styles.secondaryHeader}>
                                <div className={styles.filterContainer}>
                                    <div className={styles.filter}>
                                        <FormFieldSelect selectedOption={selectedActionStatus} formFieldLabel="Filter by Status" formFieldId="status" options={ActionStatusFilterOptions} handleChange={(value) => setSelectedActionStatus(value as ActionStatus)} />
                                    </div>
                                    <div className={styles.filter}>
                                        <UserFilter filterLabel="Filter by Owner" onUsersSelected={(filter) => setSelectedOwnerIds(filter.value as string[])} users={users} userFilterId="owner" formFieldId="owner" />
                                    </div>
                                    <div className={styles.filter}>
                                        <FormFieldMultiOptionSelect
                                            defaultSelectedOptions={getTagOptionsState.data
                                                .map((group) => group.options)
                                                .flat()
                                                .filter((option) => selectedTagsFilter.includes(option.value as string))}
                                            formFieldLabel="Filter by Tags"
                                            formFieldId="tags"
                                            handleChange={(value: GroupOptionType[]) => setSelectedTagsFilter(value.map((tag) => tag.value as string))}
                                            options={getTagOptionsState.data}
                                            accessibilityLabel="tag selection"
                                        />
                                    </div>
                                </div>
                            </div>
                            <ActionsTable {...getActionsTableProps(actions, getDisplayableTagsState.data)} />
                        </PageCell>
                    </PageContent>
                </PageBackground>
            </>
        );
    } else if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else {
        return <Placeholder />;
    }
};
