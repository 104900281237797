import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon, IconButton as MaterialIconButton, Menu, MenuItem } from '@mui/material';
import { Component, Fragment } from 'react';

import Text from 'Components/Text/Text';

import styles from './Button.module.css';

const DOT_COLOR_DEFAULT = 'black';
interface OverflowItem {
    text: string;
    onClickAction: () => void;
    icon?: IconProp;
}

export interface OverflowMenuProps {
    overflowItems: OverflowItem[];
    overflowDotsColor?: 'black' | 'darkGray';
    accessibilityTitle: string; //Passed to the FontAwesomeIcon which enables accessibility on the overflow button.
    disabled?: boolean;
}
export interface OverflowMenuState {
    anchorEl: HTMLElement | null;
}

export default class OverflowMenu extends Component<OverflowMenuProps, OverflowMenuState> {
    constructor(props: OverflowMenuProps) {
        super(props);

        this.state = {
            anchorEl: null,
        };
    }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    };

    menuItemHandleClick = (overflowItem: OverflowItem): void => {
        this.setState({ anchorEl: null }, (): void => {
            overflowItem.onClickAction();
        });
    };

    overflowItem = (overflowItem: OverflowItem, index: number): JSX.Element => {
        return (
            <MenuItem onClick={() => this.menuItemHandleClick(overflowItem)} key={index}>
                {overflowItem.icon && (
                    <ListItemIcon sx={{ color: 'var(--hps-dark-blue)', paddingRight: '5px' }}>
                        <FontAwesomeIcon icon={overflowItem.icon} size="2x" />
                    </ListItemIcon>
                )}
                <Text noStyles={true}>{overflowItem.text}</Text>
            </MenuItem>
        );
    };

    render(): JSX.Element {
        const { overflowDotsColor = DOT_COLOR_DEFAULT, disabled = false } = this.props;
        return (
            <Fragment>
                <MaterialIconButton disabled={disabled} onClick={this.handleClick}>
                    <FontAwesomeIcon title={this.props.accessibilityTitle} icon={faEllipsisV} className={overflowDotsColor === DOT_COLOR_DEFAULT ? styles.overflowDots : styles.overflowDotsDarkGray} />
                </MaterialIconButton>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={this.state.anchorEl !== null}
                    onClose={this.handleClose}
                    elevation={0}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {this.props.overflowItems.map(this.overflowItem)}
                </Menu>
            </Fragment>
        );
    }
}
