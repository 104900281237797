import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import PageContent from 'Components/Containers/PageContent/PageContent';
import GovernanceLibraryTable, { GovernanceLibraryTableProps } from 'Components/Governance/GovernanceLibraryTable/GovernanceLibraryTable';
import Text from 'Components/Text/Text';
import { GovernanceVersion, TextBasedGovernanceVersion } from 'Models/Governance';
import { UserResponse } from 'Models/User';

import styles from '../GovernanceLibrary.module.css';

export interface RetiredGovernanceTabProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    governanceItems: GovernanceVersion[];
    users: UserResponse[];
    openExternalUrl: (url: string) => void;
    displayGovernanceTextModal: (governance: TextBasedGovernanceVersion) => void;
    displayGovernanceWithAssociatedControlsModal: (governanceWithAssociatedControls: GovernanceVersion) => void;
}

const RetiredGovernanceTab = (props: RetiredGovernanceTabProps): JSX.Element => {
    const governanceListingTableProps: GovernanceLibraryTableProps = {
        governanceApi: props.governanceApi,
        documentApi: props.documentApi,
        users: props.users,
        governanceItems: props.governanceItems,
        governanceItemsAreManageable: false,
        openExternalUrl: props.openExternalUrl,
        displayGovernanceTextModal: props.displayGovernanceTextModal,
        displayGovernanceMappedControlsModal: props.displayGovernanceWithAssociatedControlsModal,
    };

    return (
        <PageContent>
            <div className={styles.header}>
                <div className={styles.pageSectionHeaderText}>
                    <Text variant="Header2">Policies, Standards, and Procedures</Text>
                </div>
            </div>
            <GovernanceLibraryTable {...governanceListingTableProps} />
        </PageContent>
    );
};

export default RetiredGovernanceTab;
