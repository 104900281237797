import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import OverflowMenu, { OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import Switch from 'Components/Buttons/Switch';
import Text from 'Components/Text/Text';
import { controlTextToString } from 'Helpers/ControlFormatter/ControlFormatter';
import { Limit, MetricAlertPreference, MetricLimit, ModifyLimitRequest } from 'Models/Limits';
import { ControlText } from 'Models/OperationalControls';

import styles from './MetricLimitsTableRow.module.css';

export interface MetricLimitsTableRowProps {
    limit: MetricLimit;
    displayDeleteLimitModal: (deleteLimitId: string, deleteLimitTitle: string) => void;
    displayModifyLimitModal: (modifyLimit: Limit) => void;
    alertsLimitsApi: AlertsLimitsApi;
}

const MetricLimitsTableRow = (props: MetricLimitsTableRowProps) => {
    const [toggleChecked, setToggleChecked] = useState(props.limit.enabled);

    const overflowMenuProps: OverflowMenuProps = {
        overflowItems: [
            {
                text: 'Delete Limit',
                onClickAction: (): void => props.displayDeleteLimitModal(props.limit.id, props.limit.title),
                icon: faTrash,
            },
            {
                text: 'Update Limit',
                onClickAction: (): void => props.displayModifyLimitModal(props.limit),
                icon: faEdit,
            },
        ],
        accessibilityTitle: `${props.limit.title} Menu`,
    };

    /**
     * If the Limit is for a Control, the description will be in the form of ControlText[].
     * If the Limit is for a Group, the description will be a string.
     * If the Limit is for a Framework, the description will be undefined.
     * This function checks the number of # symbols in the entity ID to determine if the entity is a Control or not, and parses the description accordingly.
     */
    const displayDescription = (): string => {
        const hashCount = (props.limit.entity_id.match(/#/g) || []).length;
        // This is a Framework Limit, so there is no description.
        if (props.limit.entity_description === undefined) {
            return '';
        } else if (hashCount === 2) {
            return controlTextToString(props.limit.entity_description as ControlText[]);
        } else {
            return props.limit.entity_description as string;
        }
    };

    const displayPreference = (): string => {
        if (props.limit.alert_preference === MetricAlertPreference.ONCE_PER_DAY) {
            return 'Once per Day';
        } else if (props.limit.alert_preference === MetricAlertPreference.WHEN_BREACHED) {
            return 'When Breached';
        } else if (props.limit.alert_preference === MetricAlertPreference.WHEN_POLLED) {
            return 'When Polled';
        } else {
            return '';
        }
    };

    const handleRequestError = (error: Error): void => {
        console.log(error);
        setToggleChecked(!toggleChecked);
    };

    const toggleLimitHandler = async (): Promise<void> => {
        const toggleState = !toggleChecked;
        setToggleChecked(toggleState);
        try {
            const toggleLimitRequest: ModifyLimitRequest = { enabled: toggleState };
            await props.alertsLimitsApi.modifyLimit(props.limit.id, toggleLimitRequest);
        } catch (err) {
            handleRequestError(err);
        }
    };

    return (
        <div className={styles.rowContainer}>
            <div className={styles.toggleContainer}>
                <Switch checked={toggleChecked} onChange={toggleLimitHandler} name={props.limit.title} />
            </div>
            <div className={styles.titleContainer}>
                <Text noStyles={true} variant="Text2">
                    {props.limit.title}
                </Text>
                <Text color="darkGray">{displayDescription()}</Text>
            </div>
            <div className={styles.limitPreferenceContainer}>
                <Text>{props.limit.alert_limit.toString()}</Text>
            </div>
            <div className={styles.limitPreferenceContainer}>
                <Text>{displayPreference()}</Text>
            </div>
            <div className={styles.overflowContainer}>
                <OverflowMenu {...overflowMenuProps} />
            </div>
        </div>
    );
};

export default MetricLimitsTableRow;
