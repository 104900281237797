import { faCalendar, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';

import OverflowMenu, { OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import SortableTableHeader, { HeaderData, SortDirection, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Table, { TableBody, TableCell, TableCellDefaultText, TableCellLink, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import Text from 'Components/Text/Text';
import { DASHBOARDS, SERVICES, TPRM, VENDORS } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { ServiceListingSortFilterOptions } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import styles from './ServiceListingTableView.module.css';
import TableRiskStatusBar from './TableRiskStatusBar/TableRiskStatusBar';
import { ServiceDisplayData } from '../ServiceListing';

export interface ServiceListingTableViewProps {
    serviceRows: ServiceDisplayData[];
    applySorting: (sortProperty: string, sortDirection: SortDirection) => void;
    selectedDeleteService: (service: ServiceDisplayData) => void;
    selectedModifyService: (service: ServiceDisplayData) => void;
    selectedServiceAssessmentDueDateService: (service: ServiceDisplayData) => void;
    currentSort: string;
    currentSortDirection: SortDirection;
    users: UserResponse[];
}

export default class ServiceListingTableView extends Component<ServiceListingTableViewProps> {
    headerValues: HeaderData[] = [
        { dataKey: ServiceListingSortFilterOptions.VENDOR, label: 'VENDOR' },
        { dataKey: ServiceListingSortFilterOptions.SERVICE, label: 'SERVICE' },
        { dataKey: ServiceListingSortFilterOptions.RISKRATING, label: 'INHERENT RISK' },
        { dataKey: ServiceListingSortFilterOptions.RESIDUAL_RISK, label: 'RESIDUAL RISK' },
        { dataKey: ServiceListingSortFilterOptions.VENDOR_SERVICE_MANAGER_USER_ID, label: 'VENDOR SERVICE MANAGER' },
        { dataKey: ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE, label: 'ASSESSMENT DUE DATE' },
        { dataKey: ServiceListingSortFilterOptions.DATE_CREATED, label: 'DATE CREATED' },
    ];

    tableRow = (serviceRow: ServiceDisplayData, index: number): JSX.Element => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [
                {
                    text: 'Update Service',
                    onClickAction: () => this.props.selectedModifyService(this.props.serviceRows[index]),
                    icon: faEdit,
                },
                {
                    text: 'Set Assessment Due Date',
                    onClickAction: () => this.props.selectedServiceAssessmentDueDateService(this.props.serviceRows[index]),
                    icon: faCalendar,
                },
                {
                    text: 'Delete Service',
                    onClickAction: () => this.props.selectedDeleteService(this.props.serviceRows[index]),
                    icon: faTrash,
                },
            ],
            accessibilityTitle: `${serviceRow.serviceName} Menu`,
        };

        return (
            <TableRow key={index}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{serviceRow.vendorName}</Text>
                </TableCellDefaultText>
                <TableCellLink link={`/${TPRM}/${VENDORS}/${serviceRow.vendorId}/${SERVICES}/${serviceRow.serviceID}/${DASHBOARDS}`}>{serviceRow.serviceName}</TableCellLink>
                <TableCell>
                    <TableRiskStatusBar risk={serviceRow.riskRating} />
                </TableCell>
                <TableCell>
                    <TableRiskStatusBar risk={serviceRow.residualRisk ? serviceRow.residualRisk : 0} />
                </TableCell>
                <TableCellDefaultText>
                    <Text noStyles={true}>{getUserNameFromSubject(serviceRow.vendorManagerId, this.props.users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{serviceRow.assessmentDueDate ? iso8601ToUsDateShort(serviceRow.assessmentDueDate) : undefined}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{iso8601ToUsDateShort(serviceRow.dateCreated)}</Text>
                </TableCellDefaultText>
                <TableOverflowCell>
                    <OverflowMenu {...overflowMenuProps} />
                </TableOverflowCell>
            </TableRow>
        );
    };

    render(): JSX.Element {
        const sortableTableProps: SortableTableHeaderProps = {
            headers: this.headerValues,
            applySorting: this.props.applySorting,
            currentSort: this.props.currentSort,
            currentSortDirection: this.props.currentSortDirection,
            tableIncludesOverflowMenu: true,
        };
        return (
            <div className={styles.riskTable}>
                <Table>
                    <SortableTableHeader {...sortableTableProps} />
                    <TableBody>{this.props.serviceRows.map(this.tableRow)}</TableBody>
                </Table>
            </div>
        );
    }
}
