import PageCell from 'Components/Containers/PageCell/PageCell';
import Text from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { RiskHistory, RiskResponse, formatTotalImpactOrProbability } from 'Models/RiskRegister';

import styles from './RiskListingCallouts.module.css';

export interface RiskListingCalloutsProps {
    risks: RiskResponse[] | RiskHistory[];
    completeEnvironmentEffectivenessAverage: number;
}

export const RiskListingCallouts = (props: RiskListingCalloutsProps): JSX.Element => {
    const getRiskStats = (riskType: keyof RiskResponse): { total?: number; average?: number } => {
        const definedValues = props.risks
            .map((risk) => {
                switch (riskType) {
                    case 'total_target_risk':
                        return risk.total_target_risk ?? risk.total_current_risk;
                    default:
                        return risk[riskType];
                }
            })
            .filter((value) => value !== undefined);
        const total = definedValues.length ? (definedValues as number[]).reduce((a, b) => a + b, 0) : undefined;
        const average = total !== undefined && definedValues.length ? total / definedValues.length : undefined;
        return { total: riskType === 'control_environment_effectiveness' ? props.completeEnvironmentEffectivenessAverage : total, average };
    };

    const getCalloutLabel = (riskType: keyof RiskResponse) => {
        switch (riskType) {
            case 'total_inherent_risk':
                return 'Inherent Risk';
            case 'total_current_risk':
                return 'Current Residual Risk';
            case 'total_target_risk':
                return 'Target Residual Risk';
            case 'control_environment_effectiveness':
                return 'Control Effectiveness';
        }
    };

    const getTooltipText = (riskType: keyof RiskResponse) => {
        switch (riskType) {
            case 'total_target_risk':
                return 'The calculation of target residual risk takes all risks into account. If no target residual risk has been defined for a risk, then the current residual risk (if defined) is used.';
            case 'control_environment_effectiveness':
                return 'Risk-related control effectiveness is the average of all controls that are mapped to risks. Overall control effectiveness is the average of all controls in the system.';
            default:
                return undefined;
        }
    };

    const riskTypes: Array<keyof RiskResponse> = ['total_inherent_risk', 'control_environment_effectiveness', 'total_current_risk', 'total_target_risk'];

    return (
        <div className={styles.calloutContainer}>
            {riskTypes.map((riskType) => {
                const calloutLabel = getCalloutLabel(riskType);
                const tooltipText = getTooltipText(riskType);
                const riskStats = getRiskStats(riskType);

                return (
                    <PageCell variant="transparentBlue" key={riskType}>
                        <div className={styles.cellHeader}>
                            <Text variant="Header2" color="white">
                                {calloutLabel}
                            </Text>
                            {tooltipText && <FormFieldTooltip text={tooltipText} />}
                        </div>
                        <div className={styles.innerAverageCell} data-testid={riskType}>
                            <div className={styles.stat}>
                                <Text noStyles variant="Header4" color="darkGray">
                                    {riskType === 'control_environment_effectiveness' ? 'RISK-RELATED' : 'AVERAGE'}
                                </Text>
                                <Text noStyles variant="Text1" color="white">
                                    {formatTotalImpactOrProbability(riskStats.average)}
                                </Text>
                            </div>
                            <div className={styles.stat}>
                                <Text noStyles variant="Header4" color="darkGray">
                                    {riskType === 'control_environment_effectiveness' ? 'OVERALL' : 'TOTAL'}
                                </Text>
                                <Text noStyles variant="Text1" color="white">
                                    {formatTotalImpactOrProbability(riskStats.total)}
                                </Text>
                            </div>
                        </div>
                    </PageCell>
                );
            })}
        </div>
    );
};
