import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

import IconButton from 'Components/Buttons/IconButton';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import Text from 'Components/Text/Text';

import styles from './EditableQuestionOptions.module.css';

export interface EditableQuestionOptionsProps {
    options?: string[];
    newOptionText: string;
    newOptionTextChanged: (text: string) => void;
    addNewOption: () => void;
    removeOption: (option: string) => void;
}

const EditableQuestionOptions = (props: EditableQuestionOptionsProps): JSX.Element | null => {
    return (
        <div className={styles.options}>
            <div className={styles.newOption}>
                <div className={styles.newOptionTextField}>
                    <FormFieldText required formFieldLabel="Options" formFieldId="newOption" handleChange={(event: React.FormEvent<HTMLInputElement>) => props.newOptionTextChanged(event.currentTarget.value)} value={props.newOptionText} />
                </div>
                <div className={styles.newOptionButton}>
                    <IconButton aria-label="Add New Option" onClick={() => props.addNewOption()} fontAwesomeImage={faPlusCircle} />
                </div>
            </div>
            {props.options?.map((option, index) => (
                <div className={styles.questionOption} key={index}>
                    <Text noStyles>{option}</Text>
                    <div className={styles.deleteIcon}>
                        <IconButton aria-label={`Delete Option ${index}`} fontAwesomeImage={faTrash} onClick={() => props.removeOption(option)} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EditableQuestionOptions;
