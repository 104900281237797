import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import PageBackground from 'Components/Containers/PageBackground/PageBackground';
import PageContent from 'Components/Containers/PageContent/PageContent';
import { RBACContext, Role, userHasAuthorizedRole } from 'Components/Context/RBACContext';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import Text from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { DashboardType } from 'Models/Dashboards';

import styles from './Dashboards.module.css';
import { OperationalControlsDashboard } from './OperationalControlsDashboard/OperationalControlsDashboard';
import { TPRMDashboard } from './TPRMDashboard/TPRMDashboard';

export interface DashboardsProps {
    dashboardApi: DashboardApi;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
    tprmApi: TPRMApi;
}

export const Dashboards = (props: DashboardsProps): JSX.Element => {
    const userContext = useContext(RBACContext);
    const navigate = useNavigate();
    const location = useLocation();

    const activeHash = (() => {
        if (location.hash && location.hash.length > 0) {
            const strippedHash = location.hash.substring(1);
            if (Object.values(DashboardType).includes(strippedHash as DashboardType)) {
                return strippedHash;
            }
        }
        return DashboardType.OPERATIONAL_CONTROLS;
    })();

    const onTabSelect = (key?: string) => {
        if (key) {
            navigate(`${location.pathname}#${key}`, { replace: true });
        }
    };

    return (
        <div className={styles.contentWrapper}>
            <PageBackground color="blueMountains">
                <div className={styles.content}>
                    <PageContent>
                        <Text variant="Header1" color="white">
                            Dashboards
                        </Text>
                    </PageContent>
                    {userContext && userHasAuthorizedRole(userContext.roles, [Role.ADMIN, Role.REPORTING_USER]) ? (
                        <PrimaryTabs defaultActiveTab={activeHash} transparent tabButtonTextColor={'white'} onSelect={onTabSelect}>
                            <Tab eventKey={DashboardType.OPERATIONAL_CONTROLS} title="Control Automation" unmountOnExit={true}>
                                <OperationalControlsDashboard dashboardApi={props.dashboardApi} issuesApi={props.issuesApi} exceptionsApi={props.exceptionsApi} />
                            </Tab>
                            <Tab eventKey={DashboardType.THIRD_PARTY} title="Third-Party Risk Management" unmountOnExit={true}>
                                <TPRMDashboard dashboardApi={props.dashboardApi} issuesApi={props.issuesApi} exceptionsApi={props.exceptionsApi} tprmApi={props.tprmApi} />
                            </Tab>
                        </PrimaryTabs>
                    ) : (
                        <Text color="white">{UNAUTHORIZED_MESSAGE}</Text>
                    )}
                </div>
            </PageBackground>
        </div>
    );
};
