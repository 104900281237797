import { cloneDeep } from 'lodash-es';
import { Component } from 'react';

import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Text from 'Components/Text/Text';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { VendorListingSortProperty, VendorResponseWithServices } from 'Models/TPRM';
import { Filter, Filters } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './VendorListing.module.css';
import VendorListingBodyToolbar, { VendorListingBodyToolbarProps } from './VendorListingBodyToolbar/VendorListingBodyToolbar';
import VendorListingGridView, { VendorListingGridViewProps } from './VendorListingGridView/VendorListingGridView';
import VendorListingTableView, { VendorListingTableViewProps } from './VendorListingTableView/VendorListingTableView';

export interface VendorListingProps {
    users: UserResponse[];
    vendors: VendorResponseWithServices[];
    selectedDeleteVendor: (vendorId: string) => void;
    selectedModifyVendor: (vendorId: string) => void;
    selectedCreateServiceForVendor: (vendor: VendorResponseWithServices) => void;
    selectedViewAllServicesForVendor: (vendor: VendorResponseWithServices) => void;
    selectedViewFolders: (vendorId: string) => void;
}

interface VendorListingState {
    activeVendorListingView: VendorListingView;
    vendors: VendorResponseWithServices[];
    selectedFilterOptions: Filters;
    currentSortDirection: SortDirection;
    currentlySortedBy: string;
}

export enum VendorListingView {
    LIST = 'list',
    GRID = 'grid',
}

export default class VendorListing extends Component<VendorListingProps, VendorListingState> {
    constructor(props: VendorListingProps) {
        super(props);

        this.state = {
            activeVendorListingView: VendorListingView.GRID,
            vendors: this.props.vendors,
            selectedFilterOptions: {},
            currentlySortedBy: VendorListingSortProperty.NAME,
            currentSortDirection: SortDirection.ASC,
        };
    }

    switchServiceListingView = (activeServiceListingView: VendorListingView): void => {
        this.setState({ activeVendorListingView: activeServiceListingView });
    };

    setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        this.setState({ currentlySortedBy: newSort as VendorListingSortProperty, currentSortDirection: newSortDirection });
    };

    setCurrentFilterSelection = (updatedFilter: Filter | Filter[]): void => {
        const selectedFilterOptions = cloneDeep(this.state.selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    selectedFilterOptions[filter.key] = filter;
                } else {
                    delete selectedFilterOptions[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                selectedFilterOptions[updatedFilter.key] = updatedFilter;
            } else {
                delete selectedFilterOptions[updatedFilter.key];
            }
        }

        this.setState({ selectedFilterOptions });
    };

    filterAndSortVendors = (): VendorResponseWithServices[] => {
        let vendors = [...this.props.vendors];

        if (Object.keys(this.state.selectedFilterOptions).length > 0) {
            for (const filter of Object.values(this.state.selectedFilterOptions)) {
                vendors = vendors.filter((vendor) => {
                    return filter.value.includes(vendor[filter.key]);
                });
            }
        }

        if (!this.state.currentlySortedBy) {
            return vendors;
        }

        return vendors.sort((vendorA, vendorB) => {
            let sortResult = 0;
            switch (this.state.currentlySortedBy) {
                case VendorListingSortProperty.NAME:
                    sortResult = vendorA.name.localeCompare(vendorB.name);
                    break;
                case VendorListingSortProperty.CREATED_TIME:
                    sortResult = vendorA.created_time < vendorB.created_time ? 1 : -1;
                    break;
                case VendorListingSortProperty.VENDOR_MANAGER_USER_ID:
                    sortResult = compareUsersBySubjectForSorting(vendorA.vendor_manager_user_id, vendorB.vendor_manager_user_id, this.props.users);
                    break;
                default:
                    break;
            }

            return this.state.currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    render(): JSX.Element {
        if (this.props.vendors.length === 0) {
            return (
                <div className={styles.zeroStateContainer}>
                    <Text>You have not created any vendors. To do so, select "CREATE NEW VENDOR" in the top-right.</Text>
                </div>
            );
        } else {
            const vendors = this.filterAndSortVendors();

            const vendorListingBodyToolbarProps: VendorListingBodyToolbarProps = {
                activeVendorListingView: this.state.activeVendorListingView,
                switchListingView: this.switchServiceListingView,
                filterVendors: this.setCurrentFilterSelection,
                sortCardsBy: this.setCurrentSort,
                currentlySortedBy: this.state.currentlySortedBy,
                users: this.props.users,
            };

            const vendorListingGridViewProps: VendorListingGridViewProps = {
                vendors: vendors,
                selectedCreateServiceForVendor: this.props.selectedCreateServiceForVendor,
                selectedModifyVendor: this.props.selectedModifyVendor,
                selectedDeleteVendor: this.props.selectedDeleteVendor,
                users: this.props.users,
                selectedViewFolders: this.props.selectedViewFolders,
            };

            const vendorListingTableViewProps: VendorListingTableViewProps = {
                vendors: vendors,
                applySorting: this.setCurrentSort,
                currentlySortedBy: this.state.currentlySortedBy,
                currentSortDirection: this.state.currentSortDirection,
                selectedModifyVendor: this.props.selectedModifyVendor,
                selectedDeleteVendor: this.props.selectedDeleteVendor,
                selectedCreateServiceForVendor: this.props.selectedCreateServiceForVendor,
                selectedViewAllServicesForVendor: this.props.selectedViewAllServicesForVendor,
                selectedViewFolders: this.props.selectedViewFolders,
                users: this.props.users,
            };

            return (
                <>
                    <VendorListingBodyToolbar {...vendorListingBodyToolbarProps} />
                    {{ grid: <VendorListingGridView {...vendorListingGridViewProps} />, list: <VendorListingTableView {...vendorListingTableViewProps} /> }[this.state.activeVendorListingView]}
                </>
            );
        }
    }
}
