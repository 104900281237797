/*
	NavHeader.js -- Component for the header at the top of the navigation menu.
*/

import { Component } from 'react';

import { ClientDetailsApi } from 'Api/Client/ClientDetailsApi';
import Text from 'Components/Text/Text';

import styles from './NavHeader.module.css';

export interface NavHeaderProps {
    clientDetailsApi: ClientDetailsApi;
}

interface NavHeaderState {
    clientName: string | null;
}

export default class NavHeader extends Component<NavHeaderProps, NavHeaderState> {
    clientDetailsApi: ClientDetailsApi;
    constructor(props: NavHeaderProps) {
        super(props);

        this.state = {
            clientName: null,
        };
        this.clientDetailsApi = this.props.clientDetailsApi;
    }
    componentDidMount(): void {
        this.getClientDetails();
    }
    getClientDetails = async (): Promise<void> => {
        try {
            const clientDetailsResponse = await this.clientDetailsApi.getClientDetails();
            const clientDetails = clientDetailsResponse.data;
            const clientName = clientDetails.client_name;
            this.setState({ clientName });
        } catch (err) {
            console.error('Error: ', err);
        }
    };
    render(): JSX.Element {
        return (
            <div className={styles.navHeader}>
                <Text color="white" variant="Text2">
                    {this.state.clientName}
                </Text>
            </div>
        );
    }
}
