import Text from 'Components/Text/Text';

export interface VendorLocationProps {
    country: string;
    state?: string;
    city?: string;
    address_line_1?: string;
    address_line_2?: string;
    zip_code?: string;
}

const line = (text: string | undefined): JSX.Element => {
    return text ? (
        <>
            {text}
            <br />
        </>
    ) : (
        <></>
    );
};

const VendorLocation = (props: VendorLocationProps): JSX.Element => {
    const stateCityZip = (() => {
        let text = undefined;
        if (props.city && props.state) {
            text = props.zip_code ? `${props.city}, ${props.state} ${props.zip_code}` : `${props.city}, ${props.state}`;
        } else if (props.city) {
            text = props.city;
        } else if (props.state) {
            text = props.state;
        } else {
            text = undefined;
        }

        return line(text);
    })();

    const country = line(props.country);

    return (
        <Text variant="Text2" color="white">
            {stateCityZip}
            {country}
        </Text>
    );
};

export default VendorLocation;
