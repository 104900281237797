import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Button } from 'Components/Buttons/Buttons';
import Text from 'Components/Text/Text';

import styles from './ModalHeader.module.css';

export interface ModalHeaderProps {
    text: string;
    secondaryText?: string;
}

export const ModalHeader = (props: ModalHeaderProps) => {
    return (
        <>
            <Text variant="Header2">{props.text}</Text>
            {props.secondaryText && <Text variant="Header4">{props.secondaryText}</Text>}
            <hr />
        </>
    );
};

interface ModalHeaderWithButtonProps {
    title: string;
    onClick: () => void;
    fontAwesomeImage: IconProp;
    buttonText: string;
}

export const ModalHeaderWithButton = (props: ModalHeaderWithButtonProps) => {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.header}>
                <Text variant="Header2">{props.title}</Text>
                <Button variant="primary" onClick={props.onClick} fontAwesomeImage={props.fontAwesomeImage}>
                    {props.buttonText}
                </Button>
            </div>
            <hr />
        </div>
    );
};
