import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { StackedBarAndLineChart } from 'Components/BaseCharts/StackedBarAndLineChart';
import { CSSColors } from 'Components/Colors';
import PageCell from 'Components/Containers/PageCell/PageCell';
import { ChangeEventType, FormFieldDateOptionSelect } from 'Components/FormField/FormFieldDateOptionSelect/FormFieldDateOptionSelect';
import { FormFieldSelectDark } from 'Components/FormField/FormFieldSelectDark/FormFieldSelectDark';
import Text from 'Components/Text/Text';
import { DashboardType, DateOptionSelectOptions, DefaultDateRangeSelectOptions, isValidDateOption } from 'Models/Dashboards';
import { ExceptionResponse, ExceptionRiskScore, ExceptionRiskScoreFilter, ExceptionRiskScoreFilterOptions, ExceptionStatus, isValidExceptionRiskScoreFilter } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { VendorResponse } from 'Models/TPRM';
import CustomDateRangeModal, { CustomDateRange, CustomDateRangeUpdateCallback } from 'Pages/Dashboards/Components/CustomDateRangeModal/CustomDateRangeModal';
import { getMonths } from 'Pages/Dashboards/TPRMDashboard/TPRMDashboard.helpers';
import { getDatesFromPreset } from 'Pages/Dashboards/TPRMDashboard/TPRMDashboard.hooks';

import styles from './DashboardsHistoricalExceptions.module.css';
import { ClosedExceptionModalDisplayType, ClosedExceptionsDisplayModal } from './HistoricalExceptionsDisplayModal/HistoricalExceptionsDisplayModal';
import { countClosedExceptionsByMonth, countExceptionsOrIssuesByMonth, countTotalExceptionsByMonth, getClosedExceptionsPerMonth } from '../../OperationalControlsDashboard/OperationalControlsDashboard.helpers';

export interface DashboardClosedExceptionsProps {
    exceptionsApi: ExceptionsApi;
    dashboardType: DashboardType;
    vendors?: VendorResponse[];
    vendorError?: string;
}

enum Modals {
    NONE,
    DISPLAY_CLOSED_EXCEPTIONS, // TODO: rename this. It's not just for closed exceptions.
    CUSTOM_DATE,
}

/**
 * Renders a bar-and-line chart that displays, over a given time period, and grouped by month:
 * * The number of exceptions opened
 * * The number of exceptions closed
 * * The total number of exceptions in the system
 *
 * TODO: rename this. It's not just for closed exceptions.
 */
export const DashboardClosedExceptions = (props: DashboardClosedExceptionsProps): JSX.Element => {
    const [dashboardExceptions, setDashboardExceptions] = useState<ExceptionResponse[]>();
    const [closedExceptions, setClosedExceptions] = useState<number[]>();
    const [totalOpenExceptions, setTotalOpenExceptions] = useState<number[]>();
    const [openedExceptions, setOpenedExceptions] = useState<number[]>();
    const [exceptionsError, setExceptionsError] = useState<string>();
    const [monthLabel, setMonthLabel] = useState<string[]>();
    const [clickedExceptions, setClickedExceptions] = useState<ExceptionResponse[]>();
    const [selectDateOption, setSelectDateOption] = useState<DateOptionSelectOptions>(DateOptionSelectOptions.PREVIOUS_QUARTER);
    const [selectedDates, setSelectedDates] = useState<{ start: Date; end: Date }>(getDatesFromPreset(DateOptionSelectOptions.PREVIOUS_QUARTER));
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [clickedDate, setClickedDate] = useState<string>();
    const [modalDisplayType, setModalDisplayType] = useState<ClosedExceptionModalDisplayType>();
    const [exceptionsFilterType, setExceptionsFilterType] = useState<ExceptionRiskScoreFilter>(ExceptionRiskScoreFilter.ALL);

    const moderateColor = CSSColors.YELLOW;
    const lineColor = CSSColors.LIGHT_BLUE;
    const gray = CSSColors.GRAY;

    useEffect(() => {
        const getExceptions = async () => {
            try {
                const exceptionsResponse = await props.exceptionsApi.getAllExceptions();

                if (props.dashboardType === DashboardType.OPERATIONAL_CONTROLS) {
                    const allOCExceptions = exceptionsResponse.data.filter((exception) => exception.type === IssuesExceptionsModule.CONTROLS && exception.status !== ExceptionStatus.DRAFT_OPEN);
                    setDashboardExceptions(allOCExceptions);
                } else {
                    const allTPRMExceptions = exceptionsResponse.data.filter((exception) => exception.type === IssuesExceptionsModule.TPRM && exception.status !== ExceptionStatus.DRAFT_OPEN);
                    setDashboardExceptions(allTPRMExceptions);
                }
            } catch (err) {
                setExceptionsError(err.message);
            }
        };
        getExceptions();
    }, [props.dashboardType, props.exceptionsApi]);

    useEffect(() => {
        const exceptionPriorityFilter = (exception: ExceptionResponse): boolean => {
            if (exceptionsFilterType === ExceptionRiskScoreFilter.ALL) {
                return true;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.HIGH) {
                return exception.risk_score === ExceptionRiskScore.HIGH;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.MODERATE_HIGH) {
                return exception.risk_score === ExceptionRiskScore.MODERATE_HIGH;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.MODERATE) {
                return exception.risk_score === ExceptionRiskScore.MODERATE;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.LOW_MODERATE) {
                return exception.risk_score === ExceptionRiskScore.LOW_MODERATE;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.LOW) {
                return exception.risk_score === ExceptionRiskScore.LOW;
            } else {
                return true;
            }
        };
        if (dashboardExceptions) {
            const dashboardExceptionsByPriority = dashboardExceptions.filter(exceptionPriorityFilter);
            const allClosedExceptions = dashboardExceptionsByPriority.filter((exception) => exception.status === ExceptionStatus.CLOSED);

            const months = getMonths(selectedDates.start, selectedDates.end);

            const openedExceptions = countExceptionsOrIssuesByMonth(dashboardExceptionsByPriority, months);
            const exceptionsClosedByMonth = countClosedExceptionsByMonth(allClosedExceptions, months);
            const totalExceptions = countTotalExceptionsByMonth(dashboardExceptionsByPriority, months);

            setClosedExceptions(exceptionsClosedByMonth);
            setOpenedExceptions(openedExceptions);
            setMonthLabel(months);
            setTotalOpenExceptions(totalExceptions);
        }
    }, [dashboardExceptions, exceptionsFilterType, selectedDates.end, selectedDates.start]);

    const handleChartClick = (item: string) => {
        const clickedItem = item.split(' | ');
        const currentFilteredExceptions = dashboardExceptions?.filter((exception) => {
            if (exceptionsFilterType === ExceptionRiskScoreFilter.ALL) {
                return true;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.HIGH) {
                return exception.risk_score === ExceptionRiskScore.HIGH;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.MODERATE_HIGH) {
                return exception.risk_score === ExceptionRiskScore.MODERATE_HIGH;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.MODERATE) {
                return exception.risk_score === ExceptionRiskScore.MODERATE;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.LOW_MODERATE) {
                return exception.risk_score === ExceptionRiskScore.LOW_MODERATE;
            } else if (exceptionsFilterType === ExceptionRiskScoreFilter.LOW) {
                return exception.risk_score === ExceptionRiskScore.LOW;
            } else {
                return true;
            }
        });
        if (clickedItem[1] === 'Total Closed' && currentFilteredExceptions) {
            const allClosedExceptions = currentFilteredExceptions.filter((exception) => exception.status === ExceptionStatus.CLOSED);
            setClickedExceptions(getClosedExceptionsPerMonth(allClosedExceptions, clickedItem[0]));
            setClickedDate(clickedItem[0]);
            setModalState(Modals.DISPLAY_CLOSED_EXCEPTIONS);
            setModalDisplayType(ClosedExceptionModalDisplayType.CLOSED);
        } else if (clickedItem[1] === 'Total Opened' && currentFilteredExceptions) {
            const exceptions = currentFilteredExceptions.filter((exception) => moment(exception.created_timestamp).format('MMM YY') === clickedItem[0]);
            setClickedExceptions(exceptions);
            setClickedDate(clickedItem[0]);
            setModalState(Modals.DISPLAY_CLOSED_EXCEPTIONS);
            setModalDisplayType(ClosedExceptionModalDisplayType.OPEN);
        }
    };

    const handleDateFilterChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDateOption(value)) {
            if (value === DateOptionSelectOptions.CUSTOM) {
                setModalState(Modals.CUSTOM_DATE);
            } else {
                setSelectedDates(getDatesFromPreset(value));
                setSelectDateOption(value);
            }
        }
    };

    const handleSelectCustomDateRange: CustomDateRangeUpdateCallback = (customDateRange: CustomDateRange) => {
        setSelectedDates({ start: customDateRange.startDate, end: customDateRange.endDate });
        setSelectDateOption(DateOptionSelectOptions.CUSTOM);
    };

    const handleRiskTypeChange = (value: ChangeEventType, formFieldId: string) => {
        if (isValidExceptionRiskScoreFilter(value)) {
            setExceptionsFilterType(value as ExceptionRiskScoreFilter);
        }
    };

    if (exceptionsError) {
        return (
            <div className={styles.section2}>
                <PageCell variant="transparentBlue">
                    <div className={styles.cellHeader}>
                        <Text variant="Header2" color="white">
                            Exception History
                        </Text>
                    </div>
                    <Text color="white" variant="Header4">
                        {exceptionsError}
                    </Text>
                </PageCell>
            </div>
        );
    }

    if (props.vendorError) {
        return (
            <div className={styles.section2}>
                <PageCell variant="transparentBlue">
                    <div className={styles.cellHeader}>
                        <Text variant="Header2" color="white">
                            Exception History
                        </Text>
                    </div>
                    <Text color="white" variant="Header4">
                        {props.vendorError}
                    </Text>
                </PageCell>
            </div>
        );
    }

    return (
        <>
            {modalState === Modals.CUSTOM_DATE && <CustomDateRangeModal startDate={selectedDates.start} endDate={selectedDates.end} datesRestriction="pastDatesOnly" hideModal={() => setModalState(Modals.NONE)} setCustomDateRange={handleSelectCustomDateRange} />}
            {modalState === Modals.DISPLAY_CLOSED_EXCEPTIONS && clickedDate && clickedExceptions && modalDisplayType !== undefined && <ClosedExceptionsDisplayModal selectedException={clickedExceptions} date={clickedDate} hideModal={() => setModalState(Modals.NONE)} modalType={modalDisplayType} dashboardType={props.dashboardType} vendors={props.vendors} />}
            <div className={styles.section2}>
                <PageCell variant="transparentBlue">
                    <div className={styles.cellHeader}>
                        <Text variant="Header2" color="white">
                            Exception History
                        </Text>
                        <div className={styles.filters}>
                            <FormFieldSelectDark formFieldId="exceptionRiskScore" formFieldLabel="Risk Score..." options={ExceptionRiskScoreFilterOptions} invalidMessage="A risk score must be provided." selectedOption={exceptionsFilterType} handleChange={handleRiskTypeChange} />
                            <FormFieldDateOptionSelect formFieldId="date" options={DefaultDateRangeSelectOptions} selectedOption={selectDateOption} handleChange={handleDateFilterChange} />
                        </div>
                    </div>
                    {closedExceptions && monthLabel && totalOpenExceptions && openedExceptions ? (
                        <StackedBarAndLineChart
                            onChartClick={handleChartClick}
                            xAxisLabels={monthLabel}
                            line={{ name: 'Total Exceptions', color: moderateColor, data: totalOpenExceptions }}
                            bars={[
                                { name: 'Total Opened', color: lineColor, data: openedExceptions },
                                { name: 'Total Closed', color: gray, data: closedExceptions },
                            ]}
                            yAxisInfo={[
                                { name: 'TOTAL CLOSED/OPENED', position: 'left', minInterval: 1 },
                                { name: 'TOTAL EXCEPTIONS', position: 'right', minInterval: 1 },
                            ]}
                            group={true}
                        />
                    ) : (
                        <Skeleton variant="rounded" width={'100%'} height={300} />
                    )}
                </PageCell>
            </div>
        </>
    );
};
