// The following 2 lines are needed because this package never created a type declaration file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Lottie from 'react-lottie-player';

import Text from 'Components/Text/Text';

import styles from './LoggingIn.module.css';
import animationData from '../../Lottie/loading.json';

const LoggingIn = (): JSX.Element => {
    return (
        <div className={styles.logInBackground}>
            <div className={styles.logInContainer}>
                <Lottie loop animationData={animationData} play className={styles.animation} />
                <div className={styles.logInTitle}>
                    <Text variant="Header1">Logging in to SummIT Security...</Text>
                </div>
            </div>
        </div>
    );
};

export default LoggingIn;
