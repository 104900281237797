import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Component } from 'react';

import Text from 'Components/Text/Text';

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

const VARIANT_DEFAULT = 'standard';

export interface SortableTableHeaderProps {
    headers: HeaderData[];
    currentSort: string;
    currentSortDirection: SortDirection;
    applySorting: (sortProperty: string, sortDirection: SortDirection) => void;
    tableIncludesOverflowMenu?: boolean;
    variant?: 'standard' | 'dashboard';
}

export interface HeaderData {
    label: string;
    dataKey: string;
    disableSort?: boolean;
}

const standardTableStyles = {
    backgroundColor: '#E6EDF1',
    borderTop: '1px solid #dee2e6',
    maxHeight: '30px',
    borderRadius: '9px',
    width: '100%',
} as const;

const dashboardTableStyles = {
    borderTop: 'hidden',
    maxHeight: '30px',
    borderRadius: '9px',
    width: '100%',
} as const;

export default class SortableTableHeader extends Component<SortableTableHeaderProps> {
    applySorting = (newSort: string) => (): void => {
        let newSortDirection: SortDirection;

        if (newSort === this.props.currentSort) {
            if (this.props.currentSortDirection === SortDirection.ASC) {
                newSortDirection = SortDirection.DESC;
            } else {
                newSortDirection = SortDirection.ASC;
            }
        } else {
            newSortDirection = SortDirection.ASC;
        }

        this.props.applySorting(newSort, newSortDirection);
    };

    createHeaderCell = (header: HeaderData, index: number): JSX.Element => {
        const { variant = VARIANT_DEFAULT } = this.props;
        const textColor = variant === VARIANT_DEFAULT ? 'blue' : 'darkGray';
        if (header.disableSort === true) {
            return (
                <TableCell key={index}>
                    <Text variant="Text4" color={textColor} noStyles>
                        {header.label}
                    </Text>
                </TableCell>
            );
        } else {
            return (
                <TableCell key={index} sortDirection={this.props.currentSort === header.dataKey ? 'desc' : false}>
                    <TableSortLabel active={this.props.currentSort === header.dataKey} direction={this.props.currentSortDirection ? this.props.currentSortDirection : 'desc'} onClick={this.applySorting(header.dataKey)}>
                        <Text variant="Text4" color={textColor} noStyles>
                            {header.label}
                        </Text>
                    </TableSortLabel>
                </TableCell>
            );
        }
    };

    render(): JSX.Element {
        const { variant = VARIANT_DEFAULT } = this.props;
        const rootStyle = variant === VARIANT_DEFAULT ? standardTableStyles : dashboardTableStyles;
        return (
            <TableHead sx={rootStyle}>
                <TableRow sx={{ '& th': { padding: '.5rem 12px' } }}>
                    {this.props.headers.map((header, index) => this.createHeaderCell(header, index))}
                    {this.props.tableIncludesOverflowMenu === true && <TableCell></TableCell>}
                </TableRow>
            </TableHead>
        );
    }
}
