import { useEffect, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { Link } from 'Components/Buttons/Buttons';
import { CollapsibleModal } from 'Components/Modal/CollapsibleModal/CollapsibleModal';
import Placeholder from 'Components/Placeholder/Placeholder';
import Text from 'Components/Text/Text';
import { COMPLIANCE_REQUIREMENTS, DETAILS, REQUIREMENTS } from 'Config/Paths';
import { ComplianceDetails, FFIECCATMaturityLevel, MonthNumber } from 'Models/Dashboards';

import styles from './ComplianceRequirementDisplayModal.module.css';

export enum ComplianceModalType {
    MET = 'met',
    NOT_ASSESSED = 'not assessed',
    UNMET = 'unmet',
    NOT_MAPPED = 'not mapped',
}

export interface ComplianceRequirementDisplayModalProps {
    regulationInformation: ComplianceRequirementDisplayInformation;
    hideModal: () => void;
    api: DashboardApi;
}

export interface ComplianceRequirementDisplayInformation {
    regulationName: string;
    regulationTypeSelected: ComplianceModalType;

    /**
     * The timeframe for which to load a snapshot of compliance details. If not provided, current details will be loaded.
     */
    timeframe?: { month: MonthNumber; year: number };

    /**
     * The target maturity for which to load a snapshot of compliance details. Applies only to FFIEC CAT.
     */
    targetMaturity?: FFIECCATMaturityLevel;
}

export const ComplianceRequirementDisplayModal = (props: ComplianceRequirementDisplayModalProps): JSX.Element => {
    const [complianceHistoryDetails, setComplianceHistoryDetails] = useState<ComplianceDetails>();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        const getComplianceHistoryDetails = async () => {
            try {
                if (props.regulationInformation.timeframe) {
                    if (props.regulationInformation.targetMaturity) {
                        const historyDetails = await props.api.getComplianceHistoryDetailsFFIECCAT(props.regulationInformation.regulationName, props.regulationInformation.timeframe.month, props.regulationInformation.timeframe.year, props.regulationInformation.targetMaturity);
                        setComplianceHistoryDetails(historyDetails.data.details);
                    } else {
                        const historyDetails = await props.api.getComplianceHistoryDetails(props.regulationInformation.regulationName, props.regulationInformation.timeframe.month, props.regulationInformation.timeframe.year);
                        setComplianceHistoryDetails(historyDetails.data.details);
                    }
                } else {
                    const historyDetails = await props.api.getCurrentComplianceDetails(props.regulationInformation.regulationName);
                    setComplianceHistoryDetails(historyDetails.data);
                }
            } catch (err) {
                setErrorMessage(err.message);
            }
        };
        getComplianceHistoryDetails();
    }, [props.api, props.regulationInformation]);

    const createListing = (requirements: string[]) => {
        return requirements.sort((requirementA, requirementB) => requirementA.localeCompare(requirementB, undefined, { numeric: true, sensitivity: 'base' })).map((requirement) => <Link openInNewTab key={`${props.regulationInformation.regulationName} ${requirement}`} size="sm" to={`/${COMPLIANCE_REQUIREMENTS}/${props.regulationInformation.regulationName}/${REQUIREMENTS}/${requirement}/${DETAILS}`}>{`${props.regulationInformation.regulationName} ${requirement}`}</Link>);
    };

    const createRegulationListing = (details: ComplianceDetails) => {
        if (props.regulationInformation.regulationTypeSelected === ComplianceModalType.UNMET) {
            return createListing(details.not_met);
        } else if (props.regulationInformation.regulationTypeSelected === ComplianceModalType.MET) {
            return createListing(details.met);
        } else if (props.regulationInformation.regulationTypeSelected === ComplianceModalType.NOT_ASSESSED) {
            return createListing(details.awaiting_assessments);
        } else {
            return createListing(details.not_mapped);
        }
    };

    const complianceHistoryDetailsListing = () => {
        if (errorMessage) {
            return [<Text key="error">{errorMessage}</Text>];
        } else if (complianceHistoryDetails) {
            return createRegulationListing(complianceHistoryDetails);
        } else
            return [
                <div className={styles.placeholder} key="placeholder">
                    <Placeholder />
                </div>,
            ];
    };

    return <CollapsibleModal displayItems={complianceHistoryDetailsListing()} headerText={{ text: 'Compliance Requirements', secondaryText: `${props.regulationInformation.regulationName} | ${props.regulationInformation.regulationTypeSelected.toUpperCase()}` }} hideModal={props.hideModal} />;
};
