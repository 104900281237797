import { Link, LinkSize } from 'Components/Buttons/Buttons';
import Text, { TextVariant } from 'Components/Text/Text';
import { validateUrl } from 'Helpers/InputValidation';
import { ellipsize } from 'Helpers/StringUtils';

export interface UserDefinedHyperlinkProps {
    /**
     * The (optional) reference input by the user.
     */
    reference?: string;

    /**
     * The size of the `Link` to render, if the reference is a valid URL.
     * 'sm' by default.
     */
    linkSize?: LinkSize;

    /**
     * The `Text` variant that will be rendered if the reference is undefined or is not a URL.
     */
    fallbackTextVariant?: TextVariant;
}

/**
 * Renders a "reference" defined by the user. A reference may be a URL, or it may be any other string (a ticket number, an ID, etc.).
 * Use this component for literal "reference" fields, or for "website" fields.
 *
 * If the reference is a URL, it will be rendered as a hyperlink that opens in a new tab. If the reference is not a URL, it will be rendered as plain text. If the reference is undefined, it will be rendered as a plaintext "-".
 */
export const UserDefinedReference = (props: UserDefinedHyperlinkProps) => {
    const displayAsUrl = props.reference !== undefined && validateUrl(props.reference).valid;
    const displayText = props.reference ?? '-';
    const truncatedDisplayText = ellipsize(displayText);

    return displayAsUrl ? (
        <Link openInNewTab size={props.linkSize ?? 'sm'} to={props.reference!}>
            {truncatedDisplayText}
        </Link>
    ) : (
        <Text variant={props.fallbackTextVariant}>{truncatedDisplayText}</Text>
    );
};
