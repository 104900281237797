import { cloneDeep, isUndefined } from 'lodash-es';
import { Component } from 'react';

import Placeholder from 'Components/Placeholder/Placeholder';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import Text from 'Components/Text/Text';
import { undefinedComparator } from 'Helpers/Compare';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { ResidualRiskFilterOptionTypes, RiskRatingFilterOptionTypes, ServiceListingSortFilterOptions, ServiceSummary, VendorResponse } from 'Models/TPRM';
import { Filter, Filters, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './ServiceListing.module.css';
import ServiceListingBodyToolbar, { ServiceListingBodyToolbarProps } from './ServiceListingBodyToolbar/ServiceListingBodyToolbar';
import ServiceListingGridView, { ServiceListingGridViewProps } from './ServiceListingGridView/ServiceListingGridView';
import ServiceListingTableView, { ServiceListingTableViewProps } from './ServiceListingTableView/ServiceListingTableView';

export interface ServiceListingProps {
    vendors: VendorResponse[];
    services: ServiceDisplayData[];
    users: UserResponse[];
    defaultVendorByWhichToFilter?: VendorResponse;
    selectedDeleteService: (service: ServiceDisplayData) => void;
    selectedModifyService: (service: ServiceDisplayData) => void;
    selectedServiceAssessmentDueDateService: (service: ServiceDisplayData) => void;
}

interface ServiceListingState {
    activeServiceListingView: ServiceListingView;
    vendors: VendorResponse[];
    selectedFilterOptions: Filters;
    defaultSelectedOptions?: GroupOptionType[];
    currentSort: string;
    currentSortDirection: SortDirection;
}

export interface ServiceDisplayData {
    vendorName: string;
    vendorId: string;
    serviceName: string;
    serviceID: string;
    riskRating: number;
    vendorManagerId: string;
    dateCreated: string;
    assessmentDueDate?: string;
    commonAssessmentChildren: ServiceSummary[];
    residualRisk: number;
    [key: string]: any;
}

export enum ServiceListingView {
    LIST = 'list',
    GRID = 'grid',
}

export default class ServiceListing extends Component<ServiceListingProps, ServiceListingState> {
    constructor(props: ServiceListingProps) {
        super(props);

        let selectedFilterOptions: Filters = {};

        if (this.props.defaultVendorByWhichToFilter) {
            selectedFilterOptions = {
                vendorId: {
                    key: 'vendorId',
                    value: [this.props.defaultVendorByWhichToFilter.id],
                },
            };
        }

        this.state = {
            activeServiceListingView: ServiceListingView.GRID,
            vendors: this.props.vendors,
            selectedFilterOptions: selectedFilterOptions,
            currentSort: ServiceListingSortFilterOptions.SERVICE,
            currentSortDirection: SortDirection.ASC,
        };
    }

    createFilterOptions = (vendors: VendorResponse[]): GroupedOptions[] => {
        const vendorOptions: GroupOptionType[] = [];
        vendors.forEach((vendor) => {
            vendorOptions.push({
                groupId: ServiceListingSortFilterOptions.VENDOR_ID,
                label: vendor.name,
                value: vendor.id,
            });
        });
        const filterOptions: GroupedOptions[] = [
            {
                label: 'Vendors',
                options: vendorOptions,
            },
            {
                label: 'Service Risk Rating',
                options: RiskRatingFilterOptionTypes,
            },
            {
                label: 'Service Residual Risk',
                options: ResidualRiskFilterOptionTypes,
            },
            {
                label: 'Assessment Due Date',
                options: [
                    {
                        groupId: ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE,
                        label: 'Set',
                        value: 1,
                    },
                    {
                        groupId: ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE,
                        label: 'Not Set',
                        value: 0,
                    },
                ],
            },
        ];
        return filterOptions;
    };

    getDefaultSelectedOptions = (): GroupOptionType[] | undefined => {
        if (this.props.defaultVendorByWhichToFilter) {
            return [
                {
                    groupId: ServiceListingSortFilterOptions.VENDOR_ID,
                    label: this.props.defaultVendorByWhichToFilter.name,
                    value: this.props.defaultVendorByWhichToFilter.id,
                },
            ];
        }

        return undefined;
    };

    filterAndSortVendorServices = (): ServiceDisplayData[] => {
        let serviceList = [...this.props.services];

        if (Object.keys(this.state.selectedFilterOptions).length > 0) {
            for (const filter of Object.values(this.state.selectedFilterOptions)) {
                serviceList = serviceList.filter((service) => {
                    if (filter.key === ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE) {
                        // Filters can only be numbers or strings. 1 is Set 0 is Not Set
                        return (filter.value.includes(1) && !isUndefined(service.assessmentDueDate)) || (filter.value.includes(0) && isUndefined(service.assessmentDueDate));
                    }
                    return filter.value.includes(service[filter.key]);
                });
            }
        }

        if (!this.state.currentSort) {
            return serviceList;
        }

        return serviceList.sort((serviceA, serviceB) => {
            let sortResult = 0;

            switch (this.state.currentSort) {
                case ServiceListingSortFilterOptions.RISKRATING:
                case ServiceListingSortFilterOptions.RESIDUAL_RISK:
                    const riskA = serviceA[this.state.currentSort];
                    const riskB = serviceB[this.state.currentSort];
                    if (riskA === riskB) {
                        sortResult = 0;
                    } else if (riskA > riskB) {
                        sortResult = 1;
                    } else {
                        sortResult = -1;
                    }
                    break;
                case ServiceListingSortFilterOptions.VENDOR_SERVICE_MANAGER_USER_ID:
                    sortResult = compareUsersBySubjectForSorting(serviceA.vendorManagerId, serviceB.vendorManagerId, this.props.users);
                    break;
                case ServiceListingSortFilterOptions.DATE_CREATED:
                    sortResult = serviceA.dateCreated < serviceB.dateCreated ? 1 : -1;
                    break;
                case ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE:
                    sortResult = undefinedComparator(serviceA.assessmentDueDate, serviceB.assessmentDueDate, (assessmentDueDateA, assessmentDueDateB) => (assessmentDueDateA > assessmentDueDateB ? 1 : -1));
                    break;
                default:
                    sortResult = serviceA[this.state.currentSort].localeCompare(serviceB[this.state.currentSort]);
                    break;
            }

            return this.state.currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    setCurrentFilterSelection = (updatedFilter: Filter | Filter[]): void => {
        const currentFilterList = cloneDeep(this.state.selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    currentFilterList[filter.key] = filter;
                } else {
                    delete currentFilterList[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                currentFilterList[updatedFilter.key] = updatedFilter;
            } else {
                delete currentFilterList[updatedFilter.key];
            }
        }

        this.setState({ selectedFilterOptions: currentFilterList });
    };

    setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        this.setState({ currentSort: newSort as ServiceListingSortFilterOptions, currentSortDirection: newSortDirection });
    };

    switchServiceListingView = (activeServiceListingView: ServiceListingView): void => {
        this.setState({ activeServiceListingView: activeServiceListingView });
    };

    render(): JSX.Element {
        if (this.props.vendors.length === 0) {
            return (
                <div className={styles.zeroStateContainer}>
                    <Text>You cannot create a vendor service until you have created a vendor. To do so, select "Manage Vendors" from the main menu on the left.</Text>
                </div>
            );
        } else if (this.props.services.length === 0) {
            return (
                <div className={styles.zeroStateContainer}>
                    <Text>You have not created any vendor services. To do so, select "CREATE NEW SERVICE" in the top-right.</Text>
                </div>
            );
        } else if (this.props.services) {
            const services = this.filterAndSortVendorServices();

            const serviceListingGridViewProps: ServiceListingGridViewProps = {
                services: services,
                selectedDeleteService: this.props.selectedDeleteService,
                selectedModifyService: this.props.selectedModifyService,
                selectedServiceAssessmentDueDateService: this.props.selectedServiceAssessmentDueDateService,
                users: this.props.users,
            };
            const serviceListingTableViewProps: ServiceListingTableViewProps = {
                serviceRows: services,
                applySorting: this.setCurrentSort,
                currentSort: this.state.currentSort,
                currentSortDirection: this.state.currentSortDirection,
                selectedDeleteService: this.props.selectedDeleteService,
                selectedModifyService: this.props.selectedModifyService,
                selectedServiceAssessmentDueDateService: this.props.selectedServiceAssessmentDueDateService,
                users: this.props.users,
            };
            const serviceListingBodyToolbarProps: ServiceListingBodyToolbarProps = {
                activeServiceListingView: this.state.activeServiceListingView,
                switchListingView: this.switchServiceListingView,
                filterOptions: this.createFilterOptions(this.props.vendors),
                defaultSelectedOptions: this.getDefaultSelectedOptions(),
                vendorFilter: this.setCurrentFilterSelection,
                sortCardsBy: this.setCurrentSort,
                currentSort: this.state.currentSort,
                users: this.props.users,
            };
            const vendorListingView = this.state.activeServiceListingView;

            return (
                <>
                    <ServiceListingBodyToolbar {...serviceListingBodyToolbarProps} />
                    {{ list: <ServiceListingTableView {...serviceListingTableViewProps} />, grid: <ServiceListingGridView {...serviceListingGridViewProps} /> }[vendorListingView]}
                </>
            );
        } else {
            return <Placeholder />;
        }
    }
}
