import { Component, Fragment } from 'react';

import Text from 'Components/Text/Text';

import styles from './Legend.module.css';

export interface LegendProps {
    legendItems: LegendItem[];
}

export interface LegendItem {
    icon: string;
    text: string;
}

export class Legend extends Component<LegendProps> {
    render(): JSX.Element {
        return (
            <Fragment>
                <Text variant="Header2">Legend</Text>
                <div className={styles.itemsContainer}>
                    {this.props.legendItems.map((legendItem) => (
                        <div key={legendItem.icon} className={styles.itemContainer}>
                            <img src={legendItem.icon} width={20} height={20} alt={legendItem.text} />
                            <div className={styles.textContainer}>
                                <Text>{legendItem.text}</Text>
                            </div>
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    }
}
