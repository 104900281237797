import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

import { Badge } from 'Components/Badge/Badge';
import Text from 'Components/Text/Text';

import styles from './Button.module.css';

/**
 * Used to add a _type property to DropdownItem for conditional logic to determine what should happen when a drop-down item is clicked.
 * @member HYPERLINK The drop-down item is a hyperlink that should navigate to a new page when clicked.
 * @member NOP The drop-down item should do nothing (aka "no operation") when clicked.
 * @member ONCLICK The drop-down item is not a hyperlink and should perform some non-navigation function when clicked.
 */
export enum DropdownItemClickType {
    HYPERLINK = 'Hyperlink',
    NOP = 'NOP',
    ONCLICK = 'onClick',
}

/**
 * The base properties a DropdownItem can have.
 * @param text The text that will appear in the drop-down menu for a particular item.
 * @param icon An optional icon that can be shown next to the text for a particular item in a drop-down menu.
 */
interface DropdownItemBase {
    text: string;
    icon?: IconProp;
}

/**
 * Use this if clicking the item in a drop-down menu should navigate to a new page because it maintains the browser's "right-click -> open in new tab" functionality.
 * @param href The URL that the browser will navigate to when the item is clicked.
 */
interface DropdownItemWithHyperlink extends DropdownItemBase {
    _type: DropdownItemClickType.HYPERLINK;
    href: string;
    onClick?: never;
}

/**
 * Use this if clicking the item in a drop-down menu should do nothing (aka "no operation").
 */
interface DropdownItemWithNOP extends DropdownItemBase {
    _type: DropdownItemClickType.NOP;
    href?: never;
    onClick?: never;
}

/**
 * Use this if clicking the item in a drop-down menu should perform some non-navigation function.
 * Do NOT use this for page navigation as it will not work for "right-click -> open in new tab" functionality.
 * @param onClick The function that will be called when the item is clicked.
 */
interface DropdownItemWithOnClick extends DropdownItemBase {
    _type: DropdownItemClickType.ONCLICK;
    href?: never;
    onClick: () => void;
}

/** This type union ensures every DropdownItem can either call an onClick function XOR it can navigate to some URL. */
export type DropdownItem = DropdownItemWithHyperlink | DropdownItemWithNOP | DropdownItemWithOnClick;

export interface PrimaryAndSecondaryDropdownButtonProps {
    dropdownItems: DropdownItem[];
    children?: React.ReactNode;
}

/**
 * Render each item in a drop-down menu. It can handle items that perform an onClick function or that navigate to some other URL.
 * This distinction is important because using onClick to call React's useNavigate hook does not work for "right-click -> open in new tab" functionality.
 */
export const menuItem = (dropdownItem: DropdownItem, index: number): JSX.Element => {
    if (dropdownItem._type === DropdownItemClickType.HYPERLINK) {
        return (
            <Dropdown.Item as={Link} className={styles.dropdownItem} key={index} to={dropdownItem.href}>
                {dropdownItem.icon && <FontAwesomeIcon icon={dropdownItem.icon} className={styles.dropdownItemIcon} />}
                {dropdownItem.text}
            </Dropdown.Item>
        );
    } else if (dropdownItem._type === DropdownItemClickType.NOP) {
        return (
            <Dropdown.Item key={index} className={styles.dropdownItem}>
                {dropdownItem.icon && <FontAwesomeIcon icon={dropdownItem.icon} className={styles.dropdownItemIcon} />}
                {dropdownItem.text}
            </Dropdown.Item>
        );
    } else {
        return (
            <Dropdown.Item onClick={dropdownItem.onClick} key={index} className={styles.dropdownItem}>
                {dropdownItem.icon && <FontAwesomeIcon icon={dropdownItem.icon} className={styles.dropdownItemIcon} />}
                {dropdownItem.text}
            </Dropdown.Item>
        );
    }
};

/**
 * Use this for the scenario where a drop-down menu is the primary action on a page.
 */
export const PrimaryDropdownButton = (props: PrimaryAndSecondaryDropdownButtonProps): JSX.Element => {
    return (
        <Dropdown drop="down" align="end" className={styles.primaryDropdown}>
            <Dropdown.Toggle>
                <FontAwesomeIcon icon={faCaretDown} className={styles.icon} />
                {props.children}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownButtonMenuExtraWidth}>{props.dropdownItems.map(menuItem)}</Dropdown.Menu>
        </Dropdown>
    );
};

/**
 * Use this for the scenario where one or more drop-down menus are secondary actions on a page.
 */
export const SecondaryDropdownButton = (props: PrimaryAndSecondaryDropdownButtonProps): JSX.Element => {
    return (
        <Dropdown drop="down" align="end" className={styles.secondaryDropdown}>
            <Dropdown.Toggle>
                <FontAwesomeIcon icon={faCaretDown} className={styles.icon} />
                {props.children}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownButtonMenuExtraWidth}>{props.dropdownItems.map(menuItem)}</Dropdown.Menu>
        </Dropdown>
    );
};

/**
 * @param badgeColor Supplemental badge over the icon showing that there are items in the drop-down menu, or the number of items in the drop-down menu, depending on the badgeVariant.
 * @param badgeVariant 'dot' is a small dot that appears on the badge to indicate there are items in the drop-down menu; 'standard' is larger and includes a number that appears on the badge representing the number of items in the drop-down menu.
 * @param dropdownItems The items that will appear when the drop-down menu is opened.
 * @param emptyText The text that will appear when there are no items in the drop-down menu, such as "No Alerts". This is useful since it may not be intuitive what an icon drop-down menu is, especially if it has no items within it.
 * @param IconDefinition The icon that opens the drop-down menu when clicked.
 */
export interface IconDropdownButtonProps {
    badgeColor: 'blue' | 'red';
    badgeVariant: 'dot' | 'standard';
    dropdownItems: DropdownItem[];
    emptyText: string;
    icon: IconDefinition;
}

/**
 * Use this for the scenario where an icon without text opens a drop-down menu. Includes a badge showing either the existence of items within the drop-down menu, or the number of items in the drop-down menu.
 * Example: The Alerts and Notifications menus in the header.
 */
export const IconDropdownButton = (props: IconDropdownButtonProps): JSX.Element => {
    return (
        <div id={styles.iconAndTextDropdownButtonContainer}>
            <Dropdown>
                <Dropdown.Toggle className={styles.iconAndTextDropdownButtonToggle} id="iconAndTextDropdownButtonContainer">
                    <Badge amount={props.dropdownItems.length} color={props.badgeColor} variant={props.badgeVariant}>
                        <FontAwesomeIcon className={styles.iconAndTextDropdownButtonColor} icon={props.icon} size="xl" />
                    </Badge>
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropdownButtonMenuExtraWidth}>{props.dropdownItems.length > 0 ? props.dropdownItems.map(menuItem) : menuItem({ _type: DropdownItemClickType.NOP, text: props.emptyText }, 0)}</Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

/**
 * @param dropdownItems The items that will appear when the drop-down menu is opened.
 * @param text The text that is clickable to open/close the drop-down menu.
 */
export interface TextDropdownButtonProps {
    dropdownItems: DropdownItem[];
    text: string;
}

/**
 * Use this for the scenario where only text (without being part of a graphical button) opens a drop-down menu.
 * Example: The user's profile menu in the header.
 */
export const TextDropdownButton = (props: TextDropdownButtonProps): JSX.Element => {
    return (
        <div id={styles.iconAndTextDropdownButtonContainer}>
            <Dropdown>
                <Dropdown.Toggle className={styles.iconAndTextDropdownButtonToggle}>
                    <div className={styles.textDropdownButtonArrowSpacing}>
                        <Text variant="Text2" noStyles>
                            {props.text}
                        </Text>
                    </div>
                    <FontAwesomeIcon className={styles.iconAndTextDropdownButtonColor} icon={faCaretDown} />
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.dropdownButtonMenuDefaultWidth}>{props.dropdownItems.map(menuItem)}</Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
