import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import PageCell from 'Components/Containers/PageCell/PageCell';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { SaveControlFormFields, SaveControlFromFieldsProps, useSaveControl } from 'Components/OperationalControls/SaveControl/SaveControl';
import Text from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { getFrameworkGroupControlParts } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { OperationalControl, stringAsControlTypeNatureValue, stringAsControlTypeTimingValue } from 'Models/OperationalControls';

import styles from './ControlConfigurationTab.module.css';
import { ControlDeleted } from '../ControlSettings';

export interface ControlConfigurationTabProps {
    onControlSaved: () => void;
    controlsApi: ControlsApi;
    detailedControlResponse: OperationalControl;
    onControlDeleted: () => void;
}

export const ControlConfigurationTab = ({ controlIsDeleted = false, ...props }: ControlConfigurationTabProps & ControlDeleted) => {
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(props.detailedControlResponse);
    const { saveControlStatus, saveControlFormFieldsValues, error, saveControl, handleChange, handleSelectChange } = useSaveControl(
        controlFramework,
        controlGroupId,
        props.controlsApi,
        {
            controlName: props.detailedControlResponse.metadata.control_name,
            controlText: props.detailedControlResponse.metadata.control_text,
            controlTypeNature: stringAsControlTypeNatureValue(props.detailedControlResponse.metadata.control_type_nature),
            controlTypeTiming: stringAsControlTypeTimingValue(props.detailedControlResponse.metadata.control_type_timing),
        },
        controlId
    );
    const [shouldDisplayDeleteConfirmationModal, setShouldDisplayDeleteConfirmationModal] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await saveControl();
    };

    const saveControlFormFieldsProps: SaveControlFromFieldsProps = {
        saveControlFormFieldsValues: saveControlFormFieldsValues,
        handleChange: handleChange,
        handleSelectChange: handleSelectChange,
        disabled: controlIsDeleted,
    };

    const { onControlSaved } = props;

    useEffect(() => {
        switch (saveControlStatus) {
            case 'saved':
                setSuccessMessage('Control configuration updated.');
                onControlSaved();
                break;
            case 'error':
                setFailureMessage(error?.message);
                break;
            default:
                setSuccessMessage(undefined);
                setFailureMessage(undefined);
        }
    }, [error, onControlSaved, saveControlStatus]);

    const deleteCustomControl = async () => {
        await props.controlsApi.deleteControl(controlFramework, controlGroupId, controlId);
        props.onControlDeleted();
        return 'Control deleted.';
    };

    const displayDeleteConfirmationModal = () => {
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        setShouldDisplayDeleteConfirmationModal(true);
    };

    return (
        <>
            {successMessage && <LinkButtonToast variant="success" clearToast={() => setSuccessMessage(undefined)} linkButtonText={'Return to control'} linkButtonTo={getFrameworkGroupControlURL(props.detailedControlResponse.identifier)} text={successMessage} />}
            {failureMessage && <TextToast variant="failure" clearToast={() => setFailureMessage(undefined)} text={failureMessage} />}
            {shouldDisplayDeleteConfirmationModal && (
                <ConfirmationModal operationType="delete" headerText="Delete Custom Control" areYouSureText={`Are you sure you want to delete "${props.detailedControlResponse.metadata.control_name}"?`} performOperation={deleteCustomControl} hideModal={() => setShouldDisplayDeleteConfirmationModal(false)}>
                    <ul className={styles.controlDeletionImpacts}>
                        <li>
                            <Text>All evidence associated with the control will be deleted.</Text>
                        </li>
                        <li>
                            <Text>The control assessment history will be deleted.</Text>
                        </li>
                        <li>
                            <Text>Limits and alerts for the control will be deleted.</Text>
                        </li>
                        <li>
                            <Text>The assessment configuration for the control will be deleted and notifications will no longer be sent.</Text>
                        </li>
                        <li>
                            <Text>The control will be unmapped from:</Text>
                        </li>
                        <ul>
                            <li>
                                <Text>Policies, standards, and procedures.</Text>
                            </li>
                            <li>
                                <Text>Compliance requirements.</Text>
                            </li>
                            <li>
                                <Text>Issues and exceptions.</Text>
                            </li>
                            <li>
                                <Text>Integrations and metrics.</Text>
                            </li>
                            <li>
                                <Text>Risks.</Text>
                            </li>
                        </ul>
                    </ul>
                </ConfirmationModal>
            )}
            <PageCell>
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Configure Control" />
                    <SaveControlFormFields {...saveControlFormFieldsProps} />
                    <div className={styles.buttonContainer}>
                        <Button variant="submit" disabled={controlIsDeleted} isLoading={saveControlStatus === 'saving'} loadingText="Saving...">
                            SAVE
                        </Button>

                        <Button variant="danger" disabled={controlIsDeleted} onClick={displayDeleteConfirmationModal} fontAwesomeImage={faTrash}>
                            DELETE
                        </Button>
                    </div>
                </Form>
            </PageCell>
        </>
    );
};
