import { Link } from 'Components/Buttons/Buttons';
import { CollapsibleModal } from 'Components/Modal/CollapsibleModal/CollapsibleModal';
import Text from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getExceptionDetailsUrl } from 'Helpers/URLBuilder/URLBuilder';
import { DashboardType } from 'Models/Dashboards';
import { ClosedException, ExceptionResponse, VendorExceptionResponse } from 'Models/Exceptions';
import { VendorIdToNameMap, VendorResponse } from 'Models/TPRM';

import styles from './HistoricalExceptionsDisplayModal.module.css';

export enum ClosedExceptionModalDisplayType {
    OPEN,
    CLOSED,
    TOTAL,
}

export interface ClosedExceptionsDisplayModalProps {
    hideModal: () => void;
    selectedException: ExceptionResponse[];
    date: string;
    modalType: ClosedExceptionModalDisplayType;
    dashboardType: DashboardType;
    vendors?: VendorResponse[];
}

// TODO: rename this. It's not just for closed exceptions.
export const ClosedExceptionsDisplayModal = (props: ClosedExceptionsDisplayModalProps): JSX.Element => {
    const vendorIdToNameMap: VendorIdToNameMap = new Map(props.vendors?.map((vendor: VendorResponse) => [vendor.id, vendor.name]));

    const displayExceptionDate = (exception: ExceptionResponse) => {
        switch (props.modalType) {
            case ClosedExceptionModalDisplayType.CLOSED:
                return iso8601ToUsDateShort((exception as ClosedException).closed_timestamp);
            case ClosedExceptionModalDisplayType.OPEN:
                return iso8601ToUsDateShort(exception.created_timestamp);
            case ClosedExceptionModalDisplayType.TOTAL:
                return iso8601ToUsDateShort(exception.created_timestamp);
        }
    };

    const displayHeaderText = () => {
        switch (props.modalType) {
            case ClosedExceptionModalDisplayType.CLOSED:
                return 'Closed Exceptions';
            case ClosedExceptionModalDisplayType.OPEN:
                return 'Open Exceptions';
            case ClosedExceptionModalDisplayType.TOTAL:
                return 'Total Exceptions';
        }
    };

    const sortExceptionsByDateType = (exceptionA: ExceptionResponse, exceptionB: ExceptionResponse): number => {
        if (props.modalType === ClosedExceptionModalDisplayType.CLOSED) {
            if ((exceptionA as ClosedException).closed_timestamp > (exceptionB as ClosedException).closed_timestamp) {
                return 1;
            } else if ((exceptionA as ClosedException).closed_timestamp < (exceptionB as ClosedException).closed_timestamp) {
                return -1;
            } else {
                return exceptionA.title.localeCompare(exceptionB.title, undefined, { numeric: true, sensitivity: 'base' });
            }
        } else {
            if (exceptionA.created_timestamp > exceptionB.created_timestamp) {
                return 1;
            } else if (exceptionA.created_timestamp < exceptionB.created_timestamp) {
                return -1;
            } else {
                return exceptionA.title.localeCompare(exceptionB.title, undefined, { numeric: true, sensitivity: 'base' });
            }
        }
    };

    const exceptionListing = props.selectedException.sort(sortExceptionsByDateType).map((exception) => (
        <div className={styles.detailsContainer} key={exception.created_timestamp}>
            {props.dashboardType === DashboardType.THIRD_PARTY && vendorIdToNameMap.size > 0 && (
                <div className={styles.detailsVendorName}>
                    <Text color="darkGray" noStyles={true}>
                        {vendorIdToNameMap.get((exception as VendorExceptionResponse).impacted_vendor)}
                    </Text>
                </div>
            )}
            <Link to={getExceptionDetailsUrl(exception.id, exception.type)} size="sm" openInNewTab>
                {exception.title}
            </Link>
            <div className={styles.detailsDateContainer}>
                <Text color="darkGray" noStyles={true}>
                    {displayExceptionDate(exception)}
                </Text>
            </div>
        </div>
    ));

    return <CollapsibleModal headerText={{ text: displayHeaderText(), secondaryText: `${props.date}` }} hideModal={props.hideModal} displayItems={exceptionListing} />;
};
