/*
	AWSIssuesApi.ts -- AWS-specific interface to making Issues related network calls.
*/
import { HISTORY, ISSUES } from 'Config/Paths';
import { ControlIssueResponse, CreateIssueRequest, IssueHistoryResponse, IssueResponse, IssuesExceptionsModule, UpdateIssueRequest, VendorIssueResponse } from 'Models/Issues';
import { ResponseModel } from 'Models/ResponseModel';
import { restApiDelete, restApiGet, restApiPut } from 'RestApiConnector';

import { IssuesApi } from './IssuesApi';

export class AWSIssuesApi implements IssuesApi {
    getAllIssues = (): Promise<ResponseModel<IssueResponse[]>> => {
        return restApiGet(`/${ISSUES}`, {});
    };
    getAllControlIssues = (): Promise<ResponseModel<ControlIssueResponse[]>> => {
        return restApiGet(`/${ISSUES}`, { issue_type: IssuesExceptionsModule.CONTROLS });
    };
    getAllVendorIssues = (): Promise<ResponseModel<VendorIssueResponse[]>> => {
        return restApiGet(`/${ISSUES}`, { issue_type: IssuesExceptionsModule.TPRM });
    };
    getIssuesByControl = (controlIdentifier: string): Promise<ResponseModel<ControlIssueResponse[]>> => {
        return restApiGet(`/${ISSUES}`, { control: controlIdentifier });
    };
    getIssue = (id: string): Promise<ResponseModel<IssueResponse>> => {
        return restApiGet(`/${ISSUES}/${id}`, {});
    };
    getIssueHistory = (issueId: string): Promise<ResponseModel<IssueHistoryResponse[]>> => {
        return restApiGet(`/${ISSUES}/${HISTORY}/${issueId}`, {});
    };
    createIssue = (createIssueRequest: CreateIssueRequest): Promise<ResponseModel<IssueResponse>> => {
        return restApiPut(`/${ISSUES}`, createIssueRequest);
    };
    updateIssue = (id: string, updateIssueRequest: UpdateIssueRequest): Promise<ResponseModel<IssueResponse>> => {
        return restApiPut(`/${ISSUES}/${id}`, updateIssueRequest);
    };
    deleteIssue = (id: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${ISSUES}/${id}`);
    };
}
